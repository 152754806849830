@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.common-banner-reservation {

  z-index: 11;
  position: fixed;
  width:396px;
  height:120px;
  background-color: #ffeeee;
  border:2px solid #d32d26;
  border-radius: 10px;
  bottom:30px;
  right:30px;
  transition:opacity .3s ease, transform .3s ease;

  @include sp {
    width:700/750*100+vw;
    height:190/750*100+vw;
    background-color: #333333;
    border:none;
    bottom:88px;
    right:25/750*100+vw;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 13%;
      margin-left: -15px;
      border: 12px solid transparent;
      border-top: 20px solid #333;
    }
  }

  &.hide {
    pointer-events: none;
    opacity: 0;
    transform:translateY(10px);
  }

  a.common-banner-reservation-close-button {
    position: absolute;
    top: -18px;
    right: -10px;
    figure {
      svg {
        @include sp {
          .stroke-black-sp {
            stroke:#333333;
          }
          .fill-black-sp {
            fill:#333333;
          }
        }
      }
    }
  }

  a.common-banner-reservation-anchor {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity .3s;
    &:hover {
      opacity: .8;
    }

    .common-banner-reservation-box {

      display: flex;
      justify-content: center;
      align-items: center;

      .common-banner-reservation-text {
        p.sub-ttl {
          font-size:13px;
          color:#c82a23;
          @include sp {
            font-size:22/750*100+vw;
            color:#fefefe;
          }
        }

        p.ttl {
          font-weight: 600;
          letter-spacing: .07em;
          margin-top:2px;
          font-size:24px;
          color:#c82a23;
          display: inline-block;

          line-height: 1.3em;

          .ttl-body {
            border-bottom: 2px solid #c82a23;
            display: inline-block;
          }

          .common-banner-reservation-icon {
            @include sp {
              display:none;
            }
            margin-right: 2px;
            margin-left: 8px;
            svg {
              vertical-align: bottom;
            }
          }


          @include sp {
            margin-top:1vw;
            font-size:42/750*100+vw;
            //font-weight:normal;
            color:#fefefe;
            letter-spacing: .15em;
            margin-left: 5vw;

            .ttl-body {
              border-bottom: 2px solid #fff;
              display: inline-block;
            }
          }
        }
      }
    }

    .common-banner-reservation-footer {
      margin-top:10px;
      p {
        font-size:10px;
        @include sp {
          font-size:20/750*100+vw;
          color:#fefefe;
        }
      }
    }

  }
}
