@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-text13 {
  @include fz(13);
  letter-spacing: .06em;
  line-height: 1.69;
  @include pc {
    font-size: 13px;
  }
}
.c-text16 {
  @include fz(16);
  letter-spacing: .06em;
  line-height: 1.56;
  @include pc {
    font-size: 16px;
  }
}
.c-text18 {
  @include fz(18);
  letter-spacing: .06em;
  line-height: 1.56;
  @include pc {
    font-size: 18px;
  }
}
.c-text1316 {
  @include fz(13);
  letter-spacing: .06em;
  line-height: 1.8;
  @include pc {
    font-size: 16px;
  }
}
.c-text1416 {
  @include fz(14);
  letter-spacing: .06em;
  line-height: 1.6;
  @include pc {
    font-size: 16px;
  }
}
.c-text1822 {
  @include fz(18);
  letter-spacing: .06em;
  line-height: 1.6;
  @include pc {
    font-size: 22px;
  }
}
