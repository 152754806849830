.p-select {
  @include font-noto_sans;
  font-size: 13px;
  line-height: 1.7;
  overflow-x: hidden;
  @include pc {
    font-size: 16px;
  }

  + .l-aboutMaestro {
    margin-top: 0;
    background-color: #eef3f6;
  }

.l-kv {
  &__title {
    padding-top: 40px;
    @include pc {
      padding-top: 62px;
    }

    small {
      display: block;
      font-size: 13px;
      @include pc {
        font-size: 32px;
      }
    }
  }

  &__img {
    display: none;
    @include pc {
      display: inline-block;
    }
  }
}

.l-main>.l-kv.-text.p-select-kv {
  padding-bottom: 20px;
  @include pc {
    padding-bottom: 70px;
  }
}

.p-select-inner {
  max-width: 1360px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  margin-bottom: 15px !important;
  @include font-noto_sans_light;
  @include pc {
    flex-direction: row;
    font-size: 32px;
    margin-bottom: 30px !important;
  }

  &.-col {
    flex-direction: column;
  }

  &.-left {
    span {
      text-align: center;
      @include pc {
        text-align: left;
      }
    }
  }

  .small {
    font-size: 13px;
    @include pc {
      font-size: 22px;
    }
  }

  &-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin: 0 0 10px 0;
    @include font-noto_sans_regular;
    font-size: 15px;
    color: #78b7dd;
    background-color: #fff;
    border: 1px solid #78b7dd;
    border-radius: 50%;
    @include pc {
      width: 125px;
      height: 125px;
      margin: 0 25px 0 0;
      font-size: 21px;
    }

    small {
      display: block;
      font-size: 7px;
      text-transform: uppercase;
      @include font-Montserrat;
      @include pc {
        font-size: 12px;
      }
    }

    &.-beige {
      font-size: 10px;
      color: #a89982;
      border: 1px solid #a89982;
      @include pc {
        font-size: 16px;
      }

      span {
        display: block;
        width: 100%;
        font-size: 10px;
        @include pc {
          display: inline;
          width: auto;
          font-size: 16px;
        }
      }
    }
  }
}

.subheading {
  text-align: left;
  font-size: 13px;
  margin: 15px 0 0 0;
  @include pc {
    font-size: 14px;
    margin: 25px 0 0 0;
    text-align: center;
  }
}

.btn {
  max-width: 285px;
  width: 100%;
  height: 48px;

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 48px;
    border: 1px solid #222222;
    text-decoration: none;
    transition: color 200ms, background-color 200ms;

    &:hover {
      background-color: #222;
      color: #fff;
    }
  }

  &.-red {
    a {
      color: #fff;
      background-color: #e70012;
      border: 1px solid #e70012;

      &:hover {
        background-color: #fff;
        color: #e70012;
      }
    }
  }
}

/*--------------------------------
.intro
---------------------------------*/

.intro {
  padding: 40px 0;
  @include pc {
    padding: 75px 0;
  }
}

/*--------------------------------
.quest
---------------------------------*/

.quest {
  padding: 40px 0;
  @include pc {
    padding: 50px 0 80px 0;
  }

  &.quest01 {
    background-color: #eef3f6;

    .check {
      &-item {
        &Inner {
          &::before,
          &::after {
            color: #78b7dd;
          }
        }
      }
    }
  }

  &.quest02 {
    background-color: #f2ebe3;

    .check {
      &-item {
        &Inner {
          &::before,
          &::after {
            color: #a89982;
          }
        }
      }
    }
  }

  .check {
    &-list {
      counter-reset: check-list;

      #check-item {
        &-01 {
          display: block;
        }

        &-c01 {
          display: block;
        }

        &-c32 {
          .yes,
          .no {
            font-size: 12px;
            letter-spacing: 0.1em;
            text-align: center;
            @include pc {
              letter-spacing: 0.15em;
              text-align: left;
            }
          }
        }
      } // #check-item
    } // &-list

    &-item {
      display: none;

      &.-open {
        display: block !important;
      } // &.-open

      background: #fff;
      border: 1px solid #c7c7c7;
      padding: 20px 20px 40px;
      position: relative;
      margin-bottom: 20px;
      @include pc {
        padding: 50px 40px;
      }

      &Inner {
        position: relative;
        padding: 90px 0 0 0;
        @include pc {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0;
        }

        &::before,
        &::after {
          position: absolute;
          top: 0;
          transform: translateY(0);
          @include pc {
            top: 50%;
            left: 0;
            color: #78b7dd;
            transform: translateY(-50%);
          }
        }

        &::before {
          content: "CHECK";
          display: block;
          left: 50%;
          color: $c-primary;
          font-size: 14px;
          text-align: center;
          padding-bottom: 50px;
          white-space: nowrap;
          transform: translateX(-50%);
          @include font-noto_sans_light;
          @include pc {
            left: 0;
            transform: translateY(-50%);
          }
        } // &::before

        &::after {
          counter-increment: check-list;
          content: counter(check-list);
          display: block;
          padding-top: 15px;
          color: $c-primary;
          font-size: 44px;
          text-align: center;
          width: 100%;
          @include font-Montserrat;
          @include pc {
            width: 50px;
          }
        } // &::after

        .left {
          margin-bottom: 20px;
          @include pc {
            width: calc(50% - 30px);
          }
          .detail-text {
            font-size: 13px;
            @include pc {
              font-size: 16px;
              padding-left: 80px;
            }

            small {
              display: block;
              font-size: 13px;
              @include pc {
                font-size: 14px;
              }
            }
          }
        } // .left

        .right {
          @include pc {
            width: calc(50% - 30px);
          }

          .button {
            display: flex;
            justify-content: space-between;
            flex: 1 0 calc(50% - 10px);
            margin-bottom: 20px;

            &-img {
              width: 42px;
              margin: 0 auto 20px auto;
              text-align: center;
              @include pc {
                width: 52px;
              }

              &.-bigger {
                width: 55px;
                @include pc {
                  width: 110px;
                }
              }

              img {
                width: 100%;
              }
            }

            .yes,
            .no {
              width: calc(50% - 10px);
              font-size: 13px;
              letter-spacing: 0.15em;

              &.-bigger {
                label {
                  height: 54px;
                }
              }

              input {
                display: none;
                &:checked+.yesLabel,
                &:checked+.noLabel {
                  background: #000;
                  color: #fff;
                }
              } // input

              label {
                height: 100%;
                padding: 16px 0;
                border-radius: 45px;
                border: 1px solid #000;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                @include pc {
                  cursor: pointer;
                  transition: all 0.2s;
                  &:hover {
                    background: #000;
                    color: #fff;
                  }
                }

                small.small {
                  display: none;
                  font-size: 9px;
                  @include pc {
                    display: block;
                    font-size: 11px;
                    text-align: left;
                  }
                }
              } // label
            } // .yes, .no
          } // .button

          .retry {
            font-size: 12px;
            text-align: right;
            text-decoration: none;
            position: absolute;
            right: 20px;
            border-bottom: 1px solid #000;
            @include font-noto_sans;
            @include pc {
              right: 40px;
              cursor: pointer;
            }
          } // .retry
        } // .right
      } // &Inner
    } // &-item
  } // .check

  &-result {
    display: none;
    position: relative;
    padding: 25px 20px;
    background-color: #fff;
    border: 1px solid #c7c7c7;
    @include pc {
      padding: 55px;
    }

    &.-open {
      display: block;
    }

    &-list {

    }

    &-item {
      display: none;

      &.-open {
        display: block;
      }
    }

    &-heading {
      @include font-noto_sans_light;
      font-size: 22px;
      text-align: center;
      margin: 0 0 25px 0;
      @include pc {
        font-size: 32px;
        margin: 0 0 50px 0;
      }

      small {
        display: block;
        font-size: 14px;
        color: #78b7dd;
        text-transform: uppercase;
        @include font-Montserrat;
        @include pc {
          font-size: 22px;
        }
      }

      &.-beige {
        small {
          color: #a89982;
        }
      }
    }

    &-top {
      padding: 0 0 25px 0;
      border-bottom: 1px dotted #cdcdcd;
      @include pc {
        padding: 0 0 50px 0;
      }
    }

    &-bottom {
      padding: 25px 0 0 0;
      border-bottom: 1px dotted #cdcdcd;
      @include pc {
        padding: 50px 0 0 0;
      }
    }

    &-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include pc {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &-col {
      max-width: 360px;
      width: 100%;
      padding: 0;
      @include pc {
        max-width: 100%;
        width: 50%;
        padding: 0 80px;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      @include font-noto_sans_light;
      font-size: 28px;
      margin: 0 0 15px 0;
      color: #007ac3;
      @include pc {
        font-size: 42px;
      }

      &-vert {
        display: block;
      }

      small {
        display: block;
        font-size: 21px;
        @include pc {
          font-size: 22px;
        }
      }

      &-icon {
        width: 28px;
        display: flex;
        align-items: center;
        margin: 0 0 0 15px;
      }
    }

    &-text {
      font-size: 13px;
      margin: 15px 0 0 0;
      @include pc {
        font-size: 16px;
      }

      &.-small {
        font-size: 13px;
      }
    }

    &-bold {
      font-size: 13px;
      margin: 25px 0 0 0;
      @include font-noto_sans_medium;
      @include pc {
        font-size: 16px;
        margin: 35px 0 0 0;
      }
    }

    &-face {
      padding: 0 40px 15px 40px;
      @include pc {
        padding: 0;
      }
    }

    &-palette {
      margin: 15px 0 0 0;
    }

    &-frames {
      display: flex;
      margin: 15px 0 0 0;
      @include pc {
        margin: 20px 0 0 0;
      }

      li {
        margin: 0 5px;
        text-align: center;
        font-size: 13px;
        @include pc {
          margin: 0 10px;
        }
      }

      img {
        display: block;
      }
    }

    &-glasses {
      padding: 0;
      @include pc {
        padding: 0 80px;
      }

      &-title {
        font-size: 18px;
        @include font-noto_sans_medium;
        text-align: center;
        @include pc {
          font-size: 22px;
        }
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 30px 0;
        @include pc {
          margin: 20px 0 60px 0;
        }
      }

      &-item {
        width: 50%;
        padding: 20px 5px 0 5px;
        @include pc {
          width: 33.333%;
          padding: 0 30px;
        }

        .btn {
          margin: 15px auto 0 auto;
          @include pc {
            max-width: 100%;
          }
        }
      }

      &-image {
        text-align: center;
      }
    }

    &-select {
      position: absolute;
      left: 50%;
      top: -24px;
      max-width: 420px;
      width: 100%;
      height: 48px;
      transform: translateX(-50%);

      &-text {
        max-width: 420px;
        width: 92%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 16px;
        background-color: #fff;
        z-index: 1;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        pointer-events: none;
      }

      &-icon {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 21px;
        width: 16px;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
      }

      span {
        position: absolute;
        left: 50%;
        top: -12px;
        display: inline-block;
        @include font-Montserrat;
        font-size: 16px;
        text-align: center;
        background-color: #fff;
        text-transform: uppercase;
        transform: translateX(-50%);
        z-index: 2;
      }

      select {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        padding: 0 50px;
        font-size: 16px;
        appearance: none;
        outline: none;
        background-color: #fff;
        border: 1px solid #000000;
        border-radius: 48px;
        cursor: pointer;
      }

      option {
        display: block;
        width: 100%;
        text-align: center;
      }
    }

    &-footer {
      padding: 30px 0 0 0;
      @include pc {
        padding: 60px 0 0 0;
      }

      &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include pc {
          flex-direction: row;
        }

        .btn {
          margin: 10px auto;
          @include pc {
            margin: 0 15px;
          }
        }
      }
    }
  }
}

/*--------------------------------
.recommend
---------------------------------*/

.recommend {
  padding: 40px 0;
  @include pc {
    padding: 80px 0;
  }

  &-result {
    display: block;
    margin: 75px 0 0 0;
    padding: 30px 20px;
    @include pc {
      padding: 35px 55px 55px 55px;
    }

    &-glasses-list {
      display: none;

      &.-open {
        display: flex;
      }
    }

    .quest-result-glasses-item {
      padding: 20px 5px 0 5px;
      @include pc {
        padding: 20px 30px 0 30px;
      }
    }
  }
}

/*--------------------------------
.cta
---------------------------------*/

.cta {
  padding: 40px 0;
  //background-color: #eef3f6;
  @include pc {
    padding: 80px 0;
  }

  .p-select-inner {
    max-width: 1080px;
  }

  &-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
    @include pc {
      align-items: flex-start;
      flex-direction: row;
      margin: 45px -30px 0 -30px;
    }

    .c-btn.-large.-blank a {
      font-size: 13px;
      padding: 25px 40px 25px 20px;
      @include pc {
        font-size: 18px;
        padding: 35px 40px 35px 20px;
      }
    }
  }

  &-col {
    width: 100%;
    padding: 30px 0 0 0;
    text-align: center;
    @include pc {
      width: 50%;
      padding: 0 30px;
    }
  }

  &-img {
    width: 35px;
    height: 35px;
    margin: 0 auto;
  }

  &-text {
    margin: 10px 0 15px 0;
    @include pc {
      margin: 15px 0 20px 0;
    }
  }
}

/*--------------------------------
.brand
---------------------------------*/

.brand {
  padding: 40px 0;
  @include pc {
    padding: 80px 0;
  }

  &-list {
    max-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px auto 0 auto;
    @include pc {
      align-items: stretch;
      flex-direction: row;
      margin: 40px auto 0 auto;
    }
  }

  &-item {
    display: flex;
    max-width: 360px;
    width: 100%;
    margin: 20px 0 0 0;
    @include pc {
      max-width: 100%;
      width: calc(50% - 30px);
      margin: 15px;
    }

    &-inner {
      border: 1px solid #cecece;
      text-decoration: none;
    }
  }

  &-textbox {
    padding: 20px;
    font-size: 13px;
    @include pc {
      font-size: 14px;
      padding: 30px;
    }
  }

  &-text {
    text-decoration: none;
  }

  &-link {
    margin: 10px 0 0 0;
    text-align: right;

    p {
      position: relative;
      // display: inline-block;
      font-size: 13px;
      text-decoration: underline;
      @include pc {
        font-size: 14px;
      }

      // &::after {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   height: 1px;
      //   background-color: #676767;
      // }
    }
  }
}

/*--------------------------------
.faq
---------------------------------*/

.faq {
  padding: 40px 0;
  background-color: #eef3f6;
  @include pc {
    padding: 80px 0;
  }

  .heading {
    text-align: left;
    @include pc {
      text-align: center;
    }
  }

  &-info {
    padding: 40px 20px;
    background-color: #fff;
    border: 1px solid #cccccc;
    @include pc {
      margin-block-start: 30px;
      padding: 60px 130px;
    }

    .p-static {
      margin-top: 0;

      &__title {
        &.-main {
          font-size: 18px;
          font-weight: 500;
          padding-top: 30px;
          margin-top: 40px;
          margin-bottom: 15px;
          border-top: none;
          @include pc {
            padding-top: 40px;
          }

          &::before {
            position: absolute;
            top: 3px;
            left: 0;
            width: 195px;
            height: 2px;
            background-color: #bfbfbf;
          }

          &::after {
            width: 100px;
          }
          &:first-child {
            margin-block-start: 0;
          }
        }
      }

      &__text {
        margin: 15px 0;
        @include pc {
          margin: 30px 0;
        }
        &:last-child {
          margin-block-end: 0;
        }
      }
    }
  }

  &-textwrap {
    position: relative;
    @include pc {
      padding-right: 250px;
    }

    img {
      max-width: 231px;
      width: 100%;
      display: block;
      width: auto;
      margin: 0 auto;
      @include pc {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.p-select-faq {
  @at-root {
    & {
      background-color: #eef3f6;
      padding-block: 40px;
    }
    .p-select-faq-inner {
      margin-inline: auto;
      max-width: 1360px;
      padding-inline: 20px;
    }
    .p-select-faq-head {
      font-size: 22px;
      margin-bottom: 15px;
      text-align: center;
      @include font-noto_sans_light;
      @include pc {
        font-size: 32px;
        margin-bottom: 30px;
      }
    }
    .p-select-faq-body {
      background-color: #fff;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      gap: 50px;
      margin-block-start: 30px;
      padding-block: 40px;
      padding-inline: 20px;
      @include pc {
        padding-block: 60px;
        padding-inline: 130px;
      }
    }
    .p-select-faq-row {
      display: grid;
      gap: 30px;
      @include pc {
        gap: 50px;
      }
      &:not(.p-select-faq-row-full) {
        @include pc {
          grid-template-columns: 1fr 300px;
        }
      }
    }
    .p-select-faq-column {
    }
    .p-select-faq-title {
      font-size: 18px;
      letter-spacing: 0.14em;
      line-height: calc(28 / 18);
      margin-block-end: 15px;
      @include font-noto_sans_medium;
      &::before {
        background: linear-gradient(to right, #e60012 50%, #bfbfbf 50%, #bfbfbf 100%);
        content: "";
        display: block;
        height: 2px;
        margin-block-end: 30px;
        width: 200px;
        @include pc {
          margin-block-end: 30px;
        }
      }
      &:not(:first-child) {
        margin-block-start: 40px;
      }
    }
    .p-select-faq-subTitle {
      font-size: 16px;
      &:not(:first-child) {
        margin-block-start: 1em;
      }
    }
    .p-select-faq-text {
      line-height: calc(23/14);
      margin-block: 15px;
    }
    .p-select-faq-image {
      height: auto;
    }
    .p-select-faqList {
      display: flex;
      flex-direction: column;
      gap: 60px;
      @include pc {
        //padding-inline-start: 30px;
      }
    }
    .p-select-faqList-item {
      display: grid;
      gap: 30px;
      position: relative;
      @include pc {
        gap: 50px;
        grid-template-columns: 1fr 300px;
      }
      &:not(:last-child)::after {
        background-color: #ccc;
        content: "";
        display: block;
        height: 1px;
        inset: auto 0 -30px;
        position: absolute;
        width: 100%;
      }
    }
    .p-select-faqList-head {
    }
    .p-select-faq-category {
      align-items: center;
      display: flex;
      font-size: 16px;
      gap: 0.5em;
      margin-block-end: 0.25em;
    }
    .p-select-faq-category-oval {
      color: #f6ab00;
    }
    .p-select-faq-category-triangle {
      color: #e4007f;
    }
    .p-select-faq-category-square {
      color: #0079c3;
    }
    .p-select-faq-category-round {
      color: #009944;
    }
    .p-select-faq-ico {
      height: 1em;
      width: auto;
    }
    .p-select-faqList-button {
      margin-block-start: 30px;
    }
    .p-select-faqList-body {
    }
    .p-select-faq-image {
      display: block;
      height: auto;
      margin-inline: auto;
      width: auto;
    }
    .p-select-faq-image-step {
      max-height: 200px;
    }
    .p-select-faq-image-face {
      margin-block-end: 20px;
      width: 200px;
    }
    .p-select-faq-image-frame {
      width: 300px;
    }
  }
}

}
