@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-cardUrl {
  background: $c-base;
  padding: 15px 0;
  @include pc{
    padding: 40px 0;
  }
  &__title {
    text-align: center;
    margin-top: 0;
    margin-bottom: 16px;
    @include pc{
      margin-bottom: 0;
    }
    img {
      width: 120px;
      height: auto;
      @include pc{
        width: 147px;
      }
      &.sp {
        @include pc{
          display: none;
        }
      }
      &.pc {
        display: none;
        @include pc{
          display: inline;
        }
      }
    }
    span {
      display: block;
      &.en {
        margin-top: 8px;
        @include font-Montserrat_extralight;
        @include fz(34);
        line-height: 1;
        text-transform: uppercase;
        @include pc {
          margin-top: 20px;
          @include lhCrop(44 / 48);
          @include vw(48);
          line-height: (44 / 48);
        }
      }
      &.jp {
        display: inline-block;
        position: relative;
        margin-top: 5px;
        padding: 0 1.5em;
        @include fz(13);
        letter-spacing: 0.26em;
        @include font-noto_sans_light;
        @include pc {
          @include vw(14);
          padding: 0 12px;
          margin-top: 15px;
          letter-spacing: 0.16em;
        }
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 1px);
          width: 25px;
          height: 1px;
          background-color: #000;
          @include pc {
            width: 46px;
          }
        }
        &::before {
          left: -1em;
          @include pc {
            left: -46px;
          }
        }
        &::after {
          right: -1em;
          @include pc {
            right: -46px;
          }
        }
      }
    }
  }
  &__id {
    @include fz(12);
    text-align: right;
    color: #7f7f7f;
    margin-bottom: 5px;
    letter-spacing: 0.16em;
    @include pc {
      @include vw(14);
      margin-bottom: 16px;
    }
  }
  .c-sec {
    background: #fff;
    border-radius: 10px;
    width: calc(100% - 30px);
    max-width: 690px;
    margin: 0 auto;
    padding-top: 30px;
    @include pc{
      max-width: 950px;
      padding-top: 78px;
      padding-bottom: 80px;
    }
    &__inner {
      padding: 0 16px;
      margin: 0;
      @include pc{
        padding: 0 40px;
      }
    }
  }
  &__dl.size {
    border-top: 1px solid #bfbfbf;
    padding: 30px 0;
    @include pc{
      border-color: #e1e1e1;
      display: flex;
    }
    + dl {
      border-top: 1px solid #bfbfbf;
      @include pc{
        border-color: #e1e1e1;
      }
    }
    > dt {
      margin-bottom: 16px;
      @include fz(13);
      @include pc{
        width: 34%;
        flex-basis: 34%;
        max-width: 34%;
        padding: 5px 10px 0 30px;
        @include vw(14);
      }
    }
    @include pc{
      > dd {
        @include vw(14);
        width: 66%;
        flex-basis: 66%;
        max-width: 66%;
        @include IE{
          min-height: 10px;
        }
      }
    }
  }
  &__dl:not(.size) {
    display: flex;
    padding: 30px 0 25px;
    @include pc{
      padding: 30px 0;
    }
    + dl {
      border-top: 1px solid #bfbfbf;
      @include pc{
        border-color: #e1e1e1;
      }
    }
    @include pc{
      &:last-of-type {
        border-bottom: 1px solid #e1e1e1;
      }
    }
    > dt {
      @include fz(13);
      width: 35%;
      flex-basis: 35%;
      max-width: 35%;
      @include pc {
        letter-spacing: 0.16em;
        width: 34%;
        flex-basis: 34%;
        max-width: 34%;
        padding: 0 10px 0 30px;
        @include vw(14);
      }
    }
    > dd {
      @include fz(13);
      width: 65%;
      flex-basis: 65%;
      max-width: 65%;
      @include pc {
        @include vw(14);
        width: 66%;
        flex-basis: 66%;
        max-width: 66%;
      }
      @include IE{
        min-height: 10px;
      }
      p {
        @include fz(13);
        @include pc {
          @include vw(14);
        }
        &.en {
          text-transform: uppercase;
        }
      }
    }
  }
  .maestroProduct.-card {
    padding-bottom: 0;
    .maestroProduct {
      &__list {
        border-top: 1px solid #e1e1e1;
        border-left: 1px solid #e1e1e1;
        @include pc{
          border: none;
        }
      }
      &__item {
        position: relative;
        box-sizing: border-box;
        width: 33.3%;
        max-width: 33.3%;
        background: #fff;
        border-right: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        @include pc {
          width: calc(33.3% - 16px);
          max-width: calc(33.3% - 16px);
          border: 1px solid #e1e1e1;
        }
      }
      &__size {
        flex: 1 0 33.3%;
        margin-top: 0;
        border-left: 1px solid #e1e1e1;
        @include pc {
          flex: 1 0 calc(33.3% - 16px);
          border-left: none;
        }
        &Item {
          flex-basis: 33.3%;
          width: 33.3%;
          max-width: 33.3%;
          border-right: 1px solid #e1e1e1;
          border-bottom: 1px solid #e1e1e1;
          @include pc {
            flex-basis: calc(33.3% - 16px);
            width: calc(33.3% - 16px);
            max-width: calc(33.3% - 16px);
            border-left: 1px solid #e1e1e1;
          }
          &:nth-of-type(3n + 2):last-of-type {
            margin-right: calc(33.3% * 1 + ((100% - 33.3% * 3) / 2) * 1);
            @include pc {
              margin-right: calc(calc(33.3% - 17px) * 1 + ((100% - calc(33.3% - 17px) * 3) / 2) * 1);
            }
            @include IE{
              margin-right: 33.3%;
            }
            @include pcIE{
              margin-right: 201px;
            }
          }
          .text {
            @include fz(13);
            min-height: inherit;
            @include pc {
              @include vw(14);
            }
          }
        }
      }
    }
  }
}
