$trade-white: #fff;
$trade-gray-border: #ccc;
$trade-green: #22a06b;
$trade-green-light: #ebf6f1;
$trade-red: #e83333;
$trade-pink: #f161a0;
// 375px（SPデザインデータ時）の時の数値を入れる
@function svw($pxValue) {
  @return ($pxValue / 375 * 100vw);
}
// 1440px（PCデザインデータ時）の時の数値を入れる
@function vw($pxValue) {
  @return ($pxValue / 1440 * 100vw);
}
// 1440px（PCデザインデータ時）以下のときは比率維持、最大が414px時の値
@function vmin($pxValue) {
  @return min(#{vw($pxValue)}, #{$pxValue} * 1px);
}
.p-tradeIn {
  padding-top: 0 !important;
  @include font-noto_sans;
  @include tb {
    padding-top: 92px !important;
  }
  .hide-SP {
    @include sp {
      display: none !important;
    }
  }
  .hide-PC {
    @include tb {
      display: none !important;
    }
  }
  .l-kv {
    align-items: center;
    display: flex;
    height: 142px;
    justify-content: center;
    margin: 0;
    @include tb {
      height: 100px;
    }
  }
  .l-kv__title {
    @include font-noto_sans_light;
    font-size: 24px;
    letter-spacing: 0.05em;
    line-height: 1.8;
    margin: 0;
    text-align: center;
    @include tb {
      font-size: 32px;
    }
  }
  /*---------------------
  .hero
  -----------------------*/
  .hero {
    position: relative;
  }
  .hero-image {
    width: 100%;
    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      @include pc {
        aspect-ratio: 288 / 109;
      }
    }
  }
  .hero-image-pc {
    display: none;
    @include pc {
      display: block;
    }
  }
  .hero-image-sp {
    display: block;
    @include pc {
      display: none;
    }
  }
  .p-tradeIn-title {
    font-size: 22px;
    font-weight: 200;
    letter-spacing: 0.05em;
    line-height: 1.4;
    margin-block-end: 70px;
    text-align: center;
    @include tb {
      font-size: 36px;
      margin-block-end: 20px;
    }
  }
  .p-tradeIn-text {
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: 0.1em;
    text-align: center;
    @include tb {
      font-size: 16px;
    }
  }
  /*---------------------
  .p-tradeIn-lead
  -----------------------*/
  .p-tradeIn-lead {
    padding-block: 60px 70px;
    @include tb {
      padding-block: 80px 100px;
    }
  }
  .p-tradeIn-lead-inner {
    padding-inline: 40px;
    @include tb {
      margin-inline: auto;
      padding-inline: 0;
      width: 880px;
    }
  }
  .p-tradeIn-lead-title {
    color: $trade-green;
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 0.05em;
    line-height: 1.4;
    text-align: center;
  }
  /*---------------------
  .p-tradeIn-service
  -----------------------*/
  .p-tradeIn-service {
    background-color: $trade-green-light;
    padding-block: 65px 70px;
    padding-inline: 20px;
    @include tb {
      padding-block: 90px;
      padding-inline: 0;
    }
    .c-list {
    }
    .c-list__item-disc {
      padding-inline-start: 1.5em;
      &::before {
        background-color: $trade-green;
      }
    }
    .c-btnWrap {
      margin-block-end: 0;
      @include tb {
        justify-content: flex-start;
        margin-block-start: 20px;
      }
    }
    .-red {
      color: $trade-red;
    }
    strong {
      background-color: $trade-pink;
      box-decoration-break: clone;
      color: $trade-white;
      display: inline;
      padding: 0.25em;
    }
  }
  .p-tradeIn-service-list {
    display: flex;
    flex-direction: column;
    gap: 64px;
    @include tb {
      gap: 40px;
      margin-block-start: 70px;
      margin-inline: auto;
      width: vmin(1160);
    }
  }
  .p-tradeIn-service-item {
    background-color: $trade-white;
    border: 1px solid $trade-gray-border;
    padding: 60px 30px 40px;
    position: relative;
    @include tb {
      align-items: center;
      display: grid;
      grid-template-columns: vmin(300) 1fr;
      padding: vmin(40) vmin(40) vmin(40) 0;
    }
  }
  .p-tradeIn-service-number {
    align-items: center;
    background-color: $trade-green;
    border-radius: 8px;
    color: $trade-white;
    display: flex;
    font-size: 40px;
    font-style: normal;
    height: 58px;
    inset: -30px 0 auto;
    justify-content: center;
    margin-inline: auto;
    padding-inline-start: 0.2em;
    position: absolute;
    width: 58px;
    @include font-Montserrat_extralight;
    @include tb {
      inset: -22px auto auto 30px;
    }
    &::before {
      background-color: $trade-green;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      content: "";
      display: block;
      height: 11px;
      inset: auto 0 -10px;
      margin-inline: auto;
      position: absolute;
      width: 12px;
    }
  }
  .p-tradeIn-service-icon {
    //align-items: center;
    //display: flex;
    //height: 160px;
    //justify-content: center;
    //margin-inline: auto;
    //width: 160px;
  }
  .p-tradeIn-service-inner {
    @include tb {
      max-width: 770px;
    }
  }
  .p-tradeIn-service-image {
    display: block;
    margin-inline: auto;
    &[src*="about_img_01"] {
      @include sp {
        margin-block-end: 12px;
        width: 120px;
      }
      @include tb {
        width: vmin(120);
      }
    }
    &[src*="about_img_02"] {
      @include sp {
        margin-block: 20px;
        width: 140px;
      }
      @include tb {
        width: vmin(140);
      }
    }
    &[src*="about_img_03"] {
      @include sp {
        margin-block-end: 50px;
        width: 160px;
      }
      @include tb {
        width: vmin(160);
      }
    }
    &[src*="about_img_04"] {
      @include sp {
        margin-block-end: 3px;
        width: 140px;
      }
      @include tb {
        width: vmin(140);
      }
    }
  }
  .p-tradeIn-service-subTitle {
    color: $trade-green;
    font-size: 20px;
    font-weight: 200;
    line-height: 1.85;
    @include sp {
      margin-block-end: 10px;
      text-align: center;
    }
    @include tb {
      font-size: 24px;
      margin-block-end: 20px;
    }
  }
  .p-tradeIn-service-text {
    text-align: justify;
    @include tb {
      font-size: 16px;
    }
    &:not(:first-child) {
      margin-block-start: 20px;
    }
    & + .p-tradeIn-service-text {
      margin-block-start: 10px;
    }
  }
  /*---------------------
  .p-tradeIn-activity
  -----------------------*/
  .p-tradeIn-activity {
    padding-block: 60px;
    padding-inline: 20px;
    @include tb {
      padding-block: 90px;
    }
  }
  .p-tradeIn-activity-title {
  }
  .p-tradeIn-activity-lead {
  }
  .p-tradeIn-activity-logo {
    display: block;
    height: auto;
    margin-block: 30px;
    margin-inline: auto;
    width: 300px;
    @include tb {
      margin-block-end: 50px;
      width: 500px;
    }
  }
  .p-tradeIn-activity-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include tb {
      gap: 40px;
      margin-inline: auto;
      width: vmin(1160);
    }
  }
  .p-tradeIn-activity-item {
    background-color: $trade-green-light;
    display: grid;
    gap: 20px;
    padding: 50px 30px;
    @include tb {
      display: grid;
      grid-template-areas: "photo inner";
      grid-template-columns: vmin(370) 1fr;
      gap: vmin(50);
      padding: vmin(50);
    }
  }
  .p-tradeIn-activity-inner {
    @include tb {
      grid-area: inner;
    }
  }
  .p-tradeIn-activity-subTitle {
    color: $trade-green;
    font-size: 18px;
    font-weight: 200;
    line-height: 1.4;
    margin-block-end: 25px;
    @include tb {
      font-size: 28px;
    }
  }
  .p-tradeIn-activity-text {
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: 0;
    text-align: justify;
    @include tb {
      font-size: 16px;
    }
    & + .p-tradeIn-activity-text {
      @include tb {
        margin-block-start: 25px;
      }
    }
    a {
      color: $trade-green;
      @include tb {
        font-size: 14px;
      }
    }
  }
  .p-tradeIn-activity-photo {
    @include tb {
      grid-area: photo;
    }
  }
  .p-tradeIn-activity-image {
    height: auto;
  }
  /*---------------------
  .p-tradeIn-sustainability
  -----------------------*/
  .p-tradeIn-sustainability {
    border-block-start: 1px solid $trade-gray-border;
    padding-block: 60px;
    padding-inline: 20px;
    @include tb {
      padding-block: 90px;
    }
  }
  .p-tradeIn-sustainability-title {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-block-end: 25px;
    @include tb {
      flex-direction: row;
      justify-content: center;
      margin-block-end: 60px;
    }
    span {
      font-size: 36px;
      font-style: italic;
      letter-spacing: 0.05em;
      line-height: 1.4;
      @include font-Montserrat_semiBold;
      @include tb {
        font-size: 46px;
      }
    }
    svg {
      width: 217px;
      @include tb {
        width: 280px;
      }
    }
  }
  .p-tradeIn-sustainability-lead {
    color: $trade-green;
    font-size: 20px;
    font-weight: 200;
    letter-spacing: 0.05em;
    line-height: 1.4;
    margin-block-end: 20px;
    text-align: center;
    @include tb {
      font-size: 32px;
      margin-block-end: 14px;
    }
  }
  .p-tradeIn-sustainability-text {
    font-size: 16px;
    letter-spacing: 0.05em;
    line-height: 1.6;
    text-align: center;
    @include tb {
      font-size: 20px;
      line-height: 1.8;
    }
  }
  .p-tradeIn-sustainability-list {
    background-color: $trade-green-light;
    display: flex;
    flex-direction: column;
    margin-block-start: 50px;
    padding: 30px;
    @include tb {
      margin-block-start: 70px;
      margin-inline: auto;
      padding: vmin(30) vmin(50);
      width: vmin(1160);
    }
  }
  .p-tradeIn-sustainability-item {
    display: grid;
    gap: 20px;
    @include tb {
      gap: vmin(60);
      grid-template-areas: "photo inner";
      grid-template-columns: vmin(220) 1fr;
      padding-block: vmin(40);
    }
    &:nth-child(even) {
      @include tb {
        grid-template-areas: "inner photo";
        grid-template-columns: 1fr vmin(220);
      }
    }
    &:not(:first-child) {
      border-top: 1px solid $trade-green;
      margin-block-start: 30px;
      padding-block-start: 25px;
      @include tb {
        margin-block-start: 0;
        padding-block-start: vmin(40);
      }
    }
  }
  .p-tradeIn-sustainability-inner {
    @include tb {
      grid-area: inner;
    }
  }
  .p-tradeIn-sustainability-subTitle {
    color: $trade-green;
    font-size: 18px;
    font-weight: 200;
    letter-spacing: 0.05em;
    line-height: 1.6;
    margin-block-end: 25px;
    @include tb {
      font-size: 22px;
      margin-block-end: 15px;
    }
  }
  .p-tradeIn-sustainability-subText {
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 1.6;
    text-align: justify;
    @include tb {
      font-size: 18px;
    }
  }
  .p-tradeIn-sustainability-photo {
    @include tb {
      grid-area: photo;
    }
  }
  .p-tradeIn-sustainability-image {
    height: auto;
  }
}
