@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-maestro {
  width: 100%;
  height: 100%;
  // ページネーション
  &Progress {
    display: none;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 35px;
    transform: translateX(-50%);
    @include tb {
      flex-direction: column;
      top: 50%;
      left: 40px;
      transform: translateY(-50%);
    }
    // 表示
    &.-show {
      display: flex;
    }
    // 線
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 7px;
      top: calc(50% - 1px);
      background-color: #cecece;
      width: calc(100% - 14px);
      height: 1px;
      @include tb {
        left: 4px;
        top: 9px;
        width: 1px;
        height: calc(100% - 20px);
      }
    }
    // 丸
    > div {
      position: relative;
      z-index: +1;
      overflow: hidden;
      margin: 0 4px;
      border-radius: 50%;
      width: 7px;
      height: 7px;
      background-color: #cecece;
      @include tb {
        margin: 7px 0;
        width: 9px;
        height: 9px;
      }
      &.-current {
        background-color: #e60012;
      }
      span {
        display: none;
      }
    }
  }
  // コンテンツ
  &Body {
    display: none;
    position: relative;
    padding: 45px 22px 25px;
    width: 100%;
    height: 100%;
    @include tb {
      padding: 58px 50px 50px;
    }
    &.-page0 {
      display: block;
    }
    // コンテンツ（ボタン以外）
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
      //padding-bottom: 50px;
      line-height: (52/30);
      height: calc(100% - 105px);
      @include tb {
        justify-content: flex-start;
        padding-top: 0;
        height: calc(100% - 100px);
      }
      > * {
        width: 100%;
      }
    }
    &:not(.-page0) .p-maestroBody__content {
      @include tb {
        @include dotLineLAfter(#e6e8e9);
        &::after {
          left: 50%;
          transform: translateX(-50%);
          width: 157px;
        }
      }
    }
    // コンテンツ（ボタン）
    &__foot {
      position: absolute;
      left: 0;
      bottom: 33px;
      width: 100%;
      height: 75px;
      @include tb {
        bottom: 50px;
        height: 70px;
      }
    }
  }// .p-maestroBody
  // メインタイトル
  &__titleMain {
    line-height: 1;
    text-align: center;
    img {
      &[src$="icon_maestro_ll.svg"] {
        width: 125px;
        height: 57px;
        @include tb {
          width: 164px;
          height: 81px;
          margin-top: 0;
        }
      }
      &[src$="icon_card.svg"] {
        width: 45px;
        height: 29px;
        @include tb {
          width: 63px;
          height: 42px;
        }
      }
    }
    span {
      display: block;
      &.en {
        margin-top: 5px;
        @include font-Montserrat;
        @include fz(34);
        line-height: 1;
        text-transform: uppercase;
        @include tb {
          margin-top: 5px;
          @include font-Montserrat_extralight;
          @include lhCrop(52 / 50);
          font-size: 50px;
          line-height: (52 / 50);
        }
      }
      &.jp {
        display: inline-block;
        position: relative;
        margin-top: 16px;
        padding: 0 1.5em;
        @include fz(13);
        letter-spacing: 0.26em;
        @include tb {
          @include vw(14);
          padding: 0 15px;
          margin-top: 0;
          letter-spacing: 0.16em;
        }
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 1px);
          width: 25px;
          height: 1px;
          background-color: #000;
          @include tb {
            width: 37px;
          }
        }
        &::before {
          left: -1em;
          @include tb {
            left: -37px;
          }
        }
        &::after {
          right: -1em;
          @include tb {
            right: -37px;
          }
        }
      }
    }
  }
  // タイトル
  &__title {
    margin-bottom: 20px;
    font-size: 15px;
    @include font-noto_sans;
    line-height: (45/30);
    @include tb {
      font-size: 24px;
    }
    span {
      display: block;
      text-align: center;
      &.note {
        display: block;
        @include fz(11);
        color: #7f7f7f;
        margin-top: 6px;
        @include tb {
          @include vw(10);
          margin-top: 1em;
          letter-spacing: .26em;
        }
      }
    }
    &.-line {
      @include tb {
        margin-bottom: 0;
        height: 90px;
        @include dotLineAfter(#e6e8e9);
        &::after {
          left: 50%;
          width: 157px;
          transform: translateX(-50%);
        }
      }
    }
  }
  // リード文
  &__lead {
    @include fz(15);
    line-height: (52/30);
    text-align: center;
    @include tb {
      @include vw(14);
      line-height: (30/14);
    }
    .note {
      display: block;
      font-size: 10px;
      color: #7f7f7f;
    }
  }
  // ボタン
  &__btn {
    @include tb {
      width: 240px;
      margin: 0 auto;
    }
    .c-btn {
      a,
      input,
      button {
        margin: 0 auto;
        max-width: 259px;
      }
    }
  }
  // 閉じる
  &__close {
    margin-top: .75em;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.16em;
    a {
      text-decoration: none;
      position: relative;
      padding-bottom: 4px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #333;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    @include tb {
      margin-top: 0;
      padding: 0 50px;
      text-align: right;
      a {
        padding-bottom: 7px;
        @include IE {
          padding-bottom: 0;
        }
        &::after {
          opacity: 1;
          transition: opacity 0.1s;
        }
        &:hover {
          &::after {
            opacity: 0;
          }
        }
      }
    }
  }
  .c-menuform__title {
    @include lhCrop(38 / 26);
    @include fz(13);
    line-height: (38 / 26);
    text-align: center;
    @include pc {
      @include lhCrop(25 / 14);
      @include fz(14);
      line-height: (25 / 14);
      margin-top: 8px;
    }
    i {
      font-style: normal;
      border-bottom: 1px solid #000;
    }
  }
  .c-menuform__dl {
  }
  .c-menuform__dt {
    margin-bottom: 10px;
    @include tb {
      margin-bottom: 1em;
    }
  }
  .c-menuform__dd {
    margin-top: 0 !important;
    padding-right: 20px;
    padding-left: 20px;
    @include tb {
      padding-right: 0;
      padding-left: 0;
    }
    & + .c-menuform__dt {
      margin-top: 15px;
    }
  }
}// .p-maestro

// 画面別設定
.-page0 {
  .p-maestroBody__content {
  }
  .p-maestro__lead {
    @include sp {
      display: none;
    }
  }
}
.-page2 {
  .p-maestro__title {
    @include tb {
      margin-bottom: 1em;
    }
    .note {
      position: absolute;
      left: 50%;
      bottom: 2em;
      transform: translateX(-50%);
      width: 100%;
      @include tb {
        position: static;
        transform: none;
      }
    }
  }
  .c-menuform__dl {
    padding-bottom: 0;
    overflow-y: auto;
  }
}
.-page6 {
  .p-maestro__titleMain {
    margin-bottom: 5px;
    @include tb {
      margin-top: 50px;
      margin-bottom: 20px;
    }
    span {
      &.en {
        @include tb {
          margin-top: 18px;
          margin-bottom: 18px;
        }
      }
      &.jp {
        line-height: (45/30);
        font-weight: 300;
        @include fz(15);
        padding: 0;
        @include tb {
          line-height: (32 / 24);
          @include vw(24);
        }
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }
  .note {
    color: #7f7f7f;
    text-align: center;
    letter-spacing: 0.16em;
    @include tb {
      font-size: 10px;
    }
  }
  .p-maestro__btn {
    @include dotLineL(#e6e8e9);
    &::before {
      top: -50px;
      left: 50%;
      width: 76px;
      transform: translateX(-50%);
      @include tb {
        display: none;
      }
    }
  }
}
