@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-glasses {
  background: $c-base;
  padding-bottom: 20px;
  margin-bottom: 60px;
  @include pc{
    padding-bottom: 60px;
    margin-bottom: 0;
  }
  & &__lead {
    background: #fff;
    padding: 40px 0 38px;
    margin-bottom: 20px;
    @include pc{
      padding: 80px 0 94px;
      margin-bottom: 60px;
    }
    &Inner {
      @include pc{
        max-width: 1320px;
        margin: 0 auto;
      }
      figure {
        width: 170px;
        margin: 0 auto 15px;
        @include pc{
          width: 280px;
          margin-bottom: 32px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      p {
        font-size: 13px;
        line-height: (47 / 26);
        letter-spacing: 0.16em;
        text-align: center;
        @include font-noto_sans_light;
        @include pc{
          font-size: 16px;
          line-height: (30 / 16);
          letter-spacing: 0.26em;
        }
        & + p {
          margin-top: 10px;
          @include pc{
            margin-top: 16px;
          }
        }
      }
    }
  }
  & &Wrap {
    padding: 0 20px;
    @include pc{
      padding: 0 60px;
    }
    & + .p-glassesWrap {
      padding-top: 20px;
      @include pc{
        padding-top: 40px;
      }
    }
  }
  & &Inner {
    background: #fff;
    border: 1px solid $c-border1;
    padding: 60px 10px 50px;
    position: relative;
    @include pc{
      max-width: 1320px;
      padding: 120px 40px 110px;
      margin: 0 auto;
    }
    &::before,
    &::after {
      position: absolute;
      display: block;
      width: 35px;
      height: 35px;
      content: "";
      @include pc {
        width: 70px;
        height: 70px;
      }
    }
    &::before {
      top: 10px;
      left: 10px;
      background: url("/img/common/bg_ deco_about_left.svg") no-repeat 50%;
      background-size: contain;
      @include pc {
        top: 40px;
        left: 40px;
      }
    }
    &::after {
      right: 10px;
      bottom: 10px;
      background: url("/img/common/bg_ deco_about_right.svg") no-repeat 50%;
      background-size: contain;
      @include pc {
        right: 40px;
        bottom: 40px;
      }
    }
  }
  & &__title {
    text-align: center;
    font-size: 27px;
    line-height: (64 / 54);
    margin-bottom: 10px;
    @include font-noto_sans_light;
    @include pc{
      font-size: 42px;
      line-height: 1;
      margin-bottom: 40px;
    }
    &::after {
      content: "";
      display: block;
      width: 36px;
      height: 1px;
      background: $c-border1;
      margin: 11px auto 0;
      @include pc {
        margin-top: 35px;
        width: 54px;
      }
    }
  }
  .glasses {
    background: url(/img/glasses_maestro/img_glasses_bg.svg) no-repeat 50% 70px;
    background-size: 85% auto;
    @include pc{
      background-size: 68% auto;
      background-position: 50%;
      display: flex;
      justify-content: center;
      @at-root (without: media rule) {
        .p-glasses .p-glassesWrap.declaration .glasses {
          flex-direction: column;
        }
      }
    }
    &__text {
      text-align: center;
      font-size: 13px;
      line-height: (47 / 26);
      letter-spacing: 0.08em;
      @include font-noto_sans_light;
      @include pc {
        font-size: 16px;
        line-height: (30 / 16);
        letter-spacing: 0.26em;
      }
      & + .glasses__text {
        margin-top: 10px;
        @include pc {
          margin-top: 20px;
        }
      }
    }
    &__list {
      padding-top: 6px;
      @include pc{
        padding-top: 0;
      }
      li {
        @include font-noto_sans_light;
        & + li {
          margin-top: 20px;
        }
        p {
          font-size: 13px;
          line-height: (47 / 26);
          letter-spacing: 0.16em;
          text-align: center;
          @include pc{
            font-size: 16px;
            min-height: 66px;
            letter-spacing: 0.18em;
            text-align: left;
            text-indent: -73px;
            margin-left: 73px;
          }
          &::before {
            content: "";
            display: block;
            width: 40px;
            height: 47px;
            margin: 10px auto 0;
            @include pc{
              display: inline-block;
              width: 58px;
              height: 66px;
              margin-top: -7px;
              margin-right: 16px;
              vertical-align: middle;
            }
          }
        }
        &:nth-of-type(1) p::before {
          background: url(/img/glasses_maestro/icon_glasses_01.svg) no-repeat 50%;
          background-size: auto 100%;
        }
        &:nth-of-type(2) p::before {
          background: url(/img/glasses_maestro/icon_glasses_02.svg) no-repeat 50%;
          background-size: auto 100%;
        }
        &:nth-of-type(3) p::before {
          background: url(/img/glasses_maestro/icon_glasses_03.svg) no-repeat 50%;
          background-size: auto 100%;
        }
        &:nth-of-type(4) p::before {
          background: url(/img/glasses_maestro/icon_glasses_04.svg) no-repeat 50%;
          background-size: auto 100%;
        }
        &:nth-of-type(5) p::before {
          background: url(/img/glasses_maestro/icon_glasses_05.svg) no-repeat 50%;
          background-size: auto 100%;
        }
      }
    }
  }
}
