@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-lineupLead {
  @include fz(14);
  @include pc {
    font-size: 16px;
    line-height: 1.8;
    text-align: center;
  }
}

.p-lineup {
  @at-root {
    & {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px 13px;
      padding-left: 20px;
      padding-right: 20px;
      @include pc {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px 20px;
        margin-left: auto;
        margin-right: auto;
        //max-width: 1020px;
        padding-left: 0;
        padding-right: 0;
      }
      &:not(:first-child) {
        margin-top: 28px;
        @include pc {
          margin-top: 60px;
        }
      }
      &.p-lineup-lower {
        @include pc {
          //max-width: none;
        }
      }
    }
    .p-lineup__item {
      @include pc {
        //min-width: 214px;
        //width: calc(100% / 3 - 20px * 2 / 3);
        max-width: 320px;
        //width: calc(320 / 1440 * 100vw);
      }
      .p-lineup-lower & {
        @include pc {
          //width: calc(100% / 4 - 20px * 3 / 4);
        }
      }
    }
    .p-lineup__link {
      background-color: #fff;
      border: 1px solid #bad3d6;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 10px 10px 20px;
      position: relative;
      text-decoration: none;
      @include pc {
      }
    }
    .p-lineup__link-notLink {
      pointer-events: none;
    }
    .p-lineup__label {
      color: #fff;
      @include font-Montserrat_semiBold;
      @include fz(12);
      &::before {
        border-color: #007bff transparent transparent transparent;
        border-style: solid;
        border-width: 46px 46px 0 0;
        content: "";
        height: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 0;
        @include pc {
          border-width: 70px 70px 0 0;
          font-size: 14px;
          height: 70px;
          width: 70px;
        }
      }
      &.-red::before {
        border-top-color: #e50213;
      }
      i {
        font-style: normal;
        display: flex;
        left: 0;
        justify-content: center;
        height: 46px;
        padding-top: 5px;
        position: absolute;
        top: 0;
        text-align: center;
        transform: rotate(-45deg);
        width: 46px;
        white-space: nowrap;
        @include pc {
          font-size: 14px;
          height: 70px;
          padding-top: 10px;
          width: 70px;
        }
      }
    }
    .p-lineup__image {
      margin-bottom: 5px;
      @include pc {
        margin-bottom: 10px;
      }
    }
    .p-lineup__inner {
      display: flex;
      flex: 1;
      flex-direction: column;
      @include pc {
        padding: 0 10px;
      }
    }
    .p-lineupTag {
      display: flex;
      flex-wrap: wrap;
      gap: 3px 6px;
      @include pc {
        gap: 5px 3px;
      }
    }
    .p-lineupTag__item {
      border: 1px solid #ccc;
      border-radius: 1em;
      @include fz(12);
      padding: 0 .5em;
      @include pc {
        font-size: 16px;
      }
    }
    .p-lineup__title {
      @include font-noto_sans_medium;
      @include fz(14);
      letter-spacing: .06em;
      line-height: 1.4;
      margin-bottom: auto;
      margin-top: 5px;
      @include pc {
        font-size: 20px;
      }
    }
    .p-lineup__lead {
      @include font-noto_sans_light;
      @include fz(12);
      letter-spacing: .06em;
      line-height: 1.4;
      margin-top: 5px;
      @include pc {
        font-size: 16px;
        margin-top: 15px;
      }
      strong {
        font-family: "Noto Sans Japanese", sans-serif;
        font-weight: 600;
      }
    }
    .p-lineup__price {
      @include font-Montserratfont-Montserrat;
      @include fz(16);
      align-items: center;
      display: flex;
      margin-top: 12px;
      @include pc {
        font-size: 22px;
        margin-top: 15px;
      }
      @media screen and (max-width: 350px) {
        font-size: 14px;
      }
      &::after {
        @include font-noto_sans;
        @include fz(10);
        content: "税込";
        line-height: 1;
        margin-left: 0.25em;
        padding: 0.2em 0.25em 0.15em;
        @include pc {
          font-size: 12px;
        }
      }
    }
    .p-lineup__btn {
      margin-top: 10px;
      @include pc {
        margin-top: 20px;
      }
      button {
        @include fz(12);
        height: 30px;
        @include pc {
          font-size: 14px;
          height: 40px;
        }
        .p-lineup__link:hover & {
          background-color: #e50213;
          color: #fff;
        }
      }
    }
  }
}
