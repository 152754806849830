@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-kv {
  margin-top: 40px;
  margin-bottom: 40px;
  @include pc {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  & + .p-static {
    margin-top: 0;
  }
  &__img {
    img {
      width: 100%;
    }
  }
  &__body {
    margin: 0 5%;
    text-align: center;
    @include pc {
      margin: 0 auto;
      padding: 0 3.15% 10px;
      max-width: 1440px;
    }
  }
  &__title {
    @include font-noto_sans;
    @include fz(21);
    letter-spacing: 0.14em;
    margin-top: 24px;
    margin-bottom: 17px;
    @include pc {
      @include vw(32);
      margin-top: 50px;
      margin-bottom: 25px;
    }
  }
  &__read {
    @include font-noto_sans;
    @include fz(16);
    line-height: (28/16);
    letter-spacing: 0.14em;
    @include sp {
      text-align: left;
    }
    @include pc {
      font-size: 16px;
    }
    .red {
      color: #e60012;
    }
  }
  .l-main > & {
    &.-text {
      background: #eef3f6;
      position: relative;
      padding-bottom: 70px;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: -50px;
      margin-bottom: 0;
      @include pc {
        margin-top: -180px;
        padding-top: 5.75rem;
        padding-bottom: 54px;
        padding-left: 60px;
        padding-right: 60px;
      }
      .l-kv {
        &__title {
          font-size: 23px;
          @include font-noto_sans_light;
          letter-spacing: 0.14em;
          padding-top: 62px;
          margin-top: 0;
          margin-bottom: 20px;
          text-align: center;
          @include pc {
            font-size: 42px;
            padding-top: 72px;
            margin-bottom: 34px;
          }
          &::after {
            content: "";
            display: block;
            width: 54px;
            height: 1px;
            background: #333;
            margin: 12px auto 0;
            @include pc {
              height: 2px;
              margin-top: 16px;
            }
          }
        }
        &__img {
          position: absolute;
          @include pc {
            position: static;
          }
          &.afterservice {
            bottom: 0;
            right: 15px;
            img {
              width: 80px;
              @include pc {
                width: 120px;
                margin-top: -24px;
                vertical-align: text-top;
              }
            }
          }
        }
        &__text {
          font-size: 15px;
          line-height: (46 / 30);
          text-align: left;
          @include font-noto_sans_light;
          @include pc {
            font-size: 16px;
            text-align: center;
            line-height: (57 / 32);
          }
          b {
            @include font-noto_sans_medium;
          }
          small {
            font-size: 13px;
            @include pc {
              font-size: 14px;
            }
          }
        }
      }
    }
    &.maestro {
      background: #eef3f6;
      padding: 0;
      margin-top: -50px;
      margin-bottom: 0;
      text-align: center;
      @include pc {
        margin-top: -180px;
      }
      .l-kv__title {
        margin-top: 0;
        @include pc {
          margin-top: 5.75rem;
        }
        img {
          margin: 0 auto;
          width: 100%;
          max-width: 1440px;
        }
      }
    }
  }
}
