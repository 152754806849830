@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-hearingaid {
  p + p {
    margin-top: 0;
  }
  &Wrap {
    // max-width: 1440px;
    margin: auto;
    &.-blue {
      background: #eef3f6;
    }
    .prologue {
      padding: 40px 20px 0;
      max-width: 1440px;
      margin: auto;
      @include pc {
        display: flex;
        align-items: center;
      }
      .right {
        @include pc {
          width: 50%;
          order: 2;
          margin-bottom: 20px;
        }
        .title {
          @include font-noto_sans_light;
          font-size: 21px;
          text-align: center;
          @include pc {
            font-size: 32px;
          }
          .strong {
            font-size: 28px;
            color: $c-primary;
            @include pc {
              font-size: 42px;
            }
          } // .strong
        } // .title
        .point {
          &-list {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            @include pc {
              justify-content: flex-start;
            }
          } // &-list
          &-item {
            padding: 10vw 0;
            text-align: center;
            width: calc(100% / 3 - 6px);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            @include pc {
              padding: 70px 0;
              &:not(:first-child) {
                margin-left: 20px;
              }
            }
            &::before {
              content: "";
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              background:url('/img/common/bg_circle_gray.svg') no-repeat center / contain;
            }
            .text {
              font-size: 13px;
              @include font-noto_sans_regular;
              @include sp320 {
                font-size: 11px;
              }
              @include pc {
                font-size: 14px;
              }
            } // .text
          } // &-item
        } // .point
        .text {
          font-size: 13px;
          @include font-noto_sans;
          @include pc {
            font-size: 16px;
          }
        }
      } // .right
      .left {
        @include pc {
          width: 50%;
          order: 1;
        }
        .image {
          width: 100%;
        } // .image
      } // .left
    } // .prologue
    #section-09 {
      padding: 40px 20px 0;
      @include pc {
        padding: 50px 40px 0;
      }
    }
    .section {
      padding: 40px 20px;
      max-width: 1440px;
      margin: auto;
      @include pc {
        padding: 50px 40px;
      }
      .redBoder {
        border: 2px solid #fee5e0;
        padding: 40px 20px;
        @include pc {
          padding: 45px 40px 50px;
        }
      }
      .title {
        font-size: 21px;
        text-align: center;
        @include font-noto_sans_regular;
        @include pc {
          @include font-noto_sans_light;
          font-size: 32px;
          line-height: 1.2em;
        }
        .strong {
          @include font-noto_sans_bold;
        }
        .small {
          font-size: 18px;
        } // .small
        .large {
          font-size: 28px;
        } // .large
      } // .title
      .card-title {
        font-size: 21px;
        text-align: center;
        margin-bottom: 20px;
        @include font-noto_sans_light;
        @include sp320 {
          font-size: 19px;
        }
        @include pc {
          font-size: 32px;
          margin-bottom: 10px;
        }
      } // .card-title
      .card-lead {
        font-size: 14px;
        @include font-noto_sans_light;
        @include pc {
          text-align: center;
        }
      } // .card-lead
      .difference-title {
        font-size: 22px;
        text-align: center;
        margin-bottom: 20px;
        @include font-noto_sans_light;
        @include pc {
          margin-bottom: 40px;
        }
        .strong {
          @include font-noto_sans_medium
        }
      } // .difference-title
      .check-title {
        font-size: 22px;
        text-align: center;
        @include font-noto_sans_light;
        @include sp320 {
          font-size: 19px;
        }
        @include pc {
          font-size: 32px;
        }
        .small {
          font-size: 18px;
          @include pc {
            font-size: 22px;
          }
        }
      }
      .type-title {
        font-size: 21px;
        text-align: center;
        margin-bottom: 10px;
        @include font-noto_sans_light;
        @include sp320 {
          font-size: 19px;
        }
        @include pc {
          font-size: 32px;
          margin-bottom: 20px;
        }
        .small {
          font-size: 18px;
          @include pc {
            font-size: 22px;
          }
        }
      } // .type-title
      .type-subtitle {
        font-size: 21px;
        text-align: center;
        margin-top: 20px;
        @include font-noto_sans_light;
        @include pc {
          font-size: 22px;
          margin-top: 60px;
          margin-bottom: 20px;
        }
      }
      .support-title {
        color: $c-primary;
        font-size: 21px;
        text-align: center;
        margin-bottom: 20px;
        @include font-noto_sans_light;
        @include sp320 {
          font-size: 19px;
        }
        @include pc {
          font-size: 28px;
          line-height: 1.2em;
        }
      } // .support-title
      .store-title {
        font-size: 21px;
        text-align: center;
        margin-bottom: 15px;
        @include font-noto_sans_light;
        @include sp320 {
          font-size: 19px;
        }
        @include pc {
          font-size: 32px;
          line-height: 1.2em;
        }
        .small {
          font-size: 18px;
        } // .small
      } // .store-title
      .store-lead {
        font-size: 15px;
        text-align: center;
        margin-bottom: 20px;
        @include font-noto_sans_light;
      } // .store-lead
      .service-title {
        font-size: 22px;
        text-align: center;
        margin-bottom: 30px;
        @include font-noto_sans_light;
        @include sp320 {
          font-size: 19px;
        }
        @include pc {
          margin-bottom: 10px;
        }
        .large {
          font-size: 28px;
          @include sp320 {
            font-size: 24px;
          }
          @include pc {
            font-size: 32px;
          }
        }
      } // .service-title
      .service-lead {
        font-size: 15px;
        text-align: center;
        margin-bottom: 20px;
        @include font-noto_sans_light;
        @include pc {
          margin-bottom: 10px;
          font-size: 18px;
        }
      } // .service-lead
      .lead {
        font-size: 16px;
        @include sp320 {
          font-size: 14px;
        }
        @include pc {
          font-size: 14px;
          text-align: center;
        }
        .strong {
          @include font-noto_sans_bold;
        }
      } // lead
      .text {
        font-size: 14px;
        @include font-noto_sans_light;
        @include pc {
          text-align: center;
        }
        .red {
          color: $c-primary;
        }
      } // .text
      .note {
        font-size: 11px;
        @include font-noto_sans_light;
        @include pc {
          font-size: 12px;
          text-align: center;
        }
      } // .note
      .card {
        &-list {
          margin-top: 20px;
          @include pc {
            margin-top: 40px;
          }
          &.-large {
            @include pc {
              display: flex;
              justify-content: space-between;
            }
            .card-item {
              @include pc {
                margin-bottom: 0;
                width: calc(50% - 20px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media screen and (min-width: 1280px) {
                  flex-direction: row;
                  align-items: center;
                }
                .image {
                  width: 100%;
                  order: 2;
                  @include pc {
                    min-height: 0%;
                  }
                  @media screen and (min-width: 1280px) {
                    width: 65%;
                  }
                }
                .text {
                  width: 100%;
                  order: 1;
                  @media screen and (min-width: 1280px) {
                    width: 35%;
                  }
                }
              }
              &:not(:last-child) {
                margin-bottom: 20px;
                @include pc {
                  margin-bottom: 0;
                }
              }
              .text {
                padding: 1em;
              }
            } // .card-item
          } // &.-large
          &.-small {
            margin-bottom: 30px;
            @include pc {
              display: flex;
              justify-content: center;
              margin-bottom: 40px;
            }
            .card-item {
              display: flex;
              align-items: center;
              @include pc {
                width: calc(100% / 3 - 15px);
                max-width: 220px;
                flex-direction: column;
                &:not(:first-child) {
                  margin-left: 30px;
                }
              }
              &:not(:last-child) {
                margin-bottom: 20px;
                @include pc{
                  margin-bottom: 0;
                }
              }
              .image {
                order: 2;
                width: 50%;
                @include pc {
                  min-height: 0%;
                  width: 100%;
                }
              } // .image
              .text {
                order: 1;
                width: 50%;
                @include pc {
                  width: 100%;
                  padding: 20px 1em;
                }
              } // .text
            } // .card-item
          } // &.-small
        } // &-list
        &-item {
          background: #fff;
          .text {
            text-align: center;
            font-size: 13px;
            @include sp320 {
              font-size: 11px;
            }
          } // .text
        } // &-item
      } // .card
      .difference-table {
        text-align: left;
        border-collapse:  collapse;
        width: 100%;
        tr {
          th,
          td {
            font-size: 11px;
            @include font-noto_sans_light;
            border: solid 1px #c7c7c7;
            padding: 1em;
            vertical-align: top;
            @include pc {
              font-size: 14px;
            }
          }
          th {
            background: #f7f7f7;
            &.strong {
              background: #ff828c;
            }
          }
          td {
            width: 40%;
            @include pc {
              width: 45%;
            }
            &.strong {
              background: #fee5e0;
            }
          }
        }
      } // .difference-table
      .check {
        &-list {
          counter-reset: check-list;
          #check-item {
            &-02,
            &-03,
            &-04 {
              display: none;
            }
          } // #check-item
        } // &-list
        &-item {
          &.-open {
            display: block !important;
          } // &.-open
          background: #fff;
          border: 1px solid #c7c7c7;
          padding: 20px 20px 40px;
          position: relative;
          margin-bottom: 20px;
          @include pc {
            padding: 50px 40px;
          }
          &Inner {
            @include pc {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            &::before {
              content: "CHECK";
              display: block;
              color: $c-primary;
              font-size: 14px;
              text-align: center;
              margin-bottom: 54px;
              white-space: nowrap;
              @include font-noto_sans_light;
              @include pc {
                margin-bottom: 0;
                width: 50px;
                position: absolute;
                top: 50px;
                left: 40px;
              }
            } // &::before
            &::after {
              counter-increment: check-list;
              content: counter(check-list);
              display: block;
              color: $c-primary;
              font-size: 44px;
              text-align: center;
              position: absolute;
              top: 28px;
              left: 0;
              width: 100%;
              @include font-Montserrat;
              @include pc {
                width: 50px;
                top: 60px;
                left: 40px;
              }
            } // &::after
            .left {
              margin-bottom: 20px;
              @include pc {
                width: calc(50% - 30px);
              }
              .detail-text {
                font-size: 13px;
                @include pc {
                  font-size: 16px;
                  padding-left: 80px;
                }
              }
            } // .left
            .right {
              @include pc {
                width: calc(50% - 30px);
              }
              .button {
                display: flex;
                justify-content: space-between;
                flex: 1 0 calc(50% - 10px);
                margin-bottom: 20px;
                .yes,
                .no {
                  width: calc(50% - 10px);
                  font-size: 13px;
                  letter-spacing: 0.16em;
                  input {
                    display: none;
                    &:checked+.yesLabel,
                    &:checked+.noLabel {
                      background: #000;
                      color: #fff;
                    }
                  } // input
                  label {
                    padding: 16px 0;
                    border-radius: 45px;
                    border: 1px solid #000;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include pc {
                      cursor: pointer;
                      transition: all 0.2s;
                      &:hover {
                        background: #000;
                        color: #fff;
                      }
                    }
                  } // label
                } // .yes, .no
              } // .button
              .retry {
                font-size: 12px;
                text-align: right;
                text-decoration: none;
                position: absolute;
                right: 20px;
                border-bottom: 1px solid #000;
                @include font-noto_sans;
                @include pc {
                  right: 40px;
                  cursor: pointer;
                }
              } // .retry
            } // .right
          } // &Inner
        } // &-item
      } // .check
      .check-result {
        &-list {
          #check-result-item {
            &-01,
            &-02,
            &-03,
            &-04,
            &-05 {
              display: none;
            }
          } // #check-result-item
        } // &-list
        &-item {
          &.-open {
            display: block !important;
          }
          background: #fff;
          border: 1px solid #c7c7c7;
          padding: 20px 20px 40px;
          @include pc {
            padding: 40px;
          }
          .head {
            padding-bottom: 20px;
            border-bottom: 1px dotted #c7c7c7;
            @include pc {
              display: flex;
              align-items: center;
              padding-bottom: 40px;
              max-width: 950px;
              margin: auto;
            }
            .lebel {
              &Wrap {
                margin-bottom: 30px;
                width: 100%;
                @include pc {
                  margin-bottom: 0;
                  width: calc(30% - 55px);
                  margin-right: 55px;
                }
              } // &Wrap
              &Inner {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #cbb834;
                width: 100%;
                padding: 10vw 0;
                position: relative;
                line-height: 1;
                &.-red {
                  color: $c-primary;
                  &::before {
                    background: url('/img/common/bg_circle_red.svg') no-repeat center / contain;
                  }
                }
                @include pc {
                  padding: 55px 0;
                }
                &::before {
                  content: "";
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  background: url('/img/common/bg_circle_yellow.svg') no-repeat center / contain;
                }
                .degree {
                  font-size: 14px;
                  @include font-noto_sans_light;
                  margin-bottom: 20px;
                }
                .lebel {
                  font-size: 42px;
                  @include font-noto_sans_medium;
                  margin-bottom: 20px;
                }
                .decibel {
                  font-size: 22px;
                  @include font-Montserrat_extralight;
                }
              } // &Innner
            } // .lebel
            .descriptionWrap {
              @include pc {
                width: 70%;
              }
              .title {
                font-size: 15px;
                @include font-noto_sans_medium;
                text-align: left;
                @include pc {
                  font-size: 16px;
                  margin-bottom: 20px;
                }
              }
              .text {
                font-size: 15px;
                @include font-noto_sans_light;
                text-align: left;
                @include pc {
                  font-size: 16px;
                }
              }
            } // .descriptionWrap
          } // .head
          .foot {
            margin-top: 30px;
            display: flex;
            flex-direction: column; // iOS buttonタグはみ出し防止
            @include pc {
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;
              margin: 60px auto 0;
              max-width: 950px;
            }
            .left {
              @include pc {
                width: calc(30% - 55px);
                margin-right: 55px;
              }
              .text {
                font-size: 16px;
                text-align: center;
                @include pc {
                  text-align: left;
                }
              }
            } // .left
            .right {
              @include pc {
                width: 70%;
              }
              .recommend {
                &-list {
                  margin-bottom: 30px;
                  @include pc {
                    margin-bottom: 0;
                    display: flex;
                  }
                }
                &-item {
                  @include pc {
                    width: calc(100% / 3);
                  }
                  .product-image {
                    padding: 0 70px;
                    margin-bottom: 10px;
                    @include pc {
                      padding: 0;
                    }
                  }
                  .maker-name {
                    font-size: 14px;
                    text-align: center;
                  }
                }
              } // .recommend
            } // .right
            .retry-button {
              font-size: 13px;
              text-align: center;
              text-decoration: none;
              letter-spacing: 0.16em;
              width: 100%;
              cursor: pointer;
              padding: 16px 0;
              border-radius: 45px;
              border: 1px solid #000;
              background: #fff;
              margin-top: 20px;
              button {
                width: 100%;
              }
              @include pc {
                max-width: 275px;
                margin: 40px auto 0;
                transition: all 0.2s;
                &:only-child {
                  margin: 0 auto;
                }
                &:hover {
                  background: #000;
                  color: #fff;
                }
              }
            } // .retry-button
          } // .foot
        } // &-item
      } // .check-result
      .type {
        &-list {
          @include pc {
            display: flex;
          }
        } // &-list
        &-item {
          @include pc {
            width: calc(100% / 3);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          &:not(:last-child) {
            margin-bottom: 20px;
            @include pc {
              margin-bottom: 0;
            }
          }
          .head {
            @include pc {
              min-height: 0%;
            }
            .image {
              padding: 0 90px;
              width: 100%;
              margin-bottom: 20px;
            } // .image
            .name {
              font-size: 16px;
              text-align: center;
              @include font-noto_sans_medium;
              margin-bottom: 20px;
            } // .name
            .text {
              font-size: 14px;
              text-align: center;
              margin-bottom: 20px;
              @include sp320 {
                font-size: 12px;
              }
            } // .text
          }
          .price {
            font-size: 14px;
            text-align: center;
            background: #eef3f6;
            padding: 5px 0;
            border-radius: 15px;
            margin: 0 20px;
            @include pc {
              width: 100%;
              max-width: 300px;
              margin: 0 auto;
            }
          } // .price
        } // &-item
      } // .type
      .support {
        &-list {
          counter-reset: support-list;
          padding-left: 20px;
          margin-bottom: 20px;
          @include sp320 {
            padding-left: 10px;
            margin-bottom: 30px;
          }
          @include pc {
            display: flex;
          }
        } // &-list
        &-item {
          position: relative;
          display: flex;
          align-items: center;
          .text {
            font-size: 16px;
            @include font-noto_sans_medium;
            @include sp320 {
              font-size: 14px;
            }
          }
          @include pc {
            flex-direction: column;
            width: calc(100% / 3);
            padding: 0 30px;
            &:not(:first-child) {
              border-left: 1px dotted #c7c7c7;
            }
          }
          &::before {
            counter-increment: support-list;
            content: counter(support-list) ".";
            display: inline-block;
            color: $c-primary;
            font-size: 44px;
            width: 60px;
            @include font-Montserrat;
            @include sp320 {
              font-size: 38px;
              width: 50px;
            }
            @include pc {
              line-height: 1;
              margin-bottom: 20px;
            }
          } // &::before
        } // &-item
      } // support
      .service {
        &-list {
          counter-reset: service-list;
          margin-top: 20px;
          @include pc {
            display: flex;
            max-width: 950px;
            margin: 35px auto 0;
          }
        } // &-list
        &-item {
          border: 2px solid #fee5e0;
          padding: 50px 20px;
          @include pc {
            width: calc(100%/3 - 20px);
            padding: 50px 20px 110px;
            &:not(:first-child) {
              margin-left: 30px;
            }
          }
          &:not(:last-child) {
            margin-bottom: 20px;
            @include pc {
              margin-bottom: 0;
            }
          }
          &::before {
            counter-increment: service-list;
            content: counter(service-list) ".";
            display: block;
            color: $c-primary;
            font-size: 44px;
            text-align: center;
            line-height: 1;
            margin-bottom: 20px;
            @include font-Montserrat;
            @include pc {
              margin-bottom: 60px;
            }
          } // &::before
          .text {
            text-align: center;
            font-size: 16px;
            @include pc {
              @include font-noto_sans_medium;
            }
          }
        } // &-item
      } // .service
      .maker {
        &-list {
          border-bottom: 1px solid #cecece;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding-bottom: 40px;
          @include pc {
            flex-wrap: nowrap;
            max-width: 950px;
            margin: auto;
            padding-bottom: 50px;
          }
        } // &-list
        &-item {
          width: calc(50% - 10px);
          @include pc {
            width: 100%;
            &:not(:first-child) {
              margin-left: 20px;
            }
          }
          &:nth-child(-n + 2) {
            margin-bottom: 20px;
            @include pc {
              margin-bottom: 0;
            }
          }
        } // &-item
      } // maker
      .banner {
        background: $c-primary;
        padding: 30px 20px;
        position: relative;
        margin-bottom: 20px;
        @include sp320 {
          padding: 25px 10px;
        }
        @include pc {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 17px 0;
        }
        .icon {
          position: absolute;
          top: -30px;
          right: 10px;
          @include pc {
            position: static;
            padding-right: 16px;
          }
          img {
            width: 70px;
            @include pc {
              width: 85px;
            }
          }
        } // .icon
        .lead {
          color: #fff;
          text-align: center;
          font-size: 21px;
          margin-bottom: 0;
          @include font-noto_sans_light;
          @include sp320 {
            font-size: 18px;
          }
          @include pc {
            font-size: 32px;
          }
          span {
            font-size: 16px;
            @include sp320 {
              font-size: 13px;
            }
            @include pc {
              font-size: 22px;
            }
          }
        }
      } // .banner
    } // .section
  } // &Wrap
} // .p-hearingaid
