@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

$lens__color-brown: #783f04;
$lens__color-gray: #666;
$lens__color-blue: #1155cc;
$lens__color-green: #38761d;
$lens__color-pink: #e07bbd;
$lens__color-purple: #674ea7;
$lens__color-yellow: #c9a332;

.p-lens {
  @at-root {
    & {
      padding: 30px;
      position: relative;
      @include pc {
        align-items: center;
        display: flex;
        padding-bottom: 94px;
        padding-top: 94px;
      }
      &::before {
        background: radial-gradient(55.87% 69.04% at 11.56% -11.67%, #EAF1F9 0%, rgba(217, 226, 240, 0.02) 79.69%, rgba(215, 230, 248, 0.43) 100%);
        border-radius: 20px;
        content: "";
        display: block;
        height: 75%;
        min-height: 400px;
        left: 0;
        position: absolute;
        top: 0;
        transform: rotate(180deg);
        width: 100%;
        z-index: -1;
        @include pc {
          min-height: 400px;
        }
      }
      &.p-lens-noImage {
        &::before {
          height: 100%;
          min-height: 0;
        }
      }
      &.p-lens-90p {
        &::before {
          height: 90%;
          @include pc {
            height: 75%;
          }
        }
      }
      .c-inner {
        @include pc {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
    .p-lensLogo {
      margin-bottom: 40px;
      @include pc {
        margin-bottom: 0;
        margin-right: 60px;
        width: 320px;
      }
    }
    .p-lens__inner {
      flex: 1;
    }
    .p-lens__title {
      @include font-noto_sans_medium;
      font-size: 28px;
      letter-spacing: .06em;
      line-height: 1.4;
      margin: 0 0 .25em;
      text-align: center;
      @include pc {
        font-size: 40px;
        margin-bottom: .25em;
        text-align: left;
      }
    }
    .p-lens__subTitle {
      font-size: 18px;
      letter-spacing: .06em;
      line-height: 1.5;
      margin-bottom: 1em;
      text-align: center;
      @include pc {
        font-size: 20px;
        margin-bottom: 2em;
        text-align: left;
      }
    }
    .p-lens__lead {
      font-size: 14px;
      letter-spacing: .06em;
      line-height: 1.8;
      text-align: justify;
      //margin: 0 -20px;
      @include pc {
        font-size: 16px;
        margin: 0;
        text-align: left;
      }
    }
    .p-lensImages {
      display: flex;
      flex-direction: column;
      gap: 8px 0;
      margin-top: 40px;
      @include pc {
        flex-direction: row;
        gap: 0 13px;
        grid-column-start: 1;
        grid-column-end: 3;
        justify-content: center;
        margin-top: 80px;
        width: 100%;
      }
    }
    .p-lensImages__item {
      @include pc {
        max-width: 528px;
      }
    }
    .p-lensImages__image {
    }
  }
}
.p-lensFeature {
  @at-root {
    & {
      position: relative;
      margin-top: 40px;
      padding: 40px 20px 60px;
      @include pc {
        margin-top: 125px;
        padding: 76px 40px 120px;
      }
      &::before {
        background: linear-gradient(124.08deg, rgba(215, 229, 245, 0.73) 5.07%, rgba(196, 211, 233, 0.0560417) 36.66%, rgba(215, 229, 245, 0.43) 66.96%);
        border-radius: 20px;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        width: 100%;
        z-index: -1;
      }
    }
    & + .c-inner {
      margin-top: 30px;
      @include pc {
        margin-top: 60px;
      }
    }
    .p-lensFeature__title01,
    .p-lensFeature__title02 {
      @include font-noto_sans_medium;
      font-size: 28px;
      letter-spacing: .06em;
      line-height: 1.4;
      margin-bottom: 0;
      text-align: center;
      @include pc {
        font-size: 40px;
        text-align: left;
      }
    }
    .p-lensFeature__title02 {
      margin-bottom: 1em;
      margin-top: 80px;
      position: relative;
      @include pc {
        margin-top: 120px;
        padding-top: 40px;
      }
      &::before,
      &::after {
        content: '';
        display: block;
        left: 0;
        position: absolute;
        top: 0;
      }
      &::before {
        @include pc {
          background-color: #bfbfbf;
          height: 1px;
          width: 288px;
        }
      }
      &::after {
        @include pc {
          background-color: #e60012;
          height: 1px;
          width: 148px;
        }
      }
    }
    .p-lensFeatureList {
      counter-reset: number;
      margin-top: 36px;
      @include pc {
        margin-top: 90px;
      }
    }
    .p-lensFeatureList__item {
      @include pc {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 0 45px;
      }
      &:nth-child(even) {
        @include pc {
          flex-direction: row-reverse;
        }
      }
      & + & {
        margin-top: 40px;
        @include pc {
          margin-top: 70px;
        }
      }
      & + &.p-lensFeatureList__item-flexStart {
        margin-top: 20px;
        @include pc {
          margin-top: 35px;
        }
      }
      &-reverse {
        @include pc {
          @include pc {
            flex-direction: row-reverse !important;
          }
        }
      }
      &-flexStart {
        @include pc {
          align-items: flex-start;
          flex-direction: row !important;
        }
      }
    }
    .p-lensFeatureList__head {
      counter-increment: number;
      margin-bottom: 20px;
      position: relative;
      @include pc {
        margin-bottom: 0;
        width: calc(50% - 45px / 2);
      }
      &::before {
        content: counter(number, decimal-leading-zero	)'';
        @include font-Montserrat_regular;
        font-size: 26px;
        left: .5em;
        letter-spacing: .08em;
        position: absolute;
        top: -.75em;
        @include pc {
          font-size: 36px;
        }
        .p-lensFeatureList__item:nth-child(even) & {
          @include pc {
            left: auto;
            right: .5em;
          }
        }
      }
      &-noNumber {
        &::before {
          display: none;
        }
      }
    }
    .p-lensFeatureList__image {
    }
    .p-lensFeatureList__body {
      @include pc {
        flex: 1;
      }
      & + & {
        margin-top: 20px;
        @include pc {
          margin-top: 0;
        }
      }
    }
    .p-lensFeatureList__foot {
      @include pc {
        margin-top: 2em;
        width: 100%;
      }
    }
    .p-lensFeatureList__title {
      @include font-noto_sans_regular;
      font-size: 20px;
      letter-spacing: .06em;
      line-height: 1.4;
      margin-bottom: .5em;
      @include pc {
        font-size: 32px;
      }
      .p-lensFeatureList__body-colorBrown & {
        color: $lens__color-brown;
      }
      .p-lensFeatureList__body-colorGray & {
        color: $lens__color-gray;
      }
      .p-lensFeatureList__body-colorBlue & {
        color: $lens__color-blue;
      }
      .p-lensFeatureList__body-colorGreen & {
        color: $lens__color-green;
      }
      .p-lensFeatureList__body-colorPink & {
        color: $lens__color-pink;
      }
      .p-lensFeatureList__body-colorPurple & {
        color: $lens__color-purple;
      }
      .p-lensFeatureList__body-colorYellow & {
        color: $lens__color-yellow;
      }
    }
    .p-lensFeatureList__subtitle {
      @include font-noto_sans_regular;
      font-size: 16px;
      letter-spacing: .06em;
      line-height: 1.4;
      margin-bottom: .5em;
      @include pc {
        font-size: 20px;
      }
    }
    .p-lensFeatureList__text {
      font-size: 13px;
      letter-spacing: .06em;
      line-height: 1.77;
      @include pc {
        font-size: 16px;
      }
      strong {
        font-family: "Noto Sans Japanese", sans-serif;
        font-weight: 600;
        .p-lensFeatureList__body-colorBrown & {
          color: $lens__color-brown;
        }
        .p-lensFeatureList__body-colorGray & {
          color: $lens__color-gray;
        }
        .p-lensFeatureList__body-colorBlue & {
          color: $lens__color-blue;
        }
        .p-lensFeatureList__body-colorGreen & {
          color: $lens__color-green;
        }
        .p-lensFeatureList__body-colorPink & {
          color: $lens__color-pink;
        }
        .p-lensFeatureList__body-colorPurple & {
          color: $lens__color-purple;
        }
        .p-lensFeatureList__body-colorYellow & {
          color: $lens__color-yellow;
        }
      }
    }
  }
}
.p-LensNotes {
  @at-root {
    & {
      border: 1px solid #bad3d6;
      margin-top: 40px;
      padding: 40px 20px;
      @include pc {
        margin-top: 100px;
        padding-bottom: 80px;
        padding-top: 75px;
      }
    }
    .p-LensNotes__title {
      font-size: 22px;
      margin-bottom: 1em;
      padding-top: 20px;
      position: relative;
      @include pc {
      }
      &::before,
      &::after {
        content: '';
        display: block;
        left: 0;
        position: absolute;
        top: 0;
      }
      &::before {
        background-color: #bfbfbf;
        height: 1px;
        width: 196px;
        @include pc {
          width: 288px;
        }
      }
      &::after {
        background-color: #e60012;
        height: 1px;
        width: 101px;
        @include pc {
          width: 148px;
        }
      }
    }
    .p-LensNotes__image {
      margin: 0 auto 20px;
      @include pc {
        margin-bottom: 30px;
      }
      p ~ & {
        margin-top: 1em;
      }
    }
  }
}
.p-lensLineup {
  @at-root {
    & {
      background: url("/img/lens/lens-coating/lineup_bg_01_sp.jpg") no-repeat center center / cover;
      height: calc(530 / 375 * 100vw);
      min-height: 530px;
      padding-top: 40px;
      @include pc {
        background-image: url("/img/lens/lens-coating/lineup_bg_01_pc.jpg");
        height: 400px;
        padding-top: 80px;
      }
    }
    .p-lensLineup__inner {
      @include pc {
        margin-left: auto;
        margin-right: auto;
        max-width: 910px;
      }
    }
    .p-lensLineup__title {
      @include font-noto_sans_medium;
      font-size: 22px;
      letter-spacing: .06em;
      line-height: 1.4;
      margin-bottom: .5em;
      text-align: center;
      @include pc {
        font-size: 28px;
        line-height: 180%;
        text-align: left;
      }
      &-light {
        @include font-noto_sans_light;
        font-size: 20px;
        line-height: 180%;
        @include pc {
          font-size: 28px;
        }
      }
    }
    .p-lensLineup__lead {
    }
    .p-lensLineup__btn {
      margin: 30px auto 0;
      width: 295px;
      @include pc {
        margin: 40px 0 0;
        width: 277px;
      }
    }
  }
}
