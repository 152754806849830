@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

$taflex-blue: #b5d8da;
$taflex-purple: #b9acd1;

.p-taflex {
  .p-taflex-wrap {

    &.-blue {
      background: #eef3f6;
    } // &.-blue

    .p-taflex-inner {
      padding: 0 20px;
      margin: auto;
      max-width: 1440px;
      @include pc{
        padding: 0 60px;
      }
    }

    // Common
    .section-title {
      @include font-Montserrat_medium;
      font-size: 12px;
      line-height: 1em;
      letter-spacing: 0.05em;
      margin-bottom: 0;
      text-align: center;
      @include pc {
        @include font-Montserrat_regular;
        font-size: 15px;
      }
      span {
        &::before,
        &::after {
          content: "";
          display: inline-block;
          height: 10.5px;
          width: 4px;
          @include pc {
            height: 13px;
            width: 5px;
          }
        }
        &::before {
          background-color: #9ac9cf;
          margin-right: 10px;
        }
        &::after {
          background-color: $taflex-purple;
          margin-left: 10px;
        }
      }
    }

    &#section-01 {
      & > .p-taflex-inner {
        & > .lead {
          @include font-noto_sans_medium;
          color: #94c6c8;
          font-size: 15px;
          margin-top: 0;
          text-align: center;
          letter-spacing: 0.07em;
          line-height: 1.4;
          @include pc {
            @include font-noto_sans_regular;
            font-size: 27px;
          }
          &.-small {
            font-size: 12px;
            letter-spacing: 0.08em;
            margin-bottom: 5px;
            line-height: 1;
            @include pc {
              font-size: 16px;
            }
          } // &.-small
        } // .lead

        & > .page-title {
          margin: 10px auto 20px;
          max-width: 222px;
          @include pc {
            margin: 15px auto 35px;
            max-width: 425.2px;
          }
        } // .page-title

        & > .text {
          font-size: 11px;
          text-align: center;
          line-height: 2;
          @include pc {
            font-size: 16px;
          }
        }
      }

      .head {
        margin: 35px 0 30px 0;
        @include pc {
          margin: 60px 0 30px 0;
        }

        .section-title {
          margin: 0 0 25px 0;
          @include pc {
            margin: 0 0 50px 0;
          }
        }
      }

      .series-lead {
        @include font-noto_sans_medium;
        font-size: 15px;
        text-align: center;
        margin-bottom: 20px;
        @include pc {
          @include font-noto_sans;
          font-size: 22px;
          line-height: 1.5em;
          margin-bottom: 15px;
        }
      }
      // タイトル
      .series-title {
        margin-bottom: 20px;
        img {
          display: block;
          margin: auto;
          &[src$="logo_taflex-01.png"] {
            width: 110px;
            @include pc {
              width: 248px;
            }
          }
          &[src$="logo_taflex-02.png"] {
            width: 152px;
            @include pc {
              width: 347px;
            }
          }
        }
      }
    } // #section-01

    &#section-02 {
      padding-top: 40px;
      @include pc {
        padding-top: 55px;
      }
      // リード
      .series-lead {
        @include font-noto_sans_medium;
        font-size: 15px;
        text-align: center;
        margin-bottom: 20px;
        @include pc {
          @include font-noto_sans;
          font-size: 22px;
          line-height: 1.5em;
          margin-bottom: 15px;
        }
      }
      // タイトル
      .series-title {
        margin-bottom: 20px;
        img {
          display: block;
          margin: auto;
          &[src$="logo_taflex-01.png"] {
            width: 110px;
            @include pc {
              width: 248px;
            }
          }
          &[src$="logo_taflex-02.png"] {
            width: 152px;
            @include pc {
              width: 347px;
            }
          }
        }
      }
      // テキスト
      .series-text {
        @include font-noto_sans_regular;
        color: #FFF;
        font-size: 26/750*100vw;
        padding-left: 210/750*100vw;
        letter-spacing: 0.16em;
        line-height: 1.7;
        @include pc {
          font-size: 32.7/2880*100vw;
          padding-left: 0;
          margin-right: 0;
          margin-left: auto;
          width: calc(50% - 12px);
        }
      }
      // 商品
      .c-product-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: 20px;
        margin-right: 20px;
        max-width: 1286px;
        li {

          // SP,TB 2カラム
          @media screen and (max-width: 979px) {
            width: calc((100% - 15px) / 2);
            &:nth-child(2n+1) {
              margin-right: 7.5px;
            }
            &:nth-child(2n) {
              margin-left: 7.5px;
            }
            &:nth-child(n+3) {
              margin-top: 15px;
            }
          }

          // PC 4カラム
          @include pc {
            width: calc((100% - 60px) / 4);
            &:nth-child(n+2) {
              margin-left: 20px;
            }
          }

          & > .c-product {
            width: 100%;
            .c-productLink {
              @media screen and (max-width: 979px) {
                border: none;
              }
            }
          } // & > .c-product

          .caption {
            @include font-noto_sans_light;
            color: #FFF;
            font-size: 13px;
            margin-top: 10px;
            line-height: 1.77;
            @include pc {
              margin-top: 15px;
            }
          } // .caption
        } // li
      } // .c-product-list
      // すべて見るボタン
      .button {
        @include font-noto_sans_medium;
        background: #fff;
        border-radius: 35px;
        color: #333;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        letter-spacing: .2em;
        text-decoration: none;
        margin-top: 35px;
        transition: all .2s;
        height: 55px;
        width: 100%;
        @include pc {
          @include font-noto_sans;
          margin: 20px auto 0;
          max-width: 332px;
          &:hover {
            background-color: #333;
            color: #FFF;
          }
        }
      }
      .series-wrap {
        .body {
          background-size: 100%;
          padding-bottom: 50px;
          position: relative;
          @include pc {
            padding-bottom: 70px;
          }

          .glasses-image-wrap {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
          }
          .series-text {
            padding-top: 380/750*100vw;
            margin-bottom: 40/750*100vw;
            @include pc {
              padding-top: 1232/2880*100vw;
              margin-bottom: 60/2880*100vw;
            }
          }
        }
      }

      // シリーズ1
      #series-01 {
        padding-top: 25px;
        .body {
          background-color: $taflex-blue;
          background-image: url("/img/taflex/bg_series-01-SP.jpg");
          @include pc {
            background-image: url("/img/taflex/bg_series-01-PC.jpg");
          }
          .glasses-image-wrap {
            top: 20/750*100vw;
            transform: translateX(30/750*100vw);
            width: 552/750*100vw;
            @include pc {
              top: 160/2880*100vw;
              transform: translateX(40/2880*100vw);
              width: 2097/2880*100vw;
            }
          }
        }
      }
      // シリーズ2
      #series-02 {
        padding-top: 40px;
        .body {
          background-color: $taflex-purple;
          background-image: url("/img/taflex/bg_series-02-SP.jpg");
          @include pc {
            background-image: url("/img/taflex/bg_series-02-PC.jpg");
          }
          .glasses-image-wrap {
            top: 25/750*100vw;
            width: 516/750*100vw;
            @include pc {
              top: 200/2880*100vw;
              width: 1961/2880*100vw;
            }
          }
        }
      }

    } // &#section-02

    &#section-03 {
      padding-top: 40px;
      padding-bottom: 40px;
      @include pc {
        padding-top: 60px;
        padding-bottom: 65px;
      }

      .p-taflex-inner {
        & > .white-box {
          background-color: #FFF;
          padding: 30px 20px 20px;
          margin-top: 30px;
          @include pc {
            padding: 55px 20px 60px 60px;
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          & > .text-block {
            @include pc {
              padding-right: 50/2880*100vw;
            }
            & > .lead {
              @include font-noto_sans_medium;
              font-size: 13px;
              margin-bottom: 10px;
              letter-spacing: 0.07em;
              text-align: center;
              @include pc {
                font-size: 18px;
                text-align: left;
              }
              span {
                background-color: #f6e3df;
                border-radius: 35px;
                display: inline-block;
                padding: 10px 15px;
              }
            }
            & > .title {
              @include font-noto_sans_medium;
              font-size: 22px;
              margin-top: 0;
              letter-spacing: 0.07em;
              text-align: center;
              white-space: nowrap;
              @include pc {
                @include font-noto_sans_light;
                font-size: 32px;
                text-align: left;
              }
              i {
                @include font-Montserrat_medium;
                font-style: normal;
              }
              .-colorRed {
                color: #c60218;
              }
              .-underLine {
                padding-bottom: 5px;
                position: relative;
                &::after {
                  background-color: #c60218;
                  content: "";
                  display: inline;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  height: 2px;
                  width: 100%;
                }
              }
              .-large {
                font-size: 65px;
                line-height: 1em;
              }
              b {
                @include font-noto_sans_regular;
              }
            }
            & > .note {
              @include font-noto_sans_light;
              font-size: 12px;
              margin-top: 0;
              text-align: center;
              line-height: 1.83;
              @include pc {
                font-size: 13px;
                text-align: left;
              }
            }
          }
          & > .image-block {
            margin-top: 15px;
            @include pc {
              margin-top: 0;
              padding-left: 140/2880*100vw;
            }
            & > .graph-image {
              text-align: center;
              @include pc {
                max-width: 495px;
              }
            }
          }
        } // & > .white-box

        & > .lead {
          @include font-noto_sans_regular;
          font-size: 14px;
          margin-top: 30px;
          text-align: center;
          @include pc {
            @include font-noto_sans;
            font-size: 23px;
            margin-top: 75px;
            letter-spacing: .16em;
          }
          strong {
            @include font-noto_sans_bold;
            transform: translateX(4px);
            @include pc {
              @include font-noto_sans_medium;
            }
            span {
              position: relative;
              // 点
              &::before {
                background-color: #da3737;
                border-radius: 50%;
                content: "";
                display: inline-block;
                position: absolute;
                top: -7px;
                left: 0;
                right: 0;
                margin: auto;
                height: 5px;
                width: 5px;
                @include pc {
                  top: -12px;
                  height: 8px;
                  width: 8px;
                }
              }
            }
          }
        } // & > .lead

        & > .title {
          @include font-noto_sans_regular;
          font-size: 16px;
          margin-top: 35px;
          text-align: center;
          @include pc {
            font-size: 31px;
            margin-top: 25px;
          }
          .-underLine {
            padding-bottom: 5px;
            position: relative;
            &::after {
              background-color: #c60218;
              content: "";
              display: inline;
              position: absolute;
              left: 0;
              bottom: 0;
              height: 2px;
              width: 100%;
              @include pc {
                bottom: -6px;
              }
            }
          }
          .-large {
            font-size: 18px;
            @include pc {
              font-size: 31px;
            }
          }
          .-bold {
            @include font-noto_sans_bold;
            @include pc {
              @include font-noto_sans_medium;
            }
          }
        } // & > .title

        ol.ability-list {
          counter-reset: ability-list;
          letter-spacing: 0.16em;
          margin-top: 35px;
          @include pc {
            margin-top: 65px;
          }
          li.ability-item {
            position: relative;
            @include pc {
              display: flex;
              justify-content: space-between;
              &:nth-child(2n+1) {
                .text-block {
                  order: 2;
                }
                .image-block {
                  order: 1;
                }
              }
            }
            &:nth-child(n+2) {
              margin-top: 25px;
              @include pc {
                margin-top: 62px;
              }
            }

            & > .text-block {
              @include pc {
                width: calc(50% - 18px);
              }
              & > h4.title {
                @include font-noto_sans_regular;
                font-size: 22px;
                padding-top: 25px;
                position: relative;
                @include pc {
                  @include font-noto_sans_regular;
                  font-size: 31px;
                  padding-top: 70px;
                }
                // 線
                &::before {
                  background-color: #c60218;
                  content: "";
                  display: inline-block;
                  position: absolute;
                  top: 8px;
                  left: 0;
                  height: 2px;
                  width: 20px;
                  @include pc {
                    top: 48px;
                    width: 25px;
                  }
                }
                // 数字
                &::after {
                  counter-increment: ability-list;
                  content: counter(ability-list, decimal-leading-zero);
                  @include font-Montserrat_medium;
                  display: inline-block;
                  color: #c60218;
                  font-size: 17px;
                  position: absolute;
                  top: 0;
                  left: 30px;
                  line-height: 1em;
                  text-align: center;
                  @include pc {
                    @include font-Montserrat;
                    font-size: 18px;
                    top: 40px;
                    left: 35px;
                  }
                } // &::before
              }
              & > p.text {
                @include font-noto_sans_light;
                font-size: 13px;
                margin-top: 15px;
                line-height: 1.7;
                @include pc {
                  font-size: 16.5px;
                  line-height: 2;
                  margin-top: 5px;
                }
              }
              & > .button {
                display: none;
                @include pc {
                  @include font-noto_sans;
                  background: #fff;
                  border: 1px solid #bad3d6;
                  border-radius: 35px;
                  color: #000;
                  cursor: pointer;
                  display: flex;
                  font-size: 14px;
                  align-items: center;
                  justify-content: center;
                  letter-spacing: .08em;
                  text-decoration: none;
                  margin-top: 35px;
                  transition: all .2s;
                  height: 45px;
                  width: 100%;
                  max-width: 450px;

                  &:hover {
                    background-color: #bad3d6;
                    color: #FFF;
                  }
                }
              } // .button
            }

            & > .image-block {
              @include pc {
                width: calc(50% - 18px);
              }
              & > p.image {
                border: 1px solid #c0d2d5;
                overflow: hidden;
                margin-top: 15px;
                @include pc {
                  margin-top: 0;
                }
                img {
                  display: block;
                }
              }
            }
          }
        } // ol.ability-list

        & > .button {
          @include font-noto_sans_medium;
          background: #fff;
          border: 1px solid #bad3d6;
          border-radius: 35px;
          color: #000;
          cursor: pointer;
          display: flex;
          font-size: 13px;
          align-items: center;
          justify-content: center;
          letter-spacing: .08em;
          text-decoration: none;
          margin: 20px auto 0;
          transition: all .2s;
          height: 45px;
          width: 100%;
          max-width: 270px;
          @include pc {
            display: none;
          }
        } // .button

        & > p.note {
          @include font-noto_sans_light;
          font-size: 12px;
          margin-top: 30px;
          line-height: 1.83;
          letter-spacing: 0.16em;
        } // p.note
      }

    } // &#section-03

    &#section-04 {
      padding-top: 35px;
      @include pc {
        padding-top: 60px;
      }
      .p-taflex-inner {
        padding: 0 20px;
        @include pc {
          padding: 0 60px;
        }
      }
    } // &#section-04
  }

  #section-02 {
    .c-product {
      width: 100%;

      &.-new {
        position: relative;
        overflow: hidden;

        &::before {
          @include font-Montserrat_medium;
          content: "NEW!";
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          width: 80px;
          height: 80px;
          padding: 5px;
          font-size: 10px;
          color: #fff;
          background-color: #c60218;
          transform: translate(-50%, -50%) rotate(-45deg);
          z-index: 4;
          @include pc {
            width: 95px;
            height: 95px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .section-beta-01,
  .section-beta-02 {
    position: relative;
    padding: 50px 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    @include pc {
      padding: 80px 0 70px 0;
    }

    &.-new {
      &::before {
        @include font-Montserrat_medium;
        content: "NEW!";
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 115px;
        height: 115px;
        padding: 5px;
        font-size: 12px;
        color: #fff;
        background-color: #c60218;
        transform: translate(-50%, -50%) rotate(-45deg);
        z-index: 1;
        @include pc {
          width: 160px;
          height: 160px;
          font-size: 15px;
        }
      }
    }

    .glasses-image-wrap {
      text-align: center;
    }

    .textwrap {
      width: 70%;
      margin: 40px 0 0 auto;
      @include pc {
        max-width: 650px;
        width: 100%;
        margin: 25px auto 0 auto;
      }

      .text {
        font-size: 13px;
        line-height: 2;
        @include pc {
          font-size: 16px;
        }
      }
    }

    .c-product-list {
      display: flex;
      flex-wrap: wrap;
      margin: 25px 12px 0 12px;
      @include pc {
        max-width: 1024px;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: center;
        margin: 35px auto 0 auto;
      }

      li {
        width: calc(50% - 15px);
        margin: 7.5px;
        @include pc {
          max-width: 305px;
          width: 100%;
          margin: 0 15px;
        }

        .caption {
          margin-top: 10px;
          font-family: "Noto Sans Japanese",sans-serif;
          font-size: 13px;
          font-weight: 200;
          line-height: 1.77;
        }
      }

      .c-product {
        width: 100%;

        &.-new {
          position: relative;
          overflow: hidden;

          &::before {
            @include font-Montserrat_medium;
            content: "NEW!";
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 80px;
            height: 80px;
            padding: 5px;
            font-size: 10px;
            color: #fff;
            background-color: #c60218;
            transform: translate(-50%, -50%) rotate(-45deg);
            z-index: 4;
            @include pc {
              width: 95px;
              height: 95px;
              font-size: 12px;
            }
          }
        }
      }
    }

    .button {
      @include font-noto_sans_medium;
      background: #fff;
      border-radius: 35px;
      color: #333;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      letter-spacing: .2em;
      text-decoration: none;
      margin-top: 35px;
      transition: all .2s;
      height: 55px;
      width: 100%;
      @include pc {
        @include font-noto_sans;
        margin: 20px auto 0;
        max-width: 332px;
        &:hover {
          background-color: #333;
          color: #FFF;
        }
      }
    }
  }

  .section-beta-01 {
    .glasses-image-wrap {
      width: 77vw;
      margin: 0 auto;
      @include pc {
        width: auto;
      }
    }

    .textwrap {
      color: #fff;
    }

    .c-product-list {
      li {
        .caption {
          color: #fff;
        }
      }
    }
  }

  .section-beta-02 {
    .glasses-image-wrap {
      width: 48vw;
      margin: 0 auto;
      @include pc {
        width: auto;
      }
    }
  }
}
