@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-storeResult {
  &:not(.-confirm) {
    padding-top: 25px;
    margin-top: 30px;
    @include dotLineL($c-border1);
    @include pc {
      padding-top: 20px;
      margin-top: 30px;
    }
  }
  &__head {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    margin-bottom: 10px;
    line-height: (46 / 26);
    letter-spacing: 0.16em;
    @include fz(13);
    @include pc {
      @include vw(14);
      margin-bottom: 15px;
      line-height: (28 / 14);
    }
  }
  &__title {
    letter-spacing: 0.16em;
  }
  &__nom {
    letter-spacing: 0.08em;
  }
  .error {
    margin-bottom: 10px;
  }
}
