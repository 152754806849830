@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-listBox {
  &__list {
    &:not(.-white) .c-listBox__item {
      &:nth-child(odd) {
        background: $c-base;
      }
      &:nth-child(even) {
        background: $c-base2;
      }
    }
    &.-white {
      @include pc{
        border: 1px solid $c-border1;
      }
    }
  }
  &__head {
    display: block;
    padding: 18px 6% 33px;
    position: relative;
    @include pc {
      display: flex;
      padding: 22px 2.5% 20px;
    }
    &:after {
      content: '';
      background: url(/img/common/icon_arrow02.svg)no-repeat;
      background-size: 100% auto;
      display: block;
      height: 18px;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transition: all 0.25s;
      transform: translateX(-50%) rotate(90deg);
      width: 12px;
      @include pc {
        top: 50%;
        left: auto;
        right: 35px;
        transform: translateY(-50%) rotate(90deg);
      }
    }
    &:hover {
      &:after {
        @include pc {
          top: 56%;
        }
      }
    }
  }
  &__date {
    @include font-Montserrat_medium;
    font-size: 11px;
    line-height: (18/11);
    letter-spacing: 0.16em;
    margin-bottom: 16px;
    position: relative;
    @include pc {
      border-right: 1px solid #000;
      font-size: 14px;
      line-height: (23/14);
      margin-bottom: 0;
      padding-top: 2px;
      padding-bottom: 2px;
      width: 110px;
    }
    &:after {
      content: '';
      background: #776b69;
      display: block;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: -8px;
      width: 13px;
      @include pc {
        content: none;
      }
    }
  }
  &__title {
    @include font-noto_sans;
    font-size: 13px;
    line-height: (19/13);
    letter-spacing: 0.08em;
    word-break: break-all;
    @include pc {
      font-size: 14px;
      line-height: (23/14);
      margin-left: 2.4%;
      padding-right: 3.6%;
      width: calc(100% - 140px);
    }
    a {
      text-decoration: none;
      @include pc {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    b {
      display: block;
      font-weight: 700;
      margin-bottom: 5px;
    }
  }
  &__body {
    @include font-noto_sans;
    font-size: 13px;
    line-height: (19/13);
    letter-spacing: 0.16em;
    padding: 0 6.4% 20px;
    @include pc {
      font-size: 16px;
      line-height: (28/16);
      letter-spacing: 0.14em;
      padding: 13px 2.5% 30px;
    }
    &Inner {
      background: #fff;
      border: 1px solid #ebd1cc;
      padding: 25px 6.4%;
      @include pc {
        padding: 50px 5.2%;
      }
    }
    sup {
      font-size: 13px;
      top: 0;
      @include pc {
        font-size: 16px;
      }
    }
  }
}
