@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-product {
  .c-sec__inner {
    padding-top: 30px;
    padding-bottom: 30px;
    @include pc {
      padding: 4% 3.8%;
    }
  }
  & &__head {
    background: #f0f5f6;
    &__inner {
      @include pc {
        display: flex;
        flex: 1 0 auto;
        justify-content: space-between;
      }
      .c-product {
        width: 100%;
        background-color: #fff;
        border-color: $c-border1;
        @include tb {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 30px 30px 30px 0;
        }
        &Main {
          @include tb {
            flex-basis: 64.7%;
            width: 64.7%;
            max-width: 64.7%;
            border-right: 1px solid #d9dcdd;
          }
          &__pager {
            width: 100%;
            padding: 0 20px;
            @include tb {
              padding: 0 30px;
            }
            ul {
              display: flex;
              justify-content: flex-end;
              @include tb {
                justify-content: flex-start;
              }
              li {
                width: 7px;
                height: 7px;
                margin-left: 5px;
                overflow: hidden;
                border: 1px solid #000;
                border-radius: 50%;
                @include tb {
                  position: relative;
                  width: 80px;
                  height: 50px;
                  margin-right: 5px;
                  margin-left: 0;
                  border: none;
                  border-radius: 0;
                }
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  pointer-events: none;
                  @include tb {
                    position: relative;
                    overflow: hidden;
                    border: 1px solid transparent;
                  }
                  img {
                    display: none;
                    @include tb {
                      display: block;
                    }
                  }
                }
                &:hover,
                &.-active {
                  background-color: #000;
                  @include tb {
                    background-color: transparent;
                  }
                  a {
                    @include tb {
                      background-color: transparent;
                      border-color: $c-border1;
                    }
                  }
                }
              }
            }
          }
        }
        &Item {
          padding: 0 20px 20px;
          @include tb {
            flex-basis: 35%;
            width: 35%;
            max-width: 35%;
            padding-bottom: 0;
            padding-left: 30px;
          }
          .c-productMain {
            &__body {
              padding: 0;
            }
            &__title {
              height: auto !important;
              margin: 0.25em 0 0;
              em {
                display: block;
                margin-bottom: 5px;
                font-style: normal;
                @include fz(25);
                @include pc {
                  @include fz(38);
                }
              }
              .c-text {
                @include fz(13);
                @include tb {
                  font-size: 13px;
                }
              }
            }
            &__spec {
              padding: 0;
              margin-top: 20px;
              border: none;
              @include tb {
                margin-top: 30px;
              }
            }
            &__pager {
              padding: 0;
              margin-bottom: 5px;
              ul {
                justify-content: flex-start;
                li {
                  position: relative;
                  width: calc(137px / 2);
                  height: 43px;
                  margin-right: 5px;
                  margin-left: 0;
                  border: none;
                  border-radius: 0;
                  @include pc {
                    width: 77px;
                    height: 48px;
                  }
                  a {
                    pointer-events: auto;
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border: 2px solid transparent;
                    @include pc {
                      border-width: 2px;
                    }
                    img {
                      display: block;
                    }
                  }
                  &:hover,
                  &.-active {
                    background-color: transparent;
                    a {
                      background-color: transparent;
                      border-color: $c-border1;
                    }
                  }
                }
              }
            }
            &__foot {
              padding: 0 0 20px;
              margin: 0;
              position: relative;
              &::before {
                display: none;
              }
              &::after {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                height: 1.5px;
                content: "";
                background-image: linear-gradient(to right, #000, #000 1.5px, transparent 1.5px, transparent 5px);
                background-repeat: repeat-x;
                background-size: 5px 1.5px;
                @media screen and (min-width: 980px) {
                  height: 2px;
                  background-image: linear-gradient(to right, #e6e8e9, #e6e8e9 2px, transparent 2px, transparent 5px);
                  background-size: 5px 2px;
                }
              }
              &.-end {
                padding-bottom: 0;
                &::after {
                  display: none;
                }
                .c-productMain__end {
                  color: $c-primary;
                  @include fz(13);
                  margin-top: 10px;
                  @include pc {
                    @include vw(14);
                    margin-top: 10px;
                  }
                }
              }
            }
            &__priceYen {
              width: 100%;
              @include pc {
                margin-top: 0.5em;
              }
            }
            &__try {
              .c-text {
                height: 29px;
                padding-left: 2.5em;
                font-size: 15px;
                line-height: 29px;
                text-decoration: none;
                &::before {
                  width: 29px;
                  height: 29px;
                }
                &::after {
                  top: 8px;
                  left: 6px;
                  width: 17.5px;
                  height: 13px;
                }
              }
            }
          }
        }
      }
      .c-btnWrap {
        padding: 0 15px;
        margin: 16px 0 0;
        @include pc {
          margin-top: 30px;
        }
        & + .c-btnWrap {
          margin-top: 10px;
          @include pc {
            margin-top: 18px;
          }
        }
        .c-btn {
          a {
          }
        }
      }
    }
  }
  .c-productList {
    @include pc {
      flex-basis: 25.6%;
      width: 25.6%;
      max-width: 25.6%;
    }
    .c-productItem {
      padding-right: 6%;
      padding-left: 6%;
      margin-top: 20px;
      background: #fff;
      border: 1px solid #ebd1cc;
      @include pc {
        padding: 18px 7%;
        margin-top: 30px;
        &:first-of-type {
          margin-top: 0;
        }
      }
      &::before {
        display: none;
      }
      &__inner {
        @include pc {
          transform: translateY(50%);
        }
      }
      &__title {
        @include pc {
          padding-right: 0;
        }
      }
      .c-label {
        margin-bottom: 13px;
      }
      &__fav {
        top: 0;
        right: 0;
        @include pc {
          top: 20px;
          right: 6%;
        }
      }
      &__price {
        margin-bottom: 0;
        line-height: inherit;
        @include fz(25);
        @include pc {
          @include vw(30);
          margin-bottom: 15px;
        }
        &Tax {
          font-size: 12px;
          @include sp {
            font-size: 13px;
          }
        }
      }
      .c-btn {
        a {
          font-size: 11px;
          @include pc {
            font-size: 12px;
          }
        }
      }
    }
  }
  &Detail {
    padding: 25px 5% 0;
    @include pc {
      box-sizing: border-box;
      max-width: 1440px;
      padding: 56px 3.8% 0;
      margin: 0 auto;
    }
    &__head {
      margin-bottom: 10px;
      @include pc {
        margin-bottom: 30px;
      }
    }
    &__title {
      margin-bottom: 1em;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.16em;
      @include pc {
        font-size: 18px;
      }
    }
    &__titleSub {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.16em;
      display: inline;
      @include pc {
        font-size: 18px;
      }
    }
    &__inner {
      @include pc {
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;
      }
    }
    &__text {
      margin-bottom: 27px;
      @include pc {
        flex: 1;
        margin-right: 4.3%;
        margin-bottom: 0;
      }
    }
    &__textInner {
      @include font-noto_sans;
      font-size: 13px;
      line-height: (38/26);
      letter-spacing: 0.16em;
      word-break: break-all;
      @include pc {
        padding-right: 25px;
        font-size: 14px;
        line-height: (57/32);
        letter-spacing: 0.14em;
      }
      a {
        color: #d60011;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      img {
        max-width: 50%;
        max-height: 120px;
        @include pc {
          max-height: 200px;
        }
      }
    }
    &__link {
      margin-top: 10px;
      font-size: 13px;
      a {
        padding-bottom: 2px;
        color: #000;
        text-decoration: none;
        white-space: nowrap;
        border-bottom: 1px solid #000;
        transition: all 0.2s;
        &:hover {
          border-bottom: 1px solid #fff;
        }
      }
    }
    &__feature {
      @include pc {
        flex-basis: 25.6%;
        width: 25.6%;
      }
      .c-productItem__feature {
        justify-content: flex-start;
        margin-bottom: 30px;
        @include pc {
          margin-bottom: 25px;
        }
      }
      .c-icon__list {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 17px;
        margin-left: -5%;
        @include pc {
          margin-top: 3px;
          margin-bottom: 30px;
          margin-left: inherit;
        }
        .c-icon__item {
          width: calc(20% - 3%);
          margin-left: 3%;
          @include pc {
            &:first-child {
              margin-left: 0;
            }
          }
          &:nth-of-type(5n + 6) {
            margin-left: 0;
          }
          &:nth-of-type(n + 6) {
            margin-top: 5px;
          }
        }
      }
      &List {
        position: relative;
        padding-top: 25px;
        font-size: 13px;
        @include pc {
          padding-top: 20px;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 3px;
          content: "";
          background: url(/img/common/line_dot_gray.svg) repeat-x;
        }
      }
      &Item {
        line-height: (47.4/26);
        letter-spacing: 0.16em;
        @include pc {
          line-height: (50.6/26);
        }
      }
    }
  }
  .c-table {
    margin-bottom: 30px;
    @include pc {
      flex-basis: 34.1%;
      width: 34.1%;
      margin-top: 3px;
      margin-bottom: 0;
    }
    &__inner {
      @include font-Montserrat;
      font-size: 13px;
      letter-spacing: 0.06em;
      @include pc {
        font-size: 14px;
      }
    }
    &__table {
      th {
        width: 33%;
        @include pc {
          width: 26%;
        }
        .c-table__title {
          padding: 0 9px;
          @include pc {
            padding: 0 15px;
          }
        }
      }
      td {
        padding: 12px 0 10px;
        @include pc {
          padding: 12px 0 8px;
        }
      }
    }
  }
  &Info {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    &__head {
      position: relative;
      padding: 25px 0 20px;
      @include pc {
        padding: 55px 0;
        cursor: pointer;
      }
      &::before {
        position: absolute;
        top: 25px;
        right: 8px;
        display: block;
        width: 10px;
        height: 6px;
        content: "";
        background: url(/img/common/icon_arrow03.svg) no-repeat;
        background-size: 100% auto;
        transition: all 0.25s;
        transform: translateX(-50%) rotate(180deg);
        @include pc {
          top: 47px;
          right: 20px;
        }
      }
      &::after {
        position: absolute;
        right: 8px;
        bottom: 25px;
        display: block;
        width: 10px;
        height: 6px;
        content: "";
        background: url(/img/common/icon_arrow03.svg) no-repeat;
        background-size: 100% auto;
        transition: all 0.25s;
        transform: translateX(-50%);
        @include pc {
          right: 20px;
          bottom: 47px;
        }
      }
      &.-open {
        &::before {
          transform: translateX(-50%) rotate(0deg);
        }
        &::after {
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }
    &__title {
      font-weight: 500;
      letter-spacing: 0.16em;
      @include pc {
        font-size: 18px;
      }
    }
    &__inner {
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    &__close {
      .js-accordion__close {
        position: relative;
        display: block;
        padding: 20px 0;
        margin-top: 20px;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 10px;
          height: 6px;
          content: "";
          background: url(/img/common/icon_arrow03.svg) no-repeat;
          background-size: 100% auto;
          transition: all 0.25s;
          transform: translateX(-50%) rotate(180deg);
        }
        @include pc {
          &:hover::before {
            top: 40%;
          }
        }
      }
    }
  }
}
