@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-reserve {
  padding-top: 40px;
  @include pc {
    padding-top: 0;
  }
  h1 {
    margin-top: 0;
  }
  & &__date {
    &:first-of-type {
      margin-top: 8px;
      @include pc {
        margin-top: 0;
      }
    }
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }
  & &__confirm {
    &:not(:first-of-type) {
      margin-top: 20px;
      @include pc {
        margin-top: 8px;
      }
    }
  }
  & &__complete {
    padding: 35px 0;
    @include pc {
      padding: 70px 0 75px;
    }
    .p-reserve {
      &__lead {
        @include lhCrop(50 / 30);
        @include fz(15);
        line-height: (50 / 30);
        text-align: center;
        letter-spacing: 0.16em;
        @include pc {
          @include lhCrop(36 / 18);
          @include fz(18);
          line-height: (36 / 18);
        }
      }
      &__note {
        margin-top: 3px;
        line-height: 1.7;
        color: #6f6f6f;
        text-align: center;
        @include fz(12);
        @include pc {
          line-height: (24 / 12);
          margin-top: 10px;
        }

        &.-red {
          color: #e60012;
        }
      }
    }
  }
}
