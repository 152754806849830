@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-generator {
  & &__head {
    @include font-noto_sans;
    padding: 15px 0;
    font-size: 13px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.16em;
    background: $c-primary;
  }
  & &__body {
    height: calc(100% - 50px);
    padding: 30px 5.7% 25px;
    background: $c-base;
    @include tb{
      padding: 40px 40px 30px;
    }
    &.-center {
      @include tb{
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
      }
    }
    .c-btnWrap {
      position: relative;
      padding-top: 30px;
      padding-bottom: 35px;
      margin: 45px 0 0;
      @include tb{
        padding-bottom: 0;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 3px;
        content: "";
        background: url(/img/common/line_dot_gray.svg) repeat-x;
      }
      .c-btn {
        button {
          font-size: 12px;
          @include pc{
            font-size: 13px;
          }
        }
      }
    }
  }
  & &__inner {
    @include tb{
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 784px;
      margin: 0 auto;
    }
  }
  & &__thumb {
    text-align: center;
    margin-bottom: 20px;
    @include tb{
      width: 43%;
      min-width: 43%;
      flex-basis: 43%;
      padding: 0 4.4% 0 7%;
      box-sizing: border-box;
      margin-bottom: 0;
    }
    img {
      width: 50%;
      max-width: 400px;
      @include tb{
        width: 100%;
        height: auto;
        max-width: 248px;
      }
    }
  }
  & &__code {
    text-align: center;
    margin-top: 10px;
    @include tb{
      width: 57%;
      min-width: 57%;
      flex-basis: 57%;
    }
    img {
      width: 40%;
      max-width: 180px;
      height: auto;
    }
    figcaption {
      margin-top: 20px;
      @include tb{
        margin-top: 40px;
        font-size: 16px;
        line-height: (28 / 16);
      }
    }
    .url {
      letter-spacing: 0.16em;
      display: block;
      margin-top: 8px;
      @include tb{
        font-size: 14px;
        line-height: (25 / 14);
      }
    }
  }
}
