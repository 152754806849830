@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

// @include font-noto_sans;
.p-list {
  padding-bottom: 30px;
  overflow: hidden;
  background-color: #f0f5f6;
  @include pc {
    padding-bottom: 60px;
  }
  .c-sec__inner {
    padding-top: 30px;
    @include pc {
      padding: 0 60px;
    }
  }
  &__nav {
    position: relative;
    padding: 20px 0;
    margin: 0 -5%;
    @include sp {
      padding: 0;
    }
    @include pc {
      height: auto;
      margin: 0 auto;
      border-bottom: 1px solid #fff;
    }
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      content: "";
      background: #fff;
      @include pc {
        content: none;
      }
    }
    &List {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      @include sp {
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
      @include pc {
        padding: 0 20px;
      }
      a {
        @include font-noto_sans;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        padding: 8px 20px;
        font-size: 14px;
        color: #000;
        text-decoration: none;
        letter-spacing: 0.14em;
        transition: all 0.2s;
        @include sp {
          height: 100%;
          padding: 24px 0;
          font-size: 11px;
          border-bottom: 1px solid #fff;
        }
        @include pc {
          display: block;
        }
        &:hover {
          @include pc {
            color: #e60012;
          }
        }
      }
    }
    &Item {
      @include sp {
        width: 25%;
        text-align: center;
      }
      @include pc {
      }
      &.-current {
        a {
          position: relative;
          color: #e60012;
          @include pc {
            //            border-bottom: 1px solid #e60012;
          }
          &::after {
            @include sp {
              position: absolute;
              bottom: -1px;
              left: 0;
              display: block;
              width: 100%;
              height: 1px;
              content: "";
              background: #e60012;
            }
            @include pc {
              content: none;
            }
          }
        }
      }
    }
  }
  &__head {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    @include pc {
      padding-top: 50px;
      padding-bottom: 20px;
      margin-bottom: 50px;
      border-bottom: 1px solid #cecece;
    }
  }
  &__pageTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 13px;
    letter-spacing: 0.28em;
    @include pc {
      margin-bottom: 50px;
    }
    .title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 15px;
      @include font-noto_sans;
      @include pc {
        font-size: 21px;
      }
    }
    .num {
      @include font-Montserrat_regular;
      position: relative;
      z-index: 2;
      display: flex;
      flex-basis: 25px;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      font-size: 10px;
      color: #fff;
      letter-spacing: -0.01em;
      white-space: nowrap;
      @include pc {
        flex-basis: 31px;
        width: 31px;
        height: 31px;
        margin-left: 3px;
        font-size: 12px;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        width: 25px;
        height: 25px;
        content: "";
        background: #e60012;
        border-radius: 50%;
        @include pc {
          width: 31px;
          height: 31px;
        }
      }
    }
  }
  &__select {
    display: flex;
    justify-content: center;
    .c-select1 + .c-select1 {
      margin-left: 10px;
    }
    .c-select1 {
      @include sp {
        width: 38%;
      }
    }
  }
  &__refine {
    display: flex;
    .c-text.en {
      text-transform: uppercase;
    }
  }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    section:not(.p-topRecommend) & {
      @include tb {
        justify-content: flex-start;
      }
    }
    @include pc {
      justify-content: flex-start;
      flex-direction: row;
      align-items: stretch;
      padding: 0;
      margin-right: auto;
      margin-left: auto;
    }
  }
  &__product {
    width: calc(100% / 2 - 15px / 2);
    padding: 0;
    margin-bottom: 20px;
    border: none;
    @include tb {
      width: calc(100% / 3 - 15px * 2 / 3);
    }
    @include pc {
      width: calc(100% / 4 - 30px * 3 / 4);
      margin-bottom: 50px;
    }
    a {
      color: #000;
      text-decoration: none;
    }
    &:hover {
      //.c-productMain__thumb img {
      //  @include pc {
      //    transform: scale(1.05);
      //  }
      //}
      .c-productItem__title {
        color: #e60012;
      }
    }
  }
  &__resultText {
    @include font-noto_sans_regular;
    @include fz(15);
    width: 100%;
    line-height: (20/15);
    letter-spacing: 0.16em;
    @include pc {
      @include vw(18);
      line-height: (24/18);
      letter-spacing: 0.14em;
    }
    &.-center {
      text-align: center;
    }
  }
}
