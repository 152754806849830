@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-table {
  &__table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      height: 100%;
      padding: 10px 5px;
      font-size: 12px;
      text-align: left;
      word-break: break-all;
      vertical-align: middle;
    }
    th {
      width: 26%;
      font-weight: normal;
    }
    tr:nth-of-type(2n + 1) th,
    tr:nth-of-type(2n + 1) td {
      background: #f0f5f6;
    }
    tr:nth-of-type(2n + 2) th,
    tr:nth-of-type(2n + 2) td {
      background: #fff;
    }
    tbody {
      td,
      th {
        text-align: left;
        &.-center {
          text-align: center;
        }
        &.-right {
          text-align: right;
        }
      }
    }
  }
  &__title {
    display: block;
    padding: 0 15px;
    word-break: break-all;
  }
  &__inner {
    height: 100%;
    padding: 0 15px;
    margin-left: -1px;
    word-break: break-all;
    border-left: 1px solid #a0a0a0;
    &.-en {
      word-break: normal
    }
    p {
      line-height: (22/12);
      &:first-of-type {
        margin-top: 4px;
      }
    }
  }
  &.-spec {
    th,
    td {
      font-size: 12px;
    }
    .c-table__title {
      white-space: nowrap;
    }
  }
  &.-static {
    margin: 30px 0;
    @include pc {
      margin: 40px 0;
    }
    tbody {
      border: 1px solid #bfbfbf;
    }
    tr {
      border-top: 1px solid #bfbfbf;
      &:first-of-type {
        border-top: none;
      }
    }
    th {
      width: 33%;
      vertical-align: top;
      @include pc {
        width: 20%;
      }
    }
    th,
    td {
      padding: 12px 0;
      font-size: 12px;
      line-height: (22/12);
      background: #fff !important;
      @include pc {
        padding: 15px 0;
        font-size: 14px;
      }
    }
    .c-table {
      &__title {
        @include pc {
          padding: 0 18px;
        }
      }
      &__inner {
        padding: 0 20px;
      }
    }
  }
  &.-scroll {
    padding: 0 5%;
    margin-left: -5%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @include pc {
      padding: 0;
      margin: 0;
    }
    thead {
      th {
        padding: 12px 1em;
        text-align: center;
        @include pc {
          padding: 15px 1em;
        }
      }
    }
    tbody {
      th {
        width: 20%;
        padding: 12px 15px;
        @include pc {
          padding: 15px 20px;
        }
      }
      td {
      }
    }
    tr {
      border-bottom: 1px solid #bfbfbf;
    }
    th {
      width: 13%;
      background: #f7f7f7 !important;
      border-right: 1px solid #bfbfbf;
      @include pc {
        @include vw(14);
      }
      .small {
        font-size: 12px;
      }
    }
    td {
      background: #fff !important;
      border-right: 1px solid #bfbfbf;
      @include pc {
        @include vw(14);
      }
    }
    .c-table {
      &__table {
        @include font-noto_sans;
        letter-spacing: 0.16em;
        border-top: 1px solid #bfbfbf;
        border-left: 1px solid #bfbfbf;
        @include sp {
          white-space: nowrap;
        }
      }
      &__title {
        padding: 0;
      }
      &__inner {
        padding: 0 8px;
        border-left: none;
      }
    }
  }
  &.-normal {
    padding: 0 5%;
    margin: 0 -5%;
    @include pc {
      padding: 0;
      margin: 0;
    }
    thead {
      th {
        padding: 12px 0;
        text-align: center;
        @include pc {
          padding: 15px 0;
        }
      }
    }
    tbody {
      th {
        width: 20%;
        padding: 12px 15px;
        white-space: nowrap;
        @include pc {
          padding: 15px 20px;
        }
      }
      td {
      }
    }
    tr {
      border-bottom: 1px solid #bfbfbf;
    }
    th {
      width: 13%;
      background: #f7f7f7 !important;
      border-right: 1px solid #bfbfbf;
      @include pc {
        @include vw(14);
      }
      .small {
        font-size: 12px;
      }
    }
    td {
      background: #fff !important;
      border-right: 1px solid #bfbfbf;
      @include pc {
        @include vw(14);
      }
    }
    .c-table {
      &__table {
        @include font-noto_sans;
        letter-spacing: 0.16em;
        border-top: 1px solid #bfbfbf;
        border-left: 1px solid #bfbfbf;
      }
      &__title {
        padding: 0;
      }
      &__inner {
        padding: 0 8px;
        border-left: none;
      }
    }
  }
  &.-border {
    padding: 0;
    margin-left: 0;
    thead {
      th {
        padding: 8px 6px;
        text-align: center;
        @include pc {
          padding: 13px 1em;
        }
      }
    }
    tbody {
      th {
        padding: 8px 6px;
        @include pc {
          padding: 13px 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #bfbfbf;
    }
    th {
      background: #f7f7f7 !important;
      border-right: 1px solid #bfbfbf;
      @include fz(10);
      @include pc {
        font-size: 14px;
      }
      .small {
        display: inline-block;
        @include fz(10);
        transform: scale(0.7);
        @include pc {
          font-size: 10px;
          transform: none;
        }
      }
    }
    td {
      background: #fff !important;
      border-right: 1px solid #bfbfbf;
      @include fz(10);
      padding: 17px 6px;
      @include pc {
        font-size: 14px;
        padding: 14px 5px;
      }
    }
    .c-table {
      &__table {
        @include font-noto_sans;
        letter-spacing: 0.16em;
        border-top: 1px solid #bfbfbf;
        border-left: 1px solid #bfbfbf;
      }
      &__title {
        padding: 0;
      }
      &__inner {
        padding: 0;
        border-left: none;
      }
    }
  }
  &__text {
    @include font-Montserrat_semiBold;
    position: relative;
    padding-right: 12px;
    margin-top: 12px;
    font-size: 11px;
    color: #e60012;
    text-align: right;
    letter-spacing: 0.14em;
    &::after {
      position: absolute;
      top: 50%;
      right: -2px;
      display: block;
      width: 10px;
      height: 6px;
      content: "";
      background: url(/img/common/icon_arrow05.svg) no-repeat;
      background-size: 100% auto;
      transform: translateY(-50%) rotate(-90deg);
    }
  }
  &__thumb {
    .p-static & {
      margin-top: 15px;
      @include pc{
        width: auto;
        margin-top: 18px;
      }
    }
  }
}

.c-table {
  &__normal {
    width: 100%;
    border-collapse: collapse;
    caption {
      margin-bottom: 10px;
      font-size: 16px;
      color: #e60012;
      text-align: left;
    }
    th,
    td {
      height: 100%;
      padding: 10px 5px;
      font-size: 14px;
      text-align: left;
      word-break: break-all;
      vertical-align: middle;
      border-top: 1px solid #bfbfbf;
      border-bottom: 1px solid #bfbfbf;
      @include pc {
        padding: 12px 5px;
        font-size: 12px;
      }
    }
    th {
      width: 35%;
      font-weight: normal;
    }
    tbody {
      td,
      th {
        text-align: left;
        &.-center {
          text-align: center;
        }
        &.-right {
          text-align: right;
        }
      }
      th {
        border-left: 1px solid #bfbfbf;
      }
      td {
        border-right: 1px solid #bfbfbf;
      }
    }
    &Title {
      display: block;
      padding: 0 10px;
      word-break: break-all;
      @include pc {
        padding: 0 15px;
      }
    }
    &Inner {
      height: 100%;
      padding: 0 15px;
      margin-left: -1px;
      word-break: break-all;
      border-left: 1px solid #bfbfbf;
    }
  }
}
