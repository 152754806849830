@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-campaign {
  &Inner {
    margin: auto;
    max-width: 1440px;
  }
  &Banner {
    background: #c3000f;
    color: #fff;
    padding: 30px 20px;
    margin-bottom: 40px;
    @include pc {
      background: $c-primary;
      padding: 35px 0 18px;
      margin-bottom: 80px;
    }
    &__title {
      @include font-Montserrat_extralight;
      margin-bottom: 15px;
      @include pc {
        line-height: 2;
        text-align: center;
        position: relative;
      }
      .speechbubble {
        background: url(/img/common/bg_speechbubble_white.svg) no-repeat center;
        background-size: auto;
        color: $c-primary;
        font-size: 14px;
        padding: 6px 8px 10px 10px;
        line-height: 3;
        @include pc {
          padding: 10px 12px 25px 15px;
          position: absolute;
          top: -15px;
          left: 0;
          right: 0;
          margin: 0 auto;
          line-height: 1;
          transform: translateX(-11em);
          height: 3em;
          width: 7em;
          @include IE {
            transform: translateX(-12em);
          }
        }
      }
      .small {
        font-size: 15px;
        @include pc {
          font-size: 18px;
          padding-left: 6em;
        }
      }
      .large {
        font-size: 28px;
        @include pc {
          font-size: 32px;
        }
      }
    } // &__title
    .btnWrap {
      margin: 0 auto 8px;
      max-width: 440px;
      .c-btn.-white.-anchor {
        a {
          color: $c-primary;
          border: 1px solid #fff;
          font-size: 15px;
          position: relative;
          &::after {
            position: absolute;
            top: calc(50% + 4px);
            right: 19px;
            display: block;
            width: 12px;
            height: 12px;
            content: "";
            background: url(/img/common/icon_arrow_bottom_red.svg) no-repeat;
            background-size: 100% auto;
            transform: translateY(-50%);
          }
          @include pc {
            font-size: 18px;
            &:hover {
              background: $c-primary;
              color: #fff;
              &::after {
                background: url(/img/common/icon_arrow_bottom_white.svg) no-repeat;
                background-size: 100% auto;
              }
            }
          } // @include pc
        } // a
      } // .c-btn.-white.-anchor
    } // .btnWrap
    .note {
      font-size: 15px;
      text-align: center;
      @include pc {
        font-size: 18px;
      }
    }
  } // &Banner
  &Content {
    padding: 30px 20px;
    margin-bottom: 40px;
    @include pc {
      padding: 80px 60px 80px;
    }
    &.-blue {
      background: #eef3f6;
    }
    &__columns {
      margin-bottom: 40px;
      width: 100%;
      @include pc {
        margin-bottom: 60px;
        display: flex;
        align-items: center;
      }
    } // &__columns
    &__column {
      &:not(:last-child) {
        margin-bottom: 20px;
        @include pc {
          margin-bottom: 0;
          margin-right: 40px;
        }
      }
      @include pc {
        &.-large {
          margin-left: 5%;
          width: 62%;
        }
        &.-small {
          margin-right: 2.5%;
          width: 38%;
        }
      }
    } // &__column
    &__title {
      @include font-Montserrat_extralight;
      margin-bottom: 15px;
      @include pc {
        margin-bottom: 25px;
        position: relative;
      }
      .speechbubble {
        background: url(/img/common/bg_speechbubble_red.svg) no-repeat center;
        background-size: auto;
        color: #fff;
        font-size: 14px;
        padding: 6px 8px 10px 10px;
        line-height: 3;
      }
      .large {
        font-size: 28px;
        @include pc {
          font-size: 32px;
        }
      }
      .small {
        color: $c-primary;
        font-size: 15px;
        text-decoration: underline;
        @include font-noto_sans_bold;
        @include pc {
          font-size: 18px;
        }
      }
    } // &__title
    &__image {
      position: relative;
      .image {
        width: calc(100% - 30px);
      }
      .icon {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 20%;
        img {
          width: 100%;
        }
      }
    } // &__image
    &__box {
      background: #fff;
      border: solid 1px #e7e7e7;
      padding: 30px 20px;
      @include pc {
        padding: 50px 40px;
      }
      .title {
        font-size: 21px;
        @include font-Montserrat_extralight;
        margin-bottom: 30px;
        text-align: center;
        @include pc {
          font-size: 32px;
          margin-bottom: 25px;
        }
      } // .title
      .campaignContent__list {
        @include pc {
          display: flex;
        }
        li {
          &:not(:last-child) {
            margin-bottom: 30px;
            @include pc {
              margin-bottom: 0;
            }
          }
          @include pc {
            width: 50%;
          }
          figure {
            .image {
              margin-bottom: 20px;
              width: 100%;
            }
            figcaption {
              @include font-noto_sans_medium;
              font-size: 13px;
              text-align: center;
              @include pc {
                font-size: 18px;
              }
            }
          } // figure
        } // li
      } // .campaignContent__list
    } // &__box
  } // &Content
} // .p-campaign
