.c-lineBanner {
  position: fixed;
  max-width: 396px;
  width: 100%;
  bottom: 30px;
  right: 30px;
  transition: opacity .3s ease, visibility .3s ease;
  z-index: 11;
  @include sp {
    max-width: 370px;
    padding: 0 10px;
    left: 50%;
    right: auto;
    bottom: 88px;
    transform: translateX(-50%);
  }

  &.hide {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: opacity .3s ease, transform .3s ease, visibility .3s ease;
    @include sp {
      transform: translateY(10px) translateX(-50%);
    }
  }

  &-close {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;
    @include sp {
      top: -7px;
      right: 3px;
    }

    svg {
      width: 34px;
      height: 34px;
      @include sp {
        width: 25px;
        height: 25px;
      }
    }
  }

  &-image {
    &-pc {
      @include sp {
        display: none;
      }
    }

    &-sp {
      display: none;

      @include sp {
        display: block;
      }
    }
  }
}