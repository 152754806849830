@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.hdMaestro {
  display:none;
  @include pc{
    position: relative;
    z-index: 2;
  }
  .l-container.-show.-nocard & {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    @include pc {
      height: calc(100vh - 5.75rem);
    }
  }
  &__head {
    padding: 40px 20px 38px;
    background: $c-base;
    @include sp320 {
      padding-right: 15px;
      padding-left: 15px;
    }
    @include pc {
      padding: 58px 30px 55px;
    }
  }
  &__title {
    @include lhCrop(116 / 36);
    @include fz(18);
    font-weight: 500;
    line-height: (116 / 36);
    text-align: center;
    letter-spacing: 0.08em;
  }
  &__noProducts {
    @include lhCrop(38 / 26);
    @include fz(13);
    line-height: (38 / 26);
    text-align: center;
    @include pc {
      @include lhCrop(25 / 14);
      @include fz(14);
      line-height: (25 / 14);
      margin-top: 14px;
    }
    i {
      font-style: normal;
      border-bottom: 1px solid #000;
    }
  }
  &__btn {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    @include pc {
      margin-top: 30px;
    }
    .c-btn {
      &:first-of-type {
        flex-basis: 37%;
        width: 37%;
        max-width: 37%;
        @include pc {
          flex-basis: 32%;
          width: 32%;
          max-width: 32%;
        }
      }
      &:last-of-type {
        flex-basis: 60%;
        width: 60%;
        max-width: 60%;
        @include pc {
          flex-basis: 65%;
          width: 65%;
          max-width: 65%;
        }
      }
    }
  }
  &__note {
    margin-top: 8px;
    line-height: (30 / 24);
    color: #7f7f7f;
    text-align: center;
    letter-spacing: 0.16em;
    @include lhCrop(30 / 24);
    @include fz(11);
    @include pc {
      @include lhCrop(23 / 10);
      @include vw(10);
      margin-top: 14px;
      line-height: (23 / 10);
    }
  }
  &__noteProducts {
    margin-top: 18px;
    line-height: (30 / 24);
    color: #7f7f7f;
    text-align: center;
    letter-spacing: 0.16em;
    @include lhCrop(30 / 24);
    @include fz(11);
    @include pc {
      @include lhCrop(23 / 10);
      @include vw(10);
      margin-top: 21px;
      line-height: (23 / 10);
    }
  }
  &__make {
    align-self: center;
    width: 78%;
    max-width: 536px;
    padding: 40px 0;
    margin: auto;
    @include pc {
      width: 336px;
      max-width: none;
    }
  }
}
