@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-gnav {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  transition: bottom 0.2s ease;
  @include pc {
    top: 5.75rem;
    bottom: auto;
    left: 0;
    width: 100%;
    height: auto;
    transition: opacity 0.2s ease;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .l-headerNav__li.-open & {
    bottom: 0;
    @include pc {
      bottom: auto;
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }
  }
  .l-headerNav__li:not(.-open) & {
    @include pc {
    }
  }
  &__inner {
    padding: 20px 30px 60px 30px;
    @include sp320 {
      padding: 20px;
    }
    @include pc {
      display: flex;
      justify-content: center;
      padding: 50px 30px;
    }
    @media screen and (min-height: 1790px) {
      padding-top: 120px;
    }
  }
  &__column {
    @include pc {
      border-left: 1px solid #cecece;
      padding: 12px 24px;
    }
    &:not(:first-child) {
      margin-top: 20px;
      @include pc {
        margin-top: 0;
      }
    }
    &:last-child {
      @include pc {
        border-right: 1px solid #cecece;
      }
    }
  }
  &__btn {
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    height: 49px;
    color: #fff;
    background: $c-primary;
    border: 0;
    @include pc {
      height: 5.75rem;
    }
    > span {
      position: absolute;
      top: calc(16px - -2px);
      left: 50%;
      transition: all 0.3s ease;
      @include pc {
        top: calc(40% - -2px);
      }
    }
    &::before,
    &::after,
    > span::before,
    > span::after {
      position: absolute;
      left: calc(50% - 10px);
      width: 20px;
      height: 1px;
      content: "";
      background: #fff;
      transition: all 0.3s ease;
      @include pc {
        left: calc(50% - 12px);
        width: 24px;
        height: 2px;
      }
    }
    &::before {
      top: calc(16px - 5px);
      @include pc {
        top: calc(40% - 6px);
      }
    }
    &::after {
      top: calc(16px + 9px);
      @include pc {
        top: calc(40% + 10px);
      }
    }
    @include pc {
      .l-headerNav__li &:hover {
        background: #fff;
        &::before,
        &::after,
        > span::after {
          background: #c1000f;
        }
      }
    }
    .l-headerNav__li.-open & {
      background: #fff;
      @include pc {
        pointer-events: none;
      }
      &::before,
      &::after {
        background: #c1000f;
        transition: all 0.3s ease;
        @include sp {
          transform: scaleX(0);
        }
      }
      > span {
        &::before {
          background: #c1000f;
          transition: all 0.3s ease;
          @include sp {
            transform: rotate(-45deg);
          }
        }
        &::after {
          background: #c1000f;
          transition: all 0.3s ease;
          @include sp {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  &__list {
    line-height: calc(31 / 15);
    @include fz(15);
    @include pc {
      border: none;
      @include fz(14);
    }
  }
  &__list-inline {
    @include sp {
      border-top: 1px solid #d9d9d9;
      @include fz(14);
      display: flex;
      gap: 0 30px;
      justify-content: center;
      padding-top: 20px;
    }
  }
  &__list02 {
    border-left: 1px solid #cecece;
    @include fz(15);
    line-height: calc(31 / 15);
    margin: 9px 0 9px 20px;
    padding-left: 19px;
    @include pc {
      @include fz(14);
      line-height: calc(29 / 14);
      margin: 14px 0 14px 4px;
      padding-left: 16px;
    }
  }
  &__li {
    &:not(:first-of-type) {
      @include pc {
        //margin-top: 8px;
      }
    }
    a {
      display: block;
      text-decoration: none;
      @include pc {
        position: static;
        @include ov;
      }
    }
  }
  &__li-title {
    @include font-noto_sans_bold;
    @include fz(18);
    margin-bottom: 5px;
    &:not(:first-child) {
      margin-top: 20px;
      @include pc {
        margin-top: 20px;
      }
    }
  }
  &__buttons {
    margin-top: 18px;
    @include sp320 {
      margin-top: 14px;
    }
    @include pc {
      display: block;
      margin-top: 20px;
    }
    > .c-btn:nth-child(2n+1) {
      margin-right: 6px;
      @include pc {
        margin-right: 0;
      }
    }
    > .c-btn:nth-child(n+2) {
      margin-top: 15px;
      @include pc {
        margin-top: 14px;
      }
    }
  }
}
