@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-pagination {
  margin-top: 10px;
  &__list {
    display: flex;
    justify-content: center;
    a {
      color: #000;
      text-align: center;
      text-decoration: none;
    }
  }
  &__last,
  &__first,
  &__prev,
  &__next,
  &__item {
    margin: 0 5px;
    &.is-current {
      a {
        color: #fff;
        background: #e60012;
        border: 1px solid #e60012;
      }
    }
    a {
      display: block;
      width: 50px;
      height: 50px;
      font-size: 16px;
      line-height: 50px;
      background: #fff;
      border: 1px solid #353130;
      border-radius: 50%;
      transition: 0.2s ease-in-out;
      @include font-Montserrat_medium;
      @include pc {
        width: 58px;
        height: 58px;
        line-height: 58px;
      }
      &:hover {
        color: #fff;
        background: #e60012;
        border: 1px solid #e60012;
      }
      .-small & {
        width: 40px;
        height: 40px;
        font-size: 14px;
        line-height: 40px;
      }
    }
  }
  &__prev,
  &__next,
  &__last,
  &__first {
    a {
      position: relative;
      &::after,
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 10px;
        height: 12px;
        margin-top: -6px;
        margin-left: -5px;
        content: "";
        background-size: 100% auto;
      }
    }
    .text {
      display: none;
    }
  }
  &__prev {
    a::before {
      margin-left: -8px;
    }
  }
  &__next {
    a::before {
      margin-left: -3px;
    }
  }
  &__prev,
  &__first {
    a::before {
      background-image: url(/img/common/icon_paging_prev.svg);
    }
    a:hover::before {
      background-image: url(/img/common/icon_paging_prev_white.svg);
    }
  }
  &__next,
  &__last {
    a::before {
      background-image: url(/img/common/icon_paging_next.svg);
    }
    a:hover::before {
      background-image: url(/img/common/icon_paging_next_white.svg);
    }
  }
  &__first {
    a::after {
      border-left: 1px solid #000;
    }
    a:hover::after {
      border-left: 1px solid #fff;
    }
  }
  &__last {
    a::after {
      border-right: 1px solid #000;
    }
    a:hover::after {
      border-right: 1px solid #fff;
    }
  }
  &__text {
    display: none;
  }
}
