@charset "utf-8";

#privacy_agreement {
  &::after,&::before {
    display: none;
  }
  transform: scale(1.5);
  margin: 0 6px 0 6px;
}

.p-form.form {
  @include sp {
    margin-bottom: 40.33333vw;
  }
}

.common-banner-contact-us {

  z-index: 11;
  position: fixed;
  width:396px;
  height:120px;
  background-color: #ffeeee;
  border:2px solid #d32d26;
  border-radius: 10px;
  bottom:30px;
  right:30px;
  transition:opacity .3s ease, transform .3s ease;

  @include sp {
    width:700/750*100+vw;
    height:190/750*100+vw;
    bottom:88px;
    right:25/750*100+vw;
  }

  &.hide {
    pointer-events: none;
    opacity: 0;
    transform:translateY(10px);
  }

  a.common-banner-contact-us-close-button {
    position: absolute;
    top: -18px;
    right: -10px;
  }

  span.common-banner-contact-us-anchor {
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity .3s;

    .common-banner-contact-us-box {
      display: flex;
      justify-content: center;
      align-items: center;

      .common-banner-contact-us-text {
        width: 196px;
        flex-shrink: 0;

        @include sp {
          width: 53vw;
        }

        p.sub-ttl {
          font-size:40px;
          color:#c82a23;
          margin-top: 0;
          text-align: center;
          font-weight: 500;
          font-family: "Noto Sans Japanese Medium";
          line-height: 1.4em;
          @include sp {
            font-size: 10.93333vw;
            margin-left: 3vw;
            line-height: 1.3em;
            letter-spacing: 0;
          }
        }

        p.ttl {
          font-weight: 600;
          letter-spacing: .07em;
          margin-top:13px;
          font-size:13px;
          color:#c82a23;
          display: block;
          text-align: center;
          line-height: 1.3em;
          font-family: "Noto Sans Japanese Medium";
          font-weight: 500;


          @include sp {
            margin-top:1vw;
            font-size:3.2vw;
            //font-weight:normal;
            //letter-spacing: .15em;
            margin-left: 3vw;
          }
        }
      }

      .common-banner-contact-us-text-note {
        width: 166px;
        @include sp {
          width: 42vw;
          padding-right: 5vw;
        }
        p {
          font-weight: 500;
          font-family: "Noto Sans Japanese Medium";
          font-size: 10px;
          line-height: 1.3em;
          @include sp {
            font-size:2.3vw;
          }
        }
      }
    }


  }
}
