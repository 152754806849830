.p-recruit {
  @include font-noto_sans;
  &-contents {
    padding-top: 0 !important;
    @include pc {
      padding-top: 90px !important;
    }
  }
  &-inner {
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    margin-bottom: 15px !important;
    @include font-noto_sans_light;
    @include pc {
      flex-direction: row;
      font-size: 32px;
      margin-bottom: 30px !important;
    }
  }
  /*---------------------
  .hero
  -----------------------*/
  .hero {
    &-image {
      width: 100%;
      img {
        width: 100%;
      }
      &-pc {
        display: none;
        @include pc {
          display: block;
        }
      }
      &-sp {
        display: block;
        @include pc {
          display: none;
        }
      }
    }
    &-textbox {
      padding: 40px 0;
      @include pc {
        padding: 80px 0;
      }
      &-inner {
        max-width: 1225px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;
        @include pc {
          // display: flex;
          // align-items: center;
        }
      }
    }
    &-heading {
      width: 100%;
      text-align: center;
      h1 {
        @include font-noto_sans;
        font-size: 28px;
        margin: 0 0 10px 0;
        line-height: 1.5;
        @include pc {
          font-size: 40px;
        }
      }
      strong {
        @include font-Montserratfont-Montserrat;
        font-size: 16px;
        @include pc {
          font-size: 20px;
        }
      }
    }
    &-text {
      width: 100%;
      font-size: 14px;
      margin: 20px 0 0 0;
      line-height: 1.75;
      @include pc {
        font-size: 16px;
        margin: 25px 0 0 0;
        text-align: center;
      }
      p {
        font-size: 14px;
        @include pc {
          font-size: 16px;
        }
      }
    }
  }
  /*---------------------
  .point
  -----------------------*/
  .point {
    padding: 30px 0 70px 0;
    background-color: #edf3f5;
    @include pc {
      padding: 70px 0 120px 0;
    }
    &-inner {
      max-width: 1225px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
    }
    &-heading {
      position: relative;
      @include font-Montserrat;
      font-size: 30px;
      text-align: center;
      text-transform: uppercase;
      @include pc {
        font-size: 40px;
      }
      &::after {
        content: "";
        display: block;
        width: 16px;
        height: 1px;
        background-color: #333;
        margin: 15px auto 0 auto;
        @include pc {
          width: 20px;
          margin: 20px auto 0 auto;
        }
      }
    }
    &-list {
      margin: 55px auto 0 auto;
      max-width: 500px;
      width: 100%;
      @include pc {
        max-width: 1025px;
        margin: 90px auto 0 auto;
      }
    }
    &-item {
      position: relative;
      counter-increment: itemNum;
      @include pc {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      + .point-item {
        margin: 55px 0 0 0;
        @include pc {
          margin: 90px 0 0 0;
        }
      }
      &:nth-child(even) {
        @include pc {
          flex-direction: row-reverse;
        }
        &::before {
          @include pc {
            left: auto;
            right: 30px;
          }
        }
      }
      &::before {
        content: counter(itemNum, decimal-leading-zero);
        @include font-Montserrat_regular;
        font-size: 26px;
        position: absolute;
        top: -20px;
        left: 16px;
        z-index: 1;
        @include pc {
          font-size: 36px;
          top: -28px;
          left: 30px;
        }
      }
    }
    &-image {
      max-width: 515px;
      width: 100%;
      img {
        width: 100%;
      }
    }
    &-textbox {
      width: 100%;
      padding: 20px 0 0 0;
      @include pc {
        width: calc(100% - 515px);
        padding: 0 0 0 60px;
      }
    }
    &-title {
      @include font-noto_sans;
      font-size: 20px;
      @include pc {
        font-size: 28px;
      }
    }
    &-text {
      margin: 15px 0 0 0;
      line-height: 1.75;
      @include pc {
        margin: 20px 0 0 0;
      }
    }
  }
  /*---------------------
  .lineup
  -----------------------*/
  .lineup {
    margin-block-start: 60px;
    padding: 0 0 40px 0;
    @include pc {
      margin-block-start: 80px;
      padding: 0 0 100px 0;
    }
    &-head {
      position: relative;
      margin: 0 0 20px 0;
    }
    &-heading {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      @include pc {
        width: 50%;
      }
      img {
        width: 270px;
        @include pc {
          width: 436px;
        }
      }
    }
    &-image {
      img {
        height: auto;
        width: 100%;
      }
    }
    &-block {
      position: relative;
      margin: 40px 0 0 0;
      @include pc {
        margin: 80px 0 0 0;
      }
    }
    &-title {
      position: relative;
      @include font-Montserrat;
      font-size: 30px;
      text-align: center;
      text-transform: uppercase;
      z-index: 1;
      @include pc {
        font-size: 40px;
      }
      span {
        display: inline-block;
        background-color: #fff;
        padding: 0 30px;
      }
    }
    &-subtitle {
      margin: 30px 0 0 0;
      line-height: 1.75;
      @include pc {
        text-align: center;
      }
    }
    &-banner {
      margin: 20px 0 0 0;
      @include pc {
        margin: 100px 0 0 0;
      }
      a {
        transition: opacity 250ms;
        &:hover {
          opacity: 0.75;
        }
      }
      img {
        width: 100%;
      }
    }
    .product-list {
      position: relative;
      z-index: 1;
      margin: 30px 0 0 0;
      @include pc {
        margin: 85px 0 0 0;
      }
      .c-product-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        margin: 25px 12px 0 12px;
        @include pc {
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: nowrap;
          justify-content: center;
          max-width: 1024px;
          width: 100%;
          margin: 35px auto 0 auto;
        }
        li {
          position: relative;
          max-width: 305px;
          width: 100%;
          margin: 10px 0;
          padding-bottom: 70px;
          border: 1px solid #bad3d6;
          background-color: #fff;
          @include pc {
            width: calc(50% - 15px);
            margin: 0 15px;
            padding-bottom: 0;
            border: none;
          }
          .c-productLink {
            border: none;
            @include pc {
              border: 1px solid #bad3d6;
            }
          }
          .product-btn {
            position: absolute;
            bottom: 20px;
            left: 50%;
            max-width: 295px;
            width: 100%;
            height: 44px;
            padding: 0 20px;
            margin: 20px 0 0 0;
            transform: translateX(-50%);
            @include pc {
              display: none;
            }
            a {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              background-color: #e50213;
              font-size: 13px;
              border-radius: 44px;
              text-decoration: none;
            }
          }
        }
        .c-product {
          width: 100%;
          &.-new {
            &::before {
              content: "";
              background-image: url("../img/recruit/new-icon.png");
              background-size: 66px 66px;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 66px;
              height: 66px;
              z-index: 2;
            }
          }
        }
      }
    }
    // .product-list {
    //   margin: 30px 0 0 0;
    // }
    // .c-product-list {
    //   display: flex;
    //   flex-wrap: wrap;
    //   margin: 25px -10px 0 -10px;
    //   @include pc {
    //     flex-direction: row;
    //     align-items: flex-start;
    //     flex-wrap: nowrap;
    //     justify-content: center;
    //     max-width: 1024px;
    //     width: 100%;
    //     margin: 35px auto 0 auto;
    //   }
    //   li {
    //     position: relative;
    //     width: calc(50% - 20px);
    //     margin: 0 10px 20px 10px;
    //     background-color: #fff;
    //     @include pc {
    //       max-width: 315px;
    //       width: 100%;
    //       margin: 0 10px;
    //     }
    //     .c-product {
    //       width: 100%;
    //     }
    //   }
    // }
  }
  /*---------------------
  .guide
  -----------------------*/
  .guide {
    padding: 40px 0;
    background-color: #edf3f5;
    @include pc {
      padding: 50px 0;
    }
    &-heading {
      @include font-Montserrat;
      font-size: 21px;
      text-align: center;
      @include pc {
        font-size: 32px;
      }
    }
    &-content {
      padding: 60px 20px 40px 20px;
      margin: 30px 0 0 0;
      background-color: #fff;
      border: 1px solid #bad3d6;
      @include pc {
        margin: 40px 0;
        padding: 100px 140px 80px 140px;
      }
    }
    &-block {
      position: relative;
      + .guide-block {
        margin: 60px 0 0 0;
        @include pc {
          margin: 80px 0 0 0;
        }
      }
    }
    &-row {
      @include pc {
        display: flex;
        align-items: center;
      }
    }
    &-col {
      &:first-child {
        @include pc {
          width: calc(100% - 230px);
          padding: 0 20px 0 0;
        }
      }
      &:nth-child(2) {
        @include pc {
          width: 230px;
        }
      }
    }
    &-title {
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: -20px;
        height: 1px;
      }
      &::before {
        width: 196px;
        background-color: #bfbfbf;
        @include pc {
          width: 180px;
        }
      }
      &::after {
        width: 98px;
        background-color: #e60012;
        z-index: 1;
        @include pc {
          width: 90px;
        }
      }
      h3 {
        @include font-Montserrat;
        font-size: 18px;
        @include pc {
          font-size: 22px;
        }
      }
    }
    &-textbox {
      padding: 15px 0 0 0;
      line-height: 1.75;
      .c-btn {
        height: 50px;
        margin: 20px 0 0 0;
        @include pc {
          height: 58px;
          margin: 40px 0 0 0;
        }
      }
    }
    &-list {
      font-size: 13px;
      line-height: 1.75;
      @include pc {
        font-size: 14px;
      }
      dt {
        @include font-noto_sans_bold;
        margin: 20px 0 0 0;
        @include pc {
          margin: 30px 0 0 0;
        }
      }
    }
    &-image {
      max-width: 460px;
      width: 100%;
      margin: 20px 0 0 0;
      @include pc {
        margin: 30px 0 0 0;
      }
    }
  }
  /*--------------------------------
  .cta
  2025/3/25改修
  ---------------------------------*/
  .cta {
    background-color: #fee7e6;
    padding-block: 40px;
    @include pc {
      padding-block: 70px;
    }
  }
  .cta-img {
    display: block;
    margin-block: 50px 22px;
    margin-inline: auto;
    width: 42px;
    @include pc {
      width: 64px;
    }
  }
  .cta-text {
    font-size: 14px;
    letter-spacing: 0.06em;
    line-height: calc(25 / 14);
    margin-block: 22px 40px;
    text-align: center;
    @include pc {
      font-size: 16px;
      margin-block: 15px 20px;
    }
  }
  .c-btnWrap {
    display: grid;
    gap: 43px;
    grid-template-columns: min(calc(335 / 375 * 100vw), 375px);
    justify-content: center;
    @include pc {
      gap: 40px;
      grid-template-columns: repeat(2, 362px);
    }
  }
  .c-btn {
    a {
      font-size: 18px;
      height: 60px;
    }
  }
}
