@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

#formMaestro {
  @include sp {
    line-height: 1.5em;
    margin-bottom: 5vw;
  }
}

.maestroProduct {
  :not([class="l-menu"]) & {
    @include pc {
      padding-bottom: 10px;
    }
  }
  .l-menu & {
    @include pc {
      margin-top: 10px;
    }
  }
  & &__list {
    display: flex;
    flex: 1 0 calc(33.3% - 5px);
    align-content: stretch;
    justify-content: space-between;
    @include pc {
      flex: 1 0 calc(33.3% - 16px);
    }
  }
  & &__item {
    position: relative;
    box-sizing: border-box;
    width: calc(33.3% - 5px);
    max-width: calc(33.3% - 5px);
    padding: 0 10px 10px;
    background: #fff;
    @include sp320{
      padding: 0 6px 6px;
    }
    @include pc {
      width: calc(33.3% - 16px);
      max-width: calc(33.3% - 16px);
      padding: 0 20px 10px;
      .l-menu & {
        padding-bottom: 20px;
        padding-top: 5px;
      }
    }
    &:nth-of-type(3n + 2):last-of-type {
      margin-right: calc(33.3% * 1 + ((100% - 33.3% * 3) / 2) * 1);
    }
  }
  & &__thumb {
    margin: 0 -10px;
    @include sp320{
      margin: 0 -6px;
    }
    @include pc {
      margin: 0 -20px;
    }
    @include IE {
      min-height: 10px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  & &__body {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: 100%;
  }
  & &__text {
    margin-bottom: 8px;
    margin-top: 5px;
    @include pc {
      margin-bottom: 13px;
      margin-top: 0;
    }
  }
  & &__title {
    @include lhCrop(30 / 24);
    @include fz(11);
    line-height: (30 / 24);
    letter-spacing: 0.08em;
    @include pc {
      @include lhCrop(18 / 14);
      font-size: 14px;
      line-height: (18 / 14);
    }
  }
  & &__spec {
    margin-top: 9px;
    line-height: (30 / 24);
    color: #7f7f7f;
    letter-spacing: 0.08em;
    @include lhCrop(30 / 24);
    @include fz(11);
    @include pc {
      @include lhCrop(1.3);
      font-size: 10px;
      margin-top: 11px;
      line-height: (1.3);
    }
    .pc {
      @include sp {
        position: relative;
        display: block;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        border: 0;
      }
    }
  }
  & &__price {
    @include dotLine(#000);
    padding-top: 10px;
    margin-top: auto;
    @include pc {
      padding-top: 14px;
    }
    &Yen {
      @include fz(13);
      line-height: (32 / 27);
      letter-spacing: 0.08em;
    }
    &Tax {
      @include fz(11);
      line-height: (32 / 23);
      @include font-noto_sans;
      @include pc {
        @include vw(10);
        line-height: (25 / 10);
      }
    }
  }
  & &__noItem {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    height: 100%;
    line-height: 1.2;
    color: #7f7f7f;
    padding-top: 10px;
    @include fz(11);
    @include pc {
      @include vw(14);
      padding-top: 20px;
    }
  }
  & &__del {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 12px;
    height: 12px;
    @include pc {
      top: 3px;
      right: 3px;
      width: 18px;
      height: 18px;
    }
    button {
      position: relative;
      width: 12px;
      height: 12px;
      overflow: hidden;
      background: #cecece;
      display: block;
      @include pc {
        width: 18px;
        height: 18px;
        transition: background 0.2s ease;
        &:hover {
          background: rgba(#cecece, 0.7);
        }
      }
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: calc(50% - 3px);
        display: block;
        width: 6px;
        height: 1px;
        content: "";
        background: #fff;
        @include pc {
          left: calc(50% - 5px);
          width: 10px;
          height: 1px;
        }
      }
      &::before {
        transform: rotate(-45deg);
      }
      &::after {
        transform: rotate(45deg);
      }
      span {
        @include visually-hidden;
      }
    }
  }
  &__deletedWrap {
    .l-headerNav__li.maestro.-open & {
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.5);
      opacity: 0;
      transition: opacity 0.2s ease, z-index 0.2s ease;
      @include pc {
        width: 572px;
        top: 5.75rem;
        left: auto;
        right: 0;
      }
      .l-container.-show.-deleted & {
        z-index: 3;
        opacity: 1;
      }
    }
  }
  &__deleted {
    text-align: center;

    .maestro-check-button {
      width:300px;
      margin-top:30px;
      @include sp {
        width: 80%;
        margin-top: 3vw;
      }
    }

    .l-headerNav__li.maestro.-open & {
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: -1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(100% - 40px);
      height: 160px;
      letter-spacing: 0.16em;
      background: #fff;
      opacity: 0;
      transition: opacity 0.2s ease, z-index 0.2s ease;
      transform: translate(-50%, -50%);
      @include fz(13);
      @include pc {
        @include fz(17);
        // width: calc(100% - 60px);
        height: 230px;
        width: calc(572px - 60px);
        left: auto;
        transform: translateY(-50%);
        margin: auto 30px;
      }
      .btn {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 22px;
        height: 22px;
        overflow: hidden;
        z-index: 10;
        @include pc {
          width: 24px;
          height: 24px;
        }
        button {
          display: block;
          position: relative;
          width: 22px;
          height: 30px;
          @include pc {
            width: 24px;
            height: 24px;
            opacity: 1;
            transition: opacity 0.2s ease;
            &:hover {
              opacity: 0.7;
            }
          }
          &::before,
          &::after {
            position: absolute;
            top: 10px;
            left: calc(50% - 11px);
            display: block;
            width: 22px;
            height: 2px;
            content: "";
            background: #bebebe;
            @include pc {
              left: calc(50% - 12px);
              width: 24px;
            }
          }
          &::before {
            transform: rotate(-45deg);
          }
          &::after {
            transform: rotate(45deg);
          }
          span {
            @include visually-hidden;
          }
        }
      }
      .l-container.-show.-deleted & {
        z-index: 3;
        opacity: 1;
      }
    }
  }
  & &__size {
    display: flex;
    flex: 1 0 calc(33.3% - 5px);
    align-content: stretch;
    justify-content: space-between;
    margin-top: 10px;
    @include pc {
      flex: 1 0 calc(33.3% - 16px);
    }
    &Item {
      position: relative;
      box-sizing: border-box;
      flex-basis: calc(33.3% - 5px);
      width: calc(33.3% - 5px);
      max-width: calc(33.3% - 5px);
      background: #fff;
      @include pc {
        flex-basis: calc(33.3% - 16px);
        width: calc(33.3% - 16px);
        max-width: calc(33.3% - 16px);
      }
      &:nth-of-type(3n + 2):last-of-type {
        margin-right: calc(calc(33.3% - 5px) * 1 + ((100% - calc(33.3% - 5px) * 3) / 2) * 1);
        @include pc {
          margin-right: calc(calc(33.3% - 16px) * 1 + ((100% - calc(33.3% - 16px) * 3) / 2) * 1);
        }
        @include IE{
          margin-right: 33.3%;
        }
        @include pcIE{
          margin-right: 201px;
        }
      }
      .text {
        box-sizing: border-box;
        min-height: 49px;
        padding: 14px;
        @include sp320{
          padding: 10px;
        }
        @include pc {
          min-height: 50px;
          padding: 14px 25px;
        }
      }
    }
  }
}
