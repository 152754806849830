@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-hero {
  @at-root {
    & {
      background: no-repeat center center / cover;
      color: #fff;
      &.-color__black {
        color: #333;
      }
    }
    // ブルーライトカットレンズページ背景（/lens/lens-coating/）
    .-bg__bluelightcut {
      background-image: url("/img/lens/bluelightcut/hero_sp.jpg");
      @include pc {
        background-image: url("/img/lens/bluelightcut/hero_pc.jpg");
      }
    }
    // レンズコーティングページ背景（/lens/lens-coating/）
    .-bg__lens {
      background-image: url("/img/lens/lens-coating/hero_sp.jpg");
      @include pc {
        background-image: url("/img/lens/lens-coating/hero_pc.jpg");
      }
    }
    .-bg__dimming {
      background-image: url("/img/lens/dimming/hero_sp.jpg");
      @include pc {
        background-image: url("/img/lens/dimming/hero_pc.jpg");
      }
    }
    .-bg__color {
      background-image: url("/img/lens/color/hero_sp.jpg");
      @include pc {
        background-image: url("/img/lens/color/hero_pc.jpg");
      }
    }
    .-bg__lensTop {
      background-image: url("/img/lens/hero_sp.jpg");
      @include pc {
        background-image: url("/img/lens/hero_pc.jpg");
      }
    }
    .l-hero__inner {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 400px;
      justify-content: center;
      padding-left: 40px;
      padding-right: 40px;
      text-align: center;
      @include pc {
        height: 380px;
      }
      .-bg__lensTop & {
        height: 520px;
        padding-left: 20px;
        padding-right: 20px;
        @include pc {
          align-items: flex-start;
          height: 540px;
          padding-left: 40px;
          padding-right: 40px;
        }
      }
    }
    .l-hero__title {
      @include font-noto_sans_medium;
      @include fz(28);
      margin: 0;
      @include pc {
        font-size: 40px;
      }
    }
    .l-hero__lead {
      @include font-noto_sans_light;
      @include fz(18);
      margin-top: 20px;
      @include pc {
        font-size: 20px;
        margin-top: 15px;
      }
      .-bg__lensTop & {
        color: #111;
        @include fz(20);
        letter-spacing: 0.06em;
        line-height: 180%;
        margin-top: 0;
        padding-bottom: 230px;
        text-align: center;
        @include pc {
          font-size: 36px;
          padding-bottom: 0;
          padding-left: calc(700 / 1440 * 100vw);
          text-align: left;
        }
      }
    }
  }
}
