@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.faq {
  &-list {
    counter-reset: faq-list;
  } // &-list
  &-item {
    background: #fff;
    border: 1px solid #c7c7c7;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .accordion {
      &Wrap {
      }
      &Head {
        position: relative;
        padding: 20px 40px 20px 20px;
        cursor: pointer;
        @include pc {
          padding: 60px 80px 60px 40px;
        }
        &Title {
          font-size: 16px;
          @include sp320 {
            font-size: 14px;
          }
          @include pc {
            margin-left: 60px;
          }
        }
        &::before {
          counter-increment: faq-list;
          content:  "Q"counter(faq-list);
          @include font-Montserrat;
          display: inline-block;
          color: #bad4d5;
          font-size: 22px;
          @include pc {
            position: absolute;
            left: 40px;
            top: 60px;
            line-height: 1;
          }
        } // &::before
        &::after {
          background: url("/img/common/icon_arrow_accordion.svg") no-repeat center;
          background-size: 100% auto;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          right: 20px;
          bottom: 0;
          width: 10px;
          @include pc {
            right: 40px;
          }
        }
      } // &Head
      &Body {
        padding: 0 20px 20px 20px;
        @include pc {
          padding: 0 80px 60px 100px;
          margin-top: -20px;
        }
        &Inner {
          font-size: 14px;
        }
        &.-hide {
          display: none;
        }
        &.-open {
          display: block;
        }
      } // &Body
    } // .accordion
  } // &-item
} // .faq
