@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

//HTML
//<div class="c-btnWrap -center">
//<div class="c-btn -white -w460"><a href="" class="">ボタン</a></div>
//<div class="c-btn -black -wFull"><button>ボタン</button></div>
//<div class="c-btn -red -wFull"><input type="submit" value="ボタン"></div>
//</div>
//形
.c-btn {
  a,
  button,
  input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    font-size: 13px;
    text-decoration: none;
    letter-spacing: 0.16em;
    cursor: pointer;
    border-radius: 35px;
    transition: all 0.2s;
    @include font-noto_sans;
    @include pc {
      height: 50px;
    }
    &:disabled {
      background: #c9c9c9 !important;
      color: #fff !important;
      cursor: inherit;
      border: none !important;
      @include pc {
        &:hover {
          background: #c9c9c9 !important;
          color: #fff !important;
          border: none !important;
        }
      }
    }
  }
}

//色
.c-btn {
  &.-white {
    a,
    button,
    input {
      color: #000;
      background: #fff;
      border: 1px solid #000;
      @include pc {
        &:hover {
          color: #fff;
          background: #000;
        }
      }
    }
  }
  &.-black {
    a,
    button,
    input {
      color: #fff;
      background: #000;
      border: 1px solid #000;
      @include pc {
        &:hover {
          color: #000;
          background: #fff;
        }
      }
    }
  }
  &.-gray {
    a,
    button,
    input {
      color: #fff;
      background: #333;
      border: 1px solid #333;
      @include pc {
        &:hover {
          color: #333;
          background: #fff;
        }
      }
    }
  }
  &.-blue {
    a,
    button,
    input {
      color: #000;
      background: #fff;
      border: 1px solid #bad3d6;
      @include pc {
        &:hover {
          color: #fff;
          background: #bad3d6;
        }
      }
    }
  }
  &.-red {
    a,
    button,
    input {
      color: $c-primary;
      background: #fff;
      border: 1px solid #e2bfd4;
      @include pc {
        &:hover {
          color: #fff;
          background: $c-primary;
          border-color: $c-primary;
        }
      }
    }
  }
  &.-redWhite {
    a,
    button,
    input {
      color: #fff;
      background: $c-primary;
      border: 1px solid $c-primary;
      @include pc {
        &:hover {
          color: $c-primary;
          background: #fff;
        }
      }
    }
    &.-blank {
      a {
        &::after {
          background: url(/img/common/icon_blank_white.svg) no-repeat;
          background-size: 100% auto;
        }
        @include pc {
          &:hover {
            &::after {
              background: url(/img/common/icon_blank_red.svg) no-repeat;
              background-size: 100% auto;
            }
          }
        }
      }
    }
  }
  &.-blueWhite {
    a,
    button,
    input {
      color: #fff;
      background: #213aca;
      border: 1px solid #213aca;
      @include pc {
        &:hover {
          color: #213aca;
          background: #fff;
        }
      }
    }
    &.-blank {
      a {
        &::after {
          background: currentColor !important;
          mask: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzEgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iY3VycmVudENvbG9yIj48cGF0aCBkPSJtMjkgOHYxNGgtMjF2LTE0em0yLTJoLTI1djE4aDI1eiIvPjxwYXRoIGQ9Im0yNSAwaC0yNXYxOGgydi0xNmgyM3oiLz48cGF0aCBkPSJtMiAwaC0ydjE4aDJ2LTE2aDIzdi0yeiIvPjwvZz48L3N2Zz4=") no-repeat left top / contain;
        }
      }
    }
  }
  &.-green {
    a,
    button,
    input {
      color: #fff;
      background: #00b900;
      border: 1px solid #00b900;
      @include pc {
        &:hover {
          color: #000;
          background: #fff;
        }
      }
    }
  }
  &.-darkGray {
    a,
    button,
    input {
      color: #fff;
      background: #333;
      border: 1px solid #333;
      @include pc {
        &:hover {
          color: #333;
          background: #fff;
        }
      }
    }
    &.-blank {
      a {
        &::after {
          background: url(/img/common/icon_blank_white.svg) no-repeat;
          background-size: 100% auto;
        }
        @include pc {
          &:hover {
            &::after {
              background: url(/img/common/icon_blank.svg) no-repeat;
              background-size: 100% auto;
            }
          }
        }
      }
    }
  }
}

//横幅
.c-btn {
  &.-w94 {
    width: 94px;
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w130 {
    width: 100%;
    max-width: 118px;
    @include pc {
      width: 130px;
      max-width: none;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w153 {
    width: 100%;
    max-width: 120px;
    @include pc {
      width: 153px;
      max-width: none;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w235 {
    width: 235px;
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w268 {
    width: 268px;
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w320 {
    width: 100%;
    max-width: 332px;
    @include pc {
      width: 320px;
      max-width: none;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w445 {
    width: 100%;
    max-width: 372px;
    @include pc {
      width: 445px;
      max-width: none;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w460 {
    width: 90%;
    max-width: 332px;
    margin: 0 auto;
    @include pc {
      width: 460px;
      max-width: none;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-w475 {
    width: 100%;
    max-width: 347px;
    @include pc {
      width: 475px;
      max-width: none;
    }
    a,
    button,
    input {
      width: 100%;
    }
  }
  &.-wFull {
    width: 100%;
    max-width: 372px;
    @include pc {
      max-width: none;
    }
  }
}

//縦幅
.c-btn {
  &.-h28 {
    a,
    button,
    input {
      height: 28px;
      border-radius: 28px;
      @include pc {
        height: 35px;
        border-radius: 35px;
      }
    }
  }
  &.-h31 {
    a,
    button,
    input {
      height: 31px;
      border-radius: 31px;
      @include pc {
        height: 33px;
        border-radius: 33px;
      }
    }
  }
  &.-h30 {
    a,
    button,
    input {
      height: 31px;
      border-radius: 31px;
      @include pc {
        height: 30px;
        border-radius: 30px;
      }
    }
  }
  &.-h45 {
    a,
    button,
    input {
      height: auto !important;
      padding: 11.5px 20px;
      border-radius: 45px;
      @include pc {
        letter-spacing: 0.18em;
      }
    }
  }
  &.-h48 {
    a,
    button,
    input {
      height: 44px;
      border-radius: 44px;
      @include pc {
        height: 48px;
        border-radius: 48px;
      }
    }
  }
  &.-h67 {
    a,
    button,
    input {
      height: 64px;
      border-radius: 64px;
      @include pc {
        height: 67px;
        border-radius: 67px;
      }
    }
  }
}

//囲み 右寄せ 中央寄せ 左寄せ
.c-btnWrap {
  display: flex;
  margin: 30px auto;
  @include pc {
    margin: 40px auto;
  }
  &.-center {
    justify-content: center;
  }
  &.-right {
    justify-content: flex-end;
  }
  &.-marginBottom {
    margin-bottom: 30px;
    @include pc {
      margin-bottom: 40px;
    }
  }
}

//2列
.c-btnWrap {
  &.-columns {
    justify-content: space-between !important;
    @include sp {
      flex-direction: column;
    }
    .c-btn {
      width: calc(50% - 3.29%);
      @include sp {
        width: 100%;
      }
      a {
        height: 35px;
        @include pc {
          height: 45px;
        }
      }
    }
  }
}

//アイコン
.c-btn {
  &.-blank {
    a {
      position: relative;
      padding: 11.5px 40px 11.5px 20px;
      &::after {
        position: absolute;
        top: 52%;
        right: 19px;
        display: block;
        width: 15px;
        height: 16px;
        content: "";
        background: url(/img/common/icon_blank.svg) no-repeat;
        background-size: 100% auto;
        transform: translateY(-50%);
      }
      @include pc {
        &:hover {
          &::after {
            background: url(/img/common/icon_blank_white.svg) no-repeat;
            background-size: 100% auto;
          }
        }
      }
    }
  }
  &.-pdf {
    a {
      position: relative;
      padding: 11.5px 40px 11.5px 20px;
      &::after {
        position: absolute;
        top: 50%;
        right: 19px;
        display: block;
        width: 11px;
        height: 16px;
        content: "";
        background: url(/img/common/icon_pdf.svg) no-repeat;
        background-size: 100% auto;
        transform: translateY(-50%);
      }
      @include pc {
        padding: 17.5px 40px 17.5px 20px;
        &:hover {
          &::after {
            background: url(/img/common/icon_pdf_white.svg) no-repeat;
            background-size: 100% auto;
          }
        }
      }
    }
  }
  &.-page {
    a {
      position: relative;
      padding: 11.5px 40px 11.5px 20px;
      &::after {
        position: absolute;
        width: 9px;
        height: 15px;
        vertical-align: middle;
        content: "";
        background: url(/img/common/icon_arrow.svg) no-repeat 50%;
        background-size: 100% auto;
        transform: translateY(-50%) rotate(90deg);
        transform-origin: center;
        top: 52%;
        right: 19px;
      }
      @include pc {
        &:hover {
          &::after {
            background: url(/img/common/icon_arrow_white.svg) no-repeat 50%;
            background-size: 100% auto;
          }
        }
      }
    }
  }
  &.-plus {
    a {
      position: relative;
      &::before,
      &::after {
        position: absolute;
        background: #000;
        content: "";
        display: block;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &::before {
        height: 1px;
        width: 12px;
        right: 15px;
      }
      &::after {
        height: 12px;
        width: 1px;
        right: calc(15px + 6px);
      }
      @include pc {
        &:hover {
          &::before,
          &::after {
            background: #fff;
          }
        }
      }
    }
  }
  &.-tel {
    a {
      font-size: 16px;
      position: relative;
      padding: 20px 40px 20px 20px;
      &::after {
        position: absolute;
        top: 50%;
        right: 19px;
        display: block;
        width: 16px;
        height: 16px;
        content: "";
        background: url(/img/common/icon_tel_white.svg) no-repeat;
        background-size: 100% auto;
        transform: translateY(-50%);
      }
      @include pc {
        display: none;
      }
    }
  }
  &.-arrowDown {
    a {
      position: relative;
      &::after {
        background-color: #333;
        mask: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNy40Mjg0N0w5IDE1LjQyODVMMTcgNy40Mjg0NyIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTkgMTQuODU3MVYwIiBzdHJva2U9IiMzMzMzMzMiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K') no-repeat left top / contain;
        content: "";
        display: block;
        height: 16px;
        inset: 0 22px 0 auto;
        margin-block: auto;
        position: absolute;
        width: 16px;
      }
      &:hover {
        &::after {
          @include pc {
            background-color: #fff;
          }
        }
      }
    }
  }
}

// 大きさ（パークリ、補聴器、メガネページで使用）
.c-btn {
  &.-large {
    a {
      font-size: 16px;
      letter-spacing: 0.1em;
      padding: 30px 20px 30px 20px;
      @include font-noto_sans_light;
      @include tb {
        font-size: 18px;
        padding: 35px 20px 35px 20px;
      }
    }
    &.-blank {
      a {
        padding: 30px 40px 30px 20px;
        @include tb {
          padding: 35px 40px 35px 20px;
        }
      }
    }
  } // &.-large
} // .c-btn

// 装飾
.c-btn {
  &.-underLine {
    a {
      span {
        position: relative;
        &::before {
          background: #fff;
          content: "";
          display: inline-block;
          position: absolute;
          top: auto;
          bottom: 0;
          left: auto;
          right: auto;
          height: 2px;
          width: 100%;
        }
      }
      @include pc {
        &:hover {
          span::before {
            background: #333;
          }
        }
      }
    }
  }
}

//個別
.c-btn {
  &.-topInformation {
    margin: 20px auto 0;
    width: 75%;
    max-width: 518px;
    @include pc {
      margin: 40px auto 0;
      width: 310px;
      max-width: none;
    }
  }
  &.-maestroCard {
    margin: 0 auto;
    width: 78%;
    max-width: 538px;
    @include pc {
      margin: 0 auto;
      width: 240px;
      max-width: none;
    }
  }
  &WrapLine {
    display: flex;
    padding: 30px 0;
    margin: 0 auto;
    border-top: 1px solid $c-border1;
    @include pc {
      padding-top: 40px;
    }
    &.-center {
      justify-content: center;
    }
    // .c-btn:not(:first-of-type) {
    //   margin-left: 10px;
    //   @include pc {
    //     margin-left: 30px;
    //   }
    // }
    .c-btn {
      &.-white {
        width: 37%;
        max-width: 125px;
        order: 1;
        @include pc {
          width: 235px;
          max-width: none;
        }
        &:only-child {
          width: 88%;
          max-width: 332px;
          @include pc {
            width: 320px;
            max-width: none;
          }
        }
      }
      &.-black {
        width: 59%;
        max-width: 332px;
        order: 2;
        margin-left: 10px;
        @include pc {
          width: 460px;
          max-width: none;
          margin-left: 30px;
        }
        &:only-child {
          width: 88%;
          max-width: 332px;
          @include pc {
            width: 460px;
            max-width: none;
          }
        }
      }
    }
  }
  &Wrap__store {
    .c-btn {
      width: 76%;
      max-width: 510px;
      margin: 15px auto 0;
      @include pc {
        width: 440px;
        max-width: none;
        margin-top: 30px;
      }
      button {
        height: 35px;
        @include pc {
          height: 50px;
        }
      }
    }
  }
  .l-gnav & {
    &.contact,
    &.magazine,
    &.eyevory {
      width: 100%;
      @include pc {
        margin-top: 14px;
      }
      a {
        height: 55px;
        background: #fff;
        border: 1px solid #ccc;
        @include pc {
          height: 60px;
        }
        @include pc {
          &:hover {
            background: $c-primary;
            border-color: $c-primary;
          }
        }
      }
      span {
        position: static;
        display: block;
        width: 100%;
        height: 45px;
        overflow: hidden;
        white-space: nowrap;
        @include pc {
          height: 60px;
        }
        &::before {
          display: inline-block;
          width: 100%;
          height: 45px;
          content: "";
          @include pc {
            height: 60px;
          }
        }
      }
    }
    &.contact {
      span::before {
        display: flex;
        width: 147px;
        height: 100%;
        margin: 0 auto;
        background: url(/img/common/logo_vision-contact_red.svg) no-repeat 50%;
        background-size: contain;
        @include pc {
          width: 122px;
          @at-root .l-gnav .c-btn.contact a:hover span::before {
            background-image: url("/img/common/logo_vision-contact_white.svg");
          }
        }
      }
    }
    &.magazine {
      span::before {
        display: flex;
        width: 160px;
        height: 100%;
        margin: 0 auto;
        background: url(/img/common/logo_vision-magazine_red.svg) no-repeat 50%;
        background-size: contain;
        @include pc {
          width: 160px;
          @at-root .l-gnav .c-btn.magazine a:hover span::before {
            background-image: url("/img/common/logo_vision-magazine_white.svg");
          }
        }
      }
    }
    &.eyevory {
      a {
        border-color: #333;
        @include pc {
          &:hover {
            border-color: #333;
            background: #333;
          }
        }
      }
      span::before {
        display: flex;
        width: 100px;
        height: 100%;
        margin: 0 auto;
        background: url(/img/common/logo_eyevory_black.svg) no-repeat 50%;
        background-size: contain;
        @include pc {
          width: 99.77px;
          @at-root .l-gnav .c-btn.eyevory a:hover span::before {
            background-image: url("/img/common/logo_eyevory_white.svg");
          }
        }
      }
    }
  }
  .l-footer__buttons & {
    &.contact,
    &.magazine,
    &.eyevory {
      width: calc(50% - 5px);
      @include pc {
        width: 238px;
      }
      a {
        height: 45px;
        background: #333;
        border: 1px solid #4d4d4d;
        @include pc {
          height: 60px;
        }
        @include pc {
          &:hover {
            background: #fff;
          }
        }
      }
      span {
        position: static;
        display: block;
        width: 100%;
        height: 45px;
        overflow: hidden;
        white-space: nowrap;
        @include pc {
          height: 60px;
        }
        &::before {
          display: inline-block;
          width: 100%;
          height: 45px;
          content: "";
          @include pc {
            height: 60px;
          }
        }
      }
    }
    &.contact {
      margin-right: 10px;
      @include pc {
        margin-right: 8px;
      }
      span::before {
        display: flex;
        width: 92px;
        height: 100%;
        margin: 0 auto;
        background: url(/img/common/logo_vision-contact_white.svg) no-repeat 50%;
        background-size: contain;
        @include pc {
          width: 127px;
          @at-root .l-footer__buttons .c-btn.contact a:hover span::before {
            background-image: url("/img/common/logo_vision-contact_black.svg");
          }
        }
      }
    }
    &.magazine {
      @include pc {
        margin-right: 8px;
      }
      span::before {
        display: flex;
        width: 114px;
        height: 100%;
        margin: 0 auto;
        background: url(/img/common/logo_vision-magazine_white.svg) no-repeat 50%;
        background-size: contain;
        @include pc {
          width: 160px;
          height: 100%;
          @at-root .l-footer__buttons .c-btn.magazine a:hover span::before {
            background-image: url("/img/common/logo_vision-magazine_black.svg");
          }
        }
      }
    }
    &.eyevory {
      span::before {
        display: flex;
        width: 68.61px;
        height: 100%;
        margin: 0 auto;
        background: url(/img/common/logo_eyevory_white.svg) no-repeat 50%;
        background-size: contain;
        @include pc {
          width: 99.77px;
          @at-root .l-footer__buttons .c-btn.eyevory a:hover span::before {
            background-image: url("/img/common/logo_eyevory_black.svg");
          }
        }
      }
    }
  }
  &.-daisogyo {
    a {
      margin-top: 40px;
    }
  }
}
