@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-contactlens {
  & &Wrap {
    padding: 0 20px;
    @include pc{
      padding: 0 60px;
    }
    &.-blue {
      background: #eef3f6;
    }
  } // & &Wrap
  .c-card {
    padding: 35px 0;
    &.-last {
      padding-bottom: 0;
    }
    @include pc {
      max-width: 1320px;
      margin:0 auto;
      padding: 75px 0 80px;
    }
    &Head {
      margin-bottom: 10px;
      @include pc {
        margin-bottom: 40px;
      }
      &__title {
        line-height: 1.4;
        @include sp320 {
          font-size: 19px;
        }
      }
      &__lead {
        margin: 0 0 10px 0;
      }
      &__text {
        font-size: 15px;
        line-height: (50 / 30);
        letter-spacing: 0.14em;
        @include font-noto_sans_light;
        margin-top: 10px;
        text-align: left;
        @include pc{
          font-size: 16px;
          line-height: (54 / 32);
          margin-top: 8px;
          margin-bottom: 15px;
          text-align: center;
        }
      }
    } // &Head
    &Body {
      &__img {
        margin: 0 auto 15px auto;
        max-width: 700px;
      }
      &__text {
        font-size: 14px;
        margin-top: 15px;
        @include font-noto_sans_light;
        @include pc {
          text-align: center;
          margin-top: 20px;
        }
      }
      &__columns {
        @include pc {
          display: flex;
          justify-content: space-between;
        }
      } // &__columns
      &__column {
        background: #fff;
        border: solid 1px#dbdbdb;
        padding: 30px 20px;
        &:not(:first-child) {
          margin-top: 20px;
        }
        @include pc {
          width: calc(50% - 15px);
          padding: 40px;
          margin-top: 0 !important;
          display: flex;
          flex-direction: column; // 縦積み
        }
        .head,
        .body,
        .foot {
          @include pc {
            width: 100%;
            .c-btn {
              width: 100%;
              max-width: 440px;
              margin-left: auto;
              margin-right: auto;
            }
          }
          .text {
            @include font-noto_sans_light;
          }
        }
        .head {
          @include pc {
            min-height: 150px;
          }
          .title {
            text-align: center;
            font-size: 18px;
            margin-bottom: 20px;
            @include pc {
              font-size: 22px;
              margin-bottom: 25px;
            }
          }
          .lead {
            font-size: 14px;
            margin-bottom: 15px;
            @include font-noto_sans_light;
            @include pc {
              margin-bottom: 25px;
            }
          }
        } // .head
        .body {
          @include pc {
            min-height: 0%;
          }
          .image {
            margin-bottom: 20px;
            @include pc {
              margin-bottom: 30px;
            }
          }
        }
        .foot {
          .c-btn {
            text-align: center;
            &:not(:first-child) {
              margin-top: 20px;
            }
          } // .c-btn
          @include pc {
            display: flex;
            flex-direction: column; // 縦積み
          }
        }
      } // &__column
    } // &Body
    & > .c-btn {
      margin: 25px auto 0 auto;
      max-width: 450px;
    } // .c-btn
    .note {
      font-size: 12px;
    }
  } // .c-card
} // .p-contactlens
