@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-shop {
  margin-bottom: 90px;
  @include pc{
    margin-bottom: -25px;
  }
  .c-sec__inner {
    box-sizing: border-box;
  }
  .p-static {
    @include tb{
      margin-top: 0;
    }
    &__title.-main {
      padding-top: 36px;
      @include pc{
        padding-top: 50px;
        margin-bottom: 33px;
      }
      & + .c-table.-static {
        margin-top: 0;
      }
    }
    &__title.-page {
      @include pc{
        margin-left: 70px;
        margin-right: 70px;
        margin-bottom: 77px;
      }
    }
    .c-btnWrap {
      flex-direction: column;
      flex-wrap: wrap;
      @include pc{
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .c-btn {
        @include pc{
          width: 420px;
          flex-basis: 420px;
          max-width: 420px;
          &:first-of-type {
            padding-right: 15px;
          }
          &:last-of-type {
            padding-left: 15px;
          }
        }
      }

      .btn-line {
        padding-right: 0 !important;
        overflow: hidden;

        a {
          color: #fff;
          background-color: #06C755;
          flex-direction: column;
          padding-top: 3.5px;
          padding-bottom: 3.5px;
          // border-radius: 10px;
          border: 1px solid #06C755;

          &::after {
            background-image: url(/img/common/icon_blank_white.svg);
          }

          span {
            display: block;
            font-size: 10px;
          }

          &:hover {
            background-color: #fff;
            color: #06C755;

            &::after {
              background-image: url(/img/common/icon_blank_green.svg);
            }
          }
        }
      }

      @at-root .p-static .p-shopRecommend .c-btnWrap {
        margin-top: 40px;
        @include pc{
          margin-top: 86px;
          margin-right: 70px;
          margin-left: 70px;
        }
        .c-btn:not(:first-of-type) {
          margin-top: 20px;
          @include pc{
            margin-top: 0;
          }
        }
      }
      @at-root .p-static .p-shopPhoto .c-btnWrap {
        background: #eef3f6;
        padding: 40px 5%;
        margin-left: -5.2%;
        margin-right: -5.2%;
        @include pc{
          padding: 40px;
          margin-top: 55px;
          margin-left: 0;
          margin-right: 0;
        }
        .c-btn:not(:first-of-type) {
          margin-top: 20px;
          @include pc{
            margin-top: 0;
          }
        }
      }
      @at-root .p-static .p-shopInformation .c-btnWrap {
        margin-top: 30px;
        @include pc{
          margin-top: 40px;
        }
        .c-btn {
          margin: 0 auto;
        }
      }
    }
    &__caption {
      @include fz(18);
      letter-spacing: 0.14em;
      margin-bottom: 20px;
      @include pc{
        @include vw(22);
        margin-bottom: 22px;
      }
    }
  }
  & &Wrap {
    @include pc{
      margin: 0 70px;
    }
  }
  & &Important {
    &.p-shopWrap {
      margin-bottom: 30px;
      @include pc{
        margin: 0 70px 40px;
      }
      // ボックスの余白と枠線
      .box.-white {
        padding: 22px 2.5% 20px;
        border: 1px solid $c-border1;
      }
      .c-title {
        @include fz(18);
        @include font-noto_sans;
        margin-bottom: 18px;
        @include pc {
          font-size:22px;
          margin-bottom: 25px;
        }
      }
    }
  }
  & &Slider {
    position: relative;
    overflow: hidden;
    margin: 0 -6%;
    @include pc{
      padding: 0 55px 0 85px;
      overflow: visible;
      margin: 0;
    }
    &__nav {
      position: absolute;
      bottom: 20px;
      left: 50%;
      z-index: 2;
      height: 20px;
      @include pc{
        display: block;
      }
    }
    &__item {
      box-sizing: border-box;
      align-items: stretch;
      padding: 0 10px;
      @include pc{
        width: 50%;
        padding-right: 30px;
        padding-left: 0;
        box-sizing: border-box;
        margin-right: 0;
      }
      a {
        display: block;
        text-decoration: none;
        border: 1px solid #cecece;
        height: 100%;
      }
      figure {
        span {
          display: block;
          border-bottom: 1px solid #cecece;
          position: relative;
          background: url(/img/common/bg_gray.png) no-repeat center;
          background-size: cover;
          overflow: hidden;
          padding: 0 0 62.14285714% 0; // (140/87) * 100
          width: 100%;
          height: 0;
          img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }
      }
      figcaption {
        font-size: 13px;
        line-height: (38 / 26);
        margin: 20px 15px 20px 20px;
        letter-spacing: 0.16em;
        @include pc{
          font-size: 14px;
          line-height: (25 / 14);
          margin: 25px 25px 25px 30px;
        }
        @include IE{
          overflow: hidden;
          line-height: (25 / 14);
          height: calc((25 / 14) * 2);
        }
      }
    }
    &__prev,
    &__next {
      display: none;
      background-color: #e70012;
      background-image: none;
      border-radius: 50%;
      transition: all 0.25s;
      position: absolute;
      top: 50%;
      margin-top: -50px;
      z-index: 10;
      cursor: pointer;
      background-size: 27px 44px;
      background-position: center;
      background-repeat: no-repeat;
      width: 59px;
      height: 59px;
      line-height: 500px;
      overflow: hidden;
      @include pc {
        display: block;
      }
      &:after {
        content: '';
        background: url(/img/common/icon_arrow_white.svg)no-repeat 50% 0;
        background-size: auto 100%;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 13px;
      }
      &:hover {
        @include pc {
          transform: scale(1.3);
        }
      }
    }
    &__prev {
      left: 0;
      &:after {
        transform: translate(-50%, -50%) rotate(-180deg);
      }
    }
    &__next {
      right: 0;
    }
  }
  &Recommend {
    .c-title {
      @include fz(18);
      @include font-noto_sans;
      margin-bottom: 18px;
      @include tb {
        font-size: 22px;
        margin-left: 70px;
        margin-right: 70px;
        margin-bottom: 25px;
      }
    }
  }
  &Information {
    @include pc {
      &.p-shopWrap {
        margin-bottom: 90px;
      }
    }
    iframe {
      vertical-align: top;
      height: 188px;
      @include pc {
        margin-top: 25px;
        height: 450px;
      }
    }
  }
  &Photo {
    border-top: 2px solid #bfbfbf;
    margin-top: 60px;
    margin-bottom: 30px;
    position: relative;
    @include pc {
      padding-top: 10px;
      margin-top: 90px;
      margin-bottom: 40px;
    }
    &::after {
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      display: block;
      width: 30%;
      border: 1px solid #e60012;
      @include pc {
        width: 9.575%;
      }
    }
    .p-static__column {
      margin-bottom: 20px;
      @include pc {
        flex-basis: calc(50% - 2.5%);
        width: calc(50% - 2.5%);
        max-width: calc(50% - 2.5%);
        margin-bottom: 0;
      }
    }
  }


  &__note {
    @include font-noto_sans_regular;
    @include fz(13);
    margin-bottom: 20px;
    @include pc {
      @include fz(14);
    }
  }

  &__title {
    @include font-noto_sans_medium;
    @include fz(16);
    margin-top: 40px;
    @include pc {
      margin-top: 70px;
    }
  }

  &__text {
    @include font-noto_sans_regular;
    @include fz(12);
    margin-top: 20px;
    @include pc{
      margin-top: 10px;
    }
  }

  &__area-list {
    & > li {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px 15px;

      @include pc {
        padding: 10px 30px;
      }

      &:nth-child(odd) {
        background-color: #f1f5f6;
      }
      &:nth-child(even) {
        background-color: #f9fbfb;
      }

      .area-title {
        @include font-noto_sans_regular;
        border-right: 1px solid #000;
        display: flex;
        align-items: center;

        @include fz(15);
        width: 45px;
        @include pc {
          @include fz(18);
          width: 75px;
        }
      } // .area-title

      .prefectures-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 7.5px;
        width: calc(100% - 45px);
        @include pc {
          padding-left: 27px;
          width: calc(100% - 75px);
        }

        li {
          @include font-noto_sans_regular;
          @include fz(13);
          margin-left: 7.5px;
          margin-right: 7.5px;
          @include pc {
            @include fz(14);
            margin-left: 27px;
            margin-right: 27px;
          }
        }

      }
    }
  }

  &-storeNews {
    margin: 25px 0 0 0;
    @include sp {
      margin: 15px 0 0 0;
    }

    &-title {
      @include font-noto_sans_bold;
      font-size: 14px;
      @include sp {
        font-size: 11px;
      }
    }

    &-list {
      margin: 10px 0 0 0;
    }

    &-item {
      + .p-shop-storeNews-item {
        margin: 5px 0 0 0;
      }

      p {
        @include font-noto_sans_regular;
        font-size: 14px;
        @include sp {
          font-size: 11px;
        }
      }
    }
  }
}