@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-afterservice {
  & &Wrap {
    padding: 0 20px;
    @include pc{
      padding: 0 60px;
    }
    &.perfect {
      background: #f5f2dd;
      padding-bottom: 48px;
      @include pc{
        padding-bottom: 80px;
      }
    }
    &.-blue {
      background: #eef3f6;
    }
  }
  .c-card {
    padding: 40px 0;
    @include pc{
      max-width: 1320px;
      margin:0 auto;
      padding: 75px 0 80px;
    }
    &:not(:first-of-type) {
      border-top: 1px solid #cecece;
    }
    .service {
      &__item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
        padding: 13px 20px;
        min-height: 335px;
        box-sizing: border-box;
        @include pc{
          padding: 22px 25px 35px;
          min-height: 420px;
        }
        @for $i from 1 through 5 {
          &:nth-child(#{$i}) .service__title::before {
            content: '#{$i}.';
          }
        }
      }
      &__title {
        position: relative;
        font-size: 18px;
        line-height: (38 / 36);
        padding-top: 15px;
        &::before {
          position: absolute;
          font-size: 44px;
          letter-spacing: 0.08em;
          color: #bad4d5;
          @include font-Montserrat_extralight;
          top: 0;
          left: 0;
          @at-root (without: media rule) {
            .p-afterservice .p-afterserviceWrap.perfect .c-card .service__title::before {
              color: #a78b62;
            }
          }
        }
      }
      &__thumb {
        text-align: center;
        margin: 52px 0;
        @include pc{
          margin: 0;
        }
        img {
          height: auto;
        }
        @at-root (without: media rule) {
          .p-afterservice .c-card .service__item {
            &:nth-of-type(1) {
              & {
                img {
                  width: 80px;
                  @include pc{
                    width: 100px;
                  }
                }
              }
            }
            &:nth-of-type(2) {
              & {
                img {
                  width: 80px;
                  @include pc{
                    width: 100px;
                  }
                }
              }
            }
            &:nth-of-type(3) {
              & {
                img {
                  width: 74px;
                  @include pc{
                    width: 92px;
                  }
                }
              }
            }
            &:nth-of-type(4) {
              & {
                img {
                  width: 80px;
                  @include pc{
                    width: 92px;
                  }
                }
              }
            }
            &:nth-of-type(5) {
              & {
                img {
                  width: 74px;
                  @include pc{
                    width: 92px;
                  }
                }
              }
            }
          }
        }
      }
      &__icon {
        margin-bottom: 10px;
        li {
          display: inline-block;
          vertical-align: top;
        }
        span {
          display: inline-block;
          border: 1.5px solid #bad4d5;
          font-size: 10px;
          line-height: 1;
          padding: 5px 7px;
          text-align: center;
          min-width: 62px;
          box-sizing: border-box;
          border-radius: 15px;
          &.year {
            border-color: #d0bb9c;
          }
          @include IE{
            padding-bottom: 2px;
          }
        }
      }
      &__text {
        font-size: 14px;
        line-height: (46 / 28);
        @include font-noto_sans_light;
        &.-small {
          font-size: 12px;
          line-height: (34 / 24);
        }
        + .service__text {
          margin-top: 10px;
        }
        b {
          @include font-noto_sans_medium;
        }
      }
    }
  }
  & &Plan {
    border-top: 1px solid #cecece;
    padding-top: 38px;
    @include pc{
      max-width: 1320px;
      margin:0 auto;
      padding-top: 60px;
    }
    .plan {
      &__title {
        font-size: 18px;
        letter-spacing: 0.14em;
        line-height: 1;
        margin-bottom: 20px;
        @include font-noto_sans_light;
        @include pc{
          font-size: 22px;
          margin-bottom: 30px;
        }
      }
      &__note {
        font-size: 11px;
        line-height: (36 / 22);
        margin-top: 18px;
        @include pc{
          margin-top: 15px;
          font-size: 12px;
          line-height: (42 / 24);
        }
      }
    }
    .c-table__table {
      th.premium {
        background: #e3d8c4!important;
      }
      td.premium {
        background: #fdf9ef!important;
      }
      th.vip {
        background: #a78b62!important;
        color: #fff;
      }
      td.vip {
        background: #fbefd6!important;
      }
      th.infinity {
        color: #fff;
        background: #D32D26 !important;
      }
      td.infinity {
        background: #FFEEEE !important;
        color: $c-primary;

        .small {
          font-size: 10px;
        }
      }
      thead th {
        width: 20%;
        letter-spacing: 0.06em;
      }
      tbody {
        th {
          width: 20%;
        }
        td {
          text-align: center;
          width: 20%;
        }
      }
    }
    .c-btnWrap {
      margin-top: 10px;
      margin-bottom: 0;
      @include pc{
        margin-top: 25px;
      }
      a {
        border: none;
        letter-spacing: 0.01em;
        font-size: 12px;
        text-align: center;
        line-height: 1.2;
        @include pc{
          font-size: 13px;
        }
      }
    }
  }
  & &Notice {
    padding: 0 20px;
    margin-bottom: 10px;
    @include pc{
      padding: 0 60px;
      margin-bottom: 30px;
    }
    .notice {
      &__inner {
        border-bottom: 1px solid #cecece;
        padding-top: 40px;
        padding-bottom: 40px;
        @include pc{
          max-width: 1320px;
          margin:0 auto;
          padding-top: 78px;
          padding-bottom: 50px;
        }
      }
      &__title {
        font-size: 18px;
        letter-spacing: 0.14em;
        line-height: 1;
        margin-bottom: 25px;
        @include font-noto_sans_light;
        @include pc{
          font-size: 22px;
          margin-bottom: 15px;
        }
      }
      &__lead {
        font-size: 13px;
        letter-spacing: 0.16em;
        margin-bottom: 20px;
        @include pc{
          font-size: 14px;
          margin-bottom: 25px;
        }
      }
      &List {
        @include pc{
          display: flex;
          justify-content: space-between;
          flex: 1 0 auto;
        }
        &__list {
          @include pc{
            flex-basis: 50%;
            width: 50%;
            min-width: 50%;
            &:not(:first-of-type) {
              padding-left: 20px;
            }
          }
        }
        &__item {
          font-size: 12px;
          line-height: (44 / 24);
          text-indent: -1em;
          margin-left: 1em;
          @include pc{
            font-size: 12px;
          }
        }
      }
    }
  }

  & &Link {
    padding: 0 20px;
    margin-bottom: 10px;
    @include pc{
      padding: 0 60px;
      margin-bottom: 30px;
    }
    .link {
      &__inner {
        border-bottom: 1px solid #cecece;
        padding-top: 40px;
        padding-bottom: 40px;
        @include pc{
          max-width: 1320px;
          margin: 0 auto;
          padding-top: 50px;
          padding-bottom: 50px;
        }
        .c-btnWrap {
          margin: auto;
          .c-btn {
            margin: auto;
            max-width: 330px;
          }
        }
      }
      &__title {
        @include font-noto_sans_medium;
        text-align: center;
        font-size: 13px;
        margin-bottom: 10px;
        @include pc{
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
}
