@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-perfect {
  @at-root {
    & {
      border: 1px solid #bad3d6;
      padding: 40px 20px;
      text-align: center;
      @include pc {
        padding-bottom: 70px;
        padding-top: 60px;
      }
    }
    .p-perfect__title {
      @include font-noto_sans_medium;
      font-size: 22px;
      letter-spacing: .06em;
      line-height: 1.4;
      margin-bottom: .75em;
      text-align: center;
      @include pc {
        align-items: center;
        display: flex;
        font-size: 28px;
        justify-content: center;
      }
      &::before {
        background: url("/img/common/icon_visionkun_03.png") no-repeat left top / contain;
        content: '';
        display: block;
        height: 82px;
        margin: 0 auto 10px;
        width: 100px;
        @include pc {
          display: none;
        }
      }
      &::after {
        @include pc {
          background: url("/img/common/icon_visionkun_03.png") no-repeat left top / contain;
          content: '';
          display: block;
          height: 82px;
          margin-left: 10px;
          width: 100px;
        }
      }
    }
    .p-perfect__btn {
      margin-top: 30px;
      @include pc {
        margin-left: auto;
        margin-right: auto;
        width: 277px;
      }
    }
  }
}
