@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-contents.-notfound {
  .p-product.c-sec {
    .c-product {
      padding: 7vw 30px 8.5vw;
      display: block;
      .c-productMain__contents {
        height: auto;
        width: 100%;
        img {
          display: block;
          margin: 0 auto;
        }
      } // .c-productMain__contents
      .c-productMain__body {
        position: relative;
        padding-top: 19px;
        &::after {
          position: absolute;
          right: 0;
          bottom: -58px;
          left: 0;
          margin: auto;
          display: block;
          height: 2px;
          width: 550px;
          content: "";
          background-image: linear-gradient(to right,#e6e8e9,#e6e8e9 2px,transparent 2px,transparent 5px);
          background-repeat: repeat-x;
          background-size: 5px 2px;
        }
        .c-productMain__title {
          font-size: 21px;
          font-weight: 500;
          letter-spacing: 0.14em;
          text-align: center;
          margin-top: 0;
          margin-bottom: 29px;
        }
        .c-text {
          font-size: 16px;
          letter-spacing: 0.14em;
        }
      } // .c-productMain__body
    } // .c-product
    .c-btnWrap {
      margin-top: 90px;
    }
    @include sp{
      .c-product {
        padding: 1.5vw 20px;
        .c-productMain__contents > img {
          width: calc(100% - 224px);
          min-width: 86px;
          margin: 57.5px auto 21px;
        }
        .c-productMain__body {
          padding: 0;
          &::after {
            bottom: -40px;
            width: 100px;
            content: "";
            background-image: linear-gradient(to right, #000, #000 2px, transparent 2px, transparent 1px);
            background-repeat: repeat-x;
            background-size: 6px 2px;
          }
          .c-productMain__title {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 19.5px;
            letter-spacing: 0.12em;
            white-space: nowrap;
            @include sp320 {
              white-space: normal;
            }
          }
          .c-text {
            font-size: 13px;
            letter-spacing: 0.12em;
          }
        } // .c-productMain__body
        .c-btnWrap {
          margin-top: 80px;
          margin-bottom: 57px;
        }
      } // .c-product
    } // @include sp
  } 
}