@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-hanairo {

  $hanairo-red: #E60716;
  $hanairo-pink: #C47F7B;
  $hanairo-purple: #B589B1;
  $hanairo-yellow: #CCAA61;
  $hanairo-blue: #62759B;

  $hanairo-serif: 'Shippori Mincho', serif;
  $hanairo-en-serif: 'EB Garamond', serif;
  $hanairo-en-sans: 'Montserrat', sans-serif;

  @include font-noto_sans_medium;

  line-height: 1.85;
  @include pc {
    line-height: 2;
  }

  &-contents {
    &.l-contents:not(.-top):not(.p-cardUrl) {
      padding-top: 0;
      @include pc {
        padding-top: 92px;
      }
    }
  }

  &-inner {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px 60px;
    @include pc {
      padding: 0 20px 100px;
    }
  }


/*-----------
.hero
-------------*/
.hero {
  margin-top: 0;
  margin-bottom: 0;

  &-img {
    &-pc {
      display: none;
      @include pc {
        display: block;
      }
    }
    &-sp {
      display: block;
      @include pc {
        display: none;
      }
    }

    img {
      width: 100%;
    }
  }
} // .hero


/*-----------
.bnr
-------------*/
.bnr{
  padding-top: 40px;
  @include pc {
    padding-top: 70px;
  }
  .p-hanairo-inner{
    padding-bottom: 0;
  }
  &-img{
    text-align: center;

    img{
      width: 422px;
    }
  }
}

/*-----------
.intro
-------------*/
.intro {
  padding-top: 80px;
  padding-bottom: 0;
  background-image: url(/img/hanairo/intro01_sp.png), url(/img/hanairo/intro02_sp.png);
  background-size: 185px 191px, 144px 123px;
  background-position: -7% 8%,98% 94%;
  @include pc {
    margin-top: 70px;
    margin-bottom: -40px;
    padding-top: 50px;
    background-image: url(/img/hanairo/intro01_pc.png), url(/img/hanairo/intro02_pc.png);
    background-repeat: no-repeat;
    background-size: 302px 334px, 310px 263px;
    background-position: calc(50% - 385px) 3%,calc(50% + 352px) 72%;
  }
  &-title {
    text-align: center;
    font-size: 19px;
    font-family: $hanairo-serif;
    letter-spacing: 0.1em;
    @include pc {
      font-size: 26px;
    }
  }
  &-text {
    font-size: 15px;
    margin: 15px 0 0 0;
    text-align: left;
    letter-spacing: 0.05em;
    @include pc {
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.1em;
    }
    .pink{color: #EA618F;}
  }

} // .intro


/*-----------
.check
-------------*/
.check {
  position: relative;
  margin-top: 50px;
  padding-top: 20px;
  background-color: #F2F2F2;

  @include pc {
    margin-top: 100px;
    padding-top: 0;
  }

  .p-hanairo-inner {
    @include pc {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  &-title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 19px;
    font-family: $hanairo-serif;
    letter-spacing: 0.1em;
    line-height: 1.9;

    @include pc {
      margin-bottom: 50px;
      font-size: 26px;
    }
    .en{
      display: block;
      font-family: $hanairo-en-serif;
      color: $hanairo-red;
      font-weight: 500;
    }
  } // &-title

  &::before {
    content: "";
    position: absolute;
    top: -50px;
    left: 0;
    display: block;
    width: 100%;
    height: 50px;
    background-repeat: repeat-x;
    background-position: center top;
    background-image: url(/img/hanairo/check_bg_sp.png);
    background-size: 1450px 50px;

    @include pc {
      top: -100px;
      height: 100px;
      background-image: url(/img/hanairo/check_bg_pc.png);
      background-size: 2849px 100px;
    }

  } // &::before

  &-list {
    #check-item {
      &-021,
      &-022,
      &-031,
      &-032,
      &-033{
        display: none;
      }
    }
  } // &-list
  &-item {

    &.-open {
      display: block !important;
    } // &.-open

    position: relative;
    background: #fff;
    border: 1px solid #CECECE;
    padding: 30px 20px 40px;
    margin-bottom: 20px;
    @include pc {
      padding: 50px 40px;
    }
    &Inner {
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;

      @include pc {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .left {
        margin-bottom: 20px;
        @include pc {
          display: flex;
          align-items: center;
          width: calc(37% - 30px);
          margin-bottom: 0;
        }
        .num {
          width: 100%;
          text-align: center;
          display: block;
          font-size: 44px;
          line-height: 1.1;
          color: $hanairo-red;
          font-family: $hanairo-en-serif;
          font-weight: 500;
          @include pc {
            width: auto;
          }
          &::before {
            content: "Check";
            display: block;
            color: $hanairo-red;
            font-size: 16px;
            text-align: center;
            white-space: nowrap;
            font-family: $hanairo-en-serif;
            font-weight: 500;
            letter-spacing: 0.1em;
            @include pc {
              margin-bottom: 0;
              font-size: 20px;
            }
          }
        } // .num
        .detail-text {
          text-align: center;
          font-size: 18px;
          letter-spacing: 0.1em;
          @include pc {
            text-align: left;
            margin-left: 30px;
            font-size: 18px;
          }
          .small{
            font-size: 13px;
            @include pc {
              font-size: 14px;
            }
          }
        } //.detail-text
      } // .left
      .right {
        @include pc {
          width: calc(63% - 30px);
        }
        .button {
          display: flex;
          justify-content: space-between;
          flex: 1 0 calc(50% - 10px);
          flex-wrap: wrap;
          margin-bottom: 20px;
          @include pc {
            margin-bottom: 0;
          }
          .yes{
            margin-bottom: 15px;
            @include pc {
              margin-bottom: 0;
            }
          }
          .yes,
          .no {
            width: 100%;
            font-size: 13px;
            letter-spacing: 0.1em;
            @include pc {
              width: calc(50% - 10px);
            }
            .button-img{
              display: block;
              text-align: center;
              margin-bottom: 15px;
              @include pc {
                margin-bottom: 20px;
              }
              img{
                width: 70px;
              }
            }
            input {
              display: none;
              &:checked+.yesLabel,
              &:checked+.noLabel {
                background: #333;
                color: #fff;
              }
            } // input
            label {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              padding: 14px 5px;
              border-radius: 4em;
              border: 1px solid #333;
              background: #fff;
              font-size: 16px;
              max-height: 78px;
              line-height: 1.25;
              @include pc {
                cursor: pointer;
                transition: all 0.2s;
                font-size: 17px;
                padding: 10px;
                height: 80px;
                line-height: 1.75;
                &:hover {
                  background: #333;
                  color: #fff;
                }
              }
              .small{
                margin-top: 0.25em;
                font-size: 12px;
                line-height: 1.5;
                @include pc {

                }
              }
            } // label
          } // .yes, .no

        } // .button
        .retry {
          font-size: 12px;
          text-align: right;
          text-decoration: none;
          position: absolute;
          right: 20px;
          border-bottom: 1px solid #333;
          @include font-noto_sans;
          @include pc {
            bottom: 10px;
            right: 40px;
            cursor: pointer;
          }
        } // .retry
      } // .right
    } // &Inner
  } // &-item
} // .check



/*-----------
.check-result
-------------*/
.check-result {
  &-list {
    #check-result-item {
      &-01,
      &-02,
      &-03,
      &-04 {
        display: none;
      }
    } // #check-result-item
  } // &-list
  &-item {
    &.-open {
      display: block !important;
    }

    background: #fff;
    background-position: center center;
    background-size: 1440px 2364px;
    background-repeat: no-repeat;
    border: 1px solid #CECECE;
    padding: 40px 20px;
    @include pc {
      padding: 40px;
    }

    .head {
      @include pc {
        max-width: 730px;
        margin: auto;
      }
      .check-result-title{
        margin-bottom: 30px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 0.1em;
        @include pc {
          margin-bottom: 0;
        }
        .en{
          display: block;
          font-family: $hanairo-en-serif;
          color: $hanairo-red;
          font-size: 20px;
          font-weight: 500;
          @include pc {
            font-size: 26px;
          }
        }// .check-result-title
      }
      .check-result-type {
        @include pc {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .img {
          text-align: center;
          img{
            width: 200px;
          }
        }
        .text {
          margin-left: -20px;
          margin-right: -20px;
          text-align: center;
          @include  pc {
            margin-left: 0;
            margin-right: 0;
          }
          &-pc {
            display: none;
            @include  pc {
              width: 510px;
              display: block;
            }
          }
          &-sp {
            margin-top: -15px;
            width: 313px;
            display: inline-block;
            @include pc {
              display: none;
            }
          }
        }
      } // .check-result-type
    } // .head
    .foot {
      display: flex;
      flex-direction: column; // iOS buttonタグはみ出し防止
      max-width: 275px;
      margin: 0 auto;
      @include pc {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-width: 418px;
      }
      .detail-button{
        text-align: center;
        width: 100%;
        margin-top: 0;
        padding: 16px 0;
        border-radius: 3em;
        border: 1px solid #333;
        background: #333;
        color: #fff;
        font-size: 17px;
        line-height: 1.3;
        text-decoration: none;
        letter-spacing: 0.1em;
        cursor: pointer;
        @include pc {
          padding: 22px 0;
          font-size: 18px;
        }
      } // .detail-button
      .retry-button {
        font-size: 15px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        margin-top: 20px;
        letter-spacing: 0.1em;
        @include pc {
          font-size: 16px;
        }
        button {
          text-decoration: underline;
        }
        @include pc {
          margin: 40px auto 0;
          &:only-child {
            margin: 0 auto;
          }
          &:hover {
            button{
              text-decoration: none;
            }
          }
        }
      } // .retry-button
    } // .foot

    &.-spring {
      background-image: url(/img/hanairo/bg_spring.jpg);
      .detail-button{
        background: $hanairo-pink;
        border-color: $hanairo-pink;
        &:hover{
          background: #fff;
          color: $hanairo-pink;
        }
      }
    }
    &.-summer {
      background-image: url(/img/hanairo/bg_summer.jpg);
      .detail-button{
        background: $hanairo-purple;
        border-color: $hanairo-purple;
        &:hover{
          background: #fff;
          color: $hanairo-purple;
        }
      }
    }
    &.-autumn{
      background-image: url(/img/hanairo/bg_autumn.jpg);
      .detail-button{
        background: $hanairo-yellow;
        border-color: $hanairo-yellow;
        &:hover{
          background: #fff;
          color: $hanairo-yellow;
        }
      }
    }
    &.-winter{
      background-image: url(/img/hanairo/bg_winter.jpg);
      .detail-button{
        background: $hanairo-blue;
        border-color: $hanairo-blue;
        &:hover{
          background: #fff;
          color: $hanairo-blue;
        }
      }
    }
  } // &-item
} // .check-result



/*-----------
.typelink
-------------*/
.typelink{
  .p-hanairo-inner{
    @include pc {
      padding-bottom: 80px;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 55px auto 0;
    @include tb {
      flex-direction: row;
      flex-wrap: wrap;
    }
    @include pc {
      flex-wrap: nowrap;
      margin: 80px auto 0;
    }
  } // &-list

  &-item {
    max-width: 100%;
    width: 285px;
    height: 50px;
    margin: 15px 0 0;
    @include tb {
      margin: 15px 10px 0;
    }
    @include pc {
      width: 265px;
      margin: 0 10px;
      height: 60px;
    }
    a {
      position: relative;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      letter-spacing: 0.1em;
      text-align: center;
      background-color: #333;
      border: 1px solid #333;
      border-radius: 60px;
      text-decoration: none;
      transition: all .2s;
      @include pc {
        font-size: 16px;
        height: 60px;
      }
      span{
        line-height: 1;
      }
    }
    svg {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 12px;
      height: 7px;
      stroke: #fff;
      transition: stroke 250ms;
      transform: translateY(-50%);
    }

    &.-spring{
      a{
        background-color: $hanairo-pink;
        border-color: $hanairo-pink;
        &:hover{
          background-color: #fff;
          color: $hanairo-pink;
          svg {
            stroke: $hanairo-pink;
          }
        }
      }
    }
    &.-summer{
      a{
        background-color: $hanairo-purple;
        border-color: $hanairo-purple;
        &:hover{
          background-color: #fff;
          color: $hanairo-purple;
          svg {
            stroke: $hanairo-purple;
          }
        }
      }
    }
    &.-autumn{
      a{
        background-color: $hanairo-yellow;
        border-color: $hanairo-yellow;
        &:hover{
          background-color: #fff;
          color: $hanairo-yellow;
          svg {
            stroke: $hanairo-yellow;
          }
        }
      }
    }
    &.-winter{
      a{
        background-color: $hanairo-blue;
        border-color: $hanairo-blue;
        &:hover{
          background-color: #fff;
          color: $hanairo-blue;
          svg {
            stroke: $hanairo-blue;
          }
        }
      }
    }
  } // &-item
} // typelink



/*-----------
.typeDetail
-------------*/
.typeDetail{
  background-repeat: no-repeat, no-repeat;
  background-size: contain, cover;
  background-position: center bottom, center top;
  @include  pc {
    background-repeat: no-repeat, repeat-x, no-repeat;
    background-size: contain, contain, cover;
    background-position: center top, center bottom, center top;
  }
  &.-spring{
    background-image: url(/img/hanairo/type_bg_white_sp.png), url(/img/hanairo/bg_spring.jpg);
    @include  pc {
      background-image: url(/img/hanairo/type_bg_spring_pc.png), url(/img/hanairo/type_bg_white_pc.png), url(/img/hanairo/bg_spring.jpg);
    }
  }
  &.-summer{
    background-image: url(/img/hanairo/type_bg_white_sp.png), url(/img/hanairo/bg_summer.jpg);
    @include  pc {
      background-image: url(/img/hanairo/type_bg_summer_pc.png), url(/img/hanairo/type_bg_white_pc.png), url(/img/hanairo/bg_summer.jpg);
    }
  }
  &.-autumn{
    background-image: url(/img/hanairo/type_bg_white_sp.png), url(/img/hanairo/bg_autumn.jpg);
    @include  pc {
      background-image: url(/img/hanairo/type_bg_autumn_pc.png), url(/img/hanairo/type_bg_white_pc.png), url(/img/hanairo/bg_autumn.jpg);
    }
  }
  &.-winter{
    background-image: url(/img/hanairo/type_bg_white_sp.png), url(/img/hanairo/bg_winter.jpg);
    @include  pc {
      background-image: url(/img/hanairo/type_bg_winter_pc.png), url(/img/hanairo/type_bg_white_pc.png), url(/img/hanairo/bg_winter.jpg);
    }
  }

  .p-hanairo-inner{
    @include  pc {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  .typeDetail-head{
    @include  pc {
      display: flex;
      align-items: center;
    }
    .headImg{
      text-align: center;
      margin-left: -30px;
      margin-right: -30px;
      @include  pc {
        width: calc(50% + 70px);
        margin-right: 0;
      }
      &-pc {
        display: none;
        @include  pc {
          width: 100%;
          display: block;
        }
      }
      &-sp {
        display: inline-block;
        @include pc {
          display: none;
        }
      }
    } // .headImg

    .headText{
      @include  pc {
        position: relative;
        top: -15px;
        width: 50%;
      }
      .title{
        text-align: center;
        margin-bottom: 15px;
        margin-left: -30px;
        margin-right: -30px;
        @include  pc {
          margin-bottom: 15px;
          margin-left: 0;
          margin-right: 0;
        }
        &-pc {
          display: none;
          @include  pc {
            display: block;
          }
        }
        &-sp {
          display: inline-block;
          width: 375px;
          @include pc {
            display: none;
          }
        }
      }
      p{
        font-size: 15px;
        letter-spacing: 0.1em;
        @include pc {
          max-width: 460px;
          margin-left: 30px;
          font-size: 16px;
        }
      }
    } // .headText


    &.-spring,
    &.-autumn{
      @include  pc {
        flex-direction: row;
      }
      .headImg{
        @include  pc {
          margin-left: -70px;
        }
      }
    }
    &.-summer,
    &.-winter{
      @include  pc {
        flex-direction: row-reverse;
      }
      .headImg{
        @include  pc {
          margin-right: -70px;
        }
      }
    }

  } // typeDetail-head

  &-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    @include pc {
      margin-top: 0;
    }
  } // &-list

  &-item{
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    @include pc {
      margin-bottom: 30px;
      &.-color,
      &.-word,
      &.-accessory,
      &.-makeup{
        max-width: 100%;
        width: calc(50% - 15px);
      }
    }
    &:last-of-type{
      margin-bottom: 0;
    }
    .inner{
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      background: #fff;
      padding: 30px 25px;
      z-index: 2;
      @include pc {
        padding: 25px 40px;
        display: flex;
      }
      & > *{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    } // .inner

    &.-color .inner > *{
      max-width: 440px;
      @include pc {
        max-width: 100%;
      }
    }
    &.-word .inner > *{
      max-width: 100%;
      @include pc {
        max-width: 500px;
      }
    }
    &.-makeup .inner > *{
      max-width: 580px;
    }

    &::before,
    &::after{
      position: absolute;
      width: 180px;
      height: 180px;
      background-size: 100%;
      z-index: 1;
      @include pc {
        display: none;
      }
    } // &::before, &::after

    &.-spring{
      &.-color::before{
        content: "";
        top: -37%;
        left: calc(50% - 40px);
        background-image: url(/img/hanairo/flower_spring_01_sp.png);
      }
      &.-accessory::before{
        content: "";
        top: 82%;
        left: -30px;
        background-image: url(/img/hanairo/flower_spring_02_sp.png);
      }
      &.-makeup::before{
        content: "";
        top: 90%;
        right: -30px;
        background-image: url(/img/hanairo/flower_spring_03_sp.png);
      }
      &.-glasses::before{
        content: "";
        bottom: 14%;
        left: -30px;
        background-image: url(/img/hanairo/flower_spring_04_sp.png);
      }
    } //&.-spring

    &.-summer{
      &.-color::before{
        content: "";
        top: 70%;
        left: -30px;
        background-image: url(/img/hanairo/flower_summer_01_sp.png);
      }
      &.-makeup::before{
        content: "";
        top: 62%;
        left: -30px;
        background-image: url(/img/hanairo/flower_summer_02_sp.png);
      }
      &.-glasses::before{
        content: "";
        top: -4%;
        right: -30px;
        background-image: url(/img/hanairo/flower_summer_03_sp.png);
      }
    } //&.-summer

    &.-autumn{
      &.-makeup::before{
        content: "";
        top: -17%;
        left: -30px;
        background-image: url(/img/hanairo/flower_autumn_01_sp.png);
      }
      &.-glasses::before{
        content: "";
        top: 14%;
        right: -30px;
        background-image: url(/img/hanairo/flower_autumn_02_sp.png);
      }
      &.-glasses::after{
        content: "";
        top: 91%;
        left: -30px;
        background-image: url(/img/hanairo/flower_autumn_03_sp.png);
      }
    } //&.-autumn

    &.-winter{
      &.-accessory::before{
        content: "";
        top: -28%;
        right: -30px;
        background-image: url(/img/hanairo/flower_winter_01_sp.png);
      }
      &.-makeup::before{
        content: "";
        top: 80%;
        left: -30px;
        background-image: url(/img/hanairo/flower_winter_02_sp.png);
      }
    } //&.-winter

    p{
      font-size: 15px;
      line-height: 1.86;
      letter-spacing: 0;
      @include pc {
        font-size: 16px;
        line-height: 2;
        letter-spacing: 0.1em;
      }
    } // p

    &-title{
      text-align: center;
      margin-bottom: 20px;
      font-family: $hanairo-serif;
      font-size: 19px;
      font-weight: 600;
      letter-spacing: 0;
      @include pc {
        margin-bottom: 25px;
        font-size: 20px;
      }

      .en{
        display: block;
        font-family: $hanairo-en-serif;
        margin-bottom: 0.25em;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0;
        @include pc {
          font-size: 24px;
        }
      }

      &::after{
        content: "";
        display: block;
        margin: 5px auto 0;
        width: 38px;
        height: 1px;
        background: #333;
      }

      &.-spring{
        .en{
          color: $hanairo-pink;
        }
        &::after{
          background: $hanairo-pink;
        }
      }
      &.-summer{
        .en{
          color: $hanairo-purple;
        }
        &::after{
          background: $hanairo-purple;
        }
      }
      &.-autumn{
        .en{
          color: $hanairo-yellow;
        }
        &::after{
          background: $hanairo-yellow;
        }
      }
      &.-winter{
        .en{
          color: $hanairo-blue;
        }
        &::after{
          background: $hanairo-blue;
        }
      }
    } // &-title

    &.-color{
      .img{
        text-align: center;
        img{
          width: 337px;
        }
      }
    } // &.-color

    &.-word{
      p{
        margin: 0 auto;
      }
    } // &.-word

    &.-accessory{
      p{
        margin-bottom: 20px;
        @include pc {
          text-align: center;
        }
      }
      .img{
        text-align: center;
        img{
          width: 210px;
          @include pc {
            width: 245px;
          }
        }
      }
    } // &.-accessory

    &.-makeup{
      .makeup-column{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &-item{
          width: 100%;
          max-width: 275px;
          margin-left: auto;
          margin-right: auto;
          @include pc {
            width: calc(50% - 10px);
            margin-left: 0;
            margin-right: 0;
          }
          &::after{
            content: "";
            display: block;
            clear: both;
          }
          dt{
            font-size: 15px;
            margin-bottom: 10px;
            @include font-noto_sans_bold;
            @include pc {
              font-size: 16px;
            }
          }
          dd{
            font-size: 15px;
            position: relative;
            @include pc {
              font-size: 16px;
            }
          }

          &.-lip{
            dd{
              .img{
                float: right;
                margin-right: 15px;
                @include pc {
                  float: none;
                  margin-right: 0;
                  padding-left: 40px;
                  padding-top: 10px;
                }
                img{
                  width: 80px;
                  height: 80px;
                }
              }
              p{
                float: left;
                @include pc {
                  float: none;
                }
              }
            }
          } // &.lip
          &.-eye{
            dd{
              .base{
                float: left;
                margin-right: 10px;
                margin-bottom: 1em;
              }
              .img{
                float: right;
                text-align: center;
                margin-right: 15px;
                @include pc {
                  margin-right: 0;
                  float: none;
                }
                img{
                  margin-bottom: 15px;
                  width: 72px;
                  height: 80px;
                }
              }
              .point{
                clear: both;
              }
            }
          } // &.eye
        } // &-item
      } // .makeup-column
    } // &.-makeup

    &.-glasses{
      p{
        @include pc {
          text-align: center;
        }
      }
      .recommend{
        max-width: 1180px;
        margin: 0 -10px;
        width: calc(100% + 20px);
        @include pc {
          margin: 0 auto;
          width: 100%;
        }

        &-lead{
          position: relative;
          text-align: center;
          &::after{
            @include pc {
              content: "";
              position: absolute;
              left: 0;
              bottom: 18px;
              display: block;
              width: 100%;
              height: 30px;
              border-top: 1px solid #ddd;
              border-left: 1px solid #ddd;
              border-right: 1px solid #ddd;
              z-index: 1;
            }
          }
          &.-spring::after{
            border-color: $hanairo-pink;
          }
          &.-summer::after{
            border-color: $hanairo-purple;
          }
          &.-autumn::after{
            border-color: $hanairo-yellow;
          }
          &.-winter::after{
            border-color: $hanairo-blue;
          }

          &-text{
            position: relative;
            padding-bottom: 20px;
            font-size: 16px;
            z-index: 2;
            letter-spacing: 0.05em;
            margin-left: -5px;
            margin-right: -5px;
            @include font-noto_sans_bold;
            @include pc {
              padding-bottom: 30px;
              background: #fff;
              max-width: 560px;
              margin-left: auto;
              margin-right: auto;
              font-size: 18px;
              letter-spacing: 0.1em;
            }
            .block{
              display: block;
            }
            .underLine{
              text-decoration: underline;
              margin: 0.5em 0;
              line-height: 1.625;
              letter-spacing: 0.05em;
              @include pc {
                margin-bottom: 0.25em;
                letter-spacing: 0.1em;
              }
            }
            .small{
              font-size: 15px;
              letter-spacing: 0.1em;
              @include pc {
                font-size: 16px;
              }
            }
            &.-spring{
              color: $hanairo-pink;
            }
            &.-summer{
              color: $hanairo-purple;
            }
            &.-autumn{
              color: $hanairo-yellow;
            }
            &.-winter{
              color: $hanairo-blue;
            }
          } // &-text
        } // &-lead

        &-list{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          &::after{
            @include pc {
              content: "";
              display: block;
              width: calc(100% / 3 - 20px * 2 / 3);
            }
          }
          &-main{
            width: 100%;
            margin-top: 20px;
            &:first-of-type{
              margin-top: 0;
            }
            @include tb {
              width: calc(100% / 2 - 15px * 1 / 2);
              &:nth-of-type(1),
              &:nth-of-type(2){
                margin-top: 0;
              }
            }
            @include pc {
              width: calc(100% / 3 - 20px * 2 / 3);
              &:nth-of-type(1),
              &:nth-of-type(2),
              &:nth-of-type(3){
                margin-top: 0;
              }
            }

            &-link{
              display: flex;
              flex-direction: column;
              height: 100%;
              text-align: center;
              text-decoration: none;
              padding: 30px 10px;
              background: #F8F8F8;
              letter-spacing: 0.1em;
              height: 100%;
              @include pc {
                padding: 35px 20px;
              }

              &__thumb{
                margin-bottom: 20px;
                padding-left: 22px;
                padding-right: 22px;
                @include pc {
                  padding-left: 0;
                  padding-right: 0;
                }
                img{
                  width: 300px;
                }
              } //&__thumb

              &__spec{
                font-family: $hanairo-en-sans;
                color: #7F7F7F;
                font-size: 12px;
                font-weight: 600;
                @include pc {
                  font-size: 13px;
                }
              } //&__spec

              &__title{
                margin: 0.25em 0;
                font-size: 16px;
                @include font-noto_sans_medium;
              } //&__title

              &__priceYen{
                font-family: $hanairo-en-sans;
                font-size: 17px;
                font-weight: 600;
                @include pc {
                  font-size: 18px;
                }
                &__Tax{
                  margin-left: 0.5em;
                  font-size: 12px;
                  @include font-noto_sans_medium;
                  @include pc {
                    font-size: 13px;
                  }
                }
              } //&__priceYen

            } //&-link
          } // &-main
        } // &-list
      } // .recommend
    } // &.-glasses

  } // &-item
} // .typeDetail



/*-----------
.typemerit
-------------*/
.typemerit{
  background: url(/img/hanairo/merit_bg.png) repeat;
  background-size: 1440px 786px;
  padding-top:60px;
  letter-spacing: 0;
  @include pc {
    padding-top: 80px;
  }

  &-title{
    text-align: center;
    font-size: 19px;
    font-family: $hanairo-serif;
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: 0;
    @include pc {
      font-size: 26px;
      line-height: 1.5;
    }
    .brackets{
      font-size: 26px;
      @include pc {
        font-size: 40px;
      }
    }
    .num{
      font-size: 36px;
      @include pc {
        font-size: 46px;
      }
    }
    .small{
      font-size: 17px;
      @include pc {
        font-size: 22px;
      }
    }
  } // &-title

  &-text{
    text-align: center;
    font-size: 15px;
    letter-spacing: 0;
    @include pc {
      font-size: 16px;
    }
  } // &-text

  &-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin: 30px auto 0;
    width: 100%;
    max-width: 1060px;
    @include tb {
      flex-direction: row;
      justify-content: space-between;
    }
    @include pc {
      margin-top: 40px;
    }
    &-item{
      width: 320px;
      max-width: 100%;
      margin-bottom: 20px;
      @include pc {
        margin-bottom: 0;
      }
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  } // &-list
}



/*-----------
.c-staticReserveWrap
-------------*/

.c-staticReserveWrap {
  padding: 40px 20px 80px 20px;
  @include pc {
    padding: 55px 0 120px 0;
  }

  &-last {
    padding: 40px 20px 0 20px;
    @include pc {
      padding: 55px 0 0 0;
    }
  }
}

}

.recommend-list-main.-new .recommend-list-main-link::before {
  content: 'NEW';
  background-color: #c40a21;
  width: 135px;
  height: 135px;
  color: #fff;
  position: absolute;
  top: -80px;
  left: -80px;
  transform: rotate(-45deg);
  padding-top: 109px;
  font-size: 14px;
  z-index: -1;
  @include sp {
    padding-top: 111px;
  }
}

.p-hanairo .typeDetail-item.-glasses .recommend-list-main-link {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
