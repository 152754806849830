@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-hearingConsultation {
  &.l-kv {
    position: relative;
    margin-top: -50px;
    margin-bottom: 0;

    @include pc {
      margin-top: -92px;
    }

    .l-kv__title {
      @include font-noto_sans_light;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: calc(28px * 2);
      font-size: 23px;
      line-height: 28px;

      @include pc {
        height: 42px;
        font-size: 42px;
        line-height: 1;
      }
    }
    .bg {
      text-align: center;
      img {
        margin: auto;
        width: 100%;
        max-width: 1440px;
      }
    }
  }
  &Wrap {

    &.-blue {
      background: #eef3f6;
    }
    &.-red {
      background: #fee5e0;
    }

    .section {
      margin: auto;
      max-width: 1440px;

      &#section-01 {
        padding: 40px 20px 35px;

        @include pc {
          padding: 70px 60px 55px;
        }

        .term {
          @include font-noto_sans_regular;
          text-align: center;
          letter-spacing: .1em;
          line-height: 1;
          font-size: 15px;

          @include pc {
            font-size: 22px;
          }

          span {
            display: inline-block;
            background-color: #fee5e0;
            border-radius: 30px;
            padding: 7px 0;
            width: 100%;

            @include pc {
              padding: 8px 25px;
              max-width: 470px;

              @include IE {
                padding: 14px 25px 3px;
              }
            }
          }

        }
        .text {
          @include font-noto_sans_light;
          font-size: 13px;
          line-height: 23px;
          margin-top: 13px;

          @include pc{
            font-size: 14px;
            margin-top: 20px;
            text-align: center;
          }
        }
        .lead {
          @include font-noto_sans_light;
          font-size: 15px;
          line-height: 25px;
          margin-top: 12px;

          @include pc {
            font-size: 22px;
            line-height: 38px;
            margin-top: 14px;
            text-align: center;
          }

          b {
            @include font-noto_sans_light;
            color: $c-primary;
            letter-spacing: .1em;
            font-size: 18px;

            @include pc {
              font-size: 32px;
            }
          }
        }
      }

      &#section-02 {
        padding: 40px 20px;
        @include pc {
          padding: 40px;
        }
        .anchor-wrap {
          @include pc {
            display: flex;
            justify-content: space-between;
          }

          .anchor-button {
            @include pc {
              // width: calc((100% - 60px) / 3);
              width: calc((100% - 40px) / 2);
            }

            &:not(:first-child) {
              margin-top: 20px;

              @include pc {
                margin-top: 0;
              }
            }

            a {
              @include font-noto_sans;
              background-color: #fff;
              display: block;
              text-align: center;
              text-decoration: none;
              position: relative;

              border: 1px solid #000;
              border-radius: 30px;
              padding: 5px 0;
              font-size: 13px;
              line-height: 17px;

              @include pc {
                border-radius: 60px;
                padding: 14px 40px;
              }

              &::after {
                background: url(/img/common/icon_arrow_bottom_double.svg) no-repeat 100%;
                background-size: contain;
                content: "";
                display: block;
                transform-origin: center;
                margin: auto;
                position: absolute;
                top: 0;
                bottom: 0;

                right: 20px;
                width: 13.5px;
                height: 9px;
              }
            }
          }
        }
      }

      &#section-03,
      &#section-05 {
        padding: 30px 20px 40px;
        @include pc {
          padding: 65px 60px 80px;
        }

        >.title {
          @include font-noto_sans_light;
          text-align: center;
          letter-spacing: .1em;
          font-size: 21px;
          line-height: 34px;
          margin-bottom: 0;

          @include pc {
            font-size: 32px;
            line-height: 1;
          }
        }
        >.image {
          text-align: center;
          margin-top: 12px;

          @include pc{
            margin-top: 55px;
          }

          img {
            width: 100%;
          }
        }
        >.text {
          @include font-noto_sans_light;
          font-size: 13px;
          line-height: 23px;
          margin-top: 13px;

          @include pc {
            font-size: 16px;
            line-height: 27px;
            margin-top: 45px;
            text-align: center;
          }
        }
      }

      &#section-04,
      &#section-06,
      &#section-08 {
        padding: 40px 20px;
        @include pc {
          padding: 60px;
        }
      }

      &#section-07 {
        padding: 40px 20px;
        @include pc {
          padding: 70px 60px;
        }

        >.title {
          @include font-noto_sans;
          color: $c-primary;
          text-align: center;
          letter-spacing: .1em;
          font-size: 21px;
          line-height: 32px;
          margin-bottom: 20px;

          @include pc {
            @include font-noto_sans_light;
            font-size: 32px;
            line-height: 1;
            margin-bottom: 40px;
          }

          b {
            @include font-noto_sans_light;
            font-size: 32px;
            line-height: 38px;
            @include pc{
              font-size: 42px;
              line-height: 1;
            }
          }

        }
        >.special-list {
          counter-reset: special-list;

          >li {
            background-color: #fff;
            border: solid 1px #c7c7c7;
            display: flex;
            padding: 20px 20px 36px;
            flex-direction: column;

            @include pc {
              padding: 60px 40px 60px 45px;
              flex-direction: row;
              justify-content: space-between;
            }

            &:not(:first-child) {
              margin-top: 20px;
              @include pc {
                margin-top: 30px;
              }
            }

            >.head {
              padding-bottom: 20px;
              @include pc {
                width: calc(50% - 30px);
              }
              >.title {
                @include font-noto_sans_light;
                margin-bottom: 0;
                text-align: center;
                letter-spacing: .1em;
                font-size: 21px;
                line-height: 34px;

                @include pc {
                  text-align: left;
                  font-size: 32px;
                  line-height: 1;
                }

                &::before {
                  @include font-Montserrat;
                  counter-increment: special-list;
                  content: counter(special-list) ".";
                  color: $c-primary;
                  display: block;
                  line-height: 1;
                  font-size: 44px;
                  height: 44px;
                  margin-bottom: 5px;
                  text-align: center;

                  @include pc {
                    text-align: left;
                    margin-bottom: 15px;
                  }
                }
              }
              >.text {
                @include font-noto_sans;
                letter-spacing: .1em;
                font-size: 13px;
                line-height: 23px;
                margin-top: 10px;

                @include pc {
                  font-size: 16px;
                  line-height: 26px;
                  margin-top: 40px;
                }
              }
              >.note {
                @include font-noto_sans;
                letter-spacing: .1em;
                font-size: 12px;
                line-height: 22px;
                margin-top: 10px;

                @include pc {
                  font-size: 13px;
                  line-height: 23px;
                  margin-top: 25px;
                }
              }
            } // >.head
            >.body {
              @include pc {
                width: calc(50% - 30px);
              }
              >.block {
                &:first-child {
                  padding-bottom: 25px;

                  @include pc {
                    padding-bottom: 42px;
                  }
                }
                &:not(:first-child) {
                  border-top: dotted 1px #c7c7c7;
                  padding-top: 25px;

                  @include pc {
                    border: none;
                    padding-top: 0;
                  }
                }

                >.brand-logo {
                  margin-bottom: 20px;
                  width: 120/375*100vw;

                  @include pc {
                    margin-bottom: 10px;
                    width: 118px;
                  }

                  img {
                    width: 100%;
                  }
                }

                >.model-list {
                  @include pc {
                    display: flex;
                    justify-content: space-between;
                  }

                  >li {
                    @include pc {
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      width: calc(50% - 10px);
                    }

                    &:not(:first-child) {
                      margin-top: 20px;

                      @include pc {
                        margin-top: 0;
                      }
                    }

                    >.head {
                      >.name {
                        @include font-Montserrat_medium;
                        text-decoration: none;
                        line-height: 1;
                        font-size: 15px;

                        @include pc {
                          line-height: 30px;
                          font-size: 18px;
                        }

                        span {
                          position: relative;
                          transition: all 0.1s ease-in-out;
                          border-bottom: 1px solid #000;
                          padding-bottom: 1px;

                          @include pc {
                            border-bottom: 2px solid #000;
                            padding-bottom: 3px;
                          }

                          &::after {
                            content: '';
                            display: block;
                            background: url(/img/common/icon_blank.svg) no-repeat;
                            background-size: 100% auto;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: auto;
                            margin: auto;
                            right: -20px;
                            width: 15px;
                            height: 16px;
                            transform: translateY(2px);

                            @include pc {
                              //right: -26px;
                              width: 18px;
                              height: 19.5px;
                              transform: translateY(0);
                              display:inline-block;
                              //position:relative;
                              right:auto;
                              top:auto;
                              margin-left:7px;
                            }
                          }
                        }
                        &:hover {
                          span {
                            @include pc {
                              border-bottom: none;
                            }
                          }
                        }
                      }
                      >.description {
                        @include font-noto_sans;
                        font-size: 13px;
                        line-height: 23px;
                        margin-top: 20px;

                        @include pc {
                          font-size: 14px;
                          line-height: 23px;
                          margin-top: 10px;
                        }
                      }
                    }

                    >.body {
                      >.each {
                        @include font-noto_sans_medium;
                        letter-spacing: .1em;
                        line-height: 1;
                        font-size: 13px;
                        margin-top: 18px;

                        @include pc {
                          font-size: 14px;
                          margin-top: 15px;
                        }
                      }
                      >.price {
                        @include font-noto_sans;
                        color: $c-primary;
                        letter-spacing: .15em;
                        line-height: 1.5em;
                        font-size: 13px;
                        margin-top: 12px;

                        @include pc {
                          margin-top: 5px;
                          line-height: 28px;
                        }

                        b {
                          @include font-Montserrat_regular;
                          font-size: 21px;
                        }
                      }
                    }

                    >.image {
                      img {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            } // >.body
          }
        }
      }

      &#section-09 {
        padding: 40px 0 0;
        @include pc {
          padding: 80px 120px 0;
        }

        .p-newsSlider {
          position: relative;
          overflow: hidden;
          margin: 0;
          @include pc{
            overflow: visible;
            margin: 0 -15px;
          }

          &__prev,
          &__next {
            display: none !important;
            @include pc {
              display: block !important;
            }
            background-color: #e60012;
            background-image: none;
            border-radius: 50%;
            transition: all 0.25s;
            position: absolute;
            top: 50%;
            margin-top: -50px;
            z-index: 10;
            cursor: pointer;
            background-size: 27px 44px;
            background-position: center;
            background-repeat: no-repeat;
            width: 100px;
            height: 100px;
            line-height: 500px;
            overflow: hidden;
            @include pc {
              display: block;
              height: 50px;
              width: 50px;
            }
            &:after {
              content: '';
              background: url(/img/common/icon_arrow_white.svg)no-repeat;
              background-size: 100% auto;
              height: 16px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 10px;
            }
            &:hover {
              @include pc {
                transform: scale(1.3);
              }
            }
          }

          &__prev {
            left: -38px;
            @include pc {
              left: -50px;
            }
            &:after {
              transform: translateY(-50%) rotate(-180deg);
              right: 15px;
              @include pc {
                left: 50%;
                transform: translate(-50%, -50%) rotate(-180deg);
              }
            }
          }
          &__next {
            right: -38px;
            @include pc {
              right: -50px;
            }
            &:after {
              left: 15px;
              @include pc {
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

          .slick-slide {
            // 下の枠線消えない様に
            margin-bottom: 1px;
          }
          &__list {
            justify-content: space-between;
            .slick-arrow {
              display: none;
            }
          }

          &__item {
            box-sizing: border-box;
            align-items: stretch;
            // padding: 0 10px;
            // 1件用
            padding: 0 20px;
            @include pc{
              box-sizing: border-box;
              padding: 0 15px;
              // 1件用
              width: calc(100% / 3);
            }

            a {
              background-color: #fff;
              display: block;
              text-decoration: none;
              border: 1px solid #cecece;
              padding-bottom: 20px;
              height: 100%;

              figure {
                span {
                  display: block;
                  border-bottom: 1px solid #cecece;
                  position: relative;
                  background: url(/img/common/bg_gray.png) no-repeat center;
                  background-size: cover;
                  overflow: hidden;
                  padding: 0 0 62.14285714% 0; // (140/87) * 100
                  width: 100%;
                  height: 0;
                  img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                  }
                }

                figcaption {
                  @include font-noto_sans_light;
                  text-align: left;
                  font-size: 13px;
                  line-height: (38 / 26);
                  margin: 20px 15px 10px 20px;
                  letter-spacing: 0.1em;
                  @include pc {
                    font-size: 14px;
                    line-height: (25 / 14);
                    margin: 25px 25px 5px 30px;
                  }
                  @include IE {
                    overflow: hidden;
                    line-height: (25 / 14);
                    height: calc((25 / 14) * 2);
                  }
                }

                time {
                  @include font-Montserrat;
                  text-align: left;
                  line-height: 1;
                  letter-spacing: 0.1em;
                  font-size: 12px;
                  margin: 0 20px;

                  @include pc {
                    font-size: 13px;
                    margin: 0 25px 0 30px;
                  }
                }
              }
            }
          }
        }
      }

      /////////////////
      // Common
      /////////////////

      .step {
        &-list {
          counter-reset: step-list;
          margin-top: 20px;
          @include pc{
            margin-top: 40px;
          }
        } // &-list
        &-item {
          background: #fff;
          border: 1px solid$c-border1;
          padding: 30px 20px;
          position: relative;
          @include pc {
            display: flex;
            align-items: center;
            padding: 40px;
          }
          &::before {
            counter-increment: step-list;
            content: counter(step-list) ".";
            display: block;
            color: $c-border1;
            font-size: 44px;
            height: 44px;
            text-align: center;
            position: absolute;
            top: 20px;
            left: 20px;
            line-height: 1;
            @include font-Montserrat;
            @include pc {
              top: auto;
              left: 40px;
              margin: auto;
              @include IE {
                transform: translateY(-0.6em);
              }
            }
          } // &::before
          &:not(:last-child) {
            margin-bottom: 60px;
          }
          &:not(:last-child)::after {
            background: url(/img/common/icon_arrow_bottom_double.svg) no-repeat center;
            background-size: 100% auto;
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -60px;
            margin: auto;
            height: 60px;
            width: 30px;
          }
          .head {
            margin-bottom: 20px;
            @include pc {
              margin-bottom: 0;
              width: 30%;
            }
            .title {
              @include font-noto_sans_regular;
              position: relative;
              text-align: center;
              font-size: 18px;
              @include pc {
                font-size: 22px;
                padding: 0 0 0 44px;
              }
              &.-multiple {
                @include sp {
                  margin-top: -10px;
                }
              }
            } // .title
          } // .head
          .foot {
            @include pc {
              width: calc(70% - 20px);
              padding-left: 20px;
            }
            .description {
              @include font-noto_sans_light;
              font-size: 13px;
              line-height: 1.8em;
              @include pc {
                font-size: 14px;
              }
              .-red {
                color: $c-primary;
              }
            }
          } // .foot
        } // &-item
      } // .step

      .store-title {
        font-size: 21px;
        text-align: center;
        margin-bottom: 15px;
        @include font-noto_sans_light;
        @include sp320 {
          font-size: 19px;
        }
        @include pc {
          font-size: 32px;
          line-height: 1.2em;
        }
        .small {
          font-size: 18px;
        } // .small
      } // .store-title
      .store-lead {
        font-size: 15px;
        text-align: center;
        margin-bottom: 20px;
        @include font-noto_sans_light;
      } // .store-lead
    } // .section
  } // &Wrap
} //.p-hearingConsultation
