@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-checkboxWrap {
  margin: 30px auto;
  @include pc {
    margin: 40px auto;
  }
  &.-w950 {
    @include pc {
      max-width: 950px;
    }
  }
}

.c-checkbox + .c-checkbox {
  margin-top: 20px;
  @include pc {
    margin-top: 15px;
  }
}

.c-checkbox {
  input[type="checkbox"] {
    margin: 0;
    &::before {
      position: absolute;
      left: -1px;
      display: block;
      width: 20px;
      height: 20px;
      content: "";
      background: #f7f7f7;
      opacity: 1;
    }
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      box-sizing: border-box;
      display: block;
      width: 16px;
      height: 13px;
      margin-top: -6px;
      content: "";
      background: url(/img/common/icon_cheack.svg) no-repeat;
      background-size: 100% auto;
      @include pc {
        left: 3px;
      }
    }
    &:checked::after {
      background: url(/img/common/icon_cheack_on.svg) no-repeat;
      background-size: 100% auto;
    }
  }
  .c-checkbox__label {
    position: relative;
    display: inline-block;
    margin-right: -13px;
    font-size: 13px;
    line-height: 1;
    cursor: pointer;
    @include pc {
      font-size: 14px;
    }
  }
  &__note {
    padding-left: 28px;
    font-size: 12px;
    color: #888;
    @include pc {
      font-size: 13px;
    }
  }
}

.c-checkboxRound {
  input[type="checkbox"] {
    display: none;
    margin: 0;
  }
  input[type="checkbox"]:checked + label::before {
    background: #6a8287;
  }
  label {
    position: relative;
    display: block;
    min-height: 16px;
    padding: 0 0 0 24px;
    line-height: 1.3;
    cursor: pointer;
    @include fz(11);
    @include pc {
      @include fz(13);
      min-height: 24px;
      padding: 3px 0 0 37px;
      line-height: 1.5;
      .c-menuform__dd.color & {
        padding-top: 0;
      }
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 16px;
      height: 16px;
      content: "";
      background: #cddee1;
      background-size: 100% auto;
      border-radius: 50%;
      transition: background 0.2s;
      @include pc {
        width: 24px;
        height: 24px;
      }
    }
    &::after {
      position: absolute;
      top: 5px;
      left: 3px;
      display: block;
      width: 10px;
      height: 8px;
      content: "";
      background: url(/img/common/icon_check_white.svg) no-repeat;
      background-size: 100% auto;
      @include pc {
        top: 7px;
        left: 4px;
        width: 16px;
        height: 12px;
      }
    }
  }
  &.-large {
    label {
      font-size: 13px;
      @include pc {
        font-size: 14px;
      }
    }
  }
  &.-multiple {
    @include pc {
      margin-top: 6px;
    }
    label {
      padding: 0 0 10px 24px;
      @include pc {
        padding: 3px 0 10px 37px;
      }
    }
  }
  &.-white {
    input[type="checkbox"]:not(:checked) + label::before {
      background: #fff;
    }
    input[type="checkbox"]:not(:checked) + label::after {
      background: url(/img/common/icon_check_gray.svg) no-repeat;
      background-size: 100% auto;
    }
  }
}
