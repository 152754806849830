@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-footer {
  position: relative;
  z-index: 10;
  padding-bottom: 49px;
  background: #333;
  @include pc {
    padding-bottom: 0;
  }
  &__body {
    overflow: hidden;
    padding: 0 20px;
    @include pc {
      padding: 0;
    }
    &Inner {
      max-width: 673px;
      padding-top: 30px;
      padding-bottom: 30px;
      margin: 0 auto;
      @include pc {
        max-width: 1320px;
        padding-top: 45px;
        padding-bottom: 40px;
      }
    }
  }
  &__links {
    margin: 0 -20px;
    text-align: center;
    @include sp320 {
      margin: 0 -25px;
    }
    @include pc {
      margin: 0;
    }
    &Item {
      position: relative;
      display: inline-block;
      font-size: 0;
      &:not(:last-of-type) {
        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          display: inline-block;
          width: 1px;
          height: 13px;
          content: "";
          background: #5e5e5e;
          transform: translateY(-50%);
          @include pc {
            height: 14px;
          }
        }
      }
      &:nth-of-type(4) {
        &::after {
          display: none;
          @include pc {
            display: inline-block;
          }
        }
      }
    }
    a {
      display: block;
      padding: 5px 10px;
      line-height: (50 / 23);
      color: #fff;
      letter-spacing: 0.12em;
      text-decoration: none;
      @include fz(11);
      @include pc {
        @include vw(12);
        line-height: (28 / 12);
        letter-spacing: 0.16em;
        padding: 5px 15px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 17px;
    @include pc {
      margin-top: 35px;
    }
    > .c-btn:nth-child(n+3) {
      margin-top: 20px;
      @include pc {
        margin-top: 0;
      }
    }
  }
  &__foot {
    color: #fff;
    background: #c3000f;
    &Inner {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      max-width: 750px;
      padding: 30px 8px;
      margin: 0 auto;
      text-align: center;
      @include pc {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: 1440px;
        padding: 29px 60px;
        text-align: left;
      }
    }
    address {
      order: 1;
      font-style: normal;
      line-height: (38 / 23);
      letter-spacing: 0.16em;
      @include fz(11);
      @include pc {
        @include vw(12);
        line-height: (22 / 12);
      }
    }
    small {
      @include fz(11);
      order: 3;
      margin-top: 18px;
      line-height: (25 / 23);
      color: rgba(#fff, 0.4);
      letter-spacing: 0.16em;
      @include font-Montserrat_regular;
      @include pc {
        @include vw(12);
        order: 2;
        margin-top: 0;
        line-height: (25 / 12);
        color: rgba(#fff, 1);
      }
    }
    .c-sns {
      order: 2;
      margin-top: 18px;
      @include pc {
        order: 3;
        margin-top: 0;
      }
    }
  }
  &.-minimum {
    padding: 20px 10px;
    @include pc {
      padding: 40px 10px;
      margin-top: 120px;
    }
    small {
      display: block;
      line-height: (25 / 23);
      color: rgba(#fff, 0.4);
      text-align: center;
      letter-spacing: 0.16em;
      @include fz(11);
      @include font-Montserrat_regular;
      @include pc {
        @include vw(12);
        line-height: (25 / 12);
        color: rgba(#fff, 1);
      }
    }
  }
  &.-old {
    padding-bottom: 0;
  }
}
