@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

/* ニュース一覧 */
.p-news {
  padding-bottom: 50px;
  @include pc{
    padding-bottom: 0;
    margin-bottom: -30px;
  }
  .c-sec__inner {
    max-width: calc(950px + 3.15% * 2);
  }
  &__title {
    margin: 0 0 30px;
    border-left: 4px solid #e70012;
    padding-left: .65em;
    @include font-noto_sans;
    @include fz(21);
    line-height: 1;
    @include tb{
      @include vw(32);
      margin: 0 0 40px;
      padding-left: .55em;
    }
  }
  &__yearList {
    position: relative;
    display: block;
    margin-bottom: 30px;
    width: 100%;
    height: 32px;
    background: url("/img/common/icon_arrow_bottom.svg") no-repeat 95% center;
    background-size: 10px auto;
    transition: .2s ease-in-out;
    @include tb {
      max-width: 280px;
      margin-bottom: 42px;
    }
    span,
    select {
      cursor: pointer;
      display: block;
      width: 100%;
      height: 100%;
    }
    span {
      position: absolute;
      border: 1px solid #000;
      border-radius: 16px;
      padding: .5em 1em;
      width: 100%;
      font-size: 11px;
      text-align: center;
      @include tb {
        font-size: 12px;
      }
    }
    select {
      opacity: 0;
    }
  }
  .list {
    margin-bottom: 30px;
    border-bottom: 1px solid #cecece;
    padding-bottom: 30px;
    @include tb {
      margin-bottom: 50px;
      padding-bottom: 65px;
    }
    > li {
       & + li {
         margin-top: calc(40px - .25em);
         border-top: 1px solid #cecece;
         padding-top: 40px;
         @include tb {
            margin-top: 60px;
            padding-top: 60px;
          }
       }
      > a {
        display: block;
        text-decoration: none;
        transition: opacity .2s ease-in-out;
        @include tb {
          display: flex;
        }
        &:hover {
          opacity: .7;
        }
        div {
          &.imgbox {
            position: relative;
            @include tb {
              margin-right: 40px;
              width: 280px;
            }
            span {
              position: absolute;
              left: 0;
              top: 0;
              padding: .5em 1em;
              background: #e60012;
              @include font-Montserrat;
              font-size: 13px;
              color: #fff;
            }
            img {
              border: 1px solid #cecece;
            }
          }
          &.textbox {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            @include tb {
              flex: 1;
            }
            > span:not([class]) {
              order: 2;
              margin-top: 1em;
              @include tb {
                margin-top: 5px;
              }
            }
            .title {
              order: 1;
              margin-top: 1em;
              @include fz(18);
              @include tb {
                margin-top: 0;
                @include vw(22);
                line-height: (66 / 44);
              }
            }
            > p:not([class]) {
              order: 3;
              margin-top: 1em;
              line-height: (46/26);
              @include tb {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
  .pagination {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    li {
      margin: 0 3px;
      @include tb {
        margin: 0 5px;
      }
      &:not([class]) {
        display: none;
        @include tb {
          display: block;
        }
      }
      a {
        display: block;
        width: 50px;
        height: 50px;
        font-size: 16px;
        line-height: 50px;
        background: #fff;
        border: 1px solid #353130;
        border-radius: 50%;
        color: #000;
        text-align: center;
        text-decoration: none;
        transition: 0.2s ease-in-out;
        @include font-Montserrat_medium;
        @include pc {
          width: 58px;
          height: 58px;
          line-height: 58px;
        }
        &:hover {
          color: #fff;
          background: #e60012;
          border: 1px solid #e60012;
        }
        .-small & {
          width: 40px;
          height: 40px;
          font-size: 14px;
          line-height: 40px;
        }
      }
      &.previous,
      &.next,
      &.last,
      &.first {
        a {
          position: relative;
          &::after,
          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 10px;
            height: 12px;
            margin-top: -6px;
            margin-left: -5px;
            content: "";
            background-size: 100% auto;
          }
        }
        span {
          display: none;
        }
      }
      &.previous {
        a::before {
          margin-left: -8px;
        }
      }
      &.next {
        a::before {
          margin-left: -3px;
        }
      }
      &.previous,
      &.first {
        a::before {
          background-image: url(/img/common/icon_paging_prev.svg);
        }
        a:hover::before {
          background-image: url(/img/common/icon_paging_prev_white.svg);
        }
      }
      &.next,
      &.last {
        a::before {
          background-image: url(/img/common/icon_paging_next.svg);
        }
        a:hover::before {
          background-image: url(/img/common/icon_paging_next_white.svg);
        }
      }
      &.first {
        a::after {
          border-left: 1px solid #000;
        }
        a:hover::after {
          border-left: 1px solid #fff;
        }
      }
      &.last {
        a::after {
          border-right: 1px solid #000;
        }
        a:hover::after {
          border-right: 1px solid #fff;
        }
      }
      &.current {
        a {
          color: #fff;
          background: #e60012;
          border: 1px solid #e60012;
        }
      }
    }
  }
}
/* ニュース詳細 */
#letter_body {
  @include font-noto_sans;
  line-height: (46/26);
  letter-spacing: .06em;
  font-size: 13px;
  @include pc{
    font-size: 14px;
  }
  img {
    height: auto;
    &[class^="align"] {
      @include sp {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  h1 {
    margin: 50px 0 22px;
    border-left: 4px solid #e70012;
    padding-left: .6em;
    @include font-noto_sans;
    @include fz(21);
    line-height: (62 / 42);
    @include tb {
      @include vw(32);
      line-height: (84 / 64);
      margin: 50px 0 16px;
    }
    @include pc {
      margin: 0 0 16px;
    }
  }
  .date {
    font-size: 13px;
    @include tb {
      font-size: 14px;
    }
  }
  p + p {
    margin-top: 2.3em;
  }
  .post_link {
    display: flex;
    justify-content: center;
    border-top: 1px solid #cecece;
    padding-top: 30px;
    margin-top: 50px;
    @include tb {
      margin-top: 90px;
      padding-top: 60px;
    }
    li {
      margin: 0 5px;
      &:not([class]) {
        max-width: 284px;
        width: 100%;
      }
      a {
        display: block;
        border: 1px solid #353130;
        border-radius: 25px;
        height: 50px;
        background: #fff;
        font-size: 16px;
        line-height: 50px;
        text-align: center;
        text-decoration: none;
        transition: 0.2s ease-in-out;
        @include font-Montserrat_medium;
        @include tb {
          border-radius: 30px;
          height: 60px;
          line-height: 60px;
        }
        @include pc {
          &:hover {
            color: #fff;
            background: #e60012;
            border: 1px solid #e60012;
          }
        }
        .-small & {
          width: 40px;
          height: 40px;
          font-size: 14px;
          line-height: 40px;
        }
      }
      &.prev,
      &.next {
        a {
          position: relative;
          border-radius: 50%;
          width: 50px;
          @include tb {
            border-radius: 30px;
            width: 60px;
          }
          &::after,
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -6px;
            margin-left: -5px;
            width: 10px;
            height: 12px;
            background-size: 100% auto;
          }
        }
        span {
          display: none;
        }
      }
      &.prev {
        margin-left: 0;
        a::before {
          margin-left: -8px;
        }
      }
      &.next {
        margin-right: 0;
        a::before {
          margin-left: -3px;
        }
      }
      &.prev {
        a::before {
          background-image: url(/img/common/icon_paging_prev.svg);
        }
        a:hover::before {
          background-image: url(/img/common/icon_paging_prev_white.svg);
        }
      }
      &.next {
        a::before {
          background-image: url(/img/common/icon_paging_next.svg);
        }
        a:hover::before {
          background-image: url(/img/common/icon_paging_next_white.svg);
        }
      }
    }
  }
  //Wordpress
  code {
    display: block;
    padding: 24px 24px 20px;
    margin-top: 1em;
    border: 1px solid #ddd;
  }
  img.alignleft {
    float: left;
    margin: 1em 1.5em 1em 0;
  }
  img.aligncenter {
    display: block;
    margin: 1em auto 1em;
  }
  img.alignright {
    float: right;
    margin: 1em 0 1em 1.5em;
  }
  .gallery {
    display: grid;
    grid-gap: 15px;
    .gallery-item {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .gallery.gallery-columns-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .gallery-item {}
  }
  .gallery.gallery-columns-3 {
    grid-template-columns: 1fr 1fr 1fr;
    .gallery-item {}
  }
  .gallery.gallery-columns-2 {
    grid-template-columns: 1fr 1fr;
    .gallery-item {}
  }
  .gallery.gallery-columns-1 {
    grid-template-columns: 1fr;
    .gallery-item {}
  }
  .comic {
    text-align: center;
  }
  iframe {
    @include sp() {
      width: calc(100vw - 40px);
      height: calc(100vw - 40px);
    }
  }
  .movie {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    margin: 30px 0;
    .video-js,
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100% !important;
      height: 100% !important;
      border: none;
    }
  }
  h2 {
    margin-top: 60px;
    font-size: 15px;
    line-height: (50 / 30);
    @include font-noto_sans;
    @include pc{
      font-size: 18px;
      line-height: (54 / 36);
      margin-top: 80px;
    }
  }
  h3 {
    margin-top: 2em;
    font-size: 14px;
    @include font-noto_sans;
    @include pc{
      font-size: 16px;
    }
  }
  h4 {
    margin-top: 2em;
    @include font-noto_sans;
  }
  & + h2 {
    margin-top: 0;
  }
  a.arrow {
    position: relative;
    padding-left: 16px;
    text-decoration: none;
    &::before {
      position: absolute;
      top: 50%;
      left: 3px;
      display: block;
      width: 8px;
      height: 8px;
      margin-top: -5px;
      content: "";
      border-top: 2px solid #9d968d;
      border-right: 2px solid #9d968d;
      transform: rotate(45deg);
    }
  }
  a.dot {
    position: relative;
    padding-left: 16px;
    margin-left: 6px;
    text-decoration: none;
    &::before {
      position: absolute;
      top: 0.5em;
      left: 2px;
      display: block;
      width: 6px;
      height: 6px;
      margin-top: -3px;
      content: "";
      background: #000;
      border-radius: 50%;
    }
  }
  p > a[target="_blank"],
  > a[target="_blank"] {
    &::after {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-left: 5px;
      content: "";
      background: url(/img/common/new_window.svg) no-repeat 0 0;
    }
  }
  b,
  strong {
    font-weight: bold;
  }
  p {
    margin-top: 2em;
    &:nth-child(1) {
      margin-top: 0;
    }
    a {
      color: #9d968d;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  h2 + p {
    margin-top: 2em;
  }
  h3 + p {
    margin-top: 1em;
  }
  h4 + p {
    margin-top: 0.5em;
  }
  ul:not([class]),
  ol:not([class]) {
    margin-top: 45px;
    li {
      margin-top: 12px;
      font-size: 13px;
      @include pc{
        font-size: 14px;
      }
      a {
        color: #9d968d;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  ul:not([class]) {
    li {
      position: relative;
      padding-left: 16px;
      margin-left: 6px;
      text-decoration: none;
      &::before {
        position: absolute;
        top: 0.8em;
        left: 2px;
        display: block;
        width: 6px;
        height: 6px;
        margin-top: -3px;
        content: "";
        background: #000;
        border-radius: 50%;
      }
    }
  }
  ol:not([class]) {
    counter-reset: ol_li;
    li {
      margin-left: 1em;
      text-indent: -1em;
      &::before {
        margin-right: 5px;
        content: counter(ol_li);
        counter-increment: ol_li;
      }
    }
  }
  h2 + ul:not([class]) {
    margin-top: 2em;
  }
  h3 + ul:not([class]),
  h4 + ul:not([class]) {
    margin-top: 1em;
  }
  hr {
    // width: 100vw;
    // padding-top: 6px;
    // border: 1px;
    margin: 20px -20px;
    width: 100%;
    border: 1px solid rgb(128, 128, 128);
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .wp-caption {
    max-width: 100%;
    margin-top: 1em;
    &-text {
      margin-top: 15px;
      font-size: 12px;
      line-height: (18/12);
      color: #888;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    pointer-events: none;
  }
  em {
    font-style: normal;
  }
  blockquote {
    position: relative;
    padding: 30px 20px;
    margin-top: 30px;
    font-size: 13px;
    font-style: italic;
    background: #f1f1f1;
    a {
      font-size: 12px;
      font-style: normal;
      color: #9d968d;
      cursor: pointer;
    }
  }
  table {
    width: 100%;
    margin-top: 2em;
    border-collapse: collapse;
    border-style: solid;
    border-top-width: 1px;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    th,
    td {
      padding: 10px;
      font-size: 13px;
      border-style: solid;
      border-top-width: 0;
      border-right-width: 1px;
      border-bottom-width: 1px;
      border-left-width: 0;
      &:nth-last-of-type(1) {
        border-right: none;
      }
    }
    th {
      text-align: left;
      background: #f1f1f1;
    }
  }
}
