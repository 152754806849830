@charset "utf-8";
// p-pickup
// 使用箇所
// /recruit/index.ejsなど
/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/
.p-pickup {
  @at-root {
    & {
      padding-block: 74px;
      @include pc {
        padding-block: 80px 120px;
      }
    }
    .p-pickup__title {
      font-size: 30px;
      letter-spacing: 0.06em;
      line-height: calc(40 / 25);
      margin-block-end: 0;
      text-align: center;
      @include font-Montserrat_extralight;
      @include pc {
        font-size: 40px;
      }
    }
    .p-pickup__subTitle {
      font-size: 14px;
      letter-spacing: 0.06em;
      line-height: calc(25 / 16);
      text-align: center;
      @include pc {
        font-size: 16px;
      }
    }
    .p-pickupList {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr;
      justify-content: center;
      margin-block-start: 35px;
      padding-inline: 20px;
      @include tb {
        grid-template-columns: repeat(2, min(calc(315 / 980 * 100vw), 315px));
        margin-block-start: 70px;
      }
      @include pc {
        grid-template-columns: repeat(4, min(calc(315 / 1440 * 100vw), 315px));
        margin-block-start: 70px;
      }
    }
    // リンクがある場合
    .p-pickupList__item:has(:where(.p-pickupList__link)) {
      display: contents;
    }
    // リンクがない場合＆リンクそのもの
    .p-pickupList__item:not(:has(:where(.p-pickupList__link))),
    .p-pickupList__link {
      border: 1px solid #ccdee0;
      display: grid;
      grid-template-rows: auto 1fr;
      position: relative;
      text-decoration: none;
      @include ov;
    }
    .p-pickupList__photo {
      overflow: hidden;
    }
    .p-pickupList__image {
      display: block;
      height: auto;
      width: 100%;
    }
    .p-pickupList__inner {
      display: grid;
      gap: 20px;
      padding-block: 10px 20px;
      padding-inline: 20px;
    }
    .p-pickupList__title {
      font-size: 18px;
      letter-spacing: 0.16em;
      line-height: 1.5;
      @include font-noto_sans_regular;
    }
    .p-pickupList__date {
      font-size: 13px;
      line-height: 1.5;
      // target="_blank"がある場合
      :where(.p-pickupList__item:has(.p-pickupList__link[target="_blank"])) & {
        align-items: center;
        align-self: end;
        display: flex;
        justify-content: space-between;
        &::after {
          background-color: currentColor;
          content: "";
          display: block;
          height: 15px;
          mask: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzEgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iY3VycmVudENvbG9yIj48cGF0aCBkPSJtMjkgOHYxNGgtMjF2LTE0em0yLTJoLTI1djE4aDI1eiIvPjxwYXRoIGQ9Im0yNSAwaC0yNXYxOGgydi0xNmgyM3oiLz48cGF0aCBkPSJtMiAwaC0ydjE4aDJ2LTE2aDIzdi0yeiIvPjwvZz48L3N2Zz4=") no-repeat left top / contain;
          width: 19px;
        }
      }
    }
  }
}
