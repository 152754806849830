@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.__parts {
  padding: 0 0 80px;
  @include pc {
    max-width: 950px;
    padding: 0 0 120px;
    margin: 0 auto;
  }
}

.p-address {
  padding: 0 20px;
  margin: 0 -5.6%;
  background: rgba(#f7f7f7, 1);
  @include font-noto_sans_regular;
  @include pc {
    max-width: 950px;
    padding: 0 40px;
    margin: 0 auto;
  }
  &__inner {
    padding: 30px 0;
  }
  &__wrap {
    display: block;
    + .p-address__wrap {
      margin-top: 10px;
    }
  }
  &__column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 20px;
    background: #fff;
    @include pc {
      flex-wrap: nowrap;
      padding: 20px 40px 20px 20px;
    }
    + .p-address__column {
      margin-top: 10px;
    }
  }
  &__check {
    padding-right: 20px;
    font-size: 18px;
    @include font-Montserrat_medium;
    color: #bfbfbf;
  }
  &__contents {
    flex-grow: 1;
    @include font-noto_sans_regular;
    width: calc(100% - 40px);
    @include pc {
      width: auto;
    }
  }
  &__name {
    font-size: 13px;
    @include pc {
      font-size: 14px;
    }
  }
  &__text {
    font-size: 12px;
    @include sp {
      margin-top: 5px;
    }
    @include pc {
      font-size: 13px;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    @include sp {
      margin-top: 5px;
    }
    @include pc {
      width: 245px;
    }
    a {
      padding-bottom: 2px;
      color: #000;
      text-decoration: none;
      white-space: nowrap;
      border-bottom: 1px solid #000;
      transition: 0.2s ease-in-out;
      &:hover {
        border-bottom: 1px solid #fff;
      }
    }
    &List {
    }
    &Item {
      display: inline;
      font-size: 12px;
      @include pc {
        font-size: 13px;
      }
    }
    .p-address__linkItem + .p-address__linkItem {
      margin-left: 15px;
      @include pc {
        margin-left: 20px;
      }
    }
  }
  &__btnWrap {
    display: flex;
    @include sp {
      justify-content: center;
    }
    @include tb {
      justify-content: flex-end;
    }
    @include pc {
      justify-content: flex-end;
    }
    &.c-btnWrap {
      margin: 20px auto 0;
    }
  }
  .error {
    @include font-noto_sans_bold;
    @-moz-document url-prefix() {
      @include font-noto_sans_medium;
    }
    margin-top: 8px;
    font-size: 11px;
    color: #e60012;
    @include pc {
      font-size: 12px;
    }
  }
}

/**  フォーム  **/
.p-form {
  padding: 0 20px;
  margin: 30px -5.6%;
  background: $c-base;
  @include font-noto_sans_regular;
  .l-header & {
    background: none;
  }
  @include sp320 {
    padding-right: 15px;
    padding-left: 15px;
  }
  @include pc {
    max-width: 950px;
    padding: 10px 40px;
    margin: 40px auto;
  }
  .l-menu & {
    margin: 0;
    @include pc {
      padding: 0 30px;
    }
    @include pc {
      margin: 0 auto 40px;
    }
    .c-menuform__noProducts {
      @include lhCrop(38 / 26);
      @include fz(13);
      line-height: (38 / 26);
      text-align: center;
      @include pc {
        @include lhCrop(25 / 14);
        @include fz(14);
        line-height: (25 / 14);
        margin-top: 14px;
      }
    }
    .c-menuform__note {
      margin-top: 8px;
      margin-bottom: 8px;
      line-height: (30 / 24);
      color: #7f7f7f;
      text-align: center;
      letter-spacing: 0.16em;
      @include lhCrop(30 / 24);
      @include fz(11);
      @include pc {
        @include lhCrop(23 / 10);
        @include vw(10);
        margin-top: 14px;
        margin-bottom: 14px;
        line-height: (23 / 10);
      }
    }
  }
  .p-maestro & {
    margin: 0;
    height: 100%;
    background-color: transparent;
    @include sp {
      padding: 0;
    }
    a {
      color: #000;
    }
  }
  a {
    color: #e60012;
  }
  &__body {
    padding-top: 10px;
  }
  .-grid_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include sp {
      flex-direction: column;
      > :nth-of-type(n + 2) {
        margin-top: 10px;
      }
    }
    &.-sp_row {
      @include sp {
        flex-direction: row;
      }
      .-grid_row__w50 {
        width: calc(50% - 10px);
        margin-top: 0;
        & > .note {
          white-space: nowrap;
        }
      }
    }
    &__w100 {
      width: 100%;
    }
    &__w55 {
      width: calc(55% - 10px);
      @include sp {
        width: 100%;
      }
    }
    &__w50 {
      width: calc(50% - 10px);
      @include sp {
        width: 100%;
      }
    }
    &__w45 {
      width: calc(45% - 10px);
      @include sp {
        width: calc(100% - 10px);
        &.titleset {
          padding-left: 10px;
        }
      }
    }
  }
  .-grid_column {
    display: flex;
    flex-direction: column;
    &__w50 {
      width: 100%;
      @include pc {
        width: 50%;
      }
      &.-sp_w50 {
        @include sp {
          width: 50%;
        }
      }
    }
    > :nth-of-type(n + 2) {
      margin-top: 10px;
      @include pc {
        margin-top: 14px;
      }
    }
    .p-reserve & {
      margin-top: 10px;
      @include pc {
        margin-top: 14px;
      }
    }
  }
  &__dl {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    @include pc {
      flex-direction: row;
      padding: 30px 0;
    }
    + dl {
      border-top: 1px solid $c-border1;
      // input入力エリアが低い用
      &.-low {
        dt.-w33 {
          @include pc {
            padding-top: 6px;
          }
        }
      }
    }
    > dt:not([class]) {
      padding-bottom: 10px;
      font-size: 13px;
      letter-spacing: 0.16em;
      @include pc {
        width: 30%;
        min-height: 35px;
        padding-top: 8px;
        padding-bottom: 0;
        font-size: 14px;
      }
      .must {
        font-size: 12px;
        color: $c-primary;
        @include pc {
          margin-left: 6px;
        }
      }
    }
    > dt.-w33 {
      padding-bottom: 10px;
      font-size: 13px;
      letter-spacing: 0.16em;
      @include pc {
        width: 33%;
        min-height: 35px;
        padding-top: 14px;
        padding-bottom: 0;
        font-size: 14px;
      }
      .must {
        margin-left: 6px;
        font-size: 12px;
        color: $c-primary;
      }
    }
    > dd:not([class]) {
      font-size: 13px;
      @include pc {
        width: 70%;
        min-height: 35px;
        font-size: 14px;
      }
      .c-radio {
        line-height: 2;
        @include pc {
          min-height: 35px;
          line-height: 1;
        }
      }
    }
    > dd.-w67 {
      font-size: 13px;
      @include pc {
        width: 67%;
        min-height: 35px;
        font-size: 14px;
      }
      @include IE{
        min-height: 10px;
      }
      .must {
        margin-left: 6px;
        font-size: 12px;
        color: $c-primary;
      }

      &.-flex-end {
        @include pc{
          display: flex;
          align-items: flex-end;
        }
      }
    }

    > dd.-w-full {
      width:100%;
    }

    &.-column {
      flex-direction: column !important;
      + dl {
        border-top: 1px solid #bfbfbf;
      }
      dt {
        width: 100%;
        padding-bottom: 10px !important;
        font-size: 13px;
        @include pc {
          font-size: 14px;
        }
      }
      dd {
        width: 100%;
        font-size: 13px;
        @include pc {
          font-size: 14px;
        }
      }
    }
    .titleset {
      @include pc {
        display: flex;
        flex: 1 0 auto;
        align-items: center;
      }
      &.-confirm {
        @include pc {
          box-sizing: border-box;
          min-height: 68px;
          padding: 20px 30px;
          background: #fff;
        }
        @include IE{
          padding: 0 30px;
          min-height: 60px;
        }
        .titleset_text {
          box-sizing: border-box;
          display: block;
          min-height: 49px;
          padding: 15px;
          background: #fff;
          @include pc {
            flex-basis: calc(100% - 130px);
            width: calc(100% - 130px);
            max-width: calc(100% - 130px);
            min-height: auto;
            padding: 0;
            background: none;
            &.-wide {
              flex-basis: 100%;
              width: 100%;
              max-width: 100%;
            }
          }
           @include IE{
            margin-top: 18px;
          }

          &.-small {
            font-size:13px;
            @include pc {
              font-size:14px;
            }
          }
        }
        .titleset_title {
          @include pc {
            flex-basis: 130px;
            width: 130px;
            max-width: 130px;
            margin-bottom: 0;
          }
           @include IE{
            margin-top: 25px;
          }
        }
      }
      &_title {
        display: block;
        margin-bottom: 15px;
        font-size: 13px;
        white-space: nowrap;
        @include pc {
          flex-basis: 100px;
          width: 100px;
          max-width: 100px;
          padding-right: 5px;
          margin-bottom: 0;
        }
        &.-zip {
          @include sp {
            width: inherit;
          }
        }
      }
      &_body {
        display: block;
        @include pc {
          display: flex;
          flex-basis: calc(100% - 100px);
          width: calc(100% - 100px);
          max-width: calc(100% - 100px);
        }
        &.-columns {
          display: flex;
          flex: 1 0 auto;
          justify-content: space-between;
          .c-select2 {
            flex-basis: calc(50% - 5px);
            width: calc(50% - 5px);
            max-width: calc(50% - 5px);
            @include pc {
              flex-basis: calc(50% - 6px);
              width: calc(50% - 6px);
              max-width: calc(50% - 6px);
            }
          }
        }
      }
      .slink {
        flex-grow: 1;
        width: inherit;
      }
    }
    .columns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @include pc {
        width: 50%;
      }
      &.-zip {
        justify-content: flex-start;
        width: 100%;
        @include pc {
          width: 100%;
        }
        .c-input {
          width: 50%;
          margin: 0 2%;
          &:first-of-type {
            margin-left: 0;
          }
          &:nth-of-type(2) {
            margin-right: 0;
          }
        }
      }
      .c-select2 {
        width: 26%;
        &::before {
          right: 10px;
        }
      }
    }
    .columns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @include pc {
        width: 80%;
      }
      &.-col2 {
        width: 80%;
        @include sp {
          width: 100%;
        }
        .c-select2 {
          width: 35%;
        }
      }
      .c-select2 {
        width: 26%;
        &::before {
          right: 10px;
        }
      }
    }
    .text {
      @include pc {
        padding-top: 8px;
      }
    }
    .radio {
      display: flex;
      flex-wrap: wrap;
      @include sp {
        justify-content: space-between;
      }
      .radio_item + .radio_item {
        @include sp {
          min-height: 28px;
        }
        @include pc {
          margin-left: 10px;
        }
      }
      &_item {
        @include sp {
          width: 50%;
        }
      }
    }
    .note {
      margin-top: 8px;
      @include fz(11);
      color: #888;
      @include pc {
        @include vw(12);
      }
      @at-root .p-form__dl dt .note {
        display: block;
        margin-top: 0;
        @include fz(11);
        @include pc {
          @include vw(10);
          margin-top: 10px;
        }
      }
      &.-red {
        color: #e60012;
      }
    }
    .error {
      @include font-noto_sans_bold;
      @-moz-document url-prefix() {
        @include font-noto_sans_medium;
      }
      margin-top: 8px;
      font-size: 11px;
      color: #e60012;
      @include pc {
        font-size: 12px;
      }
    }
    .btnText {
      margin-top: 8px;
      font-size: 12px;
      color: #000;
      @include sp {
      }
      @include pc {
        text-align: center;
      }
    }
    .btnWrap {
      margin-top: 20px;
    }
    &.-zip {
      .-grid_row.-zip {
        padding-left: calc(1em + 10px);
        color: tomato;
      }
      .c-btn a {
        @include sp {
          font-size: 11px;
        }
      }
      .p-form a {
        @include font-noto_sans;
      }
    }
  }
}

//☆ラジオ選択エリア START
.p-address__check {
  input[type="radio"] {
    display: none;
  }
  .c-radioarea {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
  }
  input[type="radio"] + .c-radioarea {
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: block;
      width: 20px;
      height: 20px;
      content: "";
      border: 1px solid #ddd;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
  input[type="radio"]:checked + .c-radioarea {
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      display: block;
      width: 10px;
      height: 10px;
      content: "";
      background: #e60012;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
} //☆ラジオ選択エリア END
.p-address__inner + .p-address__inner,
.p-address__inner + .p-form__dl,
.p-form__dl + .p-address__inner {
  border-top: 1px solid #bfbfbf;
}
