@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/
.l-kv.-text.-zeroen {
  .l-kv__title::after {
    display: none;
  }
}
.c-card.-zeroen {
  @include pc {
    padding: 80px 0;
    border-bottom: 1px solid #cecece;
  }
  .c-cardHead__title {
    margin-bottom: 30px;
    @include fz(17);
    @include pc {
      @include fz(22);
    }
  }
  .c-cardHead__lead {
    text-align: left;
    @include fz(15);
    @include pc {
      text-align: center;
      @include fz(16);
    }
  }
}
.p-zeroen {
  &__columns {
    margin: 40px 0;
    padding-top: 40px;
    border-top: 1px solid #cecece;
    @include pc {
      display: flex;
      justify-content: space-between;
      padding-top: 70px;
      margin: 50px 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__column {
    margin-bottom: 30px;
    @include pc {
      width: calc(50% - 3%);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &__title {
    @include font-noto_sans_light;
    @include lhCrop(31/21);
    @include fz(21);
    margin-bottom: 31px;
    line-height: (31/21);
    text-align: left;
    letter-spacing: 0.14em;
    @include pc {
      @include lhCrop(42/32);
      @include fz(30);
      margin-bottom: 42px;
      padding-left: 20px;
      line-height: (42/32);
      border-left: 4px solid #e60012;
    }
    span {
      @include fz(18);
    }
  }
  &__indent {
    @include pc {
      padding-left: 24px;
    }
    .lead {
      @include font-noto_sans;
      @include lhCrop(25/15);
      @include fz(15);
      margin-bottom: 20px;
      line-height: (25/15);
      @include pc {
        @include lhCrop(29/18);
        @include fz(18);
        margin-bottom: 30px;
        line-height: (29/18);
      }
    }
    .subtitle {
      @include font-noto_sans_medium;
      @include fz(14);
      margin: 14px 0;
    }
    .text {
      @include font-noto_sans;
      @include fz(14);
      margin-top: 0;
      margin-bottom: 14px;
    }
    .noticeList {
      &__item {
        @include font-noto_sans;
        @include fz(12);
      }
    }
    .c-btnWrap {
      margin-bottom: 0;
      @include pc {
        justify-content: start;
      }
      .c-btn a {
        @include sp {
          @include fz(12);
        }
      }
    }
  } // &__indent
}
