@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-inner {
  @at-root {
    & {
      @include pc {
        margin-left: auto;
        margin-right: auto;
        max-width: 1070px;
      }
    }
    .p-LensNotes + & {
      margin-top: 40px;
      @include pc {
        margin-top: 60px;
      }
    }
  }
}
.c-innerOfInner {
  padding-left: 13px;
  padding-right: 13px;
  @include pc {
    padding-left: 60px;
    padding-right: 60px;
  }
}
