@charset "utf-8";

//
// アニメーションの設定
// ※全体の記述ルールについてはstyle.scss参照
// ※プロパティはアルファベット順で並べる。
//
.-fadeIn,
.-fadeIn__up,
.-fadeIn__left,
.-fadeIn__right,
.-scale {
  opacity: 0;
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, opacity 0.4s cubic-bezier(0.25, 0.25, 0.75, 0.75) 0.4s;
  &.-inview {
    opacity: 1;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, opacity 0.4s cubic-bezier(0.25, 0.25, 0.75, 0.75) 0.4s;
  }
}
.-fadeIn__up {
  transform: translate(0, 50px);
  &.-inview {
    transform: translate(0, 0);
  }
}
.-fadeIn__left {
  transform: translate(0, 50px);
  @include pc {
    transform: translate(-50px, 0);
  }
  &.-inview {
    transform: translate(0, 0);
  }
}
.-fadeIn__right {
  transform: translate(0, 50px);
  @include pc {
    transform: translate(50px, 0);
  }
  &.-inview {
    transform: translate(0, 0);
  }
}
.-scale {
  transform: scale(1.05) translate3d(0, 0, 0);
  transition: transform 0.3s cubic-bezier(0, 0, 0.58, 1);
  &.-inview {
    transform: scale(1) translate3d(0, 0, 0);
  }
}
.-delay {
  @at-root {
    .-delay__0s {
      transition-delay: 0s !important;
    }
    .-delay__05s {
      transition-delay: 0.5s !important;
    }
    .-delay__10s {
      transition-delay: 1s !important;
    }
    .-delay__15s {
      transition-delay: 1.5s !important;
    }
  }
}
.p-lohasVisual.js-inview {
  .p-lohasVisualImage {
    opacity: 0;
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, opacity 0.4s cubic-bezier(0.25, 0.25, 0.75, 0.75) 0.4s;
  }
  &.-inview.-imageloaded {
    .p-lohasVisualImage {
      opacity: 1;
      transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, opacity 0.4s cubic-bezier(0.25, 0.25, 0.75, 0.75) 0.4s;
    }
  }
}
