@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

////////////////////////////////////////
// 2020年4月新規　来店予約誘引用コンポーネント
////////////////////////////////////////

.c-staticReserveWrap {
  overflow: hidden;
  .staticReserve-title {
    font-size: 15px;
    text-align: center;
    margin: 0;
    padding-top: 50px;
    position: relative;
    @include font-noto_sans_light;
    @include pc {
      font-size: 22px;
    }
    &::before {
      background: url("/img/common/icon_store_black.svg") no-repeat center;
      background-size: 100% auto;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 40px;
      width: 40px;
    }
  } // .title
  .c-btnWrap.-columns.-reserve {
    max-width: 950px;
    margin: 30px auto 0;
    @include pc {
      margin: 40px auto 0;
    }
    .c-btn {
      @include font-noto_sans_light;
      font-size: 13px;
      &:first-child {
        margin-bottom: 0;
      }
      &:not(:first-child) {
        margin-top: 20px;
        @include tb {
          margin-top: 0;
        }
      }
      @include pc {
        margin: 0 auto;
      }
    } // .c-btn
  } // .c-btnWrap.-columns.-reserve
} // .c-staticReserveWrap
