@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Noto+Sans+JP:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:where(.p-kids) {
  overflow: hidden;
  /* ---------------------------------------------------------
  Reset
  --------------------------------------------------------- */
  html,
  body,
  div,
  article,
  section,
  main,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  pre,
  form,
  input,
  textarea,
  address,
  nav,
  table,
  th,
  td,
  a,
  p,
  img,
  figure,
  span,
  em,
  small {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  small {
    display: inline-block;
    font-size: 12px;
    line-height: 1.2;
  }
  main {
    display: block;
  }
  ul,
  li {
    list-style-type: none;
  }
  table {
    border-collapse: collapse;
  }
  img {
    border: none;
  }
  a {
    text-decoration: none;
  }
  a,
  button {
    outline: none;
  }
  a:focus,
  button:focus {
    outline: none;
  }
  .mt4 {
    margin-top: 4px !important;
  }
  .mt8 {
    margin-top: 8px !important;
  }
  .mt16 {
    margin-top: 16px !important;
  }
  .mt24 {
    margin-top: 24px !important;
  }
  .mt40 {
    margin-top: 40px !important;
  }
  .mb40 {
    margin-bottom: 40px;
  }
  .bold {
    font-weight: bold;
  }
  .res-pc {
    display: block !important;
  }
  .res-sp {
    display: none !important;
  }
  .widthSp {
    margin: 40px auto;
    width: calc(100% - 48px);
  }
  .center {
    text-align: center !important;
  }
  .center_margin {
    margin: 0 auto;
  }
  .bold {
    font-weight: bold;
  }
  .updown {
    /* アニメーション名の指定 */
    animation-delay: 0s;
    /* アニメーションの開始時間指定 */
    animation-duration: 3s;
    /* アニメーションの動き指定（徐々に早く） */
    animation-iteration-count: infinite;
    animation-name: updown1;
    /* アニメーション動作時間の指定 */
    animation-timing-function: ease-in-out;
  }
  @keyframes updown1 {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .updown2 {
    /* アニメーション名の指定 */
    animation-delay: 0s;
    /* アニメーションの開始時間指定 */
    animation-duration: 4s;
    /* アニメーションの動き指定（徐々に早く） */
    animation-iteration-count: infinite;
    animation-name: updown1;
    /* アニメーション動作時間の指定 */
    animation-timing-function: ease-in-out;
  }
  @keyframes updown2 {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @media only screen and (max-width: 959px) {
    .res-pc {
      display: none !important;
    }
    .res-sp {
      display: block !important;
    }
    .widthSp {
      margin: 16px auto;
    }
    .updown {
      /* アニメーション名の指定 */
      animation-delay: 0s;
      /* アニメーションの開始時間指定 */
      animation-duration: 3s;
      /* アニメーションの動き指定（徐々に早く） */
      animation-iteration-count: infinite;
      animation-name: updown1;
      /* アニメーション動作時間の指定 */
      animation-timing-function: ease-in-out;
    }
    @keyframes updown1 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-4px);
      }
      100% {
        transform: translateY(0);
      }
    }
    .updown2 {
      /* アニメーション名の指定 */
      animation-delay: 0s;
      /* アニメーションの開始時間指定 */
      animation-duration: 4s;
      /* アニメーションの動き指定（徐々に早く） */
      animation-iteration-count: infinite;
      animation-name: updown1;
      /* アニメーション動作時間の指定 */
      animation-timing-function: ease-in-out;
    }
    @keyframes updown2 {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-4px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
  /* ---------------------------------------------------------
  common
  --------------------------------------------------------- */
  html {
    font-size: 100%;
  }
  /*
  @media only screen and (min-width: 320px) and (max-width: 414px) {
    html {
      font-size: 2.41546vw;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1240px) {
    html {
      font-size: 0.80645vw;
    }
  }
  */
  .p-kids {
    box-sizing: border-box;
    color: #00030b;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 1.6;
    margin: inherit;
    min-width: 320px;
    position: relative;
    text-size-adjust: 100%;
    word-break: break-word;
    /* overflow: hidden; */
  }
  @media only screen and (max-width: 959px) {
    body {
      color: #00030b;
      font-size: 14px;
      letter-spacing: 0.04em;
      line-height: 1.4;
      margin: inherit;
    }
  }
  img {
    vertical-align: bottom;
    width: 100%;
  }
  a {
    text-decoration: none;
    transition-duration: 0.4s;
  }
  a:hover {
  }
  .underline {
    text-decoration: underline;
  }
  .underline:hover {
    text-decoration: none;
  }
  .bgwhite {
    background: #fff;
    border-radius: 16px;
    padding: 40px;
  }
  @media only screen and (max-width: 959px) {
    .bgwhite {
      padding: 24px;
    }
  }
  /* ---------------------------------------------------------
  header
  --------------------------------------------------------- */
  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    position: fixed;
    width: 100%;
    z-index: 9998;
  }
  header .headerL {
    padding-left: 40px;
  }
  header .headerL img {
    width: 154px;
  }
  header .headerR .headerbtn {
    text-align: right;
  }
  header .headerR .headerbtn a {
    align-items: center;
    background-color: #268fcf;
    border-radius: 8px;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    line-height: 14px;
    min-height: 38px;
    min-width: 140px;
    padding: 10px auto;
    text-align: center;
  }
  header .headerR .linebtn a {
    background-color: #fff;
    border: 2px solid #1f1fa3;
    color: #1f1fa3;
  }
  header .headerR .headerR_btn {
    display: flex;
    justify-content: flex-end;
  }
  header .headerR .headerbtn a:hover {
    opacity: 0.6;
  }
  header .headerR ul {
    display: flex;
    padding-right: 8px;
  }
  header .headerR ul li {
    padding: 0 8px;
  }
  header .headerR ul li a {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
  header .headerR ul li a:hover {
    opacity: 0.4;
  }
  /* ---------------------------------------------------------
  headerSP
  --------------------------------------------------------- */
  /* Navbar & Navmenu color */
  :root {
    --background-navbar: rgba(0, 3, 11, 0.96);
  }
  .headersp {
    height: 64px;
    position: fixed;
    width: 100%;
    z-index: 9998;
  }
  .menu,
  .menu span {
    box-sizing: border-box;
    display: inline-block;
    transition: all 0.4s;
  }
  .menu {
    background: none;
    border: none;
    height: 22px;
    position: fixed;
    right: 25px;
    top: 20px;
    width: 35px;
    z-index: 10;
  }
  .menu span {
    background-color: #fff;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .menu span:nth-of-type(1) {
    top: 0;
  }
  .menu span:nth-of-type(2) {
    top: 10px;
  }
  .menu span:nth-of-type(3) {
    bottom: 0;
  }
  .menu.active span:nth-of-type(1) {
    background-color: #fff;
    transform: translateY(10px) rotate(45deg);
  }
  .menu.active span:nth-of-type(2) {
    background-color: #fff;
    opacity: 0;
  }
  .menu.active span:nth-of-type(3) {
    background-color: #fff;
    transform: translateY(-10px) rotate(-45deg);
  }
  #nav {
    background: linear-gradient(#004975 0%, #00304e 35.66%, #000 100%);
    height: 100vh;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 10;
  }
  #nav.active {
    opacity: 1;
    right: 0;
    transform: translateX(0);
    visibility: visible;
  }
  #nav ul {
    margin: 20px 0;
    padding: 20px 0;
  }
  #nav ul li {
    list-style-type: none;
    padding: 16px;
  }
  #nav ul li a {
    color: #fff;
    display: block;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
  .menuList .btn a {
    background: #268fcf;
    border-radius: 8px;
    padding: 8px 0;
    text-align: center !important;
    width: 100%;
  }
  .menuList .btnlist .btn a {
    color: #fff !important;
    margin: 0 auto;
  }
  .menuList .btnlist .linebtn a {
    background: none;
    border: #1f1fa3 2px solid;
    color: #1f1fa3 !important;
  }
  .headersp .navtext a img {
    padding-left: 16px;
    padding-top: 16px;
    width: 154px;
  }
  /* ---------------------------------------------------------
  mv
  --------------------------------------------------------- */
  .float {
    bottom: 16px;
    position: fixed;
    right: 8px;
    transition: 1s;
    z-index: 9998;
  }
  .float {
    width: 240px;
  }
  /* クーポンまでスクロールしたら消す */
  .is-hidden {
    opacity: 0;
    transition: 1s;
    visibility: hidden;
  }
  .img_vision01 {
    left: 40px;
    position: absolute;
    top: 120px;
    width: 150px;
    z-index: 10;
  }
  .img_vision02 {
    bottom: 140px;
    left: 40px;
    position: absolute;
    width: 112px;
    z-index: 10;
  }
  .img_vision03 {
    position: absolute;
    right: 40px;
    top: 80px;
    width: 160px;
    z-index: 10;
  }
  .img_vision04 {
    bottom: 160px;
    position: absolute;
    right: 40px;
    width: 140px;
    z-index: 10;
  }
  .mv {
    background: #edf2f3;
    padding: 24px 0 40px;
    position: relative;
  }
  .mv .mvtx {
    position: relative;
    text-align: center;
    z-index: 2;
  }
  .mv .mvtx img {
    width: 560px;
  }
  .mv .mvimg {
    margin-top: -90px;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  @media screen and (max-width: 959px) {
    .float {
      bottom: 23%;
      transition: all 0.5s 0s ease;
      width: 140px;
      z-index: 10;
      /* transitionの記述を追加 */
    }
    .down {
      bottom: 16px;
      transition: all 0.5s 0s ease;
      /* transitionの記述を追加 */
    }
    .img_vision01 {
      left: 8px;
      top: 48px;
      width: 90px;
      z-index: 10;
    }
    .img_vision02 {
      bottom: 40px;
      left: 8px;
      position: absolute;
      width: 70px;
      z-index: 10;
    }
    .img_vision03 {
      position: absolute;
      right: 8px;
      top: 60px;
      width: 90px;
      z-index: 10;
    }
    .img_vision04 {
      bottom: 195px;
      position: absolute;
      right: 8px;
      width: 60px;
      z-index: 10;
    }
    .mv {
      padding: 80px 0 24px;
    }
    .mv .mvtx {
      margin: 40px auto 0;
    }
    .mv .mvtx img {
      width: 92%;
    }
    .mv .mvimg {
      margin-top: 8px;
    }
  }
  /* ---------------------------------------------------------
   title
  --------------------------------------------------------- */
  h2.title {
    color: #00030b;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 56px;
    text-align: center;
  }
  h2.title span {
    color: #069d59;
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
  }
  h2.title span.blue {
    color: #0e70c4;
  }
  h2.title span.pink {
    color: #ee7363;
  }
  h3.title {
    color: #00030b;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    text-align: left;
  }
  @media screen and (max-width: 959px) {
    h2.title {
      font-size: 22px;
      line-height: 30px;
    }
    h2.title span {
      font-size: 12px;
      line-height: 20px;
    }
    h2.title::before {
      padding-right: 14px;
    }
    h3.title {
      font-size: 16px;
      line-height: 24px;
    }
  }
  /* ---------------------------------------------------------
  readarea
  --------------------------------------------------------- */
  .readarea {
    background: #edf2f3;
    background-image: url("/img/lp/kids/img_bg_hougan.svg");
    background-repeat: repeat;
    padding: 56px 0 180px;
  }
  .readareain {
    margin: 0 auto;
    width: 960px;
  }
  .readarea h2 {
    text-align: center;
  }
  .readarea h2 img {
    width: 448px;
  }
  .readarea p.readareatx {
    color: #0d0100;
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    margin-top: 40px;
    text-align: center;
  }
  .readarea .message {
    margin-top: 40px;
  }
  .readarea .message .messagebox {
    background: #c00b19;
  }
  .readarea .message .messagebox span {
    font-size: 20px;
  }
  .readarea .message .box ul {
    padding-left: 16px;
  }
  .readarea .message .box img {
    width: 240px;
  }
  .readarea .message .box ul li {
    font-weight: bold;
  }
  .readarea .message .box ul li::before {
    color: red;
    content: url("/img/lp/kids/img_cheack.svg");
    padding-right: 5px;
    vertical-align: middle;
  }
  h3.readareatx {
    color: #00030b;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 32px;
    font-weight: bold;
    line-height: 45px;
    padding: 40px 0;
    text-align: center;
  }
  .pointlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .pointlist li {
    background: #fff;
    border-radius: 40px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.4;
    margin: 1% 1% 3%;
    padding: 24px;
    position: relative;
    text-align: center;
    width: 31%;
  }
  .pointlist li span {
    background: #fa9d90;
    border-radius: 100px;
    color: #00030b;
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    font-weight: 700;
    left: 40%;
    line-height: 40px;
    padding: 8px 10px;
    position: absolute;
    text-align: center;
    top: -24px;
  }
  .pointlist li img {
    width: 160px;
  }
  @media screen and (max-width: 959px) {
    .readarea {
      padding: 40px 0 180px;
    }
    .readareain {
      margin: 0 auto;
      width: 92%;
    }
    .readarea h2 img {
      width: 245px;
    }
    .readarea p.readareatx {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 24px;
      text-align: center;
    }
    .readarea .message {
      margin-top: 24px;
    }
    .readarea .message .messagebox span {
      display: inline-flex;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 1.6;
    }
    .readarea .message .box ul li::before {
      color: red;
      content: url("/img/lp/kids/img_cheack.svg");
      padding-right: 5px;
      vertical-align: middle;
    }
    .readarea .message .box img {
      width: 140px;
    }
    h3.readareatx {
      font-size: 20px;
      line-height: 32px;
      padding: 40px 0 16px;
    }
    .pointlist {
      display: block;
      flex-wrap: nowrap;
    }
    .pointlist li {
      background: #fff;
      border-radius: 24px;
      margin: 32px auto;
      padding: 8px 16px;
      text-align: center;
      width: 100%;
    }
    .pointlist li span {
      font-size: 20px;
      left: 43%;
      line-height: 20px;
      padding: 12px 10px;
      position: absolute;
      top: -24px;
    }
    .pointlist li img {
      width: 96px;
    }
    .pointlist li div {
      align-items: center;
      display: flex;
    }
    .pointlist li div p {
      font-size: 14px;
      padding-left: 8px;
      text-align: left !important;
    }
    .readarea .message img {
      width: 170px;
    }
  }
  /* ---------------------------------------------------------
  ancarea
  --------------------------------------------------------- */
  .ancarea {
    background: #ffc69f;
    padding: 0 0 120px;
    position: relative;
  }
  .img_anc01 {
    left: 40px;
    position: absolute;
    top: 0;
    width: 200px;
    z-index: 10;
  }
  .img_anc02 {
    position: absolute;
    right: 40px;
    top: 0;
    width: 200px;
    z-index: 10;
  }
  .anclost {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 40px;
    width: 960px;
  }
  .ancarea .txb {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-top: 24px;
    text-align: center;
  }
  .anclost li {
    width: 23.5%;
  }
  .anclost li a {
    background: #fff;
    border-radius: 40px 0;
    box-shadow: 0 6px 0 rgba(0, 0, 0, 0.32);
    color: #00030b;
    display: block;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    padding: 40px 0;
    text-align: left;
    text-align: center;
  }
  .anclost li a:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
  .anclost li a img {
    margin-top: 8px;
    width: 24px;
  }
  @media only screen and (max-width: 959px) {
    .ancarea {
      padding: 24px 0 40px;
    }
    .img_anc01 {
      left: 78px;
      top: -120px;
      width: 120px;
    }
    .img_anc02 {
      right: 78px;
      top: -122px;
      width: 120px;
    }
    .anclost {
      flex-wrap: wrap;
      margin: 0 auto;
      margin-top: 24px;
      width: 92%;
    }
    .ancarea .txb {
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;
    }
    .anclost li {
      margin-bottom: 16px;
      width: 48.5%;
    }
    .anclost li a {
      border-radius: 24px 0;
      font-size: 14px;
      line-height: 20px;
      padding: 12px 0;
    }
    .anclost li a img {
      margin-top: 8px;
      width: 24px;
    }
  }
  /* ---------------------------------------------------------
  reson
  --------------------------------------------------------- */
  .reson {
    background: #caefd2;
    padding: 80px 0 200px;
    position: relative;
  }
  .img_bg_reson01 {
    left: 0;
    position: absolute;
    top: 80px;
    width: 467px;
    z-index: 0;
  }
  .img_bg_reson02 {
    position: absolute;
    right: 0;
    top: 740px;
    width: 370px;
    z-index: 0;
  }
  .img_bg_reson03 {
    bottom: 80px;
    left: 0;
    position: absolute;
    width: 370px;
    z-index: 0;
  }
  .resonin {
    margin: 0 auto;
    width: 1200px;
  }
  .resonlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px auto;
    max-width: 960px;
    min-width: 960px;
    width: 100%;
  }
  .resonlist li {
    background: #fff;
    border-radius: 40px;
    margin-bottom: 40px;
    margin-top: 24px;
    position: relative;
    width: 48%;
  }
  .resonlist li span {
    color: #069d59;
    font-family: "Open Sans", sans-serif;
    font-size: 64px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 191px;
    position: absolute;
    text-align: left;
    top: -88px;
    z-index: 1;
  }
  .resoncard {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .resoncard .resonimg {
    background-size: cover;
    border-radius: 40px 40px 0 0;
    height: 240px;
    position: relative;
    width: 100%;
  }
  .resonimg01 {
    background: url("/img/lp/kids/img_resonimg01@2x.jpg") no-repeat center center;
  }
  .resonimg02 {
    background: url("/img/lp/kids/img_resonimg02@2x.jpg") no-repeat center center;
  }
  .resonimg03 {
    background: url("/img/lp/kids/img_resonimg03@2x.jpg") no-repeat center center;
  }
  .resonimg04 {
    background: url("/img/lp/kids/img_resonimg04@2x.jpg") no-repeat center center;
  }
  .resonimg05 {
    background: url("/img/lp/kids/img_resonimg05@2x.jpg") no-repeat center center;
  }
  .resonimg06 {
    background: url("/img/lp/kids/img_resonimg06@2x.jpg") no-repeat center center;
  }
  .resontx {
    background: #fff;
    border-radius: 0 0 40px 40px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 24px;
  }
  .resontx p {
    margin: 8px 0;
    font-size: 16px;
  }
  .resontx .btn {
    margin-top: auto;
  }
  @media only screen and (max-width: 959px) {
    .reson {
      padding: 40px 0 240px;
    }
    .img_bg_reson01 {
      top: 80px;
      width: 240px;
      z-index: 0;
    }
    .img_bg_reson02 {
      top: 740px;
      width: 200px;
      z-index: 0;
    }
    .img_bg_reson03 {
      bottom: 280px;
      width: 200px;
      z-index: 0;
    }
    .resonin {
      margin: 0 auto;
      width: 92%;
    }
    .resonlist {
      display: block;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 24px auto;
      max-width: inherit;
      min-width: inherit;
      width: 100%;
    }
    .resonlist li {
      margin-bottom: 16px;
      margin-top: 40px;
      width: 100%;
    }
    .resonlist li span {
      font-size: 56px;
      line-height: 56px;
      position: absolute;
      top: -24px;
    }
    .resoncard .resonimg {
      border-radius: 24px 24px 0 0;
      height: 183px;
      width: 100%;
    }
    .resontx {
      border-radius: 0 0 24px 24px;
      padding: 16px;
    }
    .resontx p {
      margin: 8px 0;
      font-size: 14px;
    }
  }
  /* ---------------------------------------------------------
  plan
  --------------------------------------------------------- */
  .plan {
    background: #c7eefa;
    padding: 0 0 280px;
    position: relative;
  }
  .img_bg_plan01 {
    left: 0;
    position: absolute;
    top: 80px;
    width: 467px;
    z-index: 0;
  }
  .img_bg_plan02 {
    bottom: 80px;
    position: absolute;
    right: 0;
    width: 370px;
    z-index: 0;
  }
  .plan .planin {
    margin: 0 auto;
    position: relative;
    width: 960px;
  }
  .planmessage {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 64px auto 0;
    width: 760px;
  }
  .planmessage .message_tx {
    color: #00030b;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 32px;
    font-weight: bold;
    line-height: 46px;
    text-align: left;
  }
  .planmessage img {
    width: 257px;
  }
  .messageboxIn {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .messageboxIn li {
    border-right: 2px #0d0100 dashed;
    padding: 0 2%;
    text-align: center;
  }
  .boxthree li {
    width: 33%;
  }
  .boxtwo li {
    display: flex;
    width: 50%;
  }
  .boxtwo li div h4 {
    text-align: left;
  }
  .boxtwo li div p {
    text-align: left;
  }
  .messageboxIn li.last {
    border-right: 0;
  }
  .messageboxIn li h4 {
    color: #00030b;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-top: 8px;
    text-align: center;
  }
  .messageboxIn li p.tx {
    margin-top: 8px;
    padding: 0;
    text-align: left;
  }
  .messageboxIn li p.tx small {
    margin-top: 4px;
  }
  .messageboxIn li img.icon {
    width: 134px;
  }
  .message .boxwhite {
    background: #fff;
    border-radius: 0 0 40px 40px;
    padding: 40px;
  }
  .plus_blue {
    padding: 24px 0;
    text-align: center;
  }
  p img.img_plus_blue {
    width: 40px;
  }
  .readtx {
    color: #0d0100;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 40px;
    margin-top: 40px;
    text-align: center;
  }
  .readtx small {
    font-size: 20px;
  }
  .red {
    color: #c00b19;
  }
  .blue {
    color: #0e70c4;
  }
  .bg_circle {
    margin-top: -100px;
    position: relative;
    transform: scale(1.2, 1.2);
    width: 100%;
  }
  .bg_wave {
    margin-top: -212px;
    position: relative;
  }
  @media only screen and (max-width: 959px) {
    .plan {
      padding: 0 0 280px;
    }
    .img_bg_plan01 {
      top: 80px;
      width: 300px;
      z-index: 0;
    }
    .img_bg_plan02 {
      bottom: 180px;
      width: 240px;
      z-index: 0;
    }
    .plan .planin {
      margin: 0 auto;
      width: 100%;
    }
    .planmessage {
      display: block;
      justify-content: center;
      margin: 24px auto 0;
      text-align: center;
      width: 92%;
    }
    .planmessage .message_tx {
      font-size: 20px;
      line-height: 32px;
      text-align: center;
    }
    .planmessage img {
      width: 257px;
    }
    .message {
      margin: 0 auto;
      width: 92%;
    }
    .messageboxIn {
      display: block;
      justify-content: space-between;
      width: 100%;
    }
    .messageboxIn li {
      border-bottom: 2px #0d0100 dashed;
      border-right: none;
      display: flex;
      padding: 16px 0;
      text-align: center;
    }
    .messageboxIn li div {
      padding-left: 8px;
    }
    .boxthree li {
      width: 100%;
    }
    .boxtwo li {
      display: flex;
      width: 100%;
    }
    .boxtwo li div h4 {
      text-align: left;
    }
    .boxtwo li div p {
      text-align: left;
    }
    .messageboxIn li.last {
      border-right: 0;
    }
    .messageboxIn li h4 {
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
      text-align: left !important;
    }
    .messageboxIn li p.tx {
      font-size: 14px;
      margin-top: 4px;
      padding: 0;
      text-align: left;
    }
    .messageboxIn li img.icon {
      width: 96px;
    }
    .message .boxwhite {
      border-radius: 0 0 24px 24px;
      padding: 16px;
    }
    .plus_blue {
      padding: 24px 0 0;
      text-align: center;
    }
    p img.img_plus_blue {
      width: 40px;
    }
    .readtx {
      font-size: 16px;
      line-height: 22px;
      margin-top: 24px;
    }
    .readtx small {
      display: inline-block;
      font-size: 14px;
    }
    .message .box {
      border-radius: 0 0 24px 24px;
      display: block;
      padding: 24px;
      text-align: center;
    }
    .message .box img {
      width: 140px;
    }
    .message .box p {
      padding-left: 0;
      text-align: left;
    }
    .readarea .message {
      width: 100%;
    }
    .readarea .message .box ul {
      margin-top: 8px;
      padding-left: 0;
    }
    .readarea .message .box ul li {
      display: flex;
      font-size: 14px;
      margin-bottom: 12px;
      text-align: left;
    }
  }
  /* ---------------------------------------------------------
  trivia
  --------------------------------------------------------- */
  .trivia {
    background: #f8bfb7;
    padding: 0 0 280px;
    position: relative;
  }
  .trivia .triviatext {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-top: 24px;
    text-align: center;
  }
  .img_trivia01 {
    left: 40px;
    position: absolute;
    top: 80px;
    width: 150px;
    z-index: 10;
  }
  .img_trivia02 {
    position: absolute;
    right: 40px;
    top: 80px;
    width: 160px;
    z-index: 10;
  }
  .img_trivia03 {
    bottom: 400px;
    left: 40px;
    position: absolute;
    width: 112px;
    z-index: 10;
  }
  .img_trivia04 {
    bottom: 400px;
    position: absolute;
    right: 40px;
    width: 140px;
    z-index: 10;
  }
  .trivia_list {
    margin: 0 auto;
    text-align: center;
    width: 960px;
  }
  .trivia_list li {
    background: #fff;
    border-radius: 40px 0;
    box-shadow: 8px 8px 0 #b54233;
    color: #0d0100;
    display: inline-block;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 22px;
    font-weight: bold;
    line-height: 32px;
    margin-right: 3%;
    padding: 40px 24px 24px;
    position: relative;
    text-align: center;
    transition: 1s;
    width: 30%;
  }
  .trivia_list li:hover {
    box-shadow: none;
    opacity: 1;
    transform: translateY(5px);
    transition: 0.4s;
  }
  .trivia_list li span {
    background: #fa9d90;
    border-radius: 40px;
    color: #00030b;
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    font-weight: 700;
    left: 39%;
    line-height: 50px;
    padding: 8px 10px;
    position: absolute;
    text-align: center;
    top: -40px;
  }
  .trivia_list li img.plus {
    margin-top: 16px;
    width: 48px;
  }
  .inner {
    margin: 0 auto;
    padding: 0 20px;
    width: min(100%, 1120px);
  }
  /* モーダルを開くボタン */
  .modal__trigger {
    cursor: pointer;
  }
  .modal__trigger:nth-child(n + 2) {
    margin-top: 60px;
  }
  /* モーダル本体 */
  .modal__wrapper {
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
  }
  .modal__layer {
    background: rgba(50, 50, 50, 0.85);
    cursor: pointer;
    height: 100%;
  }
  .modal__container {
    background: #fff;
    border-radius: 40px;
    left: 50%;
    max-width: 720px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: min(calc(100% - 40px), 1000px);
  }
  .modal__inner {
    /*	overflow-y: scroll; */
    height: 100%;
    padding: 40px;
    position: relative;
  }
  /* モーダルを閉じるボタン */
  .modal__close {
    background: rgba(50, 50, 50, 1);
    border-radius: 40px;
    cursor: pointer;
    height: 44px;
    position: absolute;
    right: 20px;
    top: 20px;
    transition: opacity 0.6s;
    width: 44px;
  }
  .modal__close:hover {
    opacity: 0.6;
  }
  .modal__close::before,
  .modal__close::after {
    background: #fff;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 20px;
  }
  .modal__close::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .modal__close::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  /* モーダル内のコンテンツ */
  .modal__content {
    text-align: center;
  }
  .modal__content .number {
    background: #ffbbb2;
    border-radius: 100px;
    color: #00030b;
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    font-weight: bold;
    padding: 8px;
    text-align: center;
  }
  .modal__title {
    color: #0d0100;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 32px;
    font-weight: bold;
    line-height: 49px;
    text-align: center;
  }
  .modal__title::after {
    clear: both;
    content: url("/img/lp/kids/img_dot.svg");
    display: block;
    line-height: 1;
    overflow: hidden;
  }
  .modal__text {
    margin-top: 16px;
    text-align: left;
  }
  .inyo {
    font-size: 12px;
    margin-top: 40px;
    text-align: center;
  }
  @media only screen and (max-width: 959px) {
    .trivia {
      margin-top: -4px;
      padding: 0 0 280px;
    }
    .trivia .triviatext {
      font-size: 14px;
      line-height: 20px;
      margin-top: 8px;
    }
    .img_trivia01 {
      left: 8px;
      top: 8px;
      width: 48px;
      z-index: 10;
    }
    .img_trivia02 {
      right: 8px;
      top: 0;
      width: 48px;
    }
    .img_trivia03 {
      bottom: 220px;
      left: 8px;
      width: 48px;
      z-index: 10;
    }
    .img_trivia04 {
      bottom: 215px;
      right: 16px;
      width: 48px;
      z-index: 10;
    }
    .trivia_list {
      width: 92%;
    }
    .trivia_list li {
      border-radius: 24px 0;
      box-shadow: 6px 6px 0 #b54233;
      display: inline-block;
      font-size: 13px;
      line-height: 18px;
      margin-right: 2%;
      padding: 24px 8px 8px;
      width: 46%;
    }
    .trivia_list li span {
      font-size: 20px;
      left: 38%;
      line-height: 20px;
      padding: 13px 8px;
      top: -24px;
    }
    .trivia_list li img.plus {
      margin-top: 8px;
      width: 32px;
    }
    .inner {
      margin: 0 auto;
      padding: 0 20px;
      width: min(100%, 1120px);
    }
    .modal__trigger:nth-child(n + 2) {
      margin-top: 40px;
    }
    /* モーダル本体 */
    .modal__wrapper {
      display: none;
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 9999;
    }
    .modal__container {
      background: #fff;
      border-radius: 40px;
      left: 50%;
      max-width: inherit;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: min(calc(100% - 40px), 1000px);
    }
    .modal__inner {
      padding: 24px;
    }
    /* モーダルを閉じるボタン */
    .modal__close {
      border-radius: 24px;
      height: 44px;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 44px;
    }
    .modal__close::before,
    .modal__close::after {
      background: #fff;
      content: "";
      height: 1px;
      left: 50%;
      position: absolute;
      top: 50%;
      width: 20px;
    }
    .modal__content .number {
      font-size: 20px;
    }
    .modal__title {
      font-size: 16px;
      line-height: 24px;
      margin-top: 8px;
    }
    .modal__title::after {
      clear: both;
      content: url("/img/lp/kids/img_dot.svg");
      display: block;
      line-height: 1;
      overflow: hidden;
    }
    .modal__text {
      margin-top: 4px;
      text-align: left;
    }
    .inyo {
      margin-top: 24px;
    }
  }
  /* ---------------------------------------------------------
  flow
  --------------------------------------------------------- */
  .flow {
    background: #add8f0;
    padding: 0 0 200px;
    position: relative;
  }
  .img_bg_flow01 {
    left: 0;
    position: absolute;
    top: 80px;
    width: 329px;
    z-index: 0;
  }
  .img_bg_flow02 {
    position: absolute;
    right: 0;
    top: 480px;
    width: 320px;
    z-index: 0;
  }
  .img_bg_flow03 {
    bottom: 280px;
    left: 0;
    position: absolute;
    width: 320px;
    z-index: 0;
  }
  .flowin {
    margin: 40px auto 0;
    width: 960px;
  }
  .flowlist {
    position: relative;
  }
  .flowlist span {
    color: #0074b2;
    font-family: "Open Sans", sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 97px;
    position: absolute;
    text-align: left;
    top: -40px;
    z-index: 1;
  }
  .flowlist .flowlistin {
    display: flex;
    width: 100%;
  }
  .flowlist .flowlistin .img {
    border-radius: 40px 0 0 40px;
    position: relative;
    width: 280px;
  }
  .flowlist .flowlistin .img01 {
    background: url("/img/lp/kids/img_ flowimg01@2x.jpg") no-repeat center center;
    background-size: cover;
  }
  .flowlist .flowlistin .img02 {
    background: url("/img/lp/kids/img_ flowimg02@2x.jpg") no-repeat center center;
    background-size: cover;
  }
  .flowlist .flowlistin .img03 {
    background: url("/img/lp/kids/img_ flowimg03@2x.jpg") no-repeat center center;
    background-size: cover;
  }
  .flowlist .flowlistin .img04 {
    background: url("/img/lp/kids/img_ flowimg04@2x.jpg") no-repeat center center;
    background-size: cover;
  }
  .flowlist .flowlistin .txbox {
    background: #fff;
    border-radius: 0 40px 40px 0;
    padding: 24px 40px;
    width: 680px;
  }
  .flow_tri {
    padding: 24px 0;
    text-align: center;
  }
  .flow_tri img {
    width: 40px;
  }
  .flowlist .flowlistin .txbox p {
    margin-top: 8px;
  }
  .message .messagebox {
    background: #0074b2;
    border-radius: 40px 40px 0 0;
    color: #fff;
    display: block;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
    padding: 16px 0;
    text-align: center;
  }
  .flow .message {
    margin-top: 40px;
  }
  .message {
    position: relative;
  }
  .message .box {
    align-items: flex-start;
    background: #fff;
    border-radius: 0 0 40px 40px;
    display: flex;
    padding: 40px;
  }
  .flow .message .box img {
    width: 282px;
  }
  .message .box p {
    padding-left: 40px;
  }
  .img_flow_message_ac img {
    width: 240px;
  }
  .img_flow_message_ac {
    margin-right: -40px;
    margin-top: -100px;
    text-align: right;
  }
  @media only screen and (max-width: 959px) {
    .flow {
      margin-top: -4px;
      padding: 0 0 40px;
    }
    .img_bg_flow01 {
      top: 80px;
      width: 240px;
      z-index: 0;
    }
    .img_bg_flow02 {
      top: 480px;
      width: 240px;
      z-index: 0;
    }
    .img_bg_flow03 {
      bottom: 280px;
      width: 240px;
      z-index: 0;
    }
    .flowin {
      margin: 40px auto 0;
      width: 92%;
    }
    .flowlist span {
      font-size: 56px;
      line-height: 56px;
      position: absolute;
      top: -24px;
      z-index: 1;
    }
    .flowlist .flowlistin {
      display: block;
      width: 100%;
    }
    .flowlist .flowlistin .img {
      border-radius: 24px 24px 0 0;
      height: 160px;
      position: relative;
      width: 100%;
    }
    .flowlist .flowlistin .txbox {
      border-radius: 0 0 24px 24px;
      padding: 16px;
      width: 100%;
    }
    .flow_tri {
      padding: 16px 0;
      text-align: center;
    }
    .flowlist .flowlistin .txbox p {
      margin-top: 8px;
    }
    .flow .message {
      margin-top: 24px;
      width: 100%;
    }
    .message .box {
      align-items: flex-start;
      background: #fff;
      border-radius: 0 0 24px 24px;
      display: flex;
      padding: 16px;
    }
    .flow .message .box {
      padding: 16px 16px 80px;
    }
    .flow .message .box img {
      width: 170px;
    }
    .message .box p {
      margin-top: 16px;
      padding-left: 0;
    }
    .img_flow_message_ac img {
      width: 168px;
    }
    .img_flow_message_ac {
      margin-right: 0;
      margin-top: -80px;
      text-align: right;
    }
    .message .box {
      border-radius: 0 0 24px 24px;
      display: block;
      padding: 16px;
    }
    .message .messagebox {
      border-radius: 24px 24px 0 0;
      font-size: 18px;
      line-height: 28px;
      padding: 12px;
    }
  }
  /* ---------------------------------------------------------
  coupon
  --------------------------------------------------------- */
  #coupon {
    z-index: 99999;
  }
  .coupon {
    background: #ac0914;
    padding: 0 0 80px;
    position: relative;
    z-index: 99999;
  }
  .coupon .whiteborder {
    border: #fff solid 8px;
    margin: 24px auto 0;
    max-width: 800px;
    padding: 24px;
    width: auto;
  }
  .coupon h2 {
    color: #fff;
  }
  .coupon h2 span {
    color: #fff;
  }
  .coupon .teiji {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
  }
  .coupon .imgcoupon {
    margin-top: 24px;
    text-align: center;
  }
  .coupon .imgcoupon img {
    width: 570px;
  }
  .attention {
    background: #fff;
    color: #00030b;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    margin: 24px auto 0;
    padding: 8px;
    text-align: left;
    width: 570px;
  }
  .coupon .btnbox {
    display: flex;
    justify-content: center;
    margin: 24px auto 0;
    width: 720px;
  }
  .coupon .btnbox a {
    margin: 0 1%;
  }
  @media only screen and (max-width: 959px) {
    .coupon {
      padding: 24px 0 80px;
    }
    .coupon .whiteborder {
      border: #fff solid 4px;
      margin: 16px auto 0;
      max-width: inherit;
      padding: 16px 8px;
      width: 92%;
    }
    .coupon .teiji {
      font-size: 14px;
      line-height: 20px;
    }
    .coupon .imgcoupon {
      margin-top: 16px;
    }
    .coupon .imgcoupon img {
      margin: 0 auto;
      width: 92%;
    }
    .attention {
      font-size: 10px;
      line-height: 14px;
      margin: 16px auto 0;
      padding: 8px;
      width: 92%;
    }
    .coupon .btnbox {
      display: block;
      margin: 16px auto 0;
      width: 92%;
    }
    .coupon .btnbox a {
      margin: 4% 0;
      width: 100%;
    }
  }
  /* ---------------------------------------------------------
  accordion
  --------------------------------------------------------- */
  .qandabox {
    background: #e5ecf1;
    padding: 80px 0;
  }
  .qandaboxin {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 960px;
  }
  .accordion {
    max-width: 640px;
    position: relative;
    width: 100%;
  }
  .accordion-title {
    color: #0d0100;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    padding: 24px;
  }
  .accordion-content {
    background: #ccdce8;
    border-radius: 0 0 8px 8px;
    display: none;
    padding: 24px;
  }
  .accordion-content p {
    font-size: 16px;
    line-height: 1.6;
  }
  /* タイトルの背景色 */
  .accordion-item .accordion-title {
    background-color: #004975;
    border-radius: 8px;
    color: #fff;
    margin-top: 16px;
  }
  /* 矢印 */
  .accordion-title {
    position: relative;
  }
  .accordion-title::after {
    border-right: solid 2px #fff;
    border-top: solid 2px #fff;
    content: "";
    display: block;
    height: 8px;
    position: absolute;
    right: 25px;
    top: 38%;
    transform: rotate(135deg);
    transition:
      transform 0.3s ease-in-out,
      top 0.3s ease-in-out;
    width: 8px;
  }
  .accordion-title.open {
    border-radius: 8px 8px 0 0;
    transition: transform 0.3s ease-in-out;
  }
  .accordion-title.open::after {
    top: 45%;
    transform: rotate(-45deg);
  }
  @media only screen and (max-width: 959px) {
    .qandabox {
      padding: 64px 0;
    }
    .qandaboxin {
      display: block;
      width: 92%;
    }
    .accordion {
      max-width: inherit;
      width: 100%;
    }
    .accordion-title {
      font-size: 14px;
      padding: 16px 28px 16px 16px;
    }
    .accordion-content {
      border-radius: 0 0 8px 8px;
      padding: 16px;
    }
    .accordion-content p {
      font-size: 14px;
      line-height: 1.4;
    }
    /* タイトルの背景色 */
    .accordion-item .accordion-title {
      margin-top: 16px;
    }
    .accordion-title::after {
      right: 12px;
      top: 38%;
      width: 8px;
    }
  }
  .btn {
    background: #f7ed00;
    border-radius: 100px;
    box-sizing: border-box;
    color: #0d0100;
    display: table;
    font-size: 16px;
    font-weight: bold;
    margin: 0 auto;
    min-width: 280px;
    padding: 1em 2.5em;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: 0.5s;
  }
  .btn:hover {
    background-color: #faa33d;
  }
  /* ---------------------------------------------------------
  footer
  --------------------------------------------------------- */
  footer {
    background: #00030b;
    color: #fff;
    padding: 80px 0;
    position: relative;
    text-align: center;
  }
  footer img {
    width: 154px;
  }
  footer .pagetop {
    bottom: 0;
    position: absolute;
    right: 0;
  }
  footer .pagetop:hover {
    opacity: 0.5;
  }
  footer .pagetop img {
    width: 72px;
  }
  @media screen and (max-width: 959px) {
    footer {
      padding: 64px 0;
    }
  }
  /* ---------------------------------------------------------
  list
  --------------------------------------------------------- */
  .disc {
    list-style-type: disc;
    margin-top: 24px;
    padding-left: 24px;
  }
  .disc li {
    list-style-type: disc;
  }
  .decimal {
    list-style-type: decimal;
    margin-top: 24px;
    padding-left: 24px;
  }
  .decimal li {
    list-style-type: decimal;
  }
  /* ---------------------------------------------------------
  base
  --------------------------------------------------------- */
  p.mt {
    margin-top: 80px;
  }
  p.line {
    border-bottom: 1px solid #e0dfdf;
    margin: 120px auto;
    max-width: 1120px;
    padding: 1rem 2rem;
    position: relative;
    width: 100%;
  }
  p.line::before {
    background: #10a5cc;
    bottom: -1px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 16px;
  }
  .inner1360 {
    margin: 0 auto;
    max-width: 1360px;
  }
  .inner1120 {
    margin: 0 auto;
    max-width: 1120px;
  }
  .inner960 {
    margin: 0 auto;
    max-width: 960px;
  }
  .pankuzu {
    padding: 16px 0;
    position: absolute;
    top: 88px;
    width: 100%;
  }
  .pankuzu ul {
    display: flex;
  }
  .pankuzu ul li {
    color: #fff;
    font-weight: normal;
    margin-right: 8px;
  }
  .pankuzu ul li a {
    color: #fff;
    font-weight: normal;
  }
  .anc {
    margin: 80px auto;
    width: 960px;
  }
  .anc ul {
    display: flex;
    justify-content: center;
  }
  .anc ul li {
    padding: 2%;
    width: 293px;
  }
  .anc ul li a {
    max-width: 293px;
    width: 100%;
  }
  .anc ul li .button_solid019 a::after {
    display: none;
  }
  span.circle {
    background-color: #0a3464;
    border-radius: 40px;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: inherit;
    margin-right: 16px;
    padding: 1px 9px;
    text-align: center;
  }
  .blue {
    color: #1e6cc3;
  }
  @media only screen and (max-width: 959px) {
    p.mt {
      margin-top: 64px;
    }
    p.line {
      margin: 80px auto;
      max-width: inherit;
      width: calc(100% - 48px);
    }
    .inner1360 {
      margin: 0 auto;
      max-width: inherit;
      width: 100%;
    }
    .inner1120 {
      margin: 0 auto;
      max-width: inherit;
      width: calc(100% - 48px);
    }
    .inner960 {
      margin: 0 auto;
      max-width: inherit;
      width: 100%;
    }
    .anc {
      margin: 40px auto;
      width: calc(100% - 48px);
    }
    .anc ul {
      display: flex;
    }
    .anc ul li {
      width: 161px;
    }
    .anc ul li a {
      max-width: inherit;
    }
    .anc ul li .button_solid019 a::after {
      display: none;
    }
    span.circle {
      font-size: 16px;
      margin-right: 8px;
      padding: 7px;
    }
    .pankuzu {
      top: 72px;
    }
  }
  /* ---------------------------------------------------------
  fadein
  --------------------------------------------------------- */
  /* 画面外にいる状態 */
  .fadein {
    opacity: 0.1;
    transform: translate(0, 24px);
    transition: all 500ms;
  }
  /* 画面内に入った状態 */
  .fadein.scrollin {
    opacity: 1;
    transform: translate(0, 0);
  }
  @media only screen and (max-width: 959px) {
  }
  /* ---------------------------------------------------------
  Pagination
  --------------------------------------------------------- */
  .Pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .Pagination-Item-Link {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    color: #111;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: bold;
    height: 45px;
    justify-content: center;
    overflow: hidden;
    transition: all 0.15s linear;
    width: 45px;
  }
  .Pagination-Item-Link img {
    width: 24px;
  }
  .Pagination-Item-Link.isActive {
    background: #0072c6;
    color: #fff;
    pointer-events: none;
  }
  .Pagination-Item-Link:not(.isActive):hover {
    background: #0072c6;
    color: #fff;
  }
  .Pagination > * + * {
    margin-left: 8px;
  }
}
.p-kids {
  .p-lensTopOptionSec {
    margin-block-start: 0;
    padding-block-start: 70px;
    @include sp {
      padding-inline: 5%;
    }
    .c-title05 {
      font-size: 16px;
      @include font-noto_sans_bold;
    }
  }
}
