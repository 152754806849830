@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-header {
  width: 100%;
  height: 49px;
  background: $c-primary;
  @include pc {
    position: fixed;
    top: 0;
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    height: 5.75rem;
    margin: 0 auto;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include pc {
      width: auto;
    }
  }
  &__site {
    &Logo {
      margin-top: 0;
      margin-bottom: 0;
      @include pc {
        margin-left: 32px;
      }
      a,
      span {
        display: flex;
        align-items: center;
        height: 49px;
        padding: 0 19px;
        @include pc {
          height: 5.75rem;
          padding: 0 10px;
        }
      }
      a {
        @include pc {
          @include ov;
        }
      }
      img {
        width: 115px;
        @include pc {
          width: 173px;
        }
      }
    }
  }
  &__link {
    &.sp {
      position: relative;
      display: block;
      @include pc {
        display: none;
      }
    }
    a {
      display: flex;
      flex: 0 0 49px;
      align-items: center;
      justify-content: center;
      width: 95px;
      height: 49px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      background: #db0011;
      @include fz(10);
    }
  }
  &__link.sp {
    .l-container.-show & {
      z-index: -1;
    }
  }
  &Nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc( 49px + env(safe-area-inset-bottom ));
    background: $c-primary;
    @include pc {
      position: static;
      width: auto;
      height: 5.75rem;
      background: none;
    }
    &__list {
      display: flex;
      flex: 1 0 auto;
      justify-content: space-between;
      height: 49px;
      @include pc {
        justify-content: flex-end;
        height: 5.75rem;
      }
    }
    &__link.reserve {
      background: #333;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      text-align: center;
      height: 64px;
      transform: translateY(-15px);
      flex-basis: 20%;
      width: 20%;
      max-width: 20%;
      @include pc {
        border: none;
        height: 92px;
        transform: translateY(0);
        flex-basis: 173px;
        width: 173px;
        max-width: 173px;
        &:hover {
          background: #fff;
          a {
            color: #333;
            .text::after {
              background: #333;
            }
          }
        }
      }
      a {
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 1.7;
        text-decoration: none;
        height: 100%;
        width: 100%;
        @include pc {
          flex-direction: row;
          font-size: 20px;
        }
        .text {
          position: relative;
          transform: translateY(-3px);
          @include pc {
            transform: translateY(-4px);
          }
          &::after {
            background: #fff;
            content: '';
            display: inline-block;
            position: absolute;
            top: auto;
            bottom: 0;
            transform: translateX(-100%);
            height: 1px;
            width: 100%;
            @include pc {
              height: 2px;
            }
          }
        }
      }
    }
    &__link.sp {
      display: block;
      flex-basis: 20%;
      width: 20%;
      max-width: 20%;
      @include pc {
        display: none;
      }
      a {
        display: block;
        height: 49px;
        padding-top: 7px;
        text-decoration: none;
        background: $c-primary;
        transition: background 0.2s ease;
      }
      .text {
        display: block;
        line-height: (11 / 10);
        color: #fff;
        text-align: center;
        transform: scale(0.9);
        transform-origin: 50%;
        @include fz(10);
      }
      .icon {
        display: block;
        width: 27px;
        height: 22px;
        margin: 0 auto 4px;
        background: url(/img/common/icon_home.svg) no-repeat 50%;
        background-size: contain;
      }
    }
    &__li {
      flex-basis: 20%;
      width: 20%;
      max-width: 20%;
      @include pc {
        flex-basis: 90px;
        width: 90px;
        max-width: 90px;
      }
      &.maestro {
        position: relative;
      }
      &.gnav {
        position: relative;
        @include pc {
          width: 88px;
        }
        &::after {
          position: absolute;
          bottom:3px;
          left: 50%;
          display: block;
          color: #fff;
          white-space: nowrap;
          content: "メニュー";
          transform: translateX(-50%) scale(0.9);
          @include fz(10);
          @include pc {
            bottom: 18px;
          }
        }
        &.-open::after {
          color: #db0011;
          content: "閉じる";
          @include pc {
            content: "メニュー";
          }
        }
      }
    }
    &__nav {
      display: block;
      height: 49px;
      padding-top: 8px;
      text-decoration: none;
      background: $c-primary;
      transition: background 0.2s ease;
      @include pc {
        box-sizing: border-box;
        height: 5.75rem;
        padding-top: 24px;
      }
      .l-headerNav__li.-open & {
        background: #fff;
      }
      @include pc {
        &:hover {
          background: #fff;
        }
      }
      .l-headerNav__li.maestro & {
        padding-top: 6px;
        @include pc {
          padding-top: 24px;
        }
      }
      .text {
        display: block;
        line-height: (11 / 10);
        color: #fff;
        text-align: center;
        white-space: nowrap;
        transform: scale(0.9);
        transform-origin: 50%;
        @include fz(10);
        .l-headerNav__li.-open & {
          color: #db0011;
        }
        @include pc {
          transform: none;
          @at-root .l-headerNav__nav:hover .text {
            color: #db0011;
          }
        }
        .l-headerNav__li.maestro & {
          letter-spacing: -0.1em;
          transform: scale(0.8);
          transform-origin: 50%;
          @include sp320 {
            letter-spacing: -0.2em;
            transform: scale(0.7);
            transform-origin: center;
          }
          @include pc {
            transform: none;
          }
        }
      }
      .icon {
        display: block;
        .l-headerNav__li.store & {
          width: 24px;
          height: 20px;
          margin: 0 auto 5px;
          background: url(/img/common/icon_store.svg) no-repeat 50%;
          background-size: contain;
          @include pc {
            width: 36px;
            height: 28px;
            margin-bottom: 7px;
          }
        }
        .l-headerNav__li.store.-open & {
          background-image: url(/img/common/icon_store_on.svg);
        }
        @include pc {
          @at-root .l-headerNav__li.store .l-headerNav__nav:hover .icon {
            background-image: url(/img/common/icon_store_on.svg);
          }
        }
        .l-headerNav__li.maestro & {
          width: 25px;
          height: 24px;
          margin: 0 auto 3px;
          background: url(/img/common/icon_maestro.svg) no-repeat 50%;
          background-size: contain;
          @include pc {
            width: 34px;
            height: 32px;
            margin-bottom: 3px;
          }
        }
        .l-headerNav__li.maestro.-open & {
          background-image: url(/img/common/icon_maestro_on.svg);
        }
        @include pc {
          @at-root .l-headerNav__li.maestro .l-headerNav__nav:hover .icon {
            background-image: url(/img/common/icon_maestro_on.svg);
          }
        }
        .l-headerNav__li.search & {
          width: 20px;
          height: 20px;
          margin: 0 auto 5px;
          background: url(/img/common/icon_search.svg) no-repeat 50%;
          background-size: contain;
          @include pc {
            width: 28px;
            height: 28px;
            margin-bottom: 7px;
          }
        }
        .l-headerNav__li.search.-open & {
          background-image: url(/img/common/icon_search_on.svg);
        }
        @include pc {
          @at-root .l-headerNav__li.search .l-headerNav__nav:hover .icon {
            background-image: url(/img/common/icon_search_on.svg);
          }
        }
      }
    }
    &__link.pc {
      display: none;
      width: 142px;
      @include pc {
        display: block;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5.75rem;
        line-height: (21 / 14);
        color: #fff;
        text-align: center;
        text-decoration: none;
        background: #db0011;
        transition: color 0.2s ease;
        @include fz(10);
        &:hover {
          color: rgba(#fff, 0.7);
        }
      }
    }
    &__cardState {
      display: none;
      position: absolute;
      top: -43px;
      left: 50%;
      padding: 10px;
      line-height: 1;
      color: #fff;
      letter-spacing: 0.16em;
      white-space: nowrap;
      background: $c-primary;
      border-radius: 3px;
      transform: translateX(-50%);
      @include fz(11);
      @include pc {
        @include vw(13);
        top: inherit;
        bottom: -50px;
        padding: 12px 16px;
        pointer-events: none;
        @include IE {
          padding: 14px 16px 8px;
        }
        &::before {
          position: absolute;
          top: -9px;
          left: 50%;
          display: block;
          width: 0;
          height: 0;
          content: "";
          border-color: transparent transparent $c-primary transparent;
          border-style: solid;
          border-width: 0 5px 9px 5px;
          transform: translateX(-50%);
        }
      }
      &::after {
        position: absolute;
        bottom: -7px;
        left: 50%;
        display: block;
        width: 0;
        height: 0;
        content: "";
        border-color: $c-primary transparent transparent transparent;
        border-style: solid;
        border-width: 7px 4px 0 4px;
        transform: translateX(-50%);
        @include pc {
          display: none;
        }
      }
      .l-container.-show & {
        display: none !important;
      }
    }
    &__icon {
      display: none;
      position: absolute;
      top: 4px;
      left: calc(50% + 23px);
      width: 7px;
      height: 7px;
      background: #ffd647;
      border-radius: 50%;
      @include sp320 {
        right: 8px;
        left: inherit;
      }
      @include pc {
        top: 8px;
        left: calc(50% + 20px);
        width: 10px;
        height: 10px;
      }
    }
  }
  &.-minimum {
    position: fixed;
    display: block;
    background:#333333;
    .l-header__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include pc {
        justify-content: flex-start;
      }
    }
    .l-header__siteLogo {
      display: flex;
      @include pc {
        margin-left: 32px;
      }

      a {
        &:nth-child(2) {
          @include sp {
            padding-left:0;
          }
        }
      }

      img {
        width: 115px;
        @include pc {
          width: 173px;
        }
      }
      img.eyevory {

        width: 89px;
        padding-top: 5px;
        margin-left: 7px;
        @include pc {
          width: 131px;
          padding-top: 6px;
          margin-left: 27px;
        }
      }
    }
    .l-header__close {
      position: relative;
      display: block;
      width: 75px;
      height: 49px;
      background: #fff;
      transition: background-color .3s ease;
      @include pc {
        position: absolute;
        top: 0;
        right: 0;
        width: 88px;
        height: 5.75rem;
      }
      span {
        position: absolute;
        top: calc(22px - -2px);
        left: 50%;
        @include pc {
          top: calc(50% - -2px);
        }
        &::before,
        &::after {
          position: absolute;
          left: calc(50% - 10px);
          width: 20px;
          height: 1px;
          content: "";
          background: #000;
          transition: all 0.3s ease;
          @include pc {
            left: calc(50% - 12px);
            width: 24px;
            height: 2px;
          }
        }
        &::before {
          transform: rotate(-45deg);
        }
        &::after {
          transform: rotate(45deg);
        }
      }
      @include pc {
        &:hover {
          background: #575757;
          span {
            &::before,
            &::after {
              background: #ffffff;
            }
          }
        }
      }
    }
  }
  &.-old {
    .l-headerNav {
      display: none;
      @include pc {
        display: block;
      }
    }
  }
}
