@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-menu {
  position: fixed;
  bottom: 49px;
  left: 0;
  width: 100%;
  height: calc(100% - 49px);
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  transition: bottom 0.2s ease;
  -webkit-overflow-scrolling: touch;
  @include pc {
    top: 5.75rem;
    right: 0;
    bottom: inherit;
    left: inherit;
    width: 572px;
    height: calc(100vh - 5.75rem);
    transition: right 0.2s ease;
  }
  .l-container.-show.-deleted & {
    overflow: hidden;
  }
  .l-headerNav__li:not(.-open) & {
    bottom: -100vh;
    @include pc {
      right: -580px;
    }
  }
  .l-container.-show.-nocard .l-headerNav__li.maestro._open & {
    height: auto;
    @include pc {
      height: calc(100vh - 5.75rem);
    }
  }
  &__inner {
    .l-headerNav__li.search & {
      padding-bottom: 30px;
      @include pc {
        padding-bottom: 63px;
      }
    }
  }
  &__title {
    @include lhCrop(116 / 36);
    @include fz(18);
    padding-top: 45px;
    line-height: (116 / 36);
    text-align: center;
    @include pc {
      @include lhCrop(89 / 18);
      padding-top: 58px;
      line-height: (89 / 18);
    }
  }
  &__closeBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: +1;
    width: 49px;
    height: 49px;
    background: #fff url("/img/common/icon_close.svg") no-repeat 50%;
    background-size: 13px auto;
    border: 1px solid #e2bfd4;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease;
    @include pc {
      display: none;
    }
    li.l-headerNav__li.-open & {
      position: fixed;
      opacity: 1;
      transition-delay: 0.5s;
    }
    span {
      @include visually-hidden;
    }
  }

  &__shop-list-box {
    width:332px;
    margin:0 auto;
    margin-bottom:40px;
    @include sp {
      padding-top:7vw;
      width:535/750*100+vw;
    }
  }
}
