@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Noto+Serif+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
$lohas__color-black: #111;
$lohas__color-gray: #ddd;
$lohas__color-border: #999;
$lohas__color-yellow: #ffe100;
$lohas__color-white: #fff;
$holt__color-blue: #0068b7;
$holt__color-gray: #efefef;
$holt__color-gold: #c6a52b;
$holt__color-lightblue: #d9e8fb;
$holt__font-number: "Montserrat", sans-serif;
%lohas-borderBox {
  border: 1px solid $lohas__color-border;
  border-radius: 20px;
  display: block;
  padding: 50px 20px;
  text-align: center;
  &:not(:first-child) {
    margin-top: 40px;
    @include pc {
      margin-top: 40px;
    }
  }
}
@mixin font-noto_serif_medium {
  font-family: 'Noto Serif JP', serif;
  font-weight: 400;
}
@mixin font-cormorant_garamond_medium {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 400;
}

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-lohas {
  @at-root {
    & .c-sec__inner {
      @include pc {
        max-width: calc(1160px + 3.15% * 2);
      }
    }
    // メインビジュアル
    .p-lohasVisual {
      margin: 0;
    }
    .p-lohasVisualImage {
    }
    .p-lohasVisualImage__image {
      height: auto;
      max-width: none;
      width: 100%;
    }
    //タイトルモジュール
    .p-lohasTitle {
      margin-bottom: 37px;
      @include pc {
        margin-bottom: 68px;
      }
      .p-lohasFeature & {
        margin-bottom: 43px;
        @include pc {
          margin-bottom: 108px;
        }
      }
      &::before {
        background: linear-gradient(to right, $lohas__color-yellow 80px, $lohas__color-black 80px, $lohas__color-black 100%);
        content: '';
        display: block;
        height: 8px;
        margin-bottom: 22px;
        width: 100%;
        @include pc {
          background: linear-gradient(to right, $lohas__color-yellow 200px, $lohas__color-black 200px, $lohas__color-black 100%);
          height: 10px;
          margin-bottom: 40px;
        }
      }
      &-small {
        font-size: 24px;
        line-height: 1.4;
        @include pc {
          font-size: 40px;
        }
        &::before {
          background: linear-gradient(to right, $lohas__color-yellow 50%, $lohas__color-black 50%, $lohas__color-black 100%);
          height: 4px;
          margin-bottom: 30px;
          width: 195px;
          @include pc {
            margin-bottom: 40px;
          }
        }
      }
    }
    .p-lohasTitle-holt {
      &::before {
        background: linear-gradient(to right, $holt__color-gold 80px, $holt__color-blue 80px, $holt__color-blue 100%);
        @include pc {
          background: linear-gradient(to right, $holt__color-gold 200px, $holt__color-blue 200px, $holt__color-blue 100%);
        }
      }
      &-small {
        &::before {
          background: linear-gradient(to right, $holt__color-gold 50%, $holt__color-blue 50%, $holt__color-blue 100%);
        }
      }
    }
    .p-lohasTitle__text {
      @include font-noto_serif_medium;
      font-size: 36px;
      letter-spacing: 0;
      line-height: calc(43 / 36);
      position: relative;
      @include pc {
        font-size: 60px;
      }
    }
    .p-lohasTitle__text-small {
      @include pc {
        font-size: 50px;
      }
    }
    .p-lohasTitle__note {
      display: block;
      font-size: 16px;
      margin-top: 20px;
      @include pc {
        margin-top: 12px;
        text-indent: 0.5em;
      }
    }
    .p-lohasBorderTitle {
      border-bottom: 2px solid $lohas__color-border;
      font-size: 24px;
      line-height: 1.6;
      margin-bottom: 65px;
      @include pc {
        align-items: center;
        border-bottom: none;
        display: flex;
        justify-content: center;
        margin-bottom: 100px;
      }
      &::before,
      &::after {
        @include pc {
          content: '';
          display: block;
          background-color: $lohas__color-border;
          flex: 1;
          height: 1px;
          width: 100%;
        }
      }
      &::before {
        @include pc {
          margin-right: 20px;
        }
      }
      &::after {
        @include pc {
          margin-left: 20px;
        }
      }
      &:not(:first-child) {
        margin-top: 65px;
        @include pc {
          margin-top: 140px;
        }
      }
    }
    .p-lohasNumberTitle {
      @include font-noto_serif_medium;
      font-size: 32px;
      letter-spacing: 0.02em;
      line-height: calc(42 / 32);
      margin-bottom: 40px;
      @include pc {
        font-size: 48px;
        margin-bottom: 100px;
      }
      &:first-letter {
        color: $lohas__color-yellow;
      }
      &:not(:first-child) {
        margin-top: 50px;
        @include pc {
          margin-top: 130px;
        }
      }
      &-holt {
        &:first-letter {
          color: $holt__color-gold;
        }
      }
    }
    .p-lohasNumberTitle__number {
      border-bottom: 4px solid $lohas__color-yellow;
      display: block;
      @include font-Montserrat_extralight;
      font-size: 80px;
      font-style: normal;
      line-height: 1;
      margin-bottom: 10px;
      padding-bottom: 10px;
      @include pc {
        font-size: 100px;
        margin-bottom: 20px;
        padding-bottom: 16px;
      }
      .p-lohasNumberTitle-holt & {
        border-bottom-color: $holt__color-gold;
      }
    }
    .p-lohasText {
      font-size: 16px;
      letter-spacing: 0.06em;
      line-height: 2;
      &:not(:first-child) {
        margin-top: 1em;
      }
      .p-lohasImage + & {
        margin-top: pxToVw(20);
        @include pc {
          margin-top: 40px;
        }
      }
    }
    // 画像モジュール
    .p-lohasImage {
      display: block;
      text-align: center;
      &:not(:first-child) {
        margin-top: 40px;
        @include pc {
          margin-top: 70px;
        }
      }
      & + .c-text13 {
        margin-top: pxToVw(20);
        @include pc {
          margin-top: 20px;
        }
      }
    }
    // ニコンZシリーズ ロハス100Z
    .p-lohasBanner {
      margin-inline: auto;
      margin-block-start: 50px;
      @include pc {
        margin-block-start: 100px;
      }
    }
    .p-lohasBanner__copy {
      font-size: 12px;
      text-align: center;
      @include pc {
        font-size: 18px;
      }
    }
    .p-lohasBannerTitle {
      font-size: 18px;
      margin-block-end: 25px;
      text-align: center;
      @include font-noto_serif_medium;
      @include pc {
        font-size: 32px;
        margin-block-end: 50px;
      }
    }
    .p-lohasBannerTitle__text {
      background: linear-gradient(to bottom, rgba(0,0,0,0) 70%, $lohas__color-yellow 70%, $lohas__color-yellow 100%);
    }
    .p-lohasBanner__link {
      display: block;
      max-width: 622px;
      margin-inline: auto;
      @include ov;
    }
    .p-lohasBannerImage {
      border: 1px solid #000;
      display: block;
    }
    .p-lohasBannerImage__image {
      height: auto;
      width: 100%;
    }
    // ニコンのオーダーメイド累進レンズ
    .p-lohasAbout {
      padding: 60px 0 80px;
      @include pc {
        padding-bottom: 140px;
        padding-top: 113px;
      }
    }
    .p-lohasAboutTitle {
      margin-bottom: 39px;
      text-align: center;
      @include pc {
        margin-bottom: 55px;
      }
    }
    .p-lohasAboutTitle__main {
      display: block;
      font-size: 40px;
      @include font-noto_serif_medium;
      line-height: calc(52 / 40);
      word-break: keep-all;
      @include pc {
        font-size: 60px;
      }
    }
    .p-lohasAboutTitle__sub {
      background: linear-gradient(to bottom, rgba(0,0,0,0) 70%, $lohas__color-yellow 70%, $lohas__color-yellow 100%);
      display: inline-block;
      @include font-Montserrat;
      font-size: 32px;
      font-style: normal;
      line-height: 1;
      margin-top: 19px;
      @include pc {
        font-size: 36px;
      }
      &-holt {
        background: linear-gradient(to bottom, rgba(0,0,0,0) 70%, $holt__color-gold 70%, $holt__color-gold 100%);
        @include sp {
          font-size: 22px;
        }
      }
    }
    .p-lohasAboutLead {
      font-size: 16px;
      letter-spacing: 0.06em;
      line-height: calc(32 / 16);
      @include pc {
        line-height: calc(35 / 16);
        text-align: center;
      }
    }
    .p-lohasAboutNavigation {
      border: 1px solid $lohas__color-black;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 60px;
      @include pc {
        border-bottom: none;
        border-left: none;
        border-top: none;
        display: flex;
        margin-top: 120px;
      }
    }
    .p-lohasAboutNavigation__item {
      @include pc {
        border-left: 1px solid $lohas__color-black;
        width: 100%;
      }
      &:nth-child(2n) {
        border-left: 1px solid $lohas__color-black;
      }
      &:nth-child(n+3) {
        border-top: 1px solid $lohas__color-black;
        @include pc {
          border-top: none;
        }
      }
    }
    .p-lohasAboutNavigation__link {
      align-items: center;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      line-height: calc(20 / 14);
      padding: 20px 0;
      text-align: center;
      text-decoration: none;
      @include pc {
        font-size: 18px;
        line-height: calc(25 / 18);
        padding: 10px 0;
      }
      &::after {
        background-color: $lohas__color-black;
        mask: url('data:image/svg+xml;charset=utf8,%3Csvg%20fill%3D%22none%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20stroke%3D%22%23111%22%3E%3Cpath%20d%3D%22m1%209%209%2010%209-10%22%2F%3E%3Cpath%20d%3D%22m1%205%209%2010%209-10%22%2F%3E%3Cpath%20d%3D%22m1%201%209%2010%209-10%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat left top;
        mask-size: contain;
        content: '';
        display: block;
        height: 14px;
        margin: 12px auto 0;
        width: 12px;
        @include pc {
          height: 18px;
          margin-top: 15px;
          width: 18px;
        }
      }
      &:hover {
        &::after {
          background-color: $lohas__color-yellow;
        }
      }
    }
    .p-lohasAboutNavigation__link-holt {
      &::after {
        background-color: $holt__color-blue;
      }
      &:hover {
        &::after {
          background-color: $holt__color-gold;
        }
      }
    }
    // こんな方におすすめ
    .p-lohasRecommend {
      background-color: $lohas__color-black;
      color: $lohas__color-white;
      padding: 90px 0 100px;
      @include pc {
        padding-bottom: 160px;
        padding-top: 150px;
      }
      &-holt {
        background-color: $holt__color-blue;
      }
    }
    .p-lohasRecommendRow {
      @include pc {
        display: flex;
        padding-top: 60px;
        position: relative;
      }
      &::before {
        background: linear-gradient(to right, $lohas__color-yellow 126px, $lohas__color-black 127px, $lohas__color-white 128px);
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        @include pc {
          background: linear-gradient(to right, $lohas__color-yellow 435px, $lohas__color-black 435px, $lohas__color-black 440px, $lohas__color-white 440px);
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      &-holt {
        &::before {
          background: linear-gradient(to right, $holt__color-gold 126px, $holt__color-blue 127px, $lohas__color-white 128px);
          @include pc {
            background: linear-gradient(to right, $holt__color-gold 435px, $holt__color-blue 435px, $holt__color-blue 440px, $lohas__color-white 440px);
          }
        }
      }
      & + & {
        margin-top: 60px;
        @include pc {
          margin-top: 90px;
        }
      }
    }
    .p-lohasRecommendRow__head {
      padding-left: 20px;
      padding-right: 20px;
      @include pc {
        width: 435px;
      }
    }
    .p-lohasRecommend__title {
      @include font-noto_serif_medium;
      font-size: 30px;
      letter-spacing: 0.06em;
      line-height: 1;
      margin-bottom: 40px;
      padding-top: 30px;
      @include pc {
        font-size: 36px;
        margin-bottom: 0;
        padding-top: 0;
      }
    }
    .p-lohasRecommendRow__body {
      font-size: 16px;
      letter-spacing: 0.06em;
      padding-left: 20px;
      padding-right: 20px;
      @include pc {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
      }
      .c-list {
        font-size: inherit;
      }
      .c-list__item + .c-list__item {
        margin-top: 0.75em;
      }
    }
    // ロハス ハンドレッドの特長
    .p-lohasFeature {
      background-color: $lohas__color-gray;
      padding: 80px 0 100px;
      @include pc {
        padding-top: 210px;
      }
    }
    .p-lohasFeature-blue {
      background-color: #f3f7fd;
    }
    .p-lohasFeatureList {
      counter-reset: number;
    }
    .p-lohasFeatureList__item {
      background: linear-gradient(to bottom, rgba(0,0,0,0), $lohas__color-white 90%, $lohas__color-white 100%);
      counter-increment: number;
      margin-left: -5%;
      margin-right: -5%;
      overflow: hidden;
      padding: 40px 5% 60px;
      @include pc {
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0) 40%, $lohas__color-white 100%);
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
      }
      &:first-child {
        padding-top: 0;
      }
      &:nth-child(2n) {
        @include pc {
          background: linear-gradient(to right, $lohas__color-white, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%);
          flex-direction: row-reverse;
        }
      }
      & + & {
        @include pc {
          margin-top: 80px;
        }
      }
    }
    .p-lohasFeature__content {
      @include pc {
        flex: 1;
        max-width: 540px;
      }
    }
    .p-lohasFeature__photo {
      margin-top: 40px;
      transform: translateZ(0);
      @include pc {
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
        margin-top: 0;
        padding: 76px 62px 60px 100px;
      }
      .p-lohasFeatureList__item:nth-child(2n) & {
        @include pc {
          padding-right: 100px;
          padding-left: 62px;
        }
      }
    }
    .p-lohasFeature__title {
      @include font-noto_serif_medium;
      font-size: 30px;
      letter-spacing: 0;
      line-height: calc(42 / 30);
      margin-bottom: 20px;
      padding-top: 70px;
      position: relative;
      z-index: 1;
      @include pc {
        font-size: 40px;
        line-height: calc(56 / 40);
        margin-bottom: 18px;
        padding-top: 117px;
      }
      &::before {
        color: $lohas__color-white;
        content: counter(number, decimal-leading-zero);
        @include font-Montserrat_extralight;
        font-size: 100px;
        left: 0;
        line-height: 1;
        position: absolute;
        top: 0;
        z-index: -1;
        @include pc {
          font-size: 160px;
        }
        .p-lohasFeatureList__item:nth-child(2n) & {
          @include pc {
            left: auto;
            right: 0;
          }
        }
        .p-lohasFeature-blue & {
          color: #c9daf8;
        }
      }
    }
    .p-lohasFeature__text {
      font-size: 16px;
      line-height: calc(29 / 16);
    }
    .p-lohasFeature__image {
      mix-blend-mode: multiply;
      &[src$="feature_01.png"] {
        @include pc {
          width: 456px;
        }
      }
      &[src$="feature_02.png"] {
        @include pc {
          width: 374px;
        }
      }
      &[src$="feature_03.png"] {
        @include pc {
          width: 444px;
        }
      }
      &[src$="feature_04.png"] {
        @include pc {
          width: 394px;
        }
      }
      &[src$="feature_05.png"] {
        @include pc {
          width: 448px;
        }
      }
      &[src$="feature_06.png"] {
        @include pc {
          width: 483px;
        }
      }
      &[src$="feature_07.png"] {
        @include pc {
          width: 459px;
        }
      }
    }
    .p-lohasFeature__image-holt {
      mix-blend-mode: multiply;
      &[src$="feature_01.png"] {
        @include pc {
          width: 400px;
        }
      }
      &[src$="feature_02.png"] {
        @include pc {
          width: 400px;
        }
      }
      &[src$="feature_03.png"] {
        @include pc {
          width: 450px;
        }
      }
      &[src$="feature_04.png"] {
        @include pc {
          width: 450px;
        }
      }
      &[src$="feature_05.png"] {
        display: block;
        margin: 0 auto;
        width: pxToVw(450);
        @include pc {
          margin: 0;
          width: 300px;
        }
      }
      &[src$="feature_06.png"] {
        @include pc {
          width: 450px;
        }
      }
      &[src$="feature_07.png"] {
        @include pc {
          width: 450px;
        }
      }
    }
    .p-lohasStyle {
      padding: 80px 0;
      @include pc {
        padding-bottom: 120px;
        padding-top: 200px;
      }
    }
    .p-lohasStyleImage {
      @extend %lohas-borderBox;
      &02 {
        padding-left: 10px;
        padding-right: 10px;
      }
      & + .c-text16 {
        margin-top: 30px;
        @include pc {
          margin-top: 40px;
        }
      }
      & + .u-textRight {
        margin-top: 1em;
      }
    }
    .p-lohasStyleImage__image {
      width: 100%;
      @include pc {
        width: auto;
      }
    }
    .p-lohasStyleType {
    }
    .p-lohasStyleType__item {
      border: 1px solid $lohas__color-border;
      border-radius: 20px;
      padding: 60px 20px;
      position: relative;
      z-index: 1;
      @include pc {
        padding-top: 50px;
      }
      & + & {
        margin-top: 30px;
      }
      &::before {
        background-color: $lohas__color-black;
        content: '';
        display: block;
        height: 32px;
        position: absolute;
        left: 0;
        top: 60px;
        width: 10px;
        @include pc {
          height: 40px;
          width: 15px;
        }
      }
    }
    .p-lohasStyleType__bg {
      background: linear-gradient(180deg, #DEDEDE 14.53%, #F8F8F8 81.4%);
      background-clip: text;
      color: transparent;
      display: block;
      @include font-Montserrat_extralight;
      font-size: 40px;
      line-height: 1;
      position: absolute;
      right: 0;
      top: 0;
      transform: rotate(90deg) translateX(calc(100% + 30px));
      transform-origin: right top;
      z-index: -1;
      @include pc {
        font-size: 80px;
        right: 90px;
        top: 50px;
        transform: none;
      }
    }
    .p-lohasStyleTypeTitle {
      line-height: 1;
      margin-bottom: 20px;
      margin-left: -21px;
      padding-left: 20px;
      position: relative;
      @include pc {
        line-height: 1.7;
        margin-bottom: 45px;
        margin-left: auto;
        margin-right: auto;
        max-width: 980px;
        padding-left: 0;
      }
    }
    .p-lohasStyleTypeTitle__text {
      display: block;
      @include font-noto_sans_bold;
      font-size: 30px;
      @include pc {
        display: inline;
        font-size: 36px;
      }
    }
    .p-lohasStyleTypeTitle__note {
      display: block;
      font-size: 18px;
      margin-top: 5px;
      @include pc {
        display: inline;
        font-size: 24px;
      }
    }
    .p-lohasStyleType__inner {
      @include pc {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        max-width: 980px;
      }
    }
    .p-lohasStyleTypeList {
      font-size: 18px;
      @include pc {
        border-left: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        font-size: 20px;
        justify-content: center;
        padding-left: 24px;
        position: relative;
        width: 230px;
      }
      @include wide {
        padding-left: 54px;
      }
      &::before,
      &::after {
        @include pc {
          background-color: #ccc;
          content: '';
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          width: 25px;
        }
      }
      &::before {
        @include pc {
          top: 0;
        }
      }
      &::after {
        @include pc {
          bottom: 0;
        }
      }
    }
    .p-lohasStyleTypeList__item {
      &::before {
        content: '✓';
      }
      & + & {
        margin-top: 0.5em;
      }
    }
    .p-lohasStyleTypeImage {
      margin-top: 40px;
      @include pc {
        display: flex;
        gap: 0 44px;
        margin-top: 0;
      }
    }
    .p-lohasStyleTypeImage__item {
      text-align: center;
      & + & {
        margin-top: 10px;
        padding-top: 25px;
        position: relative;
        @include pc {
          margin-top: 0;
          padding-top: 0;
        }
        &::before {
          background: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2219%22%20height%3D%2215%22%20viewBox%3D%220%200%2019%2015%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M9.53516%2015L0.874902%20-1.63133e-06L18.1954%20-1.17124e-07L9.53516%2015Z%22%20fill%3D%22%23111111%22%2F%3E%3C%2Fsvg%3E') no-repeat left top / contain;
          content: '';
          display: block;
          height: 20px;
          left: calc(50% - 10px);
          position: absolute;
          top: 0;
          width: 20px;
          @include pc {
            left: 0;
            top: calc(50% - 10px);
            transform: rotate(-90deg) translateY(-30px);
          }
        }
      }
    }
    .p-lohasStyleTypeImage__image {
      @include pc {
        width: 330px;
      }
    }
    .p-lohasStyleCompare {
      @include pc {
        display: flex;
        gap: 0 30px;
        justify-content: center;
      }
    }
    .p-lohasStyleCompare__item {
      border: 1px solid $lohas__color-border;
      border-radius: 20px;
      padding: 0 20px 40px;
      text-align: center;
      @include pc {
        width: 100%;
      }
      & + & {
        margin-top: 55px;
        @include pc {
          margin-top: 0;
        }
      }
    }
    .p-lohasStyleCompareTitle {
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
    }
    .p-lohasStyleCompareTitle__text {
      align-items: center;
      background-color: $lohas__color-black;
      border-radius: 30px;
      color: $lohas__color-white;
      display: inline-flex;
      @include font-noto_sans_bold;
      font-size: 20px;
      justify-content: center;
      letter-spacing: 0.06em;
      line-height: 1.4;
      margin-left: auto;
      margin-right: auto;
      min-height: 50px;
      min-width: 180px;
      text-align: center;
      transform: translateY(-50%);
    }
    .p-lohasStyleCompare__image {
      @include pc {
        max-width: 405px;
      }
    }
    .p-lohasStyleText {
      font-size: 18px;
      letter-spacing: 0.06em;
      line-height: calc(32 / 18);
      margin-top: 20px;
      @include pc {
        margin-top: 40px;
        text-align: center;
      }
    }
    .p-lohasStyleText02 {
      margin-top: 30px;
      @include pc {
        display: flex;
        justify-content: center;
        margin-top: 60px;
      }
    }
    .p-lohasStyleNote {
      font-size: 16px;
      line-height: calc(29 / 16);
      &::before {
        background: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2233%22%20height%3D%2216%22%20viewBox%3D%220%200%2033%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%220.269531%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%23FFE100%22%2F%3E%3Crect%20x%3D%2216.2695%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%23111111%22%2F%3E%3C%2Fsvg%3E') no-repeat left top / contain;
        content: '';
        display: block;
        height: 16px;
        margin-bottom: 15px;
        width: 32px;
      }
      &:not(:first-child) {
        margin-top: 60px;
        @include pc {
          margin-top: 120px;
        }
      }
    }
    .p-lohasStyleNote02 {
      color: #4b43ba;
      //@include font-noto_sans_bold;
      font-size: 16px;
      line-height: calc(29 / 16);
      margin-top: 0;
    }
    .p-lohasStyleFocus {
      @extend %lohas-borderBox;
    }
    .p-lohasStyleFocusImage {
    }
    .p-lohasStyleFocusImage__image {
    }
    .p-lohasStyleFocus__text {
      font-size: 16px;
      letter-spacing: 0.06em;
      line-height: 29px;
      margin-top: 20px;
      text-align: center;
      strong {
        @include font-noto_sans_bold;
        font-size: 36px;
      }
      em {
        @include font-noto_sans_bold;
        font-size: 20px;
        font-style: normal;
      }
    }
    // ラインアップ・レンズスペック
    .p-lohasLineup {
      padding-bottom: 80px;
      padding-top: 70px;
      @include pc {
        padding-bottom: 240px;
        padding-top: 120px;
      }
    }
    .p-lohasLineupList {
    }
    .p-lohasLineupList__item {
      border-bottom: 2px solid $lohas__color-black;
      padding-bottom: 30px;
      @include pc {
        display: flex;
        gap: 0 60px;
        flex-wrap: wrap;
      }
      & + & {
        padding-top: 40px;
      }
    }
    .p-lohasLineupList__head {
      text-align: center;
      @include pc {
        width: 210px;
      }
    }
    .p-lohasLineupList__head-wide {
      @include pc {
        width: 315px;
      }
    }
    .p-lohasLineup__image {
      max-height: 152px;
      @include pc {
        max-height: 197px;
      }
    }
    .p-lohasLineup__image-holt {
      @include pc {
        margin-top: 15px;
      }
    }
    .p-lohasLineupList__body {
      margin-top: 30px;
      @include pc {
        flex: 1;
        margin-top: 0;
      }
    }
    .lohasLineupIcon {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      @include pc {
        display: flex;
        flex-wrap: wrap;
      }
      @include wide {
        gap: 20px 30px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $lohas__color-border;
        margin-bottom: 20px;
        padding-bottom: 20px;
        @include pc {
          padding-bottom: 30px;
        }
      }
    }
    .lohasLineupIcon__item {
      @include pc {
        width: 85px;
      }
    }
    .lohasLineupIcon__image {
    }
    .p-lohasLineupList__foot {
      border-top: 1px solid $lohas__color-border;
      margin-top: 30px;
      padding-top: 20px;
    }
    .p-lohasLineup__text {
      font-size: 16px;
      line-height: calc(29 / 16);
    }
    .p-lohasLineupPrice {
      font-size: 16px;
      text-align: right;
      @include pc {
        margin-top: 15px;
      }
    }
    .p-lohasLineupPrice__number {
      @include font-noto_sans_bold;
      font-size: 20px;
    }
    // 老眼や累進レンズに関する誤解
    .p-lohasColumn {
      background-color: #d9d9d9;
      padding: 80px 5%;
      @include pc {
        padding: 140px 3.15%;
      }
      .c-sec__inner {
        @include pc {
          max-width: calc(980px + 3.15% * 2);
        }
      }
      .c-title04 ~ .c-title04 {
        margin-top: 40px;
        @include pc {
          margin-top: 85px;
        }
      }
    }
    .p-lohasColumn__inner {
      background-color: $lohas__color-white;
      border-radius: 20px;
      margin-left: auto;
      margin-right: auto;
      max-width: 1280px;
      padding-bottom: 70px;
      padding-top: 70px;
      @include pc {
        padding-bottom: 140px;
        padding-top: 140px;
      }
      & + & {
        margin-top: 50px;
        @include pc {
          margin-top: 80px;
        }
      }
    }
    .p-lohasColumnWhy {
      counter-reset: number;
    }
    .p-lohasColumnWhy__item {
      & + & {
        margin-top: 60px;
        @include pc {
          margin-top: 120px;
        }
      }
    }
    .p-lohasColumnWhy__head {
    }
    .p-lohasColumnWhy__body {
      @include pc {
        padding-left: 50px;
      }
    }
    .p-lohasColumnWhyTitle {
      counter-increment: number;
      display: flex;
      margin-bottom: 20px;
      @include pc {
        align-items: center;
      }
    }
    .lohasColumnWhyTitle__number {
      background-color: $lohas__color-yellow;
      border-radius: 50%;
      content: counter(number);
      display: block;
      @include font-cormorant_garamond_medium;
      font-size: 36px;
      height: 40px;
      letter-spacing: 0.06em;
      line-height: 0.9;
      justify-content: center;
      margin-right: 10px;
      padding-left: 3px;
      text-align: center;
      width: 40px;
      &-sup {
        line-height: 0.7;
      }
    }
    .p-lohasColumnWhyTitle__text {
      @include font-noto_sans_medium;
      font-size: 20px;
      flex: 1;
      line-height: 1.4;
    }
    .p-lohasColumnWhy__column {
      @include pc {
        display: flex;
        gap: 0 66px;
      }
    }
    .p-lohasColumnWhy__row {
      &-text {
        @include pc {
          flex: 1;
        }
      }
      &-image {
        @include pc {
          max-width: 364px;
          width: calc(364 / 872 * 100%);
        }
      }
    }
    .p-lohasColumnWhyImage {
      display: block;
      text-align: center;
    }
    .p-lohasColumnImage__image {
      margin-top: 30px;
      @include pc {
        margin-top: 70px;
      }
      .p-lohasColumnWhy__row & {
        @include pc {
          margin-top: 0;
        }
      }
    }
    .p-lohasTable {
      background-color: $lohas__color-border;
      border: 1px solid $lohas__color-border;
      border-collapse: separate;
      border-spacing: 1px;
      letter-spacing: 0.06em;
      width: 100%;
      &:not(:first-child) {
        margin-top: pxToVw(30);
        @include pc {
          margin-top: 60px;
        }
      }
      & + .p-lohasText {
        margin-top: 1em;
      }
      .p-lohasTable__th,
      .p-lohasTable__td {
        padding: 1em 2em;
        vertical-align: middle;
      }
      .p-lohasTable__th {
        background-color: $lohas__color-white;
      }
      .p-lohasTable__th-gray {
        background-color: $holt__color-gray;
        @include font-noto_sans_bold;
      }
      .p-lohasTable__th-blue {
        background-color: $holt__color-lightblue;
      }
      .p-lohasTable__th-bold {
        @include font-noto_sans_bold;
      }
      .p-lohasTable__td {
        background-color: $lohas__color-white;
      }
    }
    .p-lohasTable__th-row {
      font-family: $holt__font-number;
      font-size: pxToVw(24);
      font-weight: 200;
      word-break: keep-all;
      @include pc {
        font-size: 24px;
      }
    }
    .p-lohasTable__number {
      align-items: center;
      background-color: $lohas__color-white;
      border: 1px solid $lohas__color-border;
      border-radius: 50%;
      display: inline-flex;
      font-family: $holt__font-number;
      font-size: pxToVw(24);
      font-weight: 200;
      height: pxToVw(44);
      justify-content: center;
      text-align: center;
      width: pxToVw(44);
      @include pc {
        font-size: 24px;
        height: 44px;
        width: 44px;
      }
    }
    .p-lohasThickness {
      &:not(:first-child) {
        margin-top: pxToVw(80);
        @include pc {
          margin-top: 70px;
        }
      }
    }
    .p-lohasThickness__title {
      @include font-noto_sans_bold;
      font-size: pxToVw(40);
      letter-spacing: 0.06em;
      line-height: calc(28 / 20);
      margin-bottom: 1em;
      text-align: center;
      @include pc {
        font-size: 24px;
      }
      .PCview {
        @include tb {
          display: inline;
        }
      }
    }
    .p-lohasThicknessList {
      align-items: center;
      color: $lohas__color-white;
      display: flex;
      justify-content: center;
    }
    .p-lohasThicknessList__item {
      align-items: center;
      background-color: $lohas__color-black;
      display: flex;
      @include font-noto_sans_bold;
      font-size: pxToVw(36);
      letter-spacing: 0.06em;
      line-height: calc(23 / 18);
      justify-content: center;
      padding: 1em;
      text-align: center;
      width: 100%;
      @include pc {
        font-size: 20px;
      }
      & + & {
        margin-left: 1px;
      }
      small {
        font-size: pxToVw(28);
        @include pc {
          font-size: 16px;
        }
      }
    }
    .p-lohasThicknessList__text {
    }
    .p-lohasThicknessList__number {
    }
    .p-lohasThicknessText {
      align-items: center;
      display: flex;
      @include font-noto_sans_medium;
      font-size: pxToVw(32);
      justify-content: space-between;
      letter-spacing: 0.06em;
      line-height: calc(22 / 16);
      margin-top: pxToVw(40);
      @include pc {
        font-size: 22px;
        margin-top: 20px;
      }
    }
    .p-lohasThicknessText__text {
    }
    .p-lohasThicknessText__arrow {
      background: no-repeat center center / contain;
      content: '';
      display: block;
      height: pxToVw(24);
      margin: 0 pxToVw(10);
      width: pxToVw(120);
      @include pc {
        flex: 1;
        height: 13px;
        margin: 0 10px;
        width: 450px;
      }
    }
    .p-lohasThicknessText__arrow-prev {
      background-image:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCA2MCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgNkwxMCAxMS43NzM1TDEwIDAuMjI2NDk2TDAgNlpNOSA3TDYwIDYuOTk5OTlMNjAgNC45OTk5OUw5IDVMOSA3WiIgZmlsbD0iIzExMTExMSIvPgo8L3N2Zz4K');
      @include pc {
        background-image:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQ4IiBoZWlnaHQ9IjEzIiB2aWV3Qm94PSIwIDAgNDQ4IDEzIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMCA2LjVMMTAgMTIuMjczNUwxMCAwLjcyNjQ5OEwwIDYuNVpNOSA3LjVMNDQ3LjQwNiA3LjUwMDA0TDQ0Ny40MDYgNS41MDAwNEw5IDUuNUw5IDcuNVoiIGZpbGw9IiMxMTExMTEiLz4KPC9zdmc+Cg==');
      }
    }
    .p-lohasThicknessText__arrow-next {
      background-image:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjEiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCA2MSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYwLjI2OTUgNkw1MC4yNjk1IDAuMjI2NDk3VjExLjc3MzVMNjAuMjY5NSA2Wk0wLjI2OTUzMSA3SDUxLjI2OTVWNUgwLjI2OTUzMVY3WiIgZmlsbD0iIzExMTExMSIvPgo8L3N2Zz4K');
      @include pc {
        background-image:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUwIiBoZWlnaHQ9IjEzIiB2aWV3Qm94PSIwIDAgNDUwIDEzIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNDUwIDYuNDk5OTZMNDQwIDAuNzI2NDU5TDQ0MCAxMi4yNzM1TDQ1MCA2LjQ5OTk2Wk0wLjc4OTA2MyA3LjVMNDQxIDcuNDk5OTZMNDQxIDUuNDk5OTZMMC43ODkwNjIgNS41TDAuNzg5MDYzIDcuNVoiIGZpbGw9IiMxMTExMTEiLz4KPC9zdmc+Cg==');
      }
    }
  }
}
