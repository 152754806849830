@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-buy {
  @include sp {
    height: 100%;
  }
  &__head {
    @include font-noto_sans;
    padding: 15px 0;
    font-size: 13px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.16em;
    background: #e60012;
  }
  &__body {
    height: calc(100% - 50px);
    padding: 30px 5.7% 25px;
    background: #f7f7f7;
    @include pc {
      padding: 40px;
    }
    &.-center {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
    .c-product {
      &Main {
        &__title {
          margin-bottom: 0;
          font-size: 18px;
          @include pc {
            font-size: 17px;
          }
        }
      }
      &Item {
        &__title {
          margin-top: 4px;
          margin-bottom: 25px;
          font-size: 18px;
          @include pc {
            margin-bottom: 28px;
            font-size: 17px;
          }
        }
      }
    }
    .p-form {
      width: 100%;
      padding: 0;
      margin: 0 auto;
      background: none;
      .-grid_row {
        margin-bottom: 10px;
        &__inner {
          @include font-noto_sans;
          padding: 4px 10px;
          font-size: 13px;
          letter-spacing: 0.16em;
          @include pc {
            padding: 6px 10px;
            font-size: 14px;
          }
        }
      }
      select {
        @include font-noto_sans;
        padding: 14px 10px;
        font-size: 13px;
        letter-spacing: 0.16em;
        @include pc {
          padding: 6px 10px;
          font-size: 14px;
        }
      }
      option {
        @include font-noto_sans;
        font-size: 13px;
        @include pc {
          font-size: 14px;
        }
      }
    }
    .c-btnWrap {
      position: relative;
      padding-top: 31px;
      padding-bottom: 35px;
      margin: 31px 0 0;
      @include pc {
        padding-bottom: 0;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 3px;
        content: "";
        background: url(/img/common/line_dot_gray.svg) repeat-x;
      }
      .c-btn {
        button {
          font-size: 12px;
          @include pc {
            font-size: 13px;
          }
        }
      }
    }
  }
  &__inner {
    @include tb {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 200px;
    }
  }
  &__columns {
    position: relative;
    padding-top: 24px;
    @include pc {
      display: flex;
      padding-top: 0;
      padding-bottom: 36px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 3px;
      content: "";
      background: url(/img/common/line_dot_gray.svg) repeat-x;
      @include pc {
        content: none;
      }
    }
  }
  &__column {
    padding-bottom: 26px;
    @include pc {
      width: 50%;
      padding-bottom: 0;
    }
    &.-left {
      border-bottom: 1px solid #bfbfbf;
      @include pc {
        padding-right: 4.5%;
        border-right: 1px solid #bfbfbf;
        border-bottom: none;
      }
    }
    &.-right {
      position: relative;
      padding-top: 27px;
      @include pc {
        padding-top: 0;
        padding-left: 4.5%;
      }
    }
  }
  &__title {
    @include font-noto_sans_medium;
    margin-bottom: 14px;
    font-size: 15px;
    letter-spacing: 0.16em;
    @include pc {
      margin-bottom: 24px;
      font-size: 16px;
    }
  }
  .c-checkbox {
    position: absolute;
    top: 26px;
    right: 10px;
    @include pc {
      top: 0;
    }
    &__label {
      @include font-noto_sans;
      font-size: 13px;
      @include pc {
        font-size: 14px;
      }
    }
  }
  .p-item {
    &__count {
      justify-content: flex-end;
      width: 100%;
      padding-top: 0;
      margin: 15px 0;
      border-top: none;
      @include pc {
        margin: 20px 0;
      }
    }
    &__amountText {
      font-size: 13px;
      @include pc {
        font-size: 15px;
      }
    }
    &__amountNumber {
      @include font-Montserrat_medium;
      color: #888;
    }
  }
  &__attention {
    @include font-noto_sans;
    font-size: 11px;
    line-height: (22/11);
    color: #e60012;
    letter-spacing: 0.14em;
    @include pc {
      margin-bottom: 18px;
      font-size: 12px;
      line-height: (18/12);
    }
  }
  &__notice {
    @include font-noto_sans;
    font-size: 11px;
    line-height: (22/11);
    letter-spacing: 0.14em;
    @include pc {
      font-size: 12px;
      line-height: (18/12);
    }
  }
}
