@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-top {
  & &Slider {
    position: relative;
    &__dots {
      position: absolute;
      left: 50%;
      bottom: 20px;
      transform: translateX(-50%);
      z-index: 2;
      font-size: 0;
      height: 8px;
      overflow: hidden;
      @include tb{
        height: 12px;
      }
      li {
        display: inline-block;
        width: 8px;
        height: 8px;
        @include tb{
          width: 12px;
          height: 12px;
        }
        &:not(:first-of-type) {
          margin-left: 8px;
          @include tb{
            margin-left: 12px;
          }
        }
        &.slick-active button {
          background: $c-primary;
        }
      }
      button {
        background: #cecece;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        @include tb{
          width: 12px;
          height: 12px;
        }
      }
    }
    &__nav {
      position: absolute;
      bottom: 20px;
      left: 50%;
      z-index: 2;
      height: 20px;
      @include tb{
        display: block;
      }
    }
    &.-main {
      max-height: 60vh;
      overflow: hidden;
      @include tb{
        max-height: 600px;
      }
      @include wide {
        background: #dee9ec;
      }
      .p-topSlider__wrap {
        width: 100%;
        max-width: 1440px;
        margin-right: auto;
        margin-left: auto;
      }
      .p-topSlider__item {
        max-height: 60vh;
        position: relative;
        vertical-align: top;
        @include tb{
          max-height: 600px;
        }
        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (750 / 750) * 100%;
          @include tb{
            max-height: 600px;
            padding-top: (600 / 1440) * 100%;
          }
        }
        a {
          display: block;
          max-height: 60vh;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          @include tb{
            max-height: 600px;
          }
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            width: auto;
            transform: translate(-50%,-50%);
            &.SPview {
              display: inline-block;
              @include tb{
                display: none;
              }
            }
            &.PCview {
              display: none;
              @include tb{
                display: inline-block;
              }
            }
          }
        }
      }
      .p-topSlider__nav {
        transform: translateX(-50%);
        @include tb{
          display: flex;
          align-items: center;
        }
      }
      .p-topSlider__dots {
        position: static;
        display: inline-block;
        transform: none;
        vertical-align: middle;
        margin: 0 25px;
        @include tb {
          order: 2;
        }
      }
      .p-topSlider__prev,
      .p-topSlider__next {
        display: none !important;
        @include pc {
          display: block !important;
        }
        transition: all 0.25s;
        cursor: pointer;
        height: 21px;
        width: 14px;
        text-indent: -9999px;
        overflow: hidden;
        vertical-align: middle;
        @include tb {
          display: inline-block;
          position: relative;
        }
        &:after {
          content: '';
          display: block;
          background: url(/img/common/icon_arrow_gray.svg)no-repeat;
          background-size: 100% auto;
          height: 21px;
          width: 14px;
          position: absolute;
          left: 0;
          top: 0;
        }
        &:hover {
          @include pc {

          }
        }
      }
      .p-topSlider__prev {
        @include tb {
          order: 1;
        }
        &:after {
          transform: rotate(-180deg);
        }
      }
      .p-topSlider__next {
        @include tb {
          order: 3;
        }
      }
    }
    &.-newarrival {
      position: relative;
      overflow: hidden;
      @include pc{
        //padding: 0 60px;
        overflow: visible;
      }
      .p-topSlider__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 5%;
        @include pc{
          margin: 0;
          width: 100%;
        }
      }
      .slick-slide {
        @include pc{
          margin-bottom: 1px;
        }
      }
      .p-topSlider__item {
        width: calc((100% - 15px) / 2);
        box-sizing: border-box;
        align-items: stretch;
        &:nth-child(2n+1) {
          margin-right: calc(15px / 2);
          @include pc {
            margin-right: 0;
          }
        }
        &:nth-child(2n) {
          margin-left: calc(15px / 2);
          @include pc {
            margin-left: 0;
          }
        }
        &:nth-child(n+3) {
          margin-top: 15px;
          @include pc {
            margin-top: 0;
          }
        }
        @include pc{
          width: 33.3%;
          padding: 0 15px;
          box-sizing: border-box;
        }
        a {
          display: block;
          text-decoration: none;
          border: 1px solid #cecece;
          height: 100%;
        }
        figure {
          span {
            display: block;
            border-bottom: 1px solid #cecece;
          }
        }
        figcaption {
          @include fz(12);
          line-height: (38 / 26);
          margin: 10px;
          letter-spacing: 0.12em;
          @include pc{
            @include vw(14);
            line-height: (25 / 14);
            margin: 25px 25px 25px 30px;
          }
          @include IE{
            overflow: hidden;
            line-height: (25 / 14);
            height: calc((25 / 14) * 2);
          }
        }
      }
      .p-topSlider__prev,
      .p-topSlider__next {
        display: none !important;
        @include pc {
          display: block !important;
        }
        background-color: #e60012;
        background-image: none;
        border-radius: 50%;
        transition: all 0.25s;
        position: absolute;
        top: 50%;
        margin-top: -50px;
        z-index: 10;
        cursor: pointer;
        background-size: 27px 44px;
        background-position: center;
        background-repeat: no-repeat;
        width: 100px;
        height: 100px;
        line-height: 500px;
        overflow: hidden;
        @include pc {
          display: block;
          height: 50px;
          top: 45%;
          width: 50px;
        }
        &:after {
          content: '';
          background: url(/img/common/icon_arrow_white.svg)no-repeat;
          background-size: 100% auto;
          height: 16px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
        }
        &:hover {
          @include pc {
            transform: scale(1.3);
          }
        }
      }
      .p-topSlider__prev {
        left: -38px;
        @include pc {
          left: 0;
          transform: translateX(-100%);
        }
        &:after {
          transform: translateY(-50%) rotate(-180deg);
          right: 15px;
          @include pc {
            left: 50%;
            transform: translate(-50%, -50%) rotate(-180deg);
          }
        }
        &:hover {
          transform: translateX(-100%) scale(1.3);
        }
      }
      .p-topSlider__next {
        right: -38px;
        @include pc {
          right: 0;
          transform: translateX(100%);
        }
        &:after {
          left: 15px;
          @include pc {
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &:hover {
          transform: translateX(100%) scale(1.3);
        }
      }
    }
  }
  & &Head {
    padding: 30px 0 40px;
    @include pc{
      padding: 30px 0;
    }
    .c-listBox__head {
      padding: 0;
      @include pc {
        padding: 22px 2.5% 20px;
      }
    }
  }
  & &Recommend {
    background: $c-base;
    padding-top: 22px;
    padding-bottom: 11px;
    @include pc{
      padding-top: 40px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &__title {
      text-align: center;
      @include pc{
        margin-bottom: 40px;
      }
      img {
        width: 97px;
        height: auto;
        @include pc{
          width: 147px;
        }
      }
      .jp {
        display: block;
        @include fz(27);
        line-height: 1;
        margin-top: 8px;
        @include font-noto_sans_light;
        @include pc{
          @include vw(42);
          margin-top: 10px;
        }
      }
      .note {
        color: #7f7f7f;
        display: block;
        @include fz(12);
        line-height: 1.6;
        margin-top: 10px;
        @include font-noto_sans_light;
        @include pc{
          @include vw(14);
          line-height: 1;
          margin-top: 15px;
        }
      }
    }
    .c-product {
      &:not(:nth-of-type(-n + 2)) {
        margin-top: 15px;
      }
      @include pc{
        &:nth-of-type(-n + 4) {
          margin-top: 0;
        }
        &:not(:nth-of-type(-n + 4)) {
          margin-top: 50px;
        }
      }
    }
    .p-list__inner {
      padding-bottom: 8px;
      @include pc{
        padding-bottom: 40px;
      }
    }
    .c-product__moreBtn {
      display: block;
      text-align: center;
      @include pc{
        border-top: 1px solid $c-border1;
      }
      a {
        display: block;
        padding: 5px 0 15px;
        position: relative;
        @include pc{
          padding: 22px 0;
        }
      }
      span {
        display: none;
        @include pc{
          display: inline-block;
          @include vw(14);
          line-height: 1;
        }
      }
      &.-close {
        a {
          &::after {
            content: "";
            background: url(/img/common/icon_arrow.svg) no-repeat 50%;
            background-size: contain;
            display: inline-block;
            width: 10px;
            height: 16px;
            transform: rotate(90deg);
            transform-origin: center;
            vertical-align: middle;
            @include pc{
              margin-left: 20px;
            }
          }
        }
      }
      &.-open {
        a {
          &::after {
            content: "";
            background: url(/img/common/icon_arrow.svg) no-repeat 50%;
            background-size: contain;
            display: inline-block;
            width: 10px;
            height: 16px;
            transform: rotate(-90deg);
            transform-origin: center;
            vertical-align: middle;
            @include pc{
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  & &Newarrival {
    padding: 40px 0;
    @include pc {
      max-width: 1440px;
      padding: 75px 4.16% 0;
      margin: 0 auto 80px;
    }
  }
  & &Movie {
    background: $c-base;
    padding: 36px 0 20px;
    @include pc{
      padding: 90px 0;
      margin-bottom: 0;
    }
    &__body {
      @include pc{
        @include clearfix;
        position: relative;
      }
    }
    .c-title {
      margin-bottom: 20px;
      @include pc{
        width: calc(45% - 50px);
        transform: translateY(calc(-50% - 40px));
        margin-bottom: 0;
        position: absolute;
        top: 50%;
      }
      @include pcIE{
        transform: translateY(-50%);
        margin-top: -40px;
      }
    }
    &__text {
      padding: 15px 20px;
      @include pc{
        width: calc(45% - 50px);
        position: absolute;
        top: calc(50% + 15px);
        padding: 0 0 0 40px;
        line-height: (25 / 14);
      }
    }
    &__thumb {
      @include pc{
        float: right;
        width: 55%;
      }
      a {
        display: block;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          background: url("/img/common/icon_play.svg") no-repeat 50%;
          background-size: contain;
          top: calc(50% - 30px);
          left: calc(50% - 30px);
          width: 60px;
          height: 60px;
          @include pc{
            top: calc(50% - 45px);
            left: calc(50% - 45px);
            width: 90px;
            height: 90px;
            transition: background 0.2s ease;
          }
        }
        @include pc{
          &:hover::after {
            background: url("/img/common/icon_play_on.svg") no-repeat 50%;
          }
        }
      }
    }
  }
  &Movie {
    &__modal {
      position:absolute;
      width:100%;
      padding-top:56.25%;
      top: 50%;
      transform: translateY(calc(-50% - 70px));
      @include tb{
        position:relative;
        top: 0;
        transform: translateY(0);
      }
      iframe {
        position:absolute;
        top:0;
        right:0;
        width:100%;
        height:100%;
      }
    }
  }
  & &Information {
    padding-top: 40px;
    @include pc{
      padding-top: 75px;
    }
  }
}
.p-topService {
  @at-root {
    & {
      padding-bottom: 23px;
      @include pc {
        padding-bottom: 70px;
      }
    }
    & .c-sec__inner {
      &::before {
        background-color: #cecece;
        content: '';
        display: block;
        height: 1px;
        margin-bottom: 30px;
        width: 100%;
        @include pc {
          margin-bottom: 80px;
        }
      }
    }
    .p-topServiceList {
      gap: 16px 17px;
      grid-template-columns: repeat(2, 1fr);
      @include tb {
        grid-template-columns: repeat(3, 1fr);
      }
      @include pc {
        gap: 26px;
      }
      &.hide-SP {
        display: none;
        @include pc {
          display: flex;
        }
      }
      &.hide-PC {
        display: grid;
        @include pc {
          display: none;
        }
      }
      & + & {
        margin-top: 26px;
      }
    }
    .p-topServiceList__item {
      @include pc {
        width: 100%;
      }
    }
    .p-topServiceList__link {
      @include ov;
    }
    .p-topServiceList__image {
    }
  }
}
.p-topTitle {
  @at-root {
    & {
      padding: 30px 5%;
      @include pc {
        padding-inline: 30px;
      }
    }
    .p-topTitle__h1 {
      margin: 0;
      text-align: center;
    }
    .p-topTitle__link {
      @include ov;
    }
    .p-topTitle__image {
      height: auto;
      margin-inline: auto;
      width: 100%;
      @include pc {
        max-width: 100%;
        width: auto;
      }
    }
  }
}
