@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-sec {
  &.-flesh {
    background: #fee5e0;
  }
  &.-blue {
    background: linear-gradient(34deg, rgb(249, 252, 255) 5.07%, rgba(196, 211, 233, 0.0260417) 36.66%, rgba(215, 229, 245, 0.43) 66.96%);
  }
  &.-lightBlue {
    background: #c6dff1;
  }
  &.-padding50 {
    padding-top: 20px;
    @include pc {
      padding-top: 50px;
    }
  }
  &.-padding80 {
    padding-bottom: 60px;
    padding-top: 50px;
    @include pc {
      padding-bottom: 90px;
      padding-top: 80px;
    }
  }
  &.-padding100 {
    padding-bottom: 40px;
    padding-top: 60px;
    @include pc {
      padding-bottom: 140px;
      padding-top: 100px;
    }
  }
  &.-padding60 {
    padding-top: 40px;
    @include pc {
      padding-top: 60px;
    }
  }
  &__inner {
    margin: 0 5%;
    @include pc {
      max-width: 1440px;
      padding: 0 3.15%;
      margin: 0 auto;
    }
    &.-small {
      @include pc {
        max-width: 950px;
        padding: 0;
      }
    }
    .p-top & {
      @include pc {
        padding: 0 4.16%;
      }
    }
  }
  &__title {
    flex: 0 0 auto;
    padding: 40px 0 32px;
    text-align: center;
    @include pc {
      padding: 63px 0 32px;
    }
    &.-line {
      @include font-noto_sans;
      padding: 2px 0 2px 10px;
      font-size: 18px;
      line-height: 1;
      text-align: left;
      letter-spacing: 0.16em;
      border-left: 5px solid $c-primary;
      @include pc {
        padding: 0 0 0 10px;
        font-size: 22px;
      }
      @at-root .l-container.-shopping h1.c-sec__title.-line {
        margin-top: 20px;
        @include pc {
          margin-top: 125px;
        }
      }
    }
    &JP {
      @include font-noto_sans;
      display: block;
      letter-spacing: 0.14em;
      @include fz(13);
      @include pc {
        @include vw(18);
      }
    }
  }
  &.-reserve {
    h1 {
      margin-top: 0;
    }
  }
}
