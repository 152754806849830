@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-contents {
  &:not(.-top):not(.p-cardUrl) {
    padding-top: 50px;
    @include pc {
      padding-top: 180px;
    }
  }
  &.-top {
    @include pc {
      padding-top: 5.75rem;
    }
  }
  &.-noPadding {
    padding-top: 0 !important;
  }
}
