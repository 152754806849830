@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};
//@include onlyTB{};
//@include onlyPC{};

/* */

br.SPbr {
  display: inline;
  @include pc {
    display: none;
  }
}

br.PCbr {
  display: none;
  @include pc {
    display: inline;
  }
}

.SPview {
  display: block;
  @include pc {
    display: none;
  }
}

.SPonly_view {
  display: none;
  @include sp {
    display: block;
  }
}

.PCview {
  display: none;
  @include pc {
    display: block;
  }
}

.u-textCenter {
  text-align: center;
}

.u-textCenterPC {
  @include pc {
    text-align: center;
  }
}

.u-textLeft {
  text-align: left;
}

.u-textLeftPC {
  @include pc {
    text-align: left;
  }
}

.u-textRight {
  text-align: right;
}

.u-textRightPC {
  @include pc {
    text-align: right;
  }
}

.u-floatCenter {
  float: left;
}

.u-floatLeft {
  float: left;
}

.u-floatRight {
  float: right;
}

.u-weightNoto200 {
  @include font-noto_sans_light;
}

.u-weightNoto300 {
  @include font-noto_sans;
}

.u-weightNoto400 {
  @include font-noto_sans_regular;
}

.u-weightNoto500 {
  @include font-noto_sans_medium;
}

.u-weightNoto700 {
  @include font-noto_sans_bold;
}

.u-weightNoto900 {
  @include font-noto_sans_black;
}

[v-cloak] {
  display: none !important;
}
