@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/
.l-kv.-text.-care {
  .l-kv__title {
    @media screen and (min-width: 980px) and (max-width: 1200px) {
      font-size: 32px;
    }
    &::after {
      display: none;
    }
  }
}

.p-care {
  & &Wrap {
    padding: 0 20px;
    @include pc{
      padding: 0 60px;
    }
    &.-blue {
      background: #eef3f6;
    } // &.-blue

    // Common Module
    h2.section-title {
      @include font-noto_sans_regular;
      font-size: 20px;
      margin-bottom: 10px;
      text-align: center;
      letter-spacing: 0.09em;
      @include pc {
        @include font-noto_sans_light;
        font-size: 32px;
        margin-bottom: 8px;
      }
    }
    p.section-lead {
      @include font-noto_sans;
      font-size: 14px;
      margin-bottom: 20px;
      letter-spacing: 0.09em;
      @include pc {
        font-size: 16px;
        margin-bottom: 43px;
        text-align: center;
      }
    }


    #section-01 {
      padding-top: 40px;
      padding-bottom: 50px;
      @include pc {
        padding-top: 65px;
        padding-bottom: 80px;
      }

      ol.care-list {
        background-color: #FFF;
        border: 2px #fde5e0 solid;
        counter-reset: care-list;
        padding: 22px 20px 24px;

        @include pc {
          display: flex;
          justify-content: space-between;
          padding: 40px 45px;
        }

        li.care-item {
          display: flex;
          flex-direction: column;
          justify-self: center;
          font-size: 13px;
          letter-spacing: 0.09em;

          @include pc {
            font-size: 14px;
            text-align: center;
            width: calc(100% - 160px / 3);
          }

          &:nth-child(n+2) {
            margin-top: 20px;
            @include pc {
              margin-top: 0;
            }
          }

          &:nth-child(1) {
            @include pc {
              margin-right: 40px;
            }
          }
          &:nth-child(2) {
            @include pc {
              margin-left: 40px;
              margin-right: 40px;
            }
          }
          &:nth-child(3) {
            @include pc {
              margin-left: 40px;
            }
          }

          &::before {
            counter-increment: care-list;
            content:  counter(care-list)".";
            @include font-Montserrat_extralight;
            display: inline-block;
            color: #e60012;
            font-size: 44px;
            line-height: 1em;
            margin-bottom: 15px;
            text-align: center;
          } // &::before
        }
      }
    } // #section-01

    #section-02 {
      padding-top: 40px;
      padding-bottom: 55px;
      @include pc {
        padding-top: 65px;
        padding-bottom: 80px;
      }

      div.maintenance-list-wrap {
        background-color: #FFF;
        border: 1px solid $c-border1;
        padding-top: 20px;
        padding-bottom: 22px;
        @include pc {
          border: 2px solid $c-border1;
          padding-top: 60px;
          padding-bottom: 60px;
          margin: auto;
          max-width: 1100px;
        }
        p.image {
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 30px;
          margin-left: auto;
          margin-right: auto;
          max-width: 790px;
        }

        ol.maintenance-list {
          counter-reset: maintenance-list;
          padding-left: 20px;
          padding-right: 20px;
          @include pc {
            padding-left: 100px;
            padding-right: 100px;
          }
          li.maintenance-item {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-self: center;

            @include pc {
              flex-direction: row;
            }

            &:nth-child(n+2) {
              border-top: 1px solid #cecece;
              padding-top: 20px;
              @include pc {
                padding-top: 16px;
              }
            }

            &:not(:last-child) {
              padding-bottom: 20px;
              @include pc {
                padding-bottom: 16px;
              }
            }

            h3.title {
              @include font-noto_sans_medium;
              display: flex;
              align-items: center;
              font-size: 16px;
              margin-bottom: 10px;
              @include pc {
                @include font-noto_sans_regular;
                font-size: 20px;
                margin-right: 20px;
                margin-bottom: 0;
                width: 210px;
              }

              &::before {
                background-color: #98b1b6;
                border-radius: 50%;
                counter-increment: maintenance-list;
                content:  counter(maintenance-list);
                @include font-Montserrat_medium;
                display: inline-block;
                color: #FFF;
                text-align: center;
                font-size: 10px;
                line-height: 19px;
                margin-right: 10px;
                height: 19px;
                width: 19px;
                @include pc {
                  font-size: 16px;
                  line-height: 30px;
                  margin-right: 20px;
                  height: 30px;
                  width: 30px;
                }
              } // &::before
            }

            p.text {
              @include font-noto_sans;
              font-size: 13px;
              letter-spacing: 0.09em;
              @include pc {
                @include font-noto_sans_light;
                font-size: 14px;
                width: calc(100% - 210px - 20px);
              }
              a {
                font-size: 10px;
                @include pc {
                }
              }
            }

          }
        }

        p.note {
          @include font-noto_sans;
          letter-spacing: 0.09em;
          font-size: 11px;
          padding-top: 25px;
          padding-left: 20px;
          padding-right: 20px;
          @include pc {
            @include font-noto_sans_light;
            font-size: 12px;
            padding-top: 30px;
            padding-left: 100px;
            padding-right: 100px;
          }
        }
      }

    } // #section-02

    #section-03 {
      padding-top: 40px;
      padding-bottom: 45px;
      @include pc {
        padding-top: 65px;
        padding-bottom: 80px;
      }

      h2.section-title {
        &:nth-of-type(n+2) {
          border-top: 1px solid #cecece;
          padding-top: 45px;
          margin-top: 45px;
          @include pc {
            padding-top: 70px;
            margin-top: 85px;
          }
        }
      }

      div.comparison-table-wrap {
        margin: auto;
        width: 290px;
        @include pc {
          width: 505px;
          max-width: 505px;
        }
        table.comparison-table {
          width: 100%;
          thead {
            tr {
              th {
                @include font-noto_sans_medium;
                line-height: 1em;
                text-align: center;
                font-size: 11px;
                padding-bottom: 10px;
                @include pc {
                  @include font-noto_sans_regular;
                  font-size: 18px;
                  padding-bottom: 18px;
                }
              }
            }
          }
          tbody {
            tr {

              &:nth-child(n+2) {
                th,td {
                  padding-top: 12px;
                  @include pc {
                    padding-top: 20px;
                  }
                }
              }

              th {
                vertical-align: middle;
                padding-right: 25px;
                @include pc {
                  padding-right: 40px;
                }
                p {
                  display: flex;
                  align-items: center;
                  span.icon {
                    margin-right: 10px;
                    height: 27px;
                    width: 27px;
                    @include pc {
                      margin-right: 18px;
                      height: 47px;
                      width: 47px;
                    }
                    img {
                      width: 100%;
                    }
                  }
                  span.text {
                    @include font-noto_sans_medium;
                    text-align: left;
                    font-size: 12px;
                    width: calc(100% - 27px - 10px);
                    @include pc {
                      @include font-noto_sans_regular;
                      font-size: 20px;
                      width: calc(100% - 47px - 20px);
                    }
                  }
                }
              }
              td {
                span.image {
                  border: 1px solid #dbdbdb;
                  display: block;
                  width: 65px;
                  @include pc {
                    width: 112px;
                  }
                }
                &.arrow {
                  width: 30px;
                  @include pc {
                    width: 54px;
                  }
                  span.arrow-icon {
                    background: url("/img/common/icon_arrow_bottom_double.svg") no-repeat center;
                    display: block;
                    margin: auto;
                    transform: rotate(-90deg);
                    height: 8px;
                    width: 12px;
                    @include pc {
                      height: 14px;
                      width: 23px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      div.maintenanceTime-table-wrap {
        max-width: 653px;
        margin: auto;
        table.maintenanceTime-table {
          border-spacing: 2.5px;
          width: 100%;
          @include pc {
            border-spacing: 8px;
          }

          thead {
            tr {
              th {
                @include font-noto_sans_medium;
                font-size: 10px;
                line-height: 1em;
                padding-top: 12px;
                padding-bottom: 12px;

                @include pc {
                  @include font-noto_sans_regular;
                  font-size: 16px;
                  line-height: 1em;
                  padding-top: 24px;
                  padding-bottom: 24px;
                }

                &:nth-child(n+2) {
                  border: 1px solid #98b1b6;
                }
              }
            }
          }

          tbody {
            tr {
              th,td {
                border: 1px solid #98b1b6;
              }
              th {
                padding: 5px;
                width: 80px;
                @include pc {
                  padding: 15px 10px;
                  width: 152px;
                }
                p {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  span.name {
                    @include font-noto_sans_medium;
                    font-size: 10px;
                    padding-bottom: 5px;
                    @include pc {
                      @include font-noto_sans_regular;
                      font-size: 16px;
                      padding-bottom: 10px;
                    }
                    i {
                      font-size: 0.8em;
                      font-style:normal;
                    }
                  }
                }
              }
              td {
                vertical-align: top;
                padding: 5px;
                width: 60px;
                @include pc {
                  padding: 8px 8px 7px 8px;
                  width: 116px;
                }
                & > ul.icon-list {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  li {
                    display: block;
                    width: calc((100% - 5px) / 2);
                    @include pc {
                      width: calc((100% - 8px) / 2);
                    }
                    &:nth-child(n+3) {
                      margin-top: 5px;
                      @include pc {
                        margin-top: 8px;
                      }
                    }
                    &:nth-child(2n-1) {
                      margin-right: 2.5px;
                      @include pc {
                        margin-right: 4px;
                      }
                      img {
                        margin-left: auto;
                        margin-right: 0;
                        display: block;
                      }
                    }
                    &:nth-child(2n) {
                      margin-left: 2.5px;
                      @include pc {
                        margin-left: 4px;
                      }
                      img {
                        margin-left: 0;
                        margin-right: auto;
                        display: block;
                      }
                    }
                    img {
                      @include pc {
                        height: 47px;
                        width: 47px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        div.description-wrap {
          & > ul.icon-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 5px;
            @include pc {
              margin-left: 10px;
            }
            li {
              display: flex;
              align-items: center;
              margin: 5px 10px;
              @include pc {
                margin: 8px 10px;
              }

              span.icon {
                margin-right: 5px;
                height: 20px;
                width: 20px;
                @include pc {
                  margin-right: 7px;
                  height: 40px;
                  width: 40px;
                }
              }
              span.text {
                @include font-noto_sans;
                font-size: 11px;
                @include pc {
                  font-size: 14px;
                }
              }
            }
          }
          p.note {
            @include font-noto_sans;
            font-size: 11px;
            margin-top: 8px;
            margin-left: 5px;
            letter-spacing: 0.09em;
            @include pc {
              font-size: 12px;
              margin-top: 12px;
              margin-left: 20px;
              line-height: 22px;
            }
          }
        }
      }
    } // #section-03

    #section-04 {
      padding-top: 45px;
      padding-bottom: 50px;
      @include pc {
        padding-top: 70px;
        padding-bottom: 80px;
      }
    } // #section-04

    #section-05 {
      padding-top: 45px;
      padding-bottom: 50px;
      @include pc {
        padding-top: 70px;
        padding-bottom: 80px;
        border-bottom: 1px solid #cecece;
      }

      div.table-wrap {
        letter-spacing: 0.09em;
        width: 100%;
        &:nth-of-type(n+2) {
          margin-top: 30px;
          @include pc {
            margin-top: 40px;
          }
        }

        h3.table-title {
          @include font-noto_sans_medium;
          text-align: center;
          font-size: 14px;
          margin-bottom: 8px;
          @include pc {
            @include font-noto_sans_regular;
            font-size: 20px;
            margin-top: 25px;
            margin-bottom: 25px;
          }
        }

        table {
          border-collapse: collapse;
          margin: auto;
          width: 100%;
          tr {
            th,td {
              border: 1px solid #dbdbdb;
              padding: 18px 8px;
              @include pc {
                padding: 18px 24px;
              }
            }
            th {
              @include font-noto_sans_medium;
              background-color: #f7f7f7;
              text-align: left;
              font-size: 12px;
              min-width: 90px;
              @include pc {
                @include font-noto_sans_regular;
                font-size: 16px;
                min-width: 296px;
              }
            }
            td {
              @include font-noto_sans_regular;
              text-align: left;
              font-size: 11px;
              @include pc {
                @include font-noto_sans_light;
                font-size: 14px;
              }
            }
          }
        }
      }

      div.link-wrap {
        margin-top: 50px;
        @include pc {
          margin-top: 80px;
        }

        h3.title {
          @include font-noto_sans_medium;
          text-align: center;
          font-size: 13px;
          margin-bottom: 10px;
          @include pc {
            font-size: 20px;
            margin-bottom: 15px;
          }
        }

        .c-btnWrap {
          margin: auto;
          .c-btn {
            margin: auto;
            max-width: 330px;
          }
        }
      }

    } // #section-05

    #section-06 {
      @include pc {
        padding-top: 65px;
      }
    } // #section-06
  } // & &Wrap
} // .p-care
