$cocoroto-brown: #823b2b;
$cocoroto-pink: #f7a4b1;
$gentlegaze-black: #000;
$gentlegaze-gray: #cdcdcd;
$gentlegaze-white: #fff;
@function svw($pxValue) {
  @return ($pxValue / 375 * 100vw);
}
@function vw($pxValue) {
  @return ($pxValue / 1440 * 100vw);
}
.p-gentlegaze {
  @include font-noto_sans;
  overflow: hidden;
  &-contents {
    padding-top: 0 !important;
    @include pc {
      padding-top: 90px !important;
    }
  }
  &-inner {
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  &-background {
    background: url("/img/gentlegaze/point_sp.jpg") no-repeat left top / cover;
    @include pc {
      background-image: url("/img/gentlegaze/point_pc.jpg");
    }
  }
  /*---------------------
  .hero
  -----------------------*/
  .hero {
    position: relative;
  }
  .hero-image {
    width: 100%;
    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      @include pc {
        aspect-ratio: 288 / 109;
      }
    }
  }
  .hero-image-pc {
    display: none;
    @include pc {
      display: block;
    }
  }
  .hero-image-sp {
    display: block;
    @include pc {
      display: none;
    }
  }
  .hero-textbox {
    background: url("/img/gentlegaze/hero_bg_sp.png") no-repeat center center / cover;
    background-blend-mode: multiply;
    color: $gentlegaze-white;
    padding-block: 40px 50px;
    @include pc {
      background-image: url("/img/gentlegaze/hero_bg_pc.png");
      padding: 80px 0;
    }
  }
  .hero-textbox-inner {
    max-width: 1225px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    @include pc {
      // display: flex;
      // align-items: center;
    }
  }
  .hero-heading {
    width: 100%;
    text-align: center;
    h1 {
      @include font-noto_sans;
      font-size: 28px;
      margin: 0 0 10px 0;
      letter-spacing: 1.68px;
      line-height: 1.5;
      @include pc {
        font-size: 40px;
        letter-spacing: 2px;
      }
    }
    strong {
      @include font-Montserratfont-Montserrat;
      font-size: 16px;
      @include pc {
        font-size: 20px;
      }
    }
  }
  .hero-text {
    width: 100%;
    font-size: 14px;
    margin-block-start: 45px;
    line-height: calc(25 / 14);
    @include pc {
      font-size: 16px;
      letter-spacing: 0.96px;
      line-height: calc(29 / 16);
      margin-block-start: 60px;
      text-align: center;
    }
    p {
      font-size: 14px;
      @include pc {
        font-size: 16px;
      }
    }
  }
  /*---------------------
  .point
  -----------------------*/
  .point {
    color: $gentlegaze-black;
    padding: 30px 0 70px 0;
    position: relative;
    //background-color: #EDF3F5;
    @include pc {
      padding: 70px 0 170px 0;
    }
    &-inner {
      max-width: 1225px;
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
    }
    &-heading {
      position: relative;
      @include font-Montserrat;
      font-size: 30px;
      text-align: center;
      text-transform: uppercase;
      @include pc {
        font-size: 40px;
      }
      &::after {
        content: "";
        display: block;
        width: 16px;
        height: 1px;
        background-color: #333333;
        margin: 15px auto 0 auto;
        @include pc {
          width: 20px;
          margin: 20px auto 0 auto;
        }
      }
    }
    &-list {
      margin: 55px auto 0 auto;
      max-width: 500px;
      width: 100%;
      @include pc {
        max-width: 1038px;
        margin: 90px auto 0 auto;
      }
    }
    &-item {
      position: relative;
      counter-increment: itemNum;
      @include pc {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      + .point-item {
        margin: 75px 0 0 0;
        @include pc {
          margin: 115px 0 0 0;
        }
      }
      &:nth-child(even) {
        @include pc {
          flex-direction: row-reverse;
        }
        &::before {
          @include pc {
            left: auto;
            right: 30px;
          }
        }
      }
      &::before {
        border-top: 3px solid $gentlegaze-gray;
        content: counter(itemNum, decimal-leading-zero);
        @include font-Montserrat_extralight;
        font-size: 26px;
        letter-spacing: 0.08em;
        position: absolute;
        top: -20px;
        left: 16px;
        z-index: 1;
        @include pc {
          font-size: 36px;
          top: -28px;
          left: 30px;
        }
      }
    }
    &-image {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
      max-width: 515px;
      width: 100%;
      @include pc {
        box-shadow: 10px 13px 29px 0 rgba(0, 0, 0, 0.10);
      }
      img {
        aspect-ratio: 521 / 294;
        width: 100%;
      }
    }
    &-textbox {
      width: 100%;
      padding: 20px 0 0 0;
      @include pc {
        width: calc(100% - 515px);
        padding: 0 0 0 60px;
      }
    }
    &-item:nth-child(even) {
      .point-textbox {
        @include pc {
          padding: 0 60px 0 0;
        }
      }
    }
    &-title {
      @include font-noto_sans;
      font-size: 20px;
      @include pc {
        font-size: 28px;
      }
    }
    &-text {
      font-size: 14px;
      margin: 15px 0 0 0;
      letter-spacing: 0.84px;
      line-height: calc(25 / 14);
      text-align: justify;
      @include pc {
        margin: 20px 0 0 0;
      }
    }
  }
  /*---------------------
  .lineup
  -----------------------*/
  .lineup {
    margin-block-start: 50px;
    padding: 0 0 40px 0;
    @include pc {
      margin-block-start: 100px;
      padding: 0 0 100px 0;
    }
    &-head {
      align-items: flex-end;
      aspect-ratio: 375 / 214;
      background: url("/img/gentlegaze/lineup_01_sp.jpg") no-repeat center center / cover;
      display: flex;
      margin-block-end: 40px;
      position: relative;
      @include pc {
        align-items: center;
        aspect-ratio: 18 / 5;
        background-image: url("/img/gentlegaze/lineup_01_pc.jpg");
        margin-block-end: 100px;
        padding-inline: vw(160);
      }
      &-title {
        background: linear-gradient(91deg, rgba(0, 0, 0, 0.70) 66.19%, rgba(36, 36, 36, 0.00) 110.11%);
        background-blend-mode: multiply;
        @include font-Montserrat;
        color: $gentlegaze-white;
        font-size: 41px;
        font-weight: 200;
        letter-spacing: 2.46px;
        line-height: 1;
        margin: 0;
        padding: 0.25em 0.5em;
      }
    }
    .p-cocoroto-inner + .lineup-head {
      margin-top: 60px;
      @include pc {
        margin-top: 80px;
      }
    }
    &-visual {
      margin-bottom: 40px;
      img {
        aspect-ratio: 375 / 214;
        @include pc {
          aspect-ratio: 67 / 20;
        }
      }
    }
    &-heading {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      @include pc {
        width: 50%;
      }
      img {
        width: 270px;
        @include pc {
          width: 436px;
        }
      }
    }
    &-image {
      img {
        width: 100%;
      }
    }
    &-block {
      position: relative;
      margin: 40px 0 0 0;
      @include pc {
        margin: 80px 0 0 0;
      }
    }
    &-title {
      position: relative;
      @include font-Montserrat;
      font-size: 30px;
      text-align: center;
      text-transform: uppercase;
      z-index: 1;
      @include pc {
        font-size: 40px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #333333;
        transform: translate(0, -50%);
        z-index: -1;
      }
      span {
        display: inline-block;
        background-color: $gentlegaze-white;
        padding: 0 30px;
      }
      small {
      }
    }
    &-subTitle {
      color: $gentlegaze-black;
      text-align: center;
      font-size: 16px;
      font-weight: 350;
      line-height: calc(23 / 16);
      letter-spacing: 0.96px;
      margin-block-start: 10px;
      @include pc {
        font-size: 20px;
        line-height: calc(25 / 20);
        letter-spacing: 1.2px;
      }
    }
    &-lead {
      color: $gentlegaze-black;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 350;
      line-height: calc(23 / 13);
      letter-spacing: 0.78px;
      @include pc {
        font-size: 14px;
        line-height: calc(25 / 14);
        letter-spacing: 0.84px;
      }
    }
    &-banner {
      margin: 20px 0 0 0;
      @include pc {
        margin: 100px 0 0 0;
      }
      a {
        transition: opacity 250ms;
        &:hover {
          opacity: 0.75;
        }
      }
      img {
        width: 100%;
      }
    }
    .product-list {
      position: relative;
      z-index: 1;
      margin: 30px 0 0 0;
      @include pc {
        margin: 85px 0 0 0;
      }
      .c-product-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        margin: 25px 12px 0 12px;
        @include pc {
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: nowrap;
          justify-content: center;
          max-width: 1024px;
          width: 100%;
          margin: 35px auto 0 auto;
        }
        li {
          position: relative;
          max-width: 305px;
          width: 100%;
          margin: 10px 0;
          padding-bottom: 70px;
          border: 1px solid #bad3d6;
          background-color: #fff;
          @include pc {
            width: calc(50% - 15px);
            margin: 0 15px;
            padding-bottom: 0;
            border: none;
          }
          .c-productLink {
            border: none;
            @include pc {
              border: 1px solid #bad3d6;
            }
          }
          .product-btn {
            position: absolute;
            bottom: 20px;
            left: 50%;
            max-width: 295px;
            width: 100%;
            height: 44px;
            padding: 0 20px;
            margin: 20px 0 0 0;
            transform: translateX(-50%);
            @include pc {
              display: none;
            }
            a {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              background-color: #E50213;
              font-size: 13px;
              border-radius: 44px;
              text-decoration: none;
            }
          }
        }
        .c-product {
          width: 100%;
          &.-new {
            &::before {
              content: "";
              background-image: url("../img/atokyo/new-icon.png");
              background-size: 66px 66px;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 66px;
              height: 66px;
              z-index: 2;
            }
          }
        }
      }
    }
    // .product-list {
    //   margin: 30px 0 0 0;
    // }
    // .c-product-list {
    //   display: flex;
    //   flex-wrap: wrap;
    //   margin: 25px -10px 0 -10px;
    //   @include pc {
    //     flex-direction: row;
    //     align-items: flex-start;
    //     flex-wrap: nowrap;
    //     justify-content: center;
    //     max-width: 1024px;
    //     width: 100%;
    //     margin: 35px auto 0 auto;
    //   }
    //   li {
    //     position: relative;
    //     width: calc(50% - 20px);
    //     margin: 0 10px 20px 10px;
    //     background-color: #fff;
    //     @include pc {
    //       max-width: 315px;
    //       width: 100%;
    //       margin: 0 10px;
    //     }
    //     .c-product {
    //       width: 100%;
    //     }
    //   }
    // }
  }
  /*---------------------
  .guide
  -----------------------*/
  .p-cocoroto-guide {
    padding: 40px 0;
    background: url("/img/cocoroto/talk_bg_sp.jpg") no-repeat center top / cover;
    @include pc {
      background-image: url("/img/cocoroto/talk_bg_pc.jpg");
      padding: 80px 0;
    }
  }
  .p-cocoroto-guide-heading {
    color: $cocoroto-brown;
    text-align: center;
  }
  .p-cocoroto-guide-heading-subTitle {
    font-size: 18px;
    margin-bottom: 1em;
  }
  .p-cocoroto-guide-heading-title {
    @include font-Montserrat;
    font-size: 21px;
    margin: 0;
    @include pc {
      font-size: 32px;
    }
  }
  .p-cocoroto-guide-heading-name {
    font-size: 12px;
    margin-top: 1em;
    @include pc {
      font-size: 18px;
    }
  }
  .p-cocoroto-guide-content {
    padding: 25px 20px 40px 20px;
    margin: 30px 0 0 0;
    background-color: #fff;
    border: 1px solid #ffa5bb;
    @include pc {
      margin: 60px 0 0 0;
      padding: 60px 140px 80px 140px;
    }
  }
  .p-cocoroto-guide-lead {
    font-size: 13px;
    margin-bottom: 40px;
    @include pc {
      @include font-noto_sans_medium;
      font-size: 16px;
      margin-bottom: 70px;
      text-align: center;
    }
  }
  .p-cocoroto-guide-faq {
    display: flex;
    flex-direction: column;
    gap: 40px 0;
  }
  .p-cocoroto-guide-faq-list {
    &::before {
      background: linear-gradient(to right, #e60012 50%, #bfbfbf 50%, #bfbfbf 100%);
      content: "";
      display: block;
      height: 1px;
      margin-bottom: 15px;
      width: 200px;
      @include pc {
        margin-bottom: 20px;
      }
    }
  }
  .p-cocoroto-guide-faq-head,
  .p-cocoroto-guide-faq-body {
    display: flex;
    gap: 0 10px;
    &::before {
      align-items: center;
      border-radius: 50%;
      @include font-noto_sans_medium;
      flex-shrink: 0;
      font-size: 16px;
      display: flex;
      height: 26px;
      justify-content: center;
      letter-spacing: 0;
      line-height: 1;
      text-align: center;
      width: 26px;
    }
  }
  .p-cocoroto-guide-faq-head {
    font-size: 18px;
    @include font-noto_sans_medium;
    @include pc {
      font-size: 22px;
    }
    &::before {
      background-color: $cocoroto-pink;
      color: #fff;
      content: "Q";
      margin-top: 5px;
    }
  }
  .p-cocoroto-guide-faq-body {
    font-size: 13px;
    line-height: calc(23 / 13);
    margin-top: 10px;
    text-align: justify;
    @include pc {
      font-size: 14px;
      margin-top: 20px;
    }
    &::before {
      background-color: #fee7e6;
      color: #e97c97;
      content: "A";
    }
  }
}
