@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-glassesLens {
  p + p {
    margin-top: 0;
  }
  .image.-wide {
    @include wide {
      max-width: 1440px;
      margin: auto;
    }
  }
  &Wrap {
    margin: auto;
    &.-blue {
      background: #eef3f6;
    }
    #section-01 {
      padding: 40px 20px 60px 20px;
      @include pc {
        padding: 50px 40px 140px 40px;
      }
    }
    #section-02 {
      padding: 40px 20px 50px 20px;
      @include pc {
        padding: 50px 40px 90px 40px;
      }
      @include IE {
        padding: calc(50px + 5.75rem) 40px 90px 40px;
      }
    }
    #section-05 {
      padding-top: 60px;
      @include pc {
        padding-top: 100px;
      }
    }
    .section {
      padding: 40px 20px;
      max-width: 1440px;
      margin: auto;
      @include pc {
        padding: 50px 40px;
      }
      .title {
        font-size: 20px;
        text-align: center;
        margin-bottom: 0;
        @include font-noto_sans_light;
        @include pc {
          font-size: 32px;
          line-height: 1.4em;
        }
        .small {
          font-size: 18px;
        } // .small
      } // .title
      .title-large {
        @include pc {
          font-size: 48px;
          letter-spacing: 0.1em;
          margin-bottom: 50px;
        }
      }
      .lead {
        font-size: 14px;
        line-height: 180%;
        margin-top: 1em;
        @include font-noto_sans_light;
        @include pc {
          font-size: 16px;
          text-align: center;
        }
        &-large {
          font-size: 14px;
          line-height: 180%;
          @include pc {
            font-size: 24px;
          }
        }
      } // lead
      .text {
        font-size: 14px;
        @include font-noto_sans_light;
        @include pc {
          text-align: center;
        }
      } // .text
      .store-text {
        font-size: 13px;
        text-align: center;
        @include font-noto_sans_light;
        margin-top: 15px;
        @include pc {
          font-size: 16px;
          margin-top: 20px;
        }
      } // store-text
      // LIST START
      .price {
        &-list {
          border: 2px solid #fee5e0;
          padding: 30px 20px;
          margin-top: 25px;
          counter-reset: price-list;
          @include pc {
            display: flex;
            justify-content: space-between;
            padding: 40px;
            margin-top: 40px;
          }
        } // &-list
        &-item {
          position: relative;
          @include pc {
            width: calc(100%/3 - 60px);
          }
          .head {
            padding-bottom: 15px;
            .title {
              font-size: 18px;
              padding-bottom: 20px;
              @include font-noto_sans_regular;
              position: relative;
              @include pc {
                font-size: 22px;
              }
              &::before {
                counter-increment: price-list;
                content: counter(price-list) ".";
                display: block;
                color: $c-primary;
                font-size: 44px;
                text-align: center;
                position: absolute;
                top: -22px;
                left: 0;
                @include font-Montserrat;
                @include pc {
                  position: static;
                  top: 0;
                  height: 44px;
                }
              } // &::after
            } // .title
            .text {
              font-size: 13px;
              @include pc {
                font-size: 14px;
              }
            }
          } // .head
          .foot {
            background: #fee5e0;
            padding: 8px;
            border-radius: 20px;
            .price {
              font-size: 14px;
              text-align: center;
              @include font-noto_sans_light;
            }
          } // foot
          &:not(:last-child) {
            margin-bottom: 80px;
            @include pc {
              margin-bottom: 0;
            }
          }
          &:not(:last-child)::before,
          &:not(:last-child)::after {
            background: #000;
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
          }
          &:not(:last-child)::before {
            height: 1px;
            width: 36px;
            bottom: calc(-18px + -20px);
            @include pc {
              top: 0;
              bottom: 0;
              left: auto;
              right: calc(-36px + -30px);
            }
          }
          &:not(:last-child)::after {
            height: 36px;
            width: 1px;
            bottom: calc(-36px + -20px);
            @include pc {
              top: 0;
              bottom: 0;
              left: auto;
              right: calc(-18px + -30px);
            }
          }
        } // &-item
      } // .price
      .step {
        &-list {
          counter-reset: step-list;
          margin-top: 20px;
          @include pc{
            margin-top: 40px;
          }
        } // &-list
        &-item {
          background: #fff;
          border: 1px solid$c-border1;
          padding: 30px 20px;
          position: relative;
          @include pc {
            display: flex;
            align-items: center;
            padding: 40px;
          }
          .head {
            margin-bottom: 20px;
            @include pc {
              margin-bottom: 0;
              width: 30%;
            }
            .title {
              font-size: 18px;
              @include font-noto_sans_regular;
              position: relative;
              @include pc {
                font-size: 22px;
                padding: 0 0 0 44px;
              }
              &.-multiple {
                @include sp {
                  margin-top: -10px;
                }
              }
            } // .title
          } // .head
          .foot {
            @include pc {
              width: calc(70% - 20px);
              padding-left: 20px;
            }
            .description {
              font-size: 13px;
              line-height: 1.8em;
              @include font-noto_sans_light;
              @include pc {
                font-size: 14px;
              }
              .-red {
                color: $c-primary;
              }
            }
          } // .foot
          &::before {
            counter-increment: step-list;
            content: counter(step-list) ".";
            display: block;
            color: $c-border1;
            font-size: 44px;
            height: 44px;
            text-align: center;
            position: absolute;
            top: 20px;
            left: 20px;
            line-height: 1;
            @include font-Montserrat;
            @include pc {
              top: auto;
              left: 40px;
              margin: auto;
              @include IE {
                transform: translateY(-0.6em);
              }
            }
          } // &::before
          &:not(:last-child) {
            margin-bottom: 60px;
          }
          &:not(:last-child)::after {
            background: url(/img/common/icon_arrow_bottom_double.svg) no-repeat center;
            background-size: 100% auto;
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -60px;
            margin: auto;
            height: 60px;
            width: 30px;
          }
        } // &-item
      } // .step
      .want {
        &-list {
          margin-top: 20px;
          @include pc {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
          }
        } // &-list
        &-item {
          background: #fff;
          border: 1px solid #c7c7c7;
          padding: 30px 20px;
          @include pc {
            padding: 90px 40px;
            width: calc(100%/3 - 30px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .title {
            color: $c-primary;
            font-size: 16px;
            @include font-noto_sans_regular;
            padding-bottom: 10px;
            line-height: 1.6em;
            @include pc {
              font-size: 22px;
            }
          }
          .text {
            font-size: 13px;
            text-align: center;
            padding-bottom: 15px;
            @include pc {
              font-size: 14px;
              padding-bottom: 30px;
            }
          }
          &:not(:last-child) {
            margin-bottom: 20px;
            @include pc {
              margin-bottom: 0;
            }
          }
        } // &-item
      } // .want
      // LIST END
      &.-campaign {
        max-width: 678px;
        margin: auto;
        @include pc {
          max-width: 950px;
        }
        .banner {
          text-align: center;
          img {
            width: 100%;
          }
        }
      } // &-campaign
    } // .section
  } // &Wrap
  &__modal {
    .modal-head {
      padding: 50px 20px 20px;
      @include pc {
        padding: 60px 80px 40px;
      }
      .title {
        font-size: 20px;
        text-align: center;
        line-height: 1.4;
        @include font-noto_sans_light;
        @include pc {
          font-size: 32px;
        }
      }
      .lead {
        font-size: 13px;
        margin-top: 1em;
        line-height: 1.8;
        @include font-noto_sans;
        @include tb{
          text-align: center;
        }
        @include pc {
          font-size: 16px;
        }
      }
      .text {
        font-size: 13px;
        margin-top: 1em;
        line-height: 1.8;
        @include font-noto_sans_light;
        @include pc {
          font-size: 14px;
        }
      }
    } // .modal-head
    .modal-body {
      padding: 0 20px 20px;
      @include pc {
        padding: 0 80px 60px;
      }
      .card {
        &-list {
          &.-type01 {
            @include tb {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              margin-right: -10px;
              margin-left: -10px;
            }
            @include pc {
              display: flex;
              margin-right: -20px;
              margin-left: -20px;
            }
            @media screen and (min-width: $point-pc) and (max-width: 1259px) {
              justify-content: center;
              flex-wrap: wrap;
            }
            @media screen and (min-width: 1260px) {
              justify-content: space-between;
              flex-direction: row;
            }
            .card-item {
              padding: 20px 0;
              @include onlyTB {
                padding: 20px;
                width: calc(100% / 2);
              }
              @include pc {
                padding: 0 20px 70px;
                position: relative;
              }
              @media screen and (min-width: $point-pc) and (max-width: 1259px) {
                width: calc(100% / 3);
              }
              @media screen and (min-width: 1260px){
                width: calc(100% / 5);
              }
              &:not(:last-child) {
                border-bottom: 1px dotted#c7c7c7;
                @include tb {
                  border-bottom: none;
                  border-right: 1px dotted#c7c7c7;
                }
                @include onlyTB {
                  margin-bottom: 30px;
                }
              } // &:not(:last-child)
              &:nth-child(2n) {
                @include onlyTB {
                  border-right: none;
                }
              }
              &:nth-child(3) {
                @media screen and (min-width: $point-pc) and (max-width: 1259px) {
                  border-right: none;
                }
              }
              &:nth-child(-n+3) {
                @media screen and (min-width: $point-pc) and (max-width: 1259px) {
                  margin-bottom: 40px;
                }
              }
              .card-head {
                @include pc {
                  margin-bottom: 10px;
                }
                .title {
                  text-align: center;
                  line-height: 1.1;
                  .large {
                    font-size: 16px;
                    @include font-Montserrat_medium;
                    @include pc {
                      font-size: 18px;
                    }
                  }
                  .small {
                    font-size: 12px;
                    @include font-noto_sans_light;
                    @include pc {
                      font-size: 14px;
                    }
                  }
                }
              } // .card-head
              .card-body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 10px;
                @include pc {
                  flex-direction: column;
                  padding-top: 0;
                }
                .left {
                  width: calc(50% - 10px);
                  @include pc {
                    width: 100%;
                  }
                  @include IE {
                    min-height: 0%;
                  }
                  .image {
                    max-width: 170px;
                    margin-left: auto;
                    margin-right: 0;
                    @include tb {
                      margin-right: auto;
                    }
                  }
                } // .left
                .right {
                  width: calc(50% - 10px);
                  @include pc {
                    width: 100%;
                  }
                  .caption {
                    font-size: 13px;
                    line-height: 1.8;
                    @include font-noto_sans_light;
                    @include pc {
                      text-align: center;
                      padding-top: 15px;
                    }
                  } // .caption
                  .lebel {
                    &-list {
                      font-size: 13px;
                      @include font-noto_sans_light;
                      @include pc {
                        text-align: center;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                      }
                    }
                    &-item {
                      .star {
                        color: #bfbfbf;
                        .red {
                          color: $c-primary;
                        }
                      }
                    } // &-item
                  } // .lebel
                } // .right
              } // .body
            } // .card-item
          } // &.-type01
          &.-type02 {
            @include tb {
              display: flex;
              flex-wrap: wrap;
              margin-right: -20px;
              margin-left: -20px;
            }
            .card-item {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
              padding: 20px 0;
              @include tb {
                width: calc(100% / 2);
                padding: 0 20px;
              }
              @include onlyTB {
                margin-bottom: 30px;
              }
              @include pc {
                width: calc(100% / 4);
                align-items: stretch;
                position: relative;
              }
              &:not(:last-child) {
                border-bottom: 1px dotted#c7c7c7;
                @include tb {
                  border-bottom: none;
                  border-right: 1px dotted#c7c7c7;
                }
              }
              &:nth-child(2n) {
                @include onlyTB {
                  border-right: none;
                }
              }
              .card-right {
                width: calc(50% - 10px);
                max-width: 170px;
                margin-left: 10px;
                margin-right: auto;
                @include tb {
                  max-width: none;
                  margin: auto;
                }
                @include pc {
                  margin-top: 0;
                  width: 100%;
                }
                .title {
                  @include pc {
                    text-align: center;
                    white-space: nowrap;
                  }
                  .large {
                    font-size: 16px;
                  }
                  .small {
                    font-size: 13px;
                  }
                }
                .caption {
                  font-size: 13px;
                  line-height: 1.8;
                  @include font-noto_sans_light;
                  @include pc {
                    padding-top: 190px; // 画像の高さ＋余白
                  }
                } // .caption
              } // .card-right
              .card-left {
                width: calc(50% - 10px);
                @include pc {
                  width: 100%;
                  position: absolute;
                  top: 60px;
                  left: 0;
                  right: 0;
                  margin: auto;
                }
                .image {
                  max-width: 170px;
                  margin-left: auto;
                  margin-right: 10px;
                  @include tb {
                    margin-right: auto;
                  }
                }
              } // .card-left
            } // .card-item
          } // &.-type02
          &.-type03 {
            @include tb {
              display: flex;
              flex-wrap: wrap;
            }
            @include pc {
              justify-content: flex-start;
            }
            .card-item {
              padding: 20px 0;
              &:not(:last-child) {
                @include sp {
                  border-bottom: 1px dotted#c7c7c7;
                }
              }
              @include onlyTB {
                &:not(:nth-child(2n + 1)) {
                  margin-left: 20px;
                }
              }
              @include tb {
                border: 1px solid #c7c7c7;
                padding: 20px;
                margin-bottom: 20px;
                width: calc(100% / 2 - 10px);
              }
              @include pc {
                width: calc(100% / 3 - 20px);
                &:not(:nth-child(3n + 1)) {
                  margin-left: 30px;
                }
              }
              .title {
                color: $c-primary;
                font-size: 16px;
                text-align: center;
              }
              .image {
                margin-top: 13px;
                img {
                  width: 100%;
                }
              }
              .lead {
                font-size: 13px;
                margin-top: 1em;
              }
              .caption {
                font-size: 13px;
                margin-top: 1em;
                @include font-noto_sans_light;
              }
              .price {
                background: #eef3f6;
                border-radius: 20px;
                font-size: 13px;
                margin-top: 1em;
                text-align: center;
                padding: .5em 0;
                @include font-noto_sans_light;
              }
            } // .card-item
          } // &.-type03
        } // &-list
      } // .card
    } // .modal-body
    .modal-foot {
      background: #eef3f6;
      padding: 30px 20px;
      @include pc {
        padding: 60px 80px;
      }
      .lead {
        font-size: 15px;
        margin-bottom: 1em;
        @include font-noto_sans_light;
        @include tb {
          text-align: center;
        }
        @include pc {
          font-size: 16px;
        }
        &.-bold {
          @include font-noto_sans_regular;
        }
      }
      .title {
        font-size: 18px;
        text-align: center;
        line-height: 1.4;
        @include font-noto_sans_light;
        @include pc {
          font-size: 22px;
        }
        &.-red {
          color: $c-primary;
        }
      }
      .c-btn {
        margin: 20px auto 0;
        max-width: 450px;
        @include pc {
          font-size: 16px;
        }
      }
      .option-list {
        font-size: 12px;
        padding-left: 20px;
        margin-top: 1em;
        margin-bottom: 1em;
        @include pc {
          font-size: 14px;
          width: 100%;
          max-width: 600px;
          margin: 1em auto 30px;
        }
        li {
          list-style-type: disc;
        }
      } // option-list
    } // .modal-foot
  } // &__modal
} // .p-glasseslens
.p-glassesLens__button {
  @include ov;
  align-items: center;
  border: 1px solid #e50213;
  border-radius: 33.5px;
  color: #e50213;
  cursor: pointer;
  display: flex;
  @include fz(14);
  line-height: 120%;
  height: 57px;
  margin: 15px auto 0;
  padding-left: 36px;
  position: relative;
  @include pc {
    border-radius: 46.5px;
    font-size: 18px;
    height: 67px;
    margin: 30px 0 0;
    width: 444px;
  }
  &::after {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTciIGhlaWdodD0iNTciIHZpZXdCb3g9IjAgMCA1NyA1NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjU3IiBoZWlnaHQ9IjU3IiByeD0iMjguNSIgZmlsbD0iI0U1MDIxMyIvPgo8cGF0aCBkPSJNMTcgMjlINDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNMjkgMTdMMjkgNDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K') no-repeat left top / cover;
    content: '';
    display: block;
    height: 57px;
    position: absolute;
    right: -1px;
    top: -1px;
    transition: 0.2s;
    width: 57px;
    @include pc {
      height: 67px;
      width: 67px;
    }
  }
  &:hover::after {
    //transform: rotate(45deg);
  }
}
.p-glassesLens__link {
  align-items: center;
  border: 1px solid #e50213;
  border-radius: 43px;
  color: #e50213;
  display: flex;
  @include fz(14);
  line-height: 140%;
  margin-top: 20px;
  padding: 20px 75px 20px 40px;
  position: relative;
  text-decoration: none;
  transition: 0.2s;
  @include pc {
    display: inline-flex;
    margin-top: 30px;
    padding: 15px 75px 15px 40px;
  }
  &::after {
    @extend %icon__arrowBottom-red;
  }
  &:hover {
    @include pc {
      background-color: #e50213;
      color: #fff;
      &::after {
        background-color: #fff;
      }
    }
  }
}
.p-glassesLens__link-right {
  &::after {
    @extend %icon__arrowRight-red;
  }
}
.p-glassLensMore {
  @include ov;
  background-color: #e50213;
  border: 1px solid #e50213;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 25px;
  text-decoration: none;
  @include pc {
    align-items: center;
    border-radius: 100px;
    flex-direction: row;
    justify-content: center;
    margin: 50px auto 0;
    max-width: 1080px;
    padding: 30px 20px 30px 35px;
  }
}
.p-glassLensMore__text {
  @include fz(16);
  font-weight: bold;
  text-align: center;
  @include pc {
    font-size: 24px;
  }
}
.p-glassLensMore__more {
  align-items: center;
  border: 1px solid #fff;
  border-radius: 33.5px;
  display: flex;
  @include font-noto_sans_medium;
  @include fz(16);
  height: 57px;
  margin: 15px auto 0;
  padding-left: 36px;
  position: relative;
  width: 190px;
  @include pc {
    border-radius: 46.5px;
    @include font-noto_sans_bold;
    font-size: 20px;
    height: 67px;
    margin: 0 0 0 80px;
    width: 300px;
  }
  &::after {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNTgiIHZpZXdCb3g9IjAgMCA1OCA1OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC45ODgyODEiIHk9IjAuMDY3MzgyOCIgd2lkdGg9IjU3IiBoZWlnaHQ9IjU3IiByeD0iMjguNSIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTE3Ljk4ODMgMjkuMDY3NEg0MS45ODgzIiBzdHJva2U9IiNFQzAwMDAiIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNMjkuOTg4MyAxNy4wNjc0TDI5Ljk4ODMgNDEuMDY3NCIgc3Ryb2tlPSIjRUMwMDAwIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==') no-repeat left top / cover;
    content: '';
    display: block;
    height: 57px;
    position: absolute;
    right: -1px;
    top: -1px;
    transition: 0.2s;
    width: 57px;
    @include pc {
      height: 67px;
      right: -1px;
      top: 0;
      width: 67px;
    }
    .p-glassLensMore:hover & {
      //transform: rotate(45deg);
    }
  }
}
.p-glassesLensFeature {
  @at-root {
    & {
      margin-top: 40px;
      @include pc {
        margin-top: 80px;
      }
    }
    .p-glassesLensFeature__image {
      margin-top: 20px;
    }
    .p-glassesLensFeature__item {
      background-color: #e2eef9;
      border-radius: 10px;
      padding: 40px 20px;
      text-align: center;
      @include pc {
        padding: 50px;
      }
      & + & {
        margin-top: 10px;
      }
    }
    .p-glassesLensFeature__sup {
      font-size: 16px;
      margin-bottom: 1em;
      @include pc {
        font-size: 18px;
      }
      &::before,
      &::after {
        background-color: #111;
        content: '';
        display: inline-block;
        height: 1.2em;
        vertical-align: text-bottom;
        width: 1px;
      }
      &::before {
        margin-right: 1em;
        transform: rotate(-30deg);
      }
      &::after {
        margin-left: 1em;
        transform: rotate(30deg);
      }
    }
    .p-glassesLensFeature__title {
      @include font-noto_sans_medium;
      font-size: 20px;
      line-height: 180%;
      @include pc {
        font-size: 26px;
      }
    }
    & .c-btnWrap {
      margin-bottom: 0;
      margin-top: 20px;
      @include pc {
        margin-bottom: 0;
        margin-top: 20px;
      }
    }
    & .c-btn a {
      height: auto;
      padding-bottom: 1em;
      padding-top: 1em;
    }
  }
}
