@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-header {
  @include pc {
    z-index: 1000;
  }
}

.l-header.-minimum {
  z-index: 1000;
}

.l-headerNav {
  z-index: 1000;
}

.l-header .l-menu,
.l-header .l-gnav,
.l-headerNav__cardState {
  z-index: 1500;
}

.l-gnav__btn,
.l-headerNav__li.gnav::after {
  z-index: 1600;
}

.l-container {
  .l-containerWrap {
    z-index: 999;
  }
}

.l-footer {
  z-index: 998;
}

.js-modal {
  z-index: 2000;
}
