@charset "utf-8";
//@include sp {};
//@include tb {};
//@include pc {};
//@include onlyTB{};
//@include onlyPC{};

/* */

html {
  font-size: 16px;
  @include font-noto_sans_regular;
  @include sp {
    font-size: 62.5%;
  }
  @include pc {
    scroll-padding-top: 5.75rem;
  }
  @include wide {
    font-size: 16px;
  }
}

body {
  margin: 0;
  line-height: 1.6;
  color: #333;
  letter-spacing: 0.06em;
  word-break: break-word;
}

html > body {
  @include pc {
    min-width: 980px;
  }
}

a {
  color: #333;
  outline: none;
  -webkit-touch-callout: none;
}

a:hover {
  text-decoration: none;
}

a:focus,
*:focus {
  outline: none;
}

a[href^="tel:"] {
  @include tb {
    pointer-events: none;
    text-decoration: none;
    display: inline-block;
  }
}

img {
  vertical-align: top;
  border: none;
  border-style: none;
  max-width: 100%;
}

ol,
ul,
li {
  list-style: none;
}

section {
  :not([class="p-top"]) > section {
    margin-bottom: 60px;
    @include pc {
      margin-bottom: 90px;
    }
  }
}

h1 {
  margin-top: 40px;
  margin-bottom: 26px;
  @include pc {
    margin-top: 125px;
    margin-bottom: 40px;
  }
}

h2 {
  margin-bottom: 30px;
}

p {
  font-size: 13px;
  @include pc {
    font-size: 14px;
  }
}

p + p {
  margin-top: 30px;
  @include pc {
    margin-top: 40px;
  }
}

.js-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include sp {
    left: auto;
  }
  &.-show {
    .js-modal__overlay {
      opacity: 0.7;
      transition: opacity 0.2s;
    }
    .js-modal__content {
      @include sp {
        -webkit-transition: -webkit-transform 0.5s ease;
        transition: transform 0.5s ease;
        transform: translateX(0);
      }
    }
  }
  &:not(.-show) {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
  }
  &2 {
    .p-form {
      .-grid_row {
        @include sp {
          margin-bottom: 20px;
        }
        &:first-child {
          @include sp {
            margin-bottom: 30px;
          }
        }
      }
    }
    .c-buy {
      .p-item__count {
        @include sp {
          margin-top: 26px;
        }
      }
    }
  }
  &4 {
    .js-modal__content {
      @include pc {
        width: 44%;
      }
    }
    .c-buy__body {
      .c-productItem__title {
        position: relative;
        padding-bottom: 27px;
        margin-bottom: 0;
        @include pc {
          margin-bottom: 69px;
        }
        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 3px;
          content: "";
          background: url(/img/common/line_dot_gray.svg) repeat-x;
          @include pc {
            content: none;
          }
        }
      }
      .p-item__count {
        margin-bottom: 28px;
        @include sp {
          margin-top: 23px;
        }
        @include pc {
          margin-bottom: 26px;
        }
      }
      .c-btnWrap {
        padding-bottom: 0;
        .c-btn {
          @include sp {
            width: 100%;
          }
        }
      }
    }
  }
  &.-verA {
    .c-buy {
      &__columns {
        padding-bottom: 0;
      }
      &__column {
        &.-left {
          @include sp {
            border-bottom: none;
          }
        }
        &.-right {
          @include sp {
            padding-top: 0;
          }
        }
      }
      .c-checkbox {
        @include sp {
          top: 0;
        }
      }
      .p-item__count {
        margin: 0 0 26px;
        @include pc {
          justify-content: center;
          margin: 20px 0;
        }
      }
      .p-item__amount {
        @include pc {
          margin-right: 40px;
        }
      }
    }
  }
  &.-verB {
    @media screen and (max-width: 979px) {
      .js-modal__contentWrapper {
        display: flex;
        .js-modal__content {
          display: block;
          margin: auto;
          height: auto;
          width: calc(100% - 40px);
        }
      }
    }
  }
  &.-maestro {
    z-index: 1000;
  }
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    overflow: auto;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.5s;
    .-maestro & {
      background-color: rgb(240,245,246);
    }
  }
  &__contentWrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 0;
    overflow: auto;
    text-align: center;
    vertical-align: middle;
    @include sp {
      position: absolute;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: right;
      -webkit-overflow-scrolling: touch;
    }
    .-maestro & {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
    }
    &::after {
      display: inline-block;
      height: 100%;
      margin-left: -0.05em;
      content: "";
    }
  }
  &__content {
    position: relative;
    display: inline-block;
    width: 94.6%;
    max-width: 1000px;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: left;
    vertical-align: middle;
    background-color: #fff;
    .-maestro & {
      overflow: hidden;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      @include tb {
        width: 960px;
        max-height: none;
        height: 600px;
        border-radius: 20px;
      }
      @include IE {
        overflow: visible; // fixedでボタンが隠れてしまうため
      }
    }
    @include sp {
      height: 100%;
      margin-top: 0;
      -webkit-transition: -webkit-transform 0.5s ease-out;
      transition: transform 0.5s ease-out;
      transform: translateX(100%);
    }
    @include pc {
      width: 66%;
    }
    &.-full {
      @include sp {
        right: inherit;
      }
      width: 100%;
      max-width: inherit;
    }
  }
  &__closeBtn {
    position: absolute;
    top: 8px;
    right: 11px;
    z-index: +1;
    display: block;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    background: url(/img/common/icon_close.png) no-repeat 50%;
    background-size: 15px auto;
    .-maestro & {
      top: 17px;
      right: 18px;
      background: url(/img/common/icon_close_gray.svg) no-repeat 50%;
      background-size: 15px auto;
      @include tb {
        position: fixed;
        right: 25px;
        top: 25px;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        background-color: #e60012;
        background-image: url(/img/common/icon_close_white.svg);
        background-size: 17px auto;
        border: 1px solid $c-primary;
        transition: background 0.2s ease;
        &:hover {
          background: #fff;
          background-image: url("/img/common/icon_close_red.svg");
          background-size: 17px auto;
          background-repeat: no-repeat;
          background-position: 50%;
          border-color: #e2bfd4;
        }
      }
    }
  }
  &.-topMovie,
  &.-mydoMovie,
  &.-brandMovie {
    @include sp {
      left: 0;
    }
    .js-modal__contentWrapper {
      @include sp {
        position: fixed;
        overflow: auto;
        text-align: center;
        vertical-align: middle;
      }
    }
    .js-modal__content {
      width: 100%;
      @include sp {
        margin-top: 70px;
        -webkit-transition: -webkit-transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
        transform: none;
      }
      @media (min-width: 768px) and (max-width: 1035px) {
        margin-top: 90px;
        -webkit-transition: -webkit-transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
        transform: none;
      }
      @include pc {
        max-width: 1000px;
      }
      @media screen and (max-height: 720px) {
        max-width: 750px;
      }
    }
    &.-show {
      .js-modal__overlay {
        background: #fff;
        opacity: 1;
      }
    }
    .js-modalClose {
      background-color: $c-primary;
      width: 49px;
      height: 49px;
      border-radius: 50%;
      top: -54px;
      @include pc {
        width: 70px;
        height: 70px;
        top: -75px;
        border: 1px solid $c-primary;
        transition: background 0.2s ease;
        &:hover {
          background: #fff;
          background-image: url("/img/common/icon_close_red.svg");
          background-size: 17px auto;
          background-repeat: no-repeat;
          background-position: 50%;
          border-color: #e2bfd4;
        }
      }
    }
    &.-show {
      .js-modal__content {
        @include sp {
          transform: none;
        }
        @media (min-width: 768px) and (max-width: 1035px) {
          transform: none;
        }
      }
    }
  }
  &.-glassesLens {
    @include sp {
      left: 0;
    }
    .js-modal__contentWrapper {
      margin: 0 auto;
      max-width: 1240px;
      opacity: unset;
      position: fixed;
      width: 100%;
      &::after {
        display: none;
      }
      // ↓スクロールバー非表示
      &::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    .js-modal__content {
      height: auto;
      -webkit-transition: -webkit-transform 0.5s ease-out;
      transition: transform 0.5s ease-out;
      transform: none;
      width: calc(100% - 40px);
      margin: 20px;
      @include pc {
        width: 100%;
        max-width: 1240px;
        margin: 120px 0 60px;
      }
    }
    &.-show {
      .js-modal__overlay {
        background: rgba(0,0,0,0.8);
        opacity: 1;
      }
    }
    .js-modalClose {
      background-color: $c-primary;
      width: 50px;
      height: 50px;
      background-image: url("/img/common/icon_close_white_single.svg");
      background-size: 17px auto;
      background-repeat: no-repeat;
      background-position: 50%;
      @include pc {
        width: 70px;
        height: 70px;
      }
    }
    .js-modal__closeText {
      color: $c-primary;
      cursor: pointer;
      font-size: 14px;
      margin: 20px auto 0;
      text-align: center;
      text-decoration: underline;
      @include font-noto_sans_regular;
      @include pc {
        font-size: 20px;
        margin-top: 40px;
        transition: text-decoration 0.2s ease;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.-show {
      .js-modal__content {
        @include sp {
          transform: none;
        }
        @media (min-width: 768px) and (max-width: 1035px) {
          transform: none;
        }
      }
    }
  }
}
