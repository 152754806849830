@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-kv {
  &.perfectcleaning {
    background: #f1f1f0;
    padding: 0;
    margin-top: -50px;
    margin-bottom: 0;
    text-align: center;
    @include pc {
      margin-top: -180px;
    }
  } // &.perfectcleaning
  &__title {
    margin-top: 0;
    margin-bottom: 0;
    @include pc {
      margin-top: 5.75rem;
    }
    img {
      margin: 0 auto;
      width: 100%;
      @include tb {
        max-width: 500px;
      }
      @include pc {
        max-width: 1440px;
      }
    }
  } // .l-kv__title
} // .l-kv

.p-perfectcleaning {
  &Wrap {
    max-width: 1440px;
    margin: auto;
    .c-card {
      padding-top: 40px;
      @include pc {
        padding-top: 80px;
      }
      &Head {
        padding: 0 20px;
        margin-bottom: 0;
        color: $c-primary;
        text-align: center;
        &__title {
          font-size: 21px;
          padding-bottom: 10px;
          @include pc {
            font-size: 32px;
          }
        } // &__title
        &__note {
          font-size: 11px;
          @include pc {
            font-size: 12px;
          }
        } // &__note
      } // &Head
      &Body {
        padding: 20px;
        counter-reset: item;
        @include pc {
          padding: 30px 60px 70px;
        }
        .service__list {
          @include tb {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: stretch;
          }
          .item {
            padding: 10px 20px 26px;
            border: solid 1px #b3b3b3;
            &:not(:last-child) {
              @include sp {
                margin-bottom: 20px;
              }
            }
            @media (min-width: $point-tb) and (max-width: 1024px) {
              width: calc(50% - 10px);
              &:nth-child(n+3) {
                margin-top: 20px;
              }
            }
            @media screen and (min-width: 1025px) {
              width: calc(100%/3 - 15px);
              padding: 15px 25px 30px;
              &:nth-child(n+4) {
                margin-top: 30px;
              }
            }
            @include wide {
              padding: 15px 35px 30px;
            }
            .title {
              color: $c-primary;
              text-align: center;
              font-size: 15px;
              padding-top: 15px;
              padding-left: 16px;
              margin-bottom: 15px;
              position: relative;
              @include pc {
                font-size: 18px;
                padding-top: 20px;
              }
              &::before {
                counter-increment: item;
                content: counter(item) ".";
                position: absolute;
                font-size: 32px;
                letter-spacing: 0.08em;
                color: $c-primary;
                @include font-Montserrat_extralight;
                top: 0;
                left: 0;
                @include pc {
                  font-size: 44px;
                }
              } // &::before
            } // .title
            .image {
              margin-bottom: 20px;
            } // .image
            .text {
              font-size: 13px;
              margin: 0;
              @include pc {
                font-size: 14px;
              }
              .note {
                font-size: 11px;
                @include pc {
                  font-size: 12px;
                }
              }
            } // .text
          } // .item
        } // .service__list
      } // &Body
      &Foot {
        padding: 0 20px;
        @include pc {
          padding: 0 60px;
        }
        .banner {
          background: $c-primary;
          position: relative;
          margin-bottom: 65px;
          @include pc {
            margin-bottom: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 17px 0;
          }
          .icon {
            position: absolute;
            top: -30px;
            right: 0;
            @include tb {
              right: 20px;
            }
            @include pc {
              position: static;
              padding-right: 16px;
            }
            img {
              width: 70px;
              @include tb {
                width: 94px;
              }
              @include pc {
                width: 84px;
              }
            }
          } // .icon
          .lead {
            color: #fff;
            font-size: 21px;
            text-align: center;
            padding: 25px 0 15px 0;
            letter-spacing: .15em;
            line-height: 1.3;
            @include font-noto_sans_light;
            @include pc {
              font-size: 32px;
              padding: 0 10px 0 0;
              margin: 0;
            }
          } // .lead
          .tag {
            font-size: 14px;
            text-align: center;
            padding-bottom: 32px;
            @include pc {
              padding: 0;
            }
            li {
              display: inline;
              background: #fff;
              padding: .6em 1em;
              border-radius: 15px;
              @include IE {
                padding: .6em 1em .3em;
                border-radius: 25px;
              }
            }
          } // .tag
        } // .banner
        .content {
          margin: 0 -20px 45px;
          padding-top: 40px;
          text-align: center;
          display: block;
          position: relative;
          padding-bottom: calc(100%*304/750);
          background: url('/img/perfect-cleaning/bg_foot_sp.jpg') no-repeat center bottom / contain;
          &::before {
            content: "";
            display: block;
            background: #f1f1f1;
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            @include pc {
              display: none;
            }
          }
          @include pc {
            background: url('/img/perfect-cleaning/bg_foot_pc.jpg') no-repeat center / cover;
            margin: 0 -60px 75px;
            padding: 25px 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .text-box {
            @include font-noto_sans_light;
            @include pc {
              margin:0;
              padding:0;
              position: relative;
              z-index: 2;
              width: 100%;
            }
            .lead {
              font-size: 13px;
              margin-bottom: 20px;
              @include pc {
                font-size: 14px;
              }
              @include wide {
                font-size: 16px;
              }
            } // .lead
            .text {
              font-size: 15px;
              margin: 0 0 15px;
              @include pc {
                font-size: 18px;
                margin: 0;
              }
              @include wide {
                font-size: 22px;
              }
              .strong {
                @include font-noto_sans_medium;
              }
            } // .text
          } // text-box
        } // .content
        .store {
          .text {
            text-align: center;
            font-size: 15px;
            padding-bottom: 25px;
            @include font-noto_sans_light;
            @include pc {
              font-size: 22px;
            }
          } // .tetx
        } // .store
      } // &Foot
    } // .c-card
  } // &Wrap
  .p-campaignBanner {
    margin-bottom: 0px;
  }
} // .p-perfectcleaning

.p-campaignBanner__title .speechbubble {
    @include pc {
      width: 9em;
      padding: 11px 12px 25px 15px;
      transform: translateX(-13em);
    }
    @include sp {
        font-size: 11px;
    }
}
