@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function pxToVw-980($pxValue) {
  @return ((strip-unit($pxValue) / 980) * 100) + vw;
}

.p-brand {
  @at-root {
    & {
    }
    & .c-title {
      text-align: center;
      margin: 62px 0 70px;
      @include pc{
        margin: 72px 0 88px;
      }
      .en {
        @include fz(32);
        line-height: 1;
        letter-spacing: 0.08em;
        @include pc{
          @include vw(44);
        }
      }
      .jp {
        @include fz(13);
        line-height: 1;
        margin-top: 5px;
        @include pc{
          @include vw(18);
          margin-top: 10px;
        }
      }
    }
    .p-brand__row {
      @include pc {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 pxToVw-980(58);
      }
      & + & {
        margin-top: 60px;
      }
    }
    .p-brand__row-full {
      @include pc {
        display: block;
      }
    }
    .p-brand__column {
      border-top: 1px solid #d9d9d9;
      padding-top: 25px;
      & + & {
        margin-top: 60px;
        @include pc {
          margin-top: 0;
        }
      }
    }
    .p-brand__title {
      font-size: 20px;
      line-height: calc(36 / 20);
      text-align: center;
    }
    .p-brandList {
      display: grid;
      gap: 35px 16px;
      grid-template-columns: repeat(2, 1fr);
      @include tb {
        grid-template-columns: repeat(3, 1fr);
      }
      @include pc {
        gap: pxToVw-980(34) pxToVw-980(20);
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .p-brandList-full {
      @include pc {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    .p-brandList-slide {
      @include pc {
        display: block;
      }
    }
    .p-brandList__item {
      width: 100%;
      .p-brandList-slide & {
        @include pc {
          padding: 0 pxToVw-980(12.5);
        }
      }
    }
    .p-brandList__link {
      display: flex;
      flex-direction: column;
      position: relative;
      text-decoration: none;
      @include pc {
        @include ov;
      }
    }
    .p-brandList__link-new {
      &::before {
        border-style: solid;
        border-width: 48px 48px 0 0;
        border-color: #e50213 transparent transparent transparent;
        content: '';
        display: block;
        height: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 0;
      }
      &::after {
        @include font-Montserrat_semiBold;
        font-size: 12px;
        color: #fff;
        content: 'NEW!';
        height: 48px;
        letter-spacing: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 48px;
        z-index: 1;
        transform: rotate(-45deg);
        transform-origin: 54% 29%;
      }
    }
    .p-brandList__thumb {
      display: block;
    }
    .p-brandList__thumb-border {
      border: 1px solid #e2e2e2;
    }
    .p-brandList__image {
      display: block;
    }
    .p-brandListLogo {
      align-items: center;
      border: 1px solid #e2e2e2;
      display: flex;
      height: 0;
      padding: 0 0 100%;
      position: relative;
      justify-content: center;
      width: 100%;
      @include pc {
        height: 0;
        padding: 0 0 100%;
        position: relative;
        width: 100%;
      }
    }
    .p-brandListLogo__image {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: auto;
      top: 0;
      right: 0;
    }
    .p-brandList__image-border {
    }
    .p-brandList__title {
      @include font-noto_sans_bold;
      font-size: 16px;
      letter-spacing: 0.16em;
      line-height: calc(29 / 16);
      margin-top: 18px;
    }
    .p-brandList__text {
      font-size: 13px;
      letter-spacing: 0.16em;
      line-height: calc(20 / 13);
      margin-top: 6px;
    }
  }
}
