@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-title {
  .en {
    display: block;
    @include font-Montserrat_extralight;
    text-transform: uppercase;
  }
  .jp {
    display: block;
    @include font-noto_sans_light;
    letter-spacing: 0.26em;
  }
  .p-topInformation &,
  .p-topMovie & {
    text-align: center;
    @include pc{
      margin-bottom: 46px;
    }
    .en {
      @include fz(32);
      line-height: 1;
      letter-spacing: 0.08em;
      @include pc{
        @include vw(44);
      }
    }
    .jp {
      @include fz(13);
      line-height: 1;
      margin-top: 5px;
      @include pc{
        @include vw(18);
      }
    }
  }
  .p-topNewarrival &,
  .p-topService & {
    text-align: center;
    margin-bottom: 18px;
    @include pc{
      margin-bottom: 40px;
    }
    .en {
      @include fz(32);
      line-height: 1;
      letter-spacing: 0.08em;
      @include pc{
        @include vw(44);
      }
    }
    .jp {
      @include fz(13);
      line-height: 1;
      margin-top: 5px;
      @include pc{
        @include vw(18);
        margin-top: 10px;
      }
    }
  }
  .p-topMovie & {
    @include pc{
      text-align: left;
    }
    .en {
      @include pc{
        border-left: 4px solid $c-primary;
        padding-left: 35px;
      }
    }
    .jp {
      @include pc{
        padding-left: 40px;
      }
    }
  }
  &-page {
    margin: 30px 0;
    text-align: center;
    @include pc {
      margin-bottom: 40px;
    }
    .en {
      @include fz(28);
      line-height: 1;
    }
    .jp {
      @include fz(12);
      line-height: 1;
      margin-top: 5px;
    }
  }
}
.c-title02 {
  @include font-Montserrat;
  @include fz(30);
  text-align: center;
  @include pc {
    font-size: 40px;
  }
  &-line {
    &::after {
      background-color: #333;
      content: "";
      display: block;
      height: 1px;
      margin: 15px auto;
      width: 16px;
      @include pc {
        margin: 20px auto;
        width: 21px;
      }
    }
  }
}
.c-title03 {
  @include fz(18);
  letter-spacing: .06em;
  line-height: 1.4;
  margin-bottom: .75em;
  @include pc {
    font-size: 22px;
  }
}
.c-title04 {
  @include fz(20);
  letter-spacing: .06em;
  line-height: 1.4;
  margin-bottom: 20px;
  @include pc {
    font-size: 24px;
    margin-bottom: 36px;
  }
}
.c-title05 {
  @include fz(24);
  font-weight: 400;
  letter-spacing: .06em;
  margin-bottom: 20px;
  @include pc {
    font-size: 36px;
    margin-bottom: 30px;
  }
}
.c-titleCheck {
  @include font-noto_sans_medium;
  font-size: 28px;
  letter-spacing: .06em;
  line-height: 1.4;
  margin-bottom: 20px;
  padding-left: 1.5em;
  position: relative;
  @include pc {
    font-size: 40px;
    margin-bottom: 60px;
  }
  &:before {
    background: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2236%22%20height%3D%2236%22%20viewBox%3D%220%200%2036%2036%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2218%22%20cy%3D%2218%22%20r%3D%2217.5%22%20fill%3D%22white%22%20stroke%3D%22%23ED0A1E%22%2F%3E%3Cpath%20d%3D%22M14.5725%2021.4285L10.7154%2019.2856L9.42969%2021.4285L16.7154%2025.7142C17.144%2023.5713%2018.0011%2021.4285%2020.144%2017.5713C22.7519%2012.8771%2025.7154%2010.2856%2026.5725%209.42847C25.7154%209.85704%2022.7154%2011.1428%2019.7154%2014.1428C16.5705%2017.2877%2015.4297%2019.7142%2014.5725%2021.4285Z%22%20fill%3D%22%23ED0A1E%22%2F%3E%3C%2Fsvg%3E') no-repeat left top / contain;
    content: '';
    display: block;
    height: 36px;
    position: absolute;
    left: 0;
    top: .1em;
    width: 36px;
    @include pc {
      left: .1em;
      height: 42px;
      top: .15em;
      width: 42px;
    }
  }
  & ~ & {
    margin-top: 70px;
    @include pc {
      margin-top: 90px;
    }
  }
}
.c-titleBorder__grayRed {
  @include font-noto_sans_medium;
  font-size: 18px;
  line-height: 139%;
  margin-bottom: 40px;
  @include pc {
    font-size: 22px;
    margin-bottom: 60px;
  }
  &::before {
    background: linear-gradient(to right, #e60012 50%, #bfbfbf 50%, #bfbfbf 100%);
    content: '';
    display: block;
    height: 1px;
    margin-bottom: 20px;
    width: 200px;
  }
}
