@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.hdStore {
  &__result {
    @include dotLineGray;
    padding-top: 30px;
    margin: 30px 20px 0;
    @include pc {
      margin: 30px 30px 0;
    }
    &Head {
      display: flex;
      flex: 1 0 auto;
      justify-content: space-between;
      margin-bottom: 20px;
      line-height: (38/34);
      @include fz(11);
      @include pc {
        @include vw(14);
        margin-bottom: 25px;
        line-height: (28/14);
      }
    }
    &Title {
      letter-spacing: 0.16em;
    }
    &Nom {
      letter-spacing: 0.08em;
    }
  }
}
