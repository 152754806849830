.p-feature {

#letter_body {
  h2 {
    font-size: 18px;
    font-weight: 500;
    background: #ececec;
    padding: 20px;
    margin-bottom: 20px;
  }

  hr {
    width: 100%;
    border: 1px solid rgb(128, 128, 128);
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

}