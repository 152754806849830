@charset "utf-8";

// レンズトップページ
// /lens/index.ejs
/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/
$lensTop__color-blue: #1867B0;
$lensTop__color-lightBlue: #d5e7f3;
$lensTop__color-gray: #eee;
$lensTop__color-grayBorder: #ccc;
$lensTop__color-red: #e50213;
$lensTop__color-white: #fff;
// 赤↓矢印
%icon__arrowBase {
  background-color: $lensTop__color-red;
  content: '';
  display: block;
  mask-position: left top;
  mask-repeat: no-repeat;
  mask-size: contain;
  height: 13.5px;
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s;
  width: 14px;
}
%icon__arrowBottom-red {
  @extend %icon__arrowBase;
  mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuOTg4MjgxIDYuNTY3MzhMNy45ODgyOCAxMy41Njc0TDE0Ljk4ODMgNi41NjczOCIgc3Ryb2tlPSIjRTUwMjEzIiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTcuOTg4MjggMTMuMDY3NFYwLjA2NzM4MjgiIHN0cm9rZT0iI0U1MDIxMyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=');
}
%icon__arrowRight-red {
  @extend %icon__arrowBase;
  mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNSAxNC43NUwxMy41IDcuNzVMNi41IDAuNzUiIHN0cm9rZT0iI0U1MDIxMyIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik0xMyA3Ljc1TDAgNy43NSIgc3Ryb2tlPSIjRTUwMjEzIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
}
%button__more {
  @include ov;
  align-items: center;
  border: 1px solid $lensTop__color-red;
  border-radius: 33.5px;
  color: $lensTop__color-red;
  display: flex;
  @include fz(14);
  height: 57px;
  margin: 15px auto 0;
  padding-left: 36px;
  position: relative;
  text-decoration: none;
  width: 190px;
  @include pc {
    border-radius: 46.5px;
    font-size: 18px;
    height: 67px;
    margin: 30px auto 0;
    width: 230px;
  }
  &::after {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTciIGhlaWdodD0iNTciIHZpZXdCb3g9IjAgMCA1NyA1NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjU3IiBoZWlnaHQ9IjU3IiByeD0iMjguNSIgZmlsbD0iI0U1MDIxMyIvPgo8cGF0aCBkPSJNMTcgMjlINDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNMjkgMTdMMjkgNDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K') no-repeat left top / cover;
    content: '';
    display: block;
    height: 57px;
    position: absolute;
    right: -1px;
    top: -1px;
    transition: 0.2s;
    width: 57px;
    @include pc {
      height: 67px;
      width: 67px;
    }
  }
}
// モーダル内上書き用
@mixin modal {
  .js-modal & {
    @content;
  }
}
.p-lensTop {
  @at-root {
    // 赤い上線のタイトル
    .p-lensTopTitle {
      border-top: 1px solid $lensTop__color-red;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 0;
      margin-bottom: 25px;
      @include pc {
        margin-bottom: 60px;
      }
    }
    .p-lensTopTitle-noBorder {
      border-top: none;
    }
    .p-lensTopTitle__text {
      border-top: 6px solid $lensTop__color-red;
      display: block;
      @include fz(28);
      font-weight: 400;
      line-height: 130%;
      padding-top: 20px;
      @include pc {
        border-top-width: 10px;
        font-size: 48px;
        padding-top: 45px;
      }
      .p-lensTopTitle-noBorder & {
        border-top: none;
        padding-top: 0;
      }
    }
    // メイン画像直下リード文
    .p-lensTopLead {
      @include fz(14);
      line-height: 180%;
      text-align: left;
      @include pc {
        font-size: 16px;
        text-align: center;
      }
    }
    // お客様の課題へのご提案
    .p-lensTopProposal {
      margin-top: 30px;
      @include pc {
        margin-top: 80px;
      }
    }
    .p-lensTopProposal__item {
      background-color: $lensTop__color-white;
      border-radius: 10px;
      padding: 20px;
      @include pc {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
      }
      & + & {
        margin-top: 10px;
      }
    }
    .p-lensTopProposal__head {
      @include pc {
        flex: 1;
      }
      & .c-list {
        font-size: 16px;
        line-height: 180%;
      }
    }
    .p-lensTopProposal__body {
      @include pc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 540px;
      }
    }
    .p-lensTopProposalButton {
      @include ov;
      background-color: $lensTop__color-red;
      border: 1px solid $lensTop__color-red;
      border-radius: 10px;
      color: $lensTop__color-white;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      padding: 25px;
      text-decoration: none;
      @include pc {
        align-items: center;
        border-radius: 46.5px;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0;
        padding: 13px 20px 13px 35px;
      }
    }
    .p-lensTopProposalButton__text {
      @include fz(18);
      font-weight: bold;
      text-align: center;
    }
    .p-lensTopProposalButton__more {
      align-items: center;
      border: 1px solid $lensTop__color-white;
      border-radius: 33.5px;
      display: flex;
      @include font-noto_sans_medium;
      @include fz(14);
      height: 57px;
      margin: 15px auto 0;
      padding-left: 36px;
      position: relative;
      width: 190px;
      @include pc {
        border-radius: 46.5px;
        @include font-noto_sans_bold;
        margin: 0;
      }
      &::after {
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNTgiIHZpZXdCb3g9IjAgMCA1OCA1OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC45ODgyODEiIHk9IjAuMDY3MzgyOCIgd2lkdGg9IjU3IiBoZWlnaHQ9IjU3IiByeD0iMjguNSIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTE3Ljk4ODMgMjkuMDY3NEg0MS45ODgzIiBzdHJva2U9IiNFQzAwMDAiIHN0cm9rZS13aWR0aD0iMiIvPgo8cGF0aCBkPSJNMjkuOTg4MyAxNy4wNjc0TDI5Ljk4ODMgNDEuMDY3NCIgc3Ryb2tlPSIjRUMwMDAwIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==') no-repeat left top / cover;
        content: '';
        display: block;
        height: 57px;
        position: absolute;
        right: -1px;
        top: -1px;
        transition: 0.2s;
        width: 57px;
        @include pc {
          right: -1px;
          top: 0;
        }
        .p-lensTopProposalButton:hover & {
          //transform: rotate(45deg);
        }
      }
    }
    .p-lensTopProposal__link {
      border: 1px solid $lensTop__color-red;
      border-radius: 43px;
      color: $lensTop__color-red;
      display: block;
      @include fz(16);
      line-height: 140%;
      margin-top: 20px;
      padding: 15px 30px;
      position: relative;
      text-decoration: none;
      transition: 0.2s;
      @include pc {
        font-size: 18px;
        margin-top: 0;
      }
      &::after {
        @extend %icon__arrowBottom-red;
      }
      &:hover {
        @include pc {
          background-color: $lensTop__color-red;
          color: $lensTop__color-white;
          &::after {
            background-color: $lensTop__color-white;
          }
        }
      }
    }
    // ビジョンメガネのレンズ価格について
    .p-lensTopPrice {
      margin: 40px -5% 0;
      @include pc {
        display: flex;
        margin: 60px 0 0;
      }
      @include modal {
        margin-top: 0;
        @include pc {
          margin-bottom: 100px;
        }
      }

    }
    .c-sec.p-lensTopPriceSec {
      @include pc {
        padding-bottom: 170px;
        padding-top: 100px;
      }
    }
    // 基本セット
    .p-lensTopPriceHead {
      background-color: $lensTop__color-gray;
      border-radius: 10px;
      padding: 40px 5%;
      @include pc {
        border: 1px solid $lensTop__color-grayBorder;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
        padding: 30px;
        width: calc(480 / 1440 * 100vw);
      }
      @include modal {
        @include pc {
          max-width: 346px;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 40px;
          width: calc(346 / 1440 * 100vw);
        }
      }
    }
    .p-lensTopPriceHead__title {
      @include fz(16);
      line-height: 160%;
      margin-bottom: 30px;
      text-align: center;
      @include modal {
        @include pc {
          margin-bottom: 50px;
        }
      }
      strong {
        @include font-noto_sans_bold;
        @include fz(22);
        margin-bottom: 45px;
      }
    }
    .p-lensTopPriceHead__subTitle {
    }
    .p-lensTopPriceHead__image {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .p-lensTopPriceHead__content {
      background-color: $lensTop__color-white;
      border: 1px solid $lensTop__color-grayBorder;
      border-radius: 10px;
      margin-top: 30px;
      padding: 35px;
      @include pc {
        margin-top: 45px;
        padding: 40px;
      }
      @include modal {
        @include pc {
          margin-top: auto;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
    .p-lensTopPriceHead__price {
      @include fz(14);
      line-height: 100%;
      text-align: center;
      strong {
        @include font-Montserrat_semiBold;
        @include fz(32);
      }
    }
    .p-lensTopPriceHead__text {
      @include fz(15);
      line-height: 160%;
      margin-top: 20px;
    }
    .p-lensTopPriceHead__note {
      @include fz(13);
      line-height: 160%;
      margin-top: 15px;
    }
    // 追加
    .p-lensTopPricePlus {
      align-items: center;
      color: $lensTop__color-red;
      display: flex;
      flex-direction: column;
      padding: 20px 0 15px;
      @include pc {
        justify-content: center;
        padding: 0 15px;
      }
    }
    .p-lensTopPricePlus__icon {
      height: 30px;
      width: 30px;
    }
    .p-lensTopPricePlus__text {
      @include fz(13);
      font-weight: 500;
      line-height: 155%;
      white-space: nowrap;
      @include pc {
        margin-top: 10px;
      }
    }
    // ライフスタイルに合わせた見え方・見え心地をお求めの方
    .p-lensTopPriceBody {
      background-color: $lensTop__color-lightBlue;
      border: 1px solid $lensTop__color-grayBorder;
      border-radius: 10px;
      margin-bottom: 130px;
      padding: 40px 5%;
      position: relative;
      @include pc {
        margin-bottom: 0;
        padding: 30px 40px 40px;
        width: calc(800 / 1440 * 100vw);
      }
      @include modal {
        @include pc {
          flex: 1;
          width: auto;
        }
      }
    }
    .p-lensTopPriceBody__title {
      color: $lensTop__color-red;
      @include font-noto_sans_medium;
      @include fz(18);
      line-height: 160%;
      margin-bottom: 20px;
      text-align: center;
      @include pc {
        @include font-noto_sans_bold;
        font-size: 22px;
      }
      @include modal {
        @include pc {
          font-size: 20px;
          margin-bottom: 10px;
        }
      }
    }
    .p-lensTopPriceBodyContent {
      background-color: $lensTop__color-white;
      border: 1px solid #a1C5df;
      border-radius: 10px;
      padding: 30px 20px 40px;
      @include pc {
        align-items: center;
        display: flex;
        gap: 0 30px;
        padding: 20px 30px;
      }
      @include modal {
        @include pc {
          padding-top: 10px;
        }
      }
      & + & {
        margin-top: 20px;
      }
    }
    .p-lensTopPriceBodyContent__head {
    }
    .p-lensTopPriceBodyContent__title {
      @include font-noto_sans_medium;
      @include fz(18);
      line-height: 140%;
      margin-bottom: 5px;
      text-align: center;
      @include pc {
        @include font-noto_sans_bold;
      }
    }
    .p-lensTopPriceBodyContent__image {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .p-lensTopPriceBodyContent__body {
      @include pc {
        flex: 1;
      }
    }
    .p-lensTopPriceBodyList {
      margin-top: 45px;
      @include pc {
        margin-top: 0;
        position: relative;
      }
      &::after {
        @include pc {
          border-bottom: 1px solid #a1c5df;
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          width: calc(100% + 30px);
        }
      }
    }
    .p-lensTopPriceBodyList__item {
      align-items: center;
      display: flex;
      justify-content: space-between;
      @include pc {
        padding-left: 25px;
      }
      &:not([class*="p-lensTopPriceBodyList__item-modal"]) + & {
        border-top: 1px solid #a1c5df;
        margin-top: 15px;
        padding-top: 15px;
        @include modal {
          @include pc {
            margin-top: 10px;
            padding-top: 10px;
          }
        }
      }
      &:last-child {
        @include pc {
          padding-bottom: 15px;
        }
      }
    }
    .p-lensTopPriceBodyList__item-vertical {
      align-items: flex-end;
      flex-direction: column;
      @include pc {
        flex-direction: row;
      }
      @include modal {
        @include pc {
          justify-content: flex-end;
        }
      }
    }
    .p-lensTopPriceBodyList__item-modal {
      display: none;
      @include modal {
        display: block;
      }
    }
    .p-lensTopPriceBodyList__text {
      @include fz(14);
      @include pc {
        flex: 1;
      }
      .SPbr {
        @include modal {
          @include pc {
            display: block;
          }
        }
      }
      .p-lensTopPriceBodyList__item-vertical & {
        @include modal {
          display: none;
        }
      }
    }
    .p-lensTopPriceBodyList__price {
      @include fz(16);
      margin-top: 0;
      @include pc {
        white-space: nowrap;
      }
      @include modal {
        @include pc {
          line-height: 1.4;
        }
      }
      strong {
        @include font-Montserrat_semiBold;
        @include fz(24);
        @include pc {
          font-size: 32px;
        }
      }
      small {
        @include fz(14);
      }
    }
    .p-lensTopPriceBody__link {
      border: 1px solid $lensTop__color-red;
      border-radius: 43px;
      color: $lensTop__color-red;
      display: block;
      @include fz(16);
      line-height: 140%;
      margin: 30px auto 0;
      padding: 15px 30px;
      position: relative;
      text-decoration: none;
      transition: 0.2s;
      width: 190px;
      @include pc {
        font-size: 16px;
        margin: 20px 0 0 auto;
        padding: 10px 25px 10px 50px;
        width: 200px;
      }
      &::after {
        @extend %icon__arrowBottom-red;
      }
      &:hover {
        @include pc {
          background-color: $lensTop__color-red;
          color: $lensTop__color-white;
          &::after {
            background-color: $lensTop__color-white;
          }
        }
      }
    }
    .p-lensTopPriceBody__note {
      @include fz(14);
      line-height: 180%;
      left: 0;
      margin: 0 5%;
      position: absolute;
      top: calc(100% + 20px);
      @include pc {
        margin: 0;
        padding-left: 1em;
        text-indent: -1em;
      }
      &::before {
        content: '※';
      }
      br {
        @include modal {
          @include pc {
            display: none;
          }
        }
      }
    }
    // アップグレードレンズ
    .p-lensTopUpgrade {
      display: grid;
      gap: 10px 6px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 30px;
      @include pc {
        gap: 20px 13px;
        grid-template-columns: repeat(4, 1fr);
        margin-left: auto;
        margin-right: auto;
        max-width: 1080px;
      }
    }
    .p-lensTopUpgradeSec {
      background: linear-gradient(124.08deg, #F9FCFF 5.07%, rgba(196, 211, 233, 0.0260417) 49.36%, rgba(215, 229, 245, 0.43) 66.96%);
      border-radius: 20px;
      margin-left: -5%;
      margin-right: -5%;
      padding: 40px 5% 60px;
      @include pc {
        background: linear-gradient(124.08deg, #F9FCFF 5.07%, rgba(196, 211, 233, 0.0260417) 36.66%, rgba(215, 229, 245, 0.43) 66.96%);
        border-radius: 20px;
        margin-left: 0;
        margin-right: 0;
        padding: 110px 40px 140px;
      }
      .p-lensTopLead {
        padding-left: 5%;
        padding-right: 5%;
        @include pc {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .p-lensTopUpgrade__item {
      background-color: $lensTop__color-white;
      border: 1px solid #e2eef9;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    .p-lensTopUpgrade__title {
      background-color: $lensTop__color-blue;
      color: $lensTop__color-white;
      @include font-noto_sans_medium;
      @include fz(14);
      line-height: 140%;
      padding: 10px 5px;
      text-align: center;
      @include pc {
        @include font-noto_sans_bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 5em;
      }
    }
    .p-lensTopUpgradeList {
      display: flex;
      flex: 1;
      flex-direction: column;
      //justify-content: space-between;
      padding: 0 7px 30px 8px;
      @include pc {
        padding: 0 6px 30px 7px;
      }
    }
    .p-lensTopUpgradeList__item {
      & + & {
        border-top: 1px solid $lensTop__color-grayBorder;
        padding-bottom: 20px;
        padding-top: 20px;
      }
      & + &:last-child {
        padding-bottom: 0;
      }
    }
    .p-lensTopUpgradeList__title {
      align-items: center;
      color: $lensTop__color-blue;
      display: flex;
      font-size: 14px;
      justify-content: center;
      min-height: 6em;
      line-height: 140%;
      text-align: center;
      @include pc {
        font-size: 20px;
        min-height: 5em;
      }
    }
    .p-lensTopUpgradeList__subTitle {
      @include font-noto_sans_medium;
      font-size: 14px;
      line-height: 120%;
      text-align: center;
      @include pc {
        @include font-noto_sans_bold;
      }
    }
    .p-lensTopUpgradeList__text {
      line-height: 160%;
      margin-top: 10px;
      @include pc {
        text-align: center;
      }
    }
    .p-lensTopUpgradeList__price {
      @include pc {
        text-align: center;
      }
      strong {
        @include font-Montserrat_semiBold;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        @include pc {
          font-size: 22px;
        }
      }
    }
    .p-lensTopUpgradeList__note {
      margin-top: 10px;
      @include pc {
        font-size: 13px;
        margin-top: 5px;
        text-align: center;
      }
    }
    .p-lensTopUpgrade__link {
      @extend %button__more;
      padding-left: 12px;
      width: auto;
      @include pc {
        padding-left: 24px;
        width: auto;
      }
      @media screen and (min-width: 1200px) {
        padding-left: 36px;
        width: 230px;
      }
      @media screen and (max-width: 375px) {
        border: none;
        font-size: 14px;
        height: auto;
        justify-content: center;
        padding: 0;
        text-align: center;
        text-decoration: underline;
        width: auto;
      }
      &::after {
        @media screen and (max-width: 375px) {
          display: none;
        }
      }
    }
    // 関連トピックス
    .p-lensTopTopics {
      display: flex;
      flex-direction: column;
      gap: 10px 0;
      @include pc {
        flex-direction: row;
        gap: 0 20px;
      }
    }
    .p-lensTopTopics__item {
      background-color: $lensTop__color-white;
      border-radius: 10px;
      padding: 40px 20px;
      @include pc {
        width: 100%;
      }
    }
    .p-lensTopTopics__link {
      display: block;
      text-decoration: none;
    }
    .p-lensTopTopics__title {
      font-size: 16px;
      line-height: 100%;
      margin-bottom: 30px;
      text-align: center;
      @include pc {
        font-size: 24px;
      }
    }
    .p-lensTopTopics__image {
      @include pc {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 83px;
      }
    }
    .p-lensTopTopics__more {
      @extend %button__more;
    }
    // 関連トピックス下オーダーメイド累進レンズ※20240522追加
    .p-lensTopBanner {
      background-color: $lensTop__color-white;
      border-radius: 10px;
      margin-top: 20px;
      padding: 40px;
    }
    .p-lensTopBanner__title {
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 1em;
      text-align: center;
      @include pc {
        font-size: 32px;
      }
    }
    .p-lensTopBanner__inner {
      display: grid;
      gap: 20px;
      @include pc {
        margin-inline: auto;
        max-width: 900px;
        width: 100%;
      }
      &:has(> :nth-child(2)) {
        @include pc {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
    .p-lensTopBanner__link {
      @include ov;
    }
    .p-lensTopBanner__image {
      width: 100%;
      height: auto;
    }
    .p-lensTopBanner__text {
      font-size: 14px;
      margin-top: 1em;
      @include pc {
        font-size: 16px;
        text-align: center;
      }
    }
    // オプション機能性レンズ
    .p-lensTopOptionSec {
      margin-top: 60px;
      @include pc {
        border-top: 1px solid #c3d2e0;
        margin-top: 70px;
        padding-top: 40px;
      }
      & + & {
        @include pc {
          margin-top: 120px;
        }
      }
      .p-lineup {
        @include sp {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .p-lensTopGraySec {
      background-color: #efefef;
      border-radius: 20px;
      margin-right: -5%;
      margin-left: -5%;
      padding: 80px 5%;
      @include pc {
        margin-left: 0;
        margin-right: 0;
        padding: 120px 40px;
      }
    }
    // レンズ交換
    .p-lensTopExchange {
      background-color: $lensTop__color-white;
      margin-top: 30px;
      padding: 30px 20px;
      @include pc {
        margin-top: 40px;
        padding: 45px 60px;
      }
    }
    // メガネレンズに関するQ&A
    .p-lensTopFaq {
    }
    .p-lensTopFaq__item {
      & + & {
        border-top: 1px solid $lensTop__color-grayBorder;
        margin-top: 20px;
        padding-top: 30px;
      }
    }
    .p-lensTopFaq__q,
    .p-lensTopFaq__a {
      font-size: 14px;
      line-height: 180%;
      padding-left: 30px;
      position: relative;
      @include pc {
        font-size: 16px;
        padding-left: 50px;
      }
      &::before {
        @include font-Montserrat_extralight;
        font-size: 20px;
        line-height: 1;
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        width: 1em;
        @include pc {
          font-size: 28px;
          margin-right: 26px;
        }
      }
      p {
        font: inherit;
        line-height: inherit;
      }
    }
    .p-lensTopFaq__q {
      color: $lensTop__color-red;
      @include pc {
        font-size: 18px;
      }
      &::before {
        content: 'Q';
      }
    }
    .p-lensTopFaq__a {
      margin-top: 10px;
      &::before {
        content: 'A';
      }
    }
    // 老眼（調節力の衰え）について
    .p-lensTopPresbyopia {
    }
    .p-lensTopPresbyopia__row {
      @include pc {
        display: flex;
      }
    }
    .p-lensTopPresbyopia__column {
      @include pc {
        width: 100%;
      }
      & + & {
        margin-top: 30px;
        @include pc {
          border-left: 1px solid #c3d2e0;
          border-top: none;
          margin-left: 30px;
          margin-top: 0;
          padding-left: 30px;
          padding-top: 0;
          width: auto;
        }
      }
    }
    .p-lensTopPresbyopia__head {
      border-top: 1px solid #c3d2e0;
      margin-bottom: 25px;
      padding: 2em 0 10px;
      @include pc {
        border-bottom: 1px solid #c3d2e0;
        padding: 0.75em 0;
      }
    }
    .p-lensTopPresbyopia__text {
      font-size: 14px;
      line-height: 180%;
    }
    .p-lensTopPresbyopia__body {
    }
    .p-lensTopPresbyopia__image {
      @include pc {
        max-width: 460px;
      }
    }
    .p-lensTopPresbyopia__figure {
      background-color: #e2eef9;
      border-radius: 10px;
      font-size: 14px;
      line-height: 160%;
      margin-top: 30px;
      padding: 70px 20px 20px 20px;
      position: relative;
      @include pc {
        margin-top: 15px;
        padding: 30px 40px 30px 80px;
      }
    }
    .p-lensTopPresbyopia__blockquote {
      &::before {
        content: '“';
        color: #c3d2e0;
        @include font-Montserrat_extralight;
        font-size: 160px;
        font-weight: 300;
        left: 10px;
        top: 0;
        line-height: 100%;
        position: absolute;
        @include pc {
          left: 10px;
          top: 15px;
        }
      }
    }
    .p-lensTopPresbyopia__caption {
      color: #666;
      font-size: 13px;
      margin-top: 1em;
    }
    .p-lensTopPresbyopia__cite {
      font-style: normal;
    }
    .p-lensTopPresbyopia__link {
      color: inherit;
    }
    // 累進レンズのグレードの違いについて
    .p-lensTopGrade {
    }
    .p-lensTopGrade__row {
      @include pc {
        display: flex;
        gap: 0 30px;
      }
    }
    .p-lensTopGrade__column {
      @include pc {
        width: 100%;
      }
      & + & {
        margin-top: 30px;
        @include pc {
          margin-top: 0;
        }
      }
    }
    .p-lensTopGrade__title {
      font-size: 22px;
      letter-spacing: 0.1em;
      line-height: 100%;
      text-align: center;
      padding: 1em;
      @include pc {
        border-top: 1px solid #c3d2e0;
      }
    }
    .p-lensTopGrade__image {
      @include pc {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 440px;
      }
      & + & {
        margin-top: 10px;
      }
    }
    .p-lensTopGrade__note {
      font-size: 14px;
      margin-top: 1em;
      text-align: center;
    }
    .p-lensTopGrade__content {
      border-top: 1px solid #c3d2e0;
      margin-top: 15px;
      padding-top: 30px;
      @include pc {
        padding-top: 50px;
      }
    }
    .p-lensTopGrade__text {
      font-size: 14px;
      line-height: 180%;
      @include pc {
        font-size: 16px;
        text-align: center;
      }
    }
    .p-lensTopGradeList {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 15px 0;
      @include pc {
        flex-direction: row;
        gap: 0 30px;
        justify-content: center;
      }
    }
    .p-lensTopGradeList__item {
      @include pc {
        max-width: 384px;
      }
    }
    .p-lensTopGradeList__link {
      @include ov;
    }
    .p-lensTopGradeList__image {
    }
  }
}
