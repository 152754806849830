@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

//Progress
.p-progress {
  position: relative;
  display: flex;
  justify-content: center;
  width: 90%;
  max-width: 400px;
  margin: 0 auto 30px;
  list-style: none;
  @include pc {
    width: 100%;
    max-width: 600px;
    margin-bottom: 50px;
  }
  & &__item {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    @include pc {
      width: 115px;
      padding: 0 20px;
    }
    &::after {
      position: absolute;
      top: 15px;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 2px;
      content: "";
      background: #bfbfbf;
      @include pc {
        top: 25px;
      }
    }
    &:first-child {
      padding-left: 0;
      @include pc {
        width: 95px;
      }
      &::after {
        left: 20px;
        width: calc(100% - 20px);
      }
    }
    &:last-child {
      padding-right: 0;
      @include pc {
        width: 95px;
      }
      &::after {
        right: 20px;
        width: calc(100% - 20px);
      }

      &.-short {
        @include sp {
          &::after {
            width: calc(100% - 40px);
          }
        }
        @include tb {
          &::after {
            width: calc(100% - 40px);
          }
        }
      }
    }
  }
  & &__number {
    top: 0;
    left: 18px;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    background: #bfbfbf;
    border-radius: 50%;
    @include font-Montserrat_semiBold;
    @include pc {
      width: 50px;
      height: 50px;
      font-size: 18px;
      line-height: 50px;
    }
  }
  & &__label {
    font-size: 12px;
    line-height: (30/23);
    color: #bfbfbf;
    text-align: center;
    white-space: nowrap;
    transform: scale(0.9);
    @include pc {
      font-size: 13px;
      transform: scale(1);
    }
  }
  .is-complete {
    .p-progress__number {
      background: $c-primary;
    }
    .p-progress__label {
      color: $c-primary;
    }
  }
}
