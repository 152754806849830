@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-storeList {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(#333, 0.2);
    border-radius: 4px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  .l-menu & {
    height: calc(100vh - 450px - 49px);
    @include pc {
      height: calc(100vh - 530px - 92px);
    }
    @media screen and (max-width: 767px) and (max-height: 800px) {
      height: auto;
      margin-bottom: 20px;
      overflow-y: auto;
    }
    @media screen and (min-width: 768px) and (max-height: 950px) {
      height: auto;
      margin-bottom: 20px;
      overflow-y: auto;
    }
    &__item {
      .storeInfo {
        &__title {
          padding: 0;
          a {
            color: $c-primary;
          }
          &::before,
          &::after {
            display: none;
          }
        }
      }
      &:hover {
        background: #fff;
      }
    }
  }
  &__item {
    padding: 20px 15px;
    background: #fff;
    @include pc {
      padding: 30px;
      .c-storeResult:not(.-confirm) & {
        transition: background 0.2s ease;
      }
      &:hover {
        .c-storeResult:not(.-confirm) & {
          background: #fff0f0;
        }
      }
    }
    .l-menu & {
      border: 1px solid $c-border1;
      &:not(:first-of-type) {
        margin-top: 10px;
        @include pc {
          margin-top: 20px;
        }
      }
    }
  }
  .storeInfo {
    &__title {
      @include fz(13);
      margin-bottom: 18px;
      line-height: (46 / 26);
      letter-spacing: 0.16em;
      .c-storeResult:not(.-confirm) & {
        position: relative;
        padding: 0 0 0 24px;
      }
      @include pc {
        @include fz(15);
        margin-bottom: 20px;
        line-height: (27 / 15);
        .c-storeResult:not(.-confirm) & {
          padding: 0 0 0 35px;
        }
      }
      .c-storeResult:not(.-confirm) & {
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 20px;
          height: 20px;
          content: "";
          background: #cddee1;
          background-size: 100% auto;
          border-radius: 50%;
          transition: background 0.2s;
          @include pc {
            width: 24px;
            height: 24px;
          }
        }
        @include pc {
          &:hover {
            cursor: pointer;
            &::before {
              background: $c-primary;
            }
          }
        }
        &::after {
          position: absolute;
          top: 6px;
          left: 4px;
          display: block;
          width: 12px;
          height: 10px;
          content: "";
          background: url(/img/common/icon_check_white.svg) no-repeat;
          background-size: 100% auto;
          @include pc {
            top: 7px;
            left: 4px;
            width: 16px;
            height: 12px;
          }
        }
        a {
          color: #000;
        }
      }
    }
    dd {
      display: flex;
      flex: 1 0 auto;
      &:not(.storeInfo__information) {
        justify-content: flex-start;
      }
      &:not(:first-of-type) {
        margin-top: 10px;
        @include pc {
          margin-top: 12px;
        }
      }
    }
    &__icon {
      position: relative;
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      @include pc {
        width: 22px;
        height: 22px;
        margin-right: 14px;
      }
      &::before {
        display: block;
        width: 18px;
        height: 18px;
        content: "";
        background: #cfcfcf;
        border-radius: 50%;
        @include pc {
          width: 22px;
          height: 22px;
        }
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        height: auto;
        transform: translate(-50%, -50%);
        &[src$="icon_pin.svg"] {
          width: 8px;
          @include pc {
            width: 10px;
          }
        }
        &[src$="icon_tel.svg"],
        &[src$="icon_time.svg"] {
          width: 9px;
          @include pc {
            width: 11px;
          }
        }
        &[src$="icon_calendar.svg"],
        &[src$="icon_detail.svg"] {
          width: 10px;
          @include pc {
            width: 12px;
          }
        }
      }
    }
    &__text {
      @include fz(11);
      line-height: (44 / 24);
      letter-spacing: 0.16em;
      @include pc {
        @include vw(13);
        line-height: (20 / 13);
      }
    }
    &__information {
      align-items: flex-end;
      justify-content: space-between;
      a {
        color: #333;
      }
    }
    &__storeNo {
      @include fz(11);
      line-height: (44 / 24);
      @include pc {
        line-height: (27 / 10);
        letter-spacing: 0.16em;
      }
    }
    &__store {
      display: flex;
      justify-content: flex-end;
      li {
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        justify-content: flex-start;
        &:not(:first-of-type) {
          @include pc {
            margin-left: 10px;
          }
        }
      }
      &Icon {
        flex-basis: 25px;
        width: 25px;
        max-width: 25px;
        margin-right: 5px;
        @include pc {
          flex-basis: 34px;
          width: 34px;
          max-width: 34px;
          margin-right: 7px;
        }
        &[src$="icon_parking.svg"] + .storeInfo__storeText {
          color: #f6ab00;
        }
        &[src$="icon_parking_blue.svg"] + .storeInfo__storeText {
          color: #39c3e0;
        }
        &[src$="icon_contact.svg"] + .storeInfo__storeText {
          color: #8fc43d;
        }

      }
      &Text {
        font-size: 10px;
        font-weight: 500;
        line-height: (20 / 18);
        letter-spacing: 0.08em;
        transform: scale(0.8);
        transform-origin: left;
      }
    }
  }
  .c-storeResult:not(.-confirm) & {
    height: 320px;
    @include pc {
      height: 440px;
    }
  }
  .c-storeResult & {
    input[type=radio] {
      display: none;
    }
    label {
      display: block;
      border: 4px solid #fff;
      transition: border 0.2s ease;
      @include pc {
        &:hover {
          border: 4px solid #fff0f0;
        }
      }
    }
    label:not(:first-of-type) {
      margin-top: 10px;
      @include pc {
        margin-top: 20px;
      }
    }
    input[type=radio]:checked + label {
      border: 4px solid $c-primary;
      .c-storeList__item {
        background: #fff0f0;
        .storeInfo {
          .storeInfo__title {
            &::before {
              background: $c-primary;
            }
          }
        }
      }
    }
  }
}


.c-storeList.-shop {
  @include pc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-storeList__item {
    border: 1px solid #bdbdbd;
    padding: 10px 15px;

    &:nth-child(n+2) {
      margin-top: 10px;
      @include pc {
        margin-top: 0;
      }
    }

    @include pc {
      padding: 15px 20px;
      width: calc(100%/2 - 10px);

      &:nth-child(n+3) {
        margin-top: 20px;
      }
    }

    .storeInfo {
      dd {
        align-items: center;
      }

      &__icon {
        transform: translateY(-1px);
        @include pc {
          transform: translateY(-3px);
        }
      }

      &__title {
        a {
          color: $c-primary;
          font-size: 13px;
          @include pc {
            font-size: 14px;
          }
        }
      } // &__title

      &__lineButton {
        margin-top: 5px;
        a {
          img {
            height: auto;
            width: 130px;
            @include pc {
              width: 95px;
            }
          }
        }
      } // &__lineButton
    }
  }
}
