@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-card {
  &Head {
    text-align: center;
    margin-bottom: 25px;
    @include pc{
      margin-bottom: 35px;
    }
    &__title {
      font-size: 20px;
      line-height: 1;
      letter-spacing: 0.04em;
      margin-bottom: 0;
      @include font-noto_sans_light;
      @include pc{
        font-size: 32px;
        letter-spacing: 0.14em;
      }
    }
    &__lead {
      font-size: 15px;
      line-height: (50 / 30);
      letter-spacing: 0.14em;
      @include font-noto_sans_light;
      margin-top: 14px;
      margin-bottom: 20px;
      @include pc{
        font-size: 16px;
        line-height: (54 / 32);
        margin-top: 8px;
        margin-bottom: 15px;
      }
      .text {
        display: block;
        margin-top: 22px;
        margin-bottom: 10px;
        letter-spacing: 0.06em;
        @include font-noto_sans_medium;
        @include pc{
          margin-top: 35px;
          margin-bottom: 9px;
          letter-spacing: 0.14em;
        }
      }
      @at-root (without: media rule) {
        .p-afterserviceWrap.perfect & {
          text-align: left;
          @include pc{
            text-align: center;
          }
          .text {
            text-align: center;
          }
        }
      }
    }
  }
  & > ul {
    @include pc{
      display: flex;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      flex: 1 0 auto;
      margin:0 -15px;
    }
    > li {
      border: 1px solid #bfbfbf;
      background: #fff;
      @include pc{
        flex-basis: calc(33.3% - 30px);
        width: calc(33.3% - 30px);
        min-width: calc(33.3% - 30px);
        margin-right: 15px;
        margin-left: 15px;
      }
      @include pcIE{
        flex-basis: calc(28% - 30px);
        width: calc(28% - 30px);
        min-width: calc(28% - 30px);
      }
      &:not(:first-of-type) {
        margin-top: 20px;
        @include pc{
          margin-top: 0;
        }
      }
      @include pc{
        &:not(:nth-of-type(-n + 3)) {
          margin-top: 30px;
        }
      }
    }
  }
}
