@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-404 {
  .c-sec__inner {
    max-width: calc(950px + 3.15% * 2);
  }
  background: #f0f5f6;
  .c-sec__inner {
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 1440px;
    background: #f0f5f6;
  }
  &__sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $c-border1;
    padding: 57px 23px;
    width: 100%;
    background-color: #fff;
    @include tb {
      min-height: 615px;
    }
  }
  &__image {
    margin: 0 auto;
    text-align: center;
    img {
      &[src$="icon_404_01.png"] {
        width: 86px;
        @include tb {
          width: 137px;
        }
      }
      &[src$="icon_404_02.png"] {
        width: 111px;
        @include tb {
          width: 163px;
        }
      }
    }
  }
  .c-sec__title {
    margin-top: 1.25em;
    margin-bottom: 1em;
    padding: 0;
  }
  .c-btnWrap {
    margin: 40px 0 0;
    padding-top: 40px;
    @include dotLine(#808080);
    @include tb {
      padding-top: 30px;
    }
    &::before {
      left: 50%;
      width: 102px;
      transform: translateX(-50%);
      @include tb {
        width: 548px;
      }
    }
  }
  .c-btn {
  }
}
