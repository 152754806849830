@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-list {
  @at-root {
    & {
      font-size: 14px;
      letter-spacing: .06em;
      line-height: 1.6;
    }
    .c-list__item {
      padding-left: 1em;
      position: relative;
      &::before {
        content: '・';
        position: absolute;
        left: 0;
        top: 0;
      }
      &-disc,
      &-discRed,
      &-discWhite {
        &::before {
          background-color: #333;
          border-radius: 50%;
          content: '';
          display: block;
          height: 12px;
          top: .4em;
          width: 12px;
        }
        & + & {
          margin-top: .55em;
        }
      }
      &-discRed {
        &::before {
          background-color: #e50213;
        }
      }
      &-discWhite {
        &::before {
          background-color: #fff;
        }
      }
    }
  }
}
