@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-pagetop {
  position: absolute;
  top: -35px;
  right: 24px;
  width: 70px;
  height: 70px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background: #e60012;
    border-radius: 50%;
    transition: all 0.25s;
    &::after {
      display: block;
      width: 11px;
      height: 17px;
      content: "";
      background: url(/img/common/icon_pagetop.svg) no-repeat;
      background-size: 100% auto;
      transform: rotate(-90deg);
    }
    &:hover {
      @include pc {
        transform: scale(1.3);
      }
    }
  }
  span {
    display: none;
  }
}
