@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-menuform {
  &__title {
    @include lhCrop(116 / 36);
    @include fz(18);
    font-weight: 500;
    line-height: (116 / 36);
    text-align: center;
  }
  &__radio {
    input[type="radio"],
    input[type="checkbox"] {
      display: none;
    }
    &Label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      color: #7f7f7f;
      text-align: center;
      letter-spacing: 0.16em;
      border: 1px solid $c-border1;
      border-radius: 18px;
      transition: background 0.2s ease;
      @include fz(11);
      @include pc {
        @include vw(13);
        height: 40px;
        cursor: pointer;
        border-radius: 20px;
      }
      @include IE {
        padding-top: 5px;
      }
      input[type="radio"]:checked + &,
      input[type="checkbox"]:checked + & {
        color: #fff;
        background-color: #98b1b6;
        border-color: #98b1b6;
      }
      .-stretch & {
        padding-right: 1em;
        padding-left: 1em;
        letter-spacing: .07em;
      }
    }
  }
  &__btn {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px;
    @include dotLineGray;
    .c-btn {
      &:first-of-type {
        flex-basis: 37%;
        width: 37%;
        max-width: 37%;
        @include pc {
          flex-basis: 32%;
          width: 32%;
          max-width: 32%;
        }
      }
      &:last-of-type {
        flex-basis: 60%;
        width: 60%;
        max-width: 60%;
        @include pc {
          flex-basis: 65%;
          width: 65%;
          max-width: 65%;
        }
      }
      &:only-child {
        margin: 0 auto;
      }
    }
  }
  &__dl {
    padding-bottom: 30px;
    &:not(:first-of-type) {
      padding-top: 30px;
      @include dotLineGray;
    }
    &.-stretch {
      margin-bottom: 0;
    }
  }
  &__dt {
    margin-bottom: 20px;
    @include pc {
      margin-bottom: 32px;
    }
    .title {
      display: block;
      @include fz(15);
      text-align: center;
      &::after {
        display: block;
        width: 90px;
        height: 2px;
        margin: 4px auto 0;
        content: "";
        background: #333;
        @include pc {
          width: 54px;
          margin-top: 5px;
        }
      }
    }
    &.type .title::after,
    &.gender .title::after {
      width: 74px;
    }
    .required {
      display: block;
      margin-top: 10px;
      line-height: 1;
      color: #7f7f7f;
      text-align: center;
      @include fz(11);
      @include pc {
        @include vw(10);
        margin-top: 16px;
      }
    }
  }
  &__dd {
    display: flex;
    flex: 1 0 50%;
    flex-wrap: wrap;
    justify-content: space-between;
    > span {
      display: block;
      width: 50%;
      max-width: 50%;
      &:not(:nth-of-type(-n + 2)) {
        margin-top: 20px;
        @include pc {
          margin-top: 25px;
        }
      }
    }
    &.-stretch {
      overflow-y: auto;
      margin-left: -5px;
      width: 100%;
      height: 100%;
      align-content: flex-start;
      @include tb {
        justify-content: flex-start;
        align-content: center;
        -ms-flex-align: center;
        margin-left: -10px;
      }
      span {
        flex: auto;
        align-items: stretch;
        margin: 0 0 5px 5px;
        max-width: none !important;
        width: auto !important;
        @include tb {
          flex: none;
          margin: 0 0 20px 10px;
          max-width: none !important;
          width: calc(25% - 10px) !important;
        }
      }
    }
    &.type {
      flex: 1 0 calc(50% - 2px);
      @include pc {
        flex: 1 0 calc(50% - 5px);
      }
      > span {
        width: calc(50% - 2px);
        max-width: calc(50% - 2px);
        @include pc {
          width: calc(50% - 5px);
          max-width: calc(50% - 5px);
        }
      }
    }
    &.gender,
    &.face {
      flex: 1 0 calc(50% - 5px);
      @include pc {
        flex: 1 0 calc(25% - 5px);
      }
      > span {
        width: calc(50% - 5px);
        max-width: calc(50% - 5px);
        @include pc {
          width: calc(25% - 5px);
          max-width: calc(25% - 5px);
          margin-top: 0;
        }
        &:not(:nth-of-type(-n + 2)) {
          margin-top: 10px;
          @include pc {
            margin-top: 0;
          }
        }
      }
    }
    &.gender {
      @include pc {
        margin-top: 30px;
      }
      label {
        text-transform: uppercase;
      }
    }
    &.face {
      margin-top: 20px;
      label {
        &::before {
          display: block;
          margin-right: 5px;
          vertical-align: middle;
          content: "";
          @include pc {
            margin-right: 8px;
          }
          @include IE {
            margin-top: -5px;
          }
        }
        &[for$="2"]::before {
          width: 13px;
          height: 13px;
          background-image: url("/img/common/icon_face_round.svg");
          background-size: contain;
          @at-root input[type="radio"]:checked + .c-menuform__radioLabel[for$="2"]::before {
            background-image: url("/img/common/icon_face_round_white.svg");
          }
          @include pc {
            width: 18px;
            height: 18px;
          }
          @supports (-ms-ime-align: auto) {
            & {
              background-size: 98% auto;
            }
          }
        }
        &[for$="4"]::before {
          width: 11px;
          height: 12px;
          background-image: url("/img/common/icon_face_square.svg");
          background-size: contain;
          @at-root input[type="radio"]:checked + .c-menuform__radioLabel[for$="4"]::before {
            background-image: url("/img/common/icon_face_square_white.svg");
          }
          @include pc {
            width: 15px;
            height: 16px;
          }
          @supports (-ms-ime-align: auto) {
            & {
              width: 13px;
              height: 16px;
              background-size: 100% 16px;
            }
          }
        }
        &[for$="3"]::before {
          width: 12px;
          height: 11px;
          background-image: url("/img/common/icon_face_triangular.svg");
          background-size: contain;
          @at-root input[type="radio"]:checked + .c-menuform__radioLabel[for$="3"]::before {
            background-image: url("/img/common/icon_face_triangular_white.svg");
          }
          @include pc {
            width: 18px;
            height: 15px;
          }
        }
        &[for$="1"]::before {
          width: 10px;
          height: 15px;
          background-image: url("/img/common/icon_face_length.svg");
          background-size: contain;
          @at-root input[type="radio"]:checked + .c-menuform__radioLabel[for$="1"]::before {
            background-image: url("/img/common/icon_face_length_white.svg");
          }
          @include pc {
            width: 14px;
            height: 18px;
          }
          @supports (-ms-ime-align: auto) {
            & {
              width: 15px;
              height: 20px;
              background-size: 15px 18px;
            }
          }
        }
      }
    }
    &.work,
    &.color,
    &.material {
      flex: 1 0 33.3%;
      > span {
        width: 33.3%;
        max-width: 33.3%;
        &:nth-of-type(-n + 3) {
          margin-top: 0;
        }
        &:nth-of-type(3n + 2):last-of-type {
          margin-right: calc(33.3% * 1 + ((100% - 33.3% * 3) / 2) * 1);
          background: red;
        }
      }
    }
    &.style,
    &.hobby {
      @include pc {
        flex: 1 0 33.3%;
        > span {
          width: 33.3%;
          max-width: 33.3%;
          &:nth-of-type(-n + 3) {
            margin-top: 0;
          }
          &:nth-of-type(3n + 2):last-of-type {
            margin-right: calc(33.3% * 1 + ((100% - 33.3% * 3) / 2) * 1);
          }
        }
      }
    }
    &.frame,
    &.frametype {
      img {
        width: 35px;
        height: auto;
        margin-right: 8px;
        vertical-align: middle;
        @include pc {
          width: 47px;
          margin-right: 12px;
        }
      }
    }
    &.color {
      label span {
        img {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 7px;
          vertical-align: bottom;
          @include pc {
            width: 24px;
            height: 24px;
            margin-right: 9px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  &__dlCol {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    padding: 30px 0;
    @include dotLineGray;
  }
  &__dtCol {
    flex-basis: 85px;
    width: 85px;
    max-width: 85px;
    @include fz(13);
    letter-spacing: 0.16em;
    @include pc {
      flex-basis: 90px;
      width: 90px;
      max-width: 90px;
    }
  }
  &__ddCol {
    flex-basis: calc(100% - 85px);
    width: calc(100% - 85px);
    max-width: calc(100% - 85px);
    @include pc {
      flex-basis: calc(100% - 90px);
      width: calc(100% - 90px);
      max-width: calc(100% - 90px);
    }
    .c-input {
      width: 100%;
      border: 1px solid $c-border1;
    }
  }

  // 個別
  &.store {
    .c-select2:not(:first-of-type) {
      margin-top: 10px;
      @include pc {
        margin-top: 20px;
      }
    }
    .c-menuform__contact {
      margin-top: 22px;
      margin-bottom: 20px;
      @include pc {
        margin-top: 27px;
        margin-bottom: 24px;
      }
    }
  }
  &.maestro {
    padding-top: 45px;
    padding-bottom: 30px;
    @include pc {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
}
