@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-container {
  .l-containerWrap {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    visibility: visible;
    content: "";
    background-color: rgba(#000, 0.75);
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.3s ease;
  }
  &.-show {
    overflow: hidden;
  }
  &:not(.-show) .l-containerWrap {
    //z-index: -1;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}
