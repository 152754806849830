@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-static {
  margin-top: 86px;
  word-break: break-all;
  @include pc {
    margin-top: 0;
  }
  .l-main &:first-child {
    @include sp {
      margin-top: 0;
    }
  }
  .c-sec {
    &__inner {
      @include pc {
        display: flex;
        justify-content: space-between;
        padding: 0 3.192%;
      }
    }
  }
  section {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__main {
    @include pc {
      width: 71.97%;
    }
  }
  &__sub {
    @include pc {
      width: 25.682%;
      padding-left: 2.2%;
    }
    &Nav {
      &__list {
        @include pc {
          margin-top: -15px;
        }
      }
      &__item {
        position: relative;
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 2px;
          content: "";
          background: url(/img/common/line_dot_black.svg) repeat-x;
          @include IE{
            background-size: 5px 2px;
          }
        }
        &.-current {
          border-bottom: 1px solid #e60012;
          &::after {
            content: none;
          }
          a {
            color: #e60012;
            &::after {
              background: url(/img/common/icon_arrow_red.svg) no-repeat;
              background-size: 100% auto;
            }
            &:hover {
              &::after {
                right: 2px !important;
              }
            }
          }
        }
        a {
          @include font-noto_sans;
          position: relative;
          display: block;
          padding: 11px 20px 11px 3px;
          font-size: 12px;
          line-height: (23/12);
          color: #000;
          text-decoration: none;
          letter-spacing: 0.16em;
          @include pc {
            font-size: 15px;
            padding: 13px 20px 13px 0;
            line-height: (23/15);
          }
          &::after {
            position: absolute;
            top: 50%;
            right: 4px;
            display: block;
            width: 9px;
            height: 14px;
            content: "";
            background: url(/img/common/icon_arrow.svg) no-repeat;
            background-size: 100% auto;
            transition: all 0.25s;
            transform: translateY(-50%);
            @include pc {
              right: 2px;
              width: 10px;
              height: 16px;
            }
          }
          &:hover {
            @include pc {
              color: #e60012;
            }
            &::after {
              @include pc {
                right: -3px;
              }
            }
          }
        }
      }
    }
  }
  &__title {
    &.-page {
      @include font-noto_sans;
      @include lhCrop(31/21);
      @include fz(21);
      padding-left: 16px;
      line-height: (31/21);
      text-align: left;
      letter-spacing: 0.14em;
      border-left: 2px solid #e60012;
      @include pc {
        @include lhCrop(42/32);
        font-size: 32px;
        margin-bottom: 60px;
        padding-left: 20px;
        line-height: (42/32);
        border-left: 4px solid #e60012;
      }
      &:first-of-type {
        margin-top: 0;
      }
    }
    &.-main {
      @include font-noto_sans;
      @include lhCrop(28/18);
      @include fz(18);
      position: relative;
      padding-top: 28px;
      margin-top: 60px;
      margin-bottom: 30px;
      line-height: (28/18);
      border-top: 2px solid #bfbfbf;
      letter-spacing: 0.14em;
      @include pc {
        @include lhCrop(34/22);
        font-size: 22px;
        padding-top: 50px;
        margin-top: 90px;
        margin-bottom: 40px;
        line-height: (34/22);
      }
      &::after {
        position: absolute;
        top: -2px;
        left: 0;
        display: block;
        width: 30%;
        content: "";
        border: 1px solid #e60012;
        @include pc {
          width: 9.575%;
        }
      }
    }
    &.-inside {
      @include font-noto_sans;
      @include lhCrop(25/15);
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 15px;
      line-height: (25/15);
      @include pc {
        @include lhCrop(29/18);
        margin-top: 60px;
        margin-bottom: 25px;
        font-size: 18px;
        line-height: (29/18);
      }
    }
    &.-sub {
      margin-top: 20px;
      margin-bottom: 10px;
      @include pc {
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
    &.-top {
      @include font-noto_sans;
      @include fz(21);
      padding-left: 16px;
      margin-top: 28px;
      line-height: (31/21);
      text-align: left;
      letter-spacing: 0.14em;
      border-left: 2px solid #e60012;
      @include pc {
        @include vw(32);
        padding-left: 20px;
        margin-top: 28px;
        line-height: (42/32);
        border-left: 4px solid #e60012;
      }
    }
  }
  p {
    @include lhCrop(23/14);
    line-height: (23/14);
  }
  img {
    width: 100%;
  }
  .c-btnWrap {
    justify-content: center;
    margin: 0 0 20px 0;
    @include pc {
      margin: 0 0 40px 0;
    }
    &.-columns {
      margin-bottom: 0;
      @include pc {
        margin-bottom: 40px;
      }
      .c-btn {
        margin: 0 auto 20px;
        @include pc {
          margin: 0;
        }
      }
    }
    &.p-address__btnWrap {
      margin: 30px 20px 0;
      @include pc {
        margin: 30px auto 0;
      }
    }
  }
  &__subTitle {
    display: block;
    margin-top: 4px;
    font-size: 13px;
    line-height: (23/13);
    letter-spacing: 0.14em;
    @include pc {
      font-size: 18px;
    }
  }
  &__read {
    @include lhCrop(23/13);
    font-size: 13px;
    line-height: (23/13);
    letter-spacing: 0.14em;
    @include pc {
      @include lhCrop(28/16);
      font-size: 16px;
      line-height: (28/16) !important;
    }
  }
  &__menu {
    &List {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 30px 0;
      @include pc {
        margin: 40px 0;
      }
      .p-static__title + & {
        margin-top: 0;
      }
    }
    &Item {
      width: calc(50% - 2%);
      margin-top: 11px;
      border: 1px solid #bfbfbf;
      transition: all 0.2s;
      @include pc {
        width: calc(50% - 0.8%);
        margin-top: 17px;
      }
      &:first-of-type,
      &:nth-of-type(2) {
        margin-top: 0;
      }
      a {
        @include font-noto_sans_medium;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 162px;
        line-height: (21/13);
        color: #000;
        text-align: center;
        text-decoration: none;
        letter-spacing: 0.14em;
        @include fz(13);
        @include pc {
          @include vw(18);
          min-height: 221px;
          line-height: (27/18);
        }
      }
      &:hover {
        @include pc {
          background: #e60012;
          border: 1px solid #e60012;
        }
        a {
          @include pc {
            color: #fff;
          }
        }
      }
    }
    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 60px;
      @include pc {
        min-height: 70px;
      }
      &.-w46 {
        img {
          max-width: 37px;
          @include pc {
            max-width: 46px;
          }
        }
      }
      &.-h50 {
        img {
          width: auto;
          max-height: 42px;
          @include pc {
            max-height: 50px;
          }
        }
      }
    }
    &Title {
      margin-top: 5px;
      @include pc {
        margin-top: 20px;
      }
    }
  }
  &__step {
    &Btn {
      &.c-btnWrap {
        margin: 27px 0 0 0;
        @include pc {
          margin: 75px 0 0 0;
        }
        &:last-child {
          margin-bottom: 40px;
        }
      }
      a {
        @include font-Montserrat;
        font-size: 18px;
        &::before {
          display: block;
          width: 29px;
          height: 23px;
          margin-right: 20px;
          content: "";
          background: url(/img/common/icon_cheack_white.svg) no-repeat;
          background-size: 100% auto;
        }
        &:hover {
          &::before {
            @include pc {
              background: url(/img/common/icon_cheack_red.svg) no-repeat;
              background-size: 100% auto;
            }
          }
        }
      }
    }
    &List {
      margin: 30px 0;
      @include pc {
        margin: 40px 0;
      }
      .p-static__list {
        margin-bottom: 0;
      }
      .p-static__title + & {
        margin-top: 0;
      }
    }
    &Item {
      padding: 27px 0;
      border-bottom: 1px solid #bfbfbf;
      @include pc {
        display: flex;
        justify-content: space-between;
        padding: 64px 0;
      }
      &:first-of-type {
        border-top: 1px solid #bfbfbf;
      }
    }
    &Body {
      margin-bottom: 30px;
      @include pc {
        width: calc(50% - 0.8%);
        padding-right: 2%;
        margin-bottom: 0;
      }
      &.-wFull {
        margin-bottom: 0;
        @include pc {
          width: 100%;
          padding-right: 0;
        }
      }
    }
    &Title {
      @include lhCrop(27/18);
      @include fz(15);
      margin-bottom: 20px;
      line-height: (23/15);
      letter-spacing: 0.14em;
      @include pc {
        @include vw(18);
        margin-bottom: 30px;
        line-height: (27/18);
      }
      span {
        @include font-Montserrat_semiBold;
        font-size: 13px;
        line-height: (18/13);
        color: #e60012;
        @include pc {
          font-size: 14px;
          line-height: (18/14);
        }
      }
    }
    &Thumb {
      @include pc {
        width: calc(50% - 0.8%);
      }
    }
  }
  &__list {
    &:not(:last-child) {
      margin-bottom: 15px;
      @include pc {
        margin-bottom: 20px;
      }
    }
    &.-disc {
      .p-static__listItem {
        position: relative;
        padding-left: 14px;
        margin-bottom: 1px;
        &::before {
          position: absolute;
          top: 0;
          left: -2px;
          content: "・";
        }
        .p-static__list.-num {
          .p-static__listItem::before {
            content: none;
          }
        }
      }
    }
    &.-num {
      padding-left: 1em;
      margin-left: 0.5em;
      .p-static__listItem {
        padding-left: 5px;
        margin-top: 5px;
        list-style: decimal;
        a {
          color: #333;
        }
      }
    }
    &.-number {
      counter-reset: number;
      .p-static__listItem {
        position: relative;
        padding-left: 31px;
        margin-top: 20px;
        @include pc {
          padding-left: 21px;
        }
        &:first-of-type {
          margin-top: 0;
        }
        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          display: inline-block;
          font-size: 15px;
          color: #e60012;
          content: counter(number) ".";
          counter-increment: number;
          @include font-Montserrat_semiBold;
          @include pc {
            font-size: 18px;
          }
        }
      }
    }
    &Title {
      @include font-noto_sans_medium;
      @include lhCrop(23/13);
      font-size: 13px;
      line-height: (23/13);
      letter-spacing: 0.14em;
      @include pc {
        @include lhCrop(27/14);
        font-size: 14px;
        line-height: (27/14);
      }
    }
    &Item {
      @include font-noto_sans;
      font-size: 13px;
      line-height: (23/13);
      letter-spacing: 0.16em;
      @include pc {
        font-size: 14px;
        line-height: (23/14);
      }
    }
  }
  &__link {
    margin-top: 36px;
    &.-right {
      display: flex;
      justify-content: flex-end;
    }
    &.-blank {
      a {
        position: relative;
        padding-right: 23px;
        &::after {
          position: absolute;
          top: 52%;
          right: 0;
          display: block;
          width: 15px;
          height: 16px;
          content: "";
          background: url(/img/common/icon_blank.svg) no-repeat;
          background-size: 100% auto;
          transform: translateY(-50%);
        }
      }
    }
    a {
      font-size: 13px;
      color: #000;
      text-decoration: none;
      letter-spacing: 0.16em;
      border-bottom: 1px solid #000;
    }
  }
  &__columns {
    margin: 30px 0;
    @include pc {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;
    }
  }
  &__column {
    margin-bottom: 30px;
    @include pc {
      width: calc(50% - 3%);
      margin-top: 0;
      margin-bottom: 0;
    }
    .p-static__title {
      &.-inside {
        margin-top: 0;
      }
    }
  }
  &__thumb {
    margin-bottom: 10px;
    @include pc {
      margin-bottom: 20px;
    }
  }
  &__caption {
    @include font-noto_sans;
    @include pc {
      @include lhCrop(23/13);
      font-size: 13px;
      line-height: (23/13);
    }
  }
  &__accordion {
    &Wrap {
      margin-top: 40px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      @include pc {
        margin-top: 76px;
      }
    }
    &Head {
      position: relative;
      padding: 25px 0 20px;
      @include pc {
        padding: 55px 0;
        cursor: pointer;
      }
      &::before {
        position: absolute;
        top: 20px;
        right: 6px;
        display: block;
        width: 10px;
        height: 6px;
        content: "";
        background: url(/img/common/icon_arrow03.svg) no-repeat;
        background-size: 100% auto;
        transition: all 0.25s;
        transform: translateX(-50%) rotate(180deg);
        @include pc {
          top: 47px;
          right: 20px;
        }
      }
      &::after {
        position: absolute;
        right: 6px;
        bottom: 20px;
        display: block;
        width: 10px;
        height: 6px;
        content: "";
        background: url(/img/common/icon_arrow03.svg) no-repeat;
        background-size: 100% auto;
        transition: all 0.25s;
        transform: translateX(-50%);
        @include pc {
          right: 20px;
          bottom: 47px;
        }
      }
      &.js-accordion:not(.-open) {
        & + .p-static__accordionBody {
          display: none;
        }
      }
      &.-open {
        &::before {
          transform: translateX(-50%) rotate(0deg);
        }
        &::after {
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }
    &Title {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.16em;
      @include pc {
        font-size: 18px;
      }
    }
    &Inner {
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    &Close {
      .js-accordion__close {
        position: relative;
        display: block;
        padding: 20px 0;
        margin-top: 20px;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 10px;
          height: 6px;
          content: "";
          background: url(/img/common/icon_arrow03.svg) no-repeat;
          background-size: 100% auto;
          transition: all 0.25s;
          transform: translateX(-50%) rotate(180deg);
        }
        @include pc {
          &:hover::before {
            top: 40%;
          }
        }
      }
    }
  }
  &__qa {
    &Wrap {
      margin: 30px 0;
      @include pc {
        margin: 40px 0;
      }
    }
    &Question {
      @include lhCrop(23/15);
      padding-top: 39px;
      margin-bottom: 12px;
      font-size: 15px;
      line-height: (23/15);
      letter-spacing: 0.14em;
      @include pc {
        padding-top: 64px;
        margin-bottom: 22px;
        font-size: 18px;
        line-height: (27/18);
      }
      &:first-of-type {
        border-top: 1px solid #bfbfbf;
      }
    }
    &Answer {
      @include lhCrop(23/13);
      position: relative;
      padding-bottom: 34px;
      padding-left: 20px;
      font-size: 13px;
      line-height: (23/13);
      letter-spacing: 0.16em;
      border-bottom: 1px solid #bfbfbf;
      @include pc {
        @include lhCrop(23/14);
        padding-bottom: 58px;
        padding-left: 30px;
        font-size: 14px;
        line-height: (23/14);
      }
      &::after {
        position: absolute;
        top: -2px;
        left: 0;
        font-size: 15px;
        color: #e60012;
        content: "A.";
        @include font-Montserrat_semiBold;
        @include pc {
          top: -4px;
          font-size: 18px;
        }
      }
    }
  }
  .p-form {
    padding: 0 20px 20px;
    @include pc {
      padding: 10px 40px;
    }
    &__dl {
      padding: 26px 0 31px;
      @include pc {
        padding: 30px 0;
      }
      &.-column {
        dd {
          width: 100%;
        }
      }
      dt {
        width: 34%;
        padding-bottom: 14px;
        @include pc {
          padding-bottom: 0;
        }
      }
      dd {
        width: 100%;
        @include pc {
          width: 66%;
        }
      }
    }
  }
  &__text {
    margin: 30px 0;
    @include pc {
      margin: 40px 0;
    }
    .p-static__title + & {
      margin-top: 0;
    }
  }
}
