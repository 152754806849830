// SNSリンク集
// 使用箇所：/feature/detail.html
.p-sns {
  @at-root {
    & {
      margin-block-start: 40px;
      @include tb {
        margin-block-start: 140px;
      }
    }
    .p-sns__title {
      align-items: center;
      display: flex;
      gap: 10px;
      font-size: 12px;
      justify-content: center;
      margin-block-end: 20px;
      text-align: center;
      @include tb {
        font-size: 16px;
      }
      &::before,
      &::after {
        background: no-repeat left top / contain;
        content: "";
        display: block;
        height: 10px;
        width: 6px;
        @include tb {
          height: 14px;
          width: 10px;
        }
      }
      &::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDggMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjQyOTY5IDEwLjMwODNMMS4yMDMxNCAwLjU1MzEiIHN0cm9rZT0iIzZGNkY2RiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=");
        @include tb {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNjU2MjUgMTIuODI0MkwwLjgwNTk2MiAwLjUyNTAyNCIgc3Ryb2tlPSIjNkY2RjZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==");
        }
      }
      &::after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDggMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02Ljc5Njg4IDAuNTUzMTAxTDAuNTcwMzI1IDEwLjMwODMiIHN0cm9rZT0iIzZGNkY2RiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=");
        @include tb {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMTk1MzEgMC41MjUwMjVMMS4zNDUwMiAxMi44MjQyIiBzdHJva2U9IiM2RjZGNkYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
        }
      }
    }
    .p-sns__list {
      display: flex;
      justify-content: center;
      gap: 30px;
      width: 100%;
      @include tb {
        gap: 40px;
      }
    }
    .p-sns__item {
    }
    .p-sns__link {
      @include ov;
      display: block;
    }
    .p-sns__icon {
      height: 40px;
      width: 40px;
      @include tb {
        height: 48px;
        width: 48px;
      }
    }
  }
}
