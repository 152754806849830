@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-product {
  width: calc(100% / 2 - 15px / 2);
  @include tb {
    width: calc(100% / 3 - 15px * 2 / 3);
    margin-left: 15px;
    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }
  @include pc {
    width: calc(100% / 4 - 30px * 3 / 4);
    margin-left: 30px;
  }
  .p-topRecommend & {
    @include tb {
      width: calc(100% / 2 - 15px / 2);
      margin-left: 0;
    }
    @include pc {
      width: calc(100% / 4 - 30px * 3 / 4);
      margin-left: 30px;
    }
    &Main {
      &__try {
        .c-text {
          @include tb {
            padding-left: 2em;
          }
          &::before {
            @include tb {
              width: 20px;
              height: 20px;
            }
          }
          &::after {
            @include tb {
              top: 6px;
              width: 12px;
              height: 8px;
            }
          }
        }
      }
    }
  }
  &:nth-child(3n + 1) {
    @include pc {
      margin-left: 30px;
    }
  }
  &:nth-child(4n + 1) {
    @include pc {
      margin-left: 0;
    }
  }
  &Link {
    display: block;
    height: 100%;
    border: 1px solid $c-border1;
    text-decoration: none;
  }
  &Main {
    position: relative;
    height: 100%;
    padding: 0;
    background-color: #fff;
    &__contents {
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
      flex-direction: column;
      overflow: hidden;
      justify-content: flex-start;
      flex: 1 0 auto;
      @include IE {
        display: block;
        position: relative;
        padding-bottom: 80px;
      }
      @include pcIE{
        padding-bottom: 110px;
      }
      }
    &__body,
    &__foot {
      display: flex;
      flex-wrap: wrap;
    }
    &__foot {
      margin-top: auto;
    }
    &__thumb {
      display: flex;
      align-items: center;
      min-height: 140px;
      margin-bottom: 3px;
      width: 100%;
      @include pc {
        min-height: 178px;
      }
      a {
        display: block;
        width: 100%;
        &:hover {
          img {
            @include pc {
              transform: scale(1.05);
            }
          }
        }
      }
      img {
        width: auto;
        @include pc {
          transition: all 0.2s;
        }
        @include IE {
          width: 100%;
          max-width: none;
        }
        :not(.p-topRecommend),
        :not(.l-pickup) {
          @include sp320{
            @at-root _:lang(x)::-ms-backdrop, & {
              width: 90%;
              height: auto;
            }
          }
          @include pc {
            @at-root _:lang(x)::-ms-backdrop, & {
              width: 80%;
            }
          }
          @at-root _:lang(x)::-ms-backdrop, & {
            max-height: none;
            max-width: none;
          }
        }
      }
    }
    &__pager.-list {
      display: none;
      @include tb {
        display: block;
        width: 100%;
        padding: 0 20px;
      }
      ul {
        display: flex;
        justify-content: flex-end;
        li {
          width: 7px;
          height: 7px;
          margin-left: 5px;
          overflow: hidden;
          border: 1px solid #000;
          border-radius: 50%;
          object,
          a {
            display: block;
            width: 100%;
            height: 100%;
            img {
              display: block;
              @include tb {
                display: none;
              }
            }
          }
          &:hover,
          &.-active {
            background-color: #000;
          }
        }
      }
    }
    &__body {
      display: block;
      width: 100%;
      padding: 0 10px;
      @include tb {
        padding-top: 10px;
      }
      @include pc {
        padding: 12px 20px 0;
      }
    }
    &__title,
    &__spec {
      width: 100%;
    }
    &__title {
      @include font-noto_sans();
      margin-bottom: 5px;
      font-size: 14px;
      line-height: (36/28);
      color: #000;
      letter-spacing: 0.16em;
      word-break: normal;
      @include pc {
        margin-bottom: 8px;
        font-size: 20px;
      }
    }
    &__spec {
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      font-size: 11.75px;
      color: #7f7f7f;
      font-weight: 200;
      @include pc {
        font-size: 12px;
      }
    }
    &__foot {
      width: 100%;
      padding: 10px;
      @include dotLineBlack;
      &::before {
        margin: 0 10px;
      }
      @include pc {
        padding: 20px;
        &::before {
          margin: 0 20px;
        }
      }
      @include IE {
        position: absolute;
        bottom: 0;
      }
    }
    &__priceYen {
      @include font-Montserrat();
      font-size: 16px;
      letter-spacing: 0.06em;
    }
    &__priceTax {
      margin-left: 0.25em;
      font-size: 11.75px;
      @include font-noto_sans_light();
      @include tb {
        font-size: 12px;
      }
    }
    &__try {
      width: 100%;
      margin-top: 1em;
      font-size: 10px;
      // text-align: right;
      @include tb {
        font-size: 14px;
      }
      object {
        display: inline-block;
      }
      .c-text {
        position: relative;
        display: inline-block;
        height: 16px;
        padding-left: 1.8em;
        color: #e60012;
        text-decoration: none;
        @include tb {
          height: 22px;
          padding-left: 1.5em;
          font-size: 14px;
        }
        @include pc {
          padding-left: 2em;
        }
        @include IE {
          padding-top: 3px;
        }
        .p-list & {
          @include tb {
            height: 23px;
            line-height: 25px;
          }
          @include pc {
            line-height: 28px;
          }
        }
        &::before,
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          content: "";
        }
        &::before {
          width: 16px;
          height: 16px;
          background-color: #ffe5e0;
          border-radius: 50%;
          @include pc {
            width: 23px;
            height: 23px;
          }
          .p-list & {
            @include tb {
              top: 3px;
            }
          }
        }
        &::after {
          top: 5px;
          left: 4px;
          width: 9.5px;
          height: 7px;
          background: url("/img/common/icon_check_white.svg") no-repeat left top;
          @include tb {
            top: 8px;
          }
          @include pc {
            top: 7px;
            left: 5px;
            width: 14px;
            height: 10px;
          }
          .p-list & {
            @include tb {
              top: 8px;
            }
            @include pc {
              top: 11px;
            }
          }
        }
        &.-checked {
          &::before {
            background-color: #e60012;
          }
        }
      }
    }
    &__pager {
      margin-top: 8px;
      @include pc {
        margin-top: 10px;
      }
      ul {
        display: flex;
        flex-wrap: nowrap;
        li {
          position: relative;
          display: block;
          width: 137px;
          height: 86px;
          overflow: hidden;
          a {
            display: block;
            width: inherit;
            height: inherit;
          }
          img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            margin: auto;
          }
        }
      }
    }
    .swiper-container {
      display: flex;
      width: 100%;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      @include pcIE {
        display: block;
        .p-top__recommend & {
          display: flex;
        }
      }
      @include pcIE {
        .l-pickup & {
          display: flex;
        }
      }
    }
    .swiper-pagination {
      display: none;
      bottom: 0;
      text-align: right;
      padding: 0 10px;
      @include pc {
        display: block;
        padding: 0 20px;
      }
      &-bullet {
        background: #fff;
        border: 1px solid #000;
        height: 8px;
        margin: 0 2px;
        opacity: 1;
        width: 8px;
        &-active {
          background: #000;
        }
      }
    }
  }
  &Item {
    @include sp {
      padding-bottom: 4px;
    }
  }
  &Item__columns {
    display: inherit;
  }
  &Item__column {
    width: inherit;
  }
  &Item__title {
    position: relative;
    width: 100%;
    padding-left: 20px;
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 10px;
      height: 16px;
      margin-right: 10px;
      content: "";
      background: url(/img/common/icon_arrow02.svg) no-repeat;
      background-size: 100% auto;
      transition: 0.3s ease-in-out;
      transform: translateY(-50%);
    }
  }
}
