@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.page-mydo {
  .l-contents {
    padding-top: 90px !important;
    @media screen and (max-width: 979px) {
      padding-top: 0 !important;
    }
  }
}

.p-mydo {
  @include font-noto_sans;
  overflow-x: hidden;

.container {
  max-width: 1320px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}


/*========================
hero
=========================*/

.hero {
  &-image {
    img {
      width: 100%;
    }
  }

  &-textbox {
    position: relative;
    color: #fff;
    padding: 130px 0 175px 0;
    background-color: #1C4295;
    z-index: 1;
    @media screen and (max-width: 980px) {
      padding: 60px 0 120px 0;
    }
  }

  &-lead {
    font-size: 20px;
    text-align: center;
    @media screen and (max-width: 980px) {
      font-size: 14px;
    }
  }

  &-heading {
    font-size: 90px;
    font-weight: 350;
    margin: 15px 0 0 0;
    text-align: center;
    line-height: 1.1;
    @media screen and (max-width: 980px) {
      font-size: 36px;
      margin: 10px 0 0 0;
    }

    small {
      display: block;
      margin: 12px 0 0 0;
      font-size: 40px;
      @media screen and (max-width: 980px) {
        font-size: 16px;
        margin: 6px 0 0 0;
      }
    }
  }

  &-text {
    font-size: 16px;
    margin: 55px 0 0 0;
    text-align: center;
    line-height: 1.8;
    @media screen and (max-width: 980px) {
      font-size: 13px;
      margin: 30px 0 0 0;
    }
  }

  &-logo {
    position: absolute;
    max-width: 1160px;
    width: 100%;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    z-index: -1;
    @media screen and (max-width: 980px) {
      bottom: 80px;
    }
  }
}

/*========================
secret
=========================*/

.secret {
  position: relative;
  background-color: #EDF3F5;
  z-index: 1;

  .container {
    position: relative;
    padding-top: 220px;
    padding-bottom: 220px;
    @media screen and (max-width: 980px) {
      padding-top: 160px;
      padding-bottom: 160px;
    }
  }

  &-heading {
    font-size: 90px;
    font-weight: 350;
    text-align: center;
    line-height: 1.2;
    @media screen and (max-width: 768px) {
      font-size: 36px;
    }

    small {
      display: block;
      font-size: 18px;
      margin: 0 0 15px 0;
      line-height: 1.8;
      @media screen and (max-width: 980px) {
        font-size: 14px;
      }
    }
  }

  &-text {
    margin: 60px 0 0 0;
    font-size: 14px;
    text-align: center;
    line-height: 1.8;
    @media screen and (max-width: 980px) {
      margin: 30px 0 0 0;
    }
    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }

  &-specs {
    display: flex;
    justify-content: center;
    max-width: 1230px;
    width: 100%;
    margin: 60px 0 0 0;
    opacity: 0;
    transition: opacity 1000ms;
    @media screen and (max-width: 980px) {
      margin: 40px 0 0 0;
    }

    &.is-anim-over {
      opacity: 1;
    }
  }

  &-image {
    position: absolute;
    top: -210px;
    right: 80px;
    max-width: 440px;
    width: 100%;
    @media screen and (max-width: 980px) {
      max-width: 334px;
      top: -140px;
      right: 25px;
    }

    &.is-anim-over {
      img {
        animation: shakeAnim 1.25s ease-in-out 3;
      }
    }

  }

  &-method {
    max-width: 1065px;
    width: 100%;
    margin: 120px auto 0 auto;
    @media screen and (max-width: 768px) {
      margin: 75px auto 0 auto;
    }

    &-title {
      position: relative;
      text-align: center;
      font-size: 36px;
      font-weight: 350;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }

      span {
        position: relative;
        display: inline-block;
        padding: 0 40px;
        background-color: #EDF3F5;
        z-index: 1;
        @media screen and (max-width: 768px) {
          padding: 0 15px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #333333;
        z-index: -1;
      }
    }

    &-list {
      display: flex;
      justify-content: center;
      margin: 80px -42px 0 -42px;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin: 20px 0 0 0;
      }

      &.is-anim-over {
        .secret-method-item {
          opacity: 1;
        }
      }
    }

    &-item {
      width: 33.333%;
      padding: 0 42px;
      opacity: 0;
      transition: opacity 500ms;
      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 20px;
      }

      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          transition-delay: #{$i * 150}ms;
        }
      }
    }

    &-num {
      text-align: center;
      @include font-Montserrat;
      font-size: 54px;
      margin: 0 0 20px 0;
      @media screen and (max-width: 768px) {
        font-size: 40px;

      }
    }

    &-image {
      @media screen and (max-width: 768px) {
        text-align: center;
      }
    }

    &-text {
      font-size: 16px;
      text-align: center;
      margin: 30px 0 0 0;
      line-height: 1.8;
    }
  }
}

/*========================
movie
=========================*/

.movie {
  position: relative;
  background-color: #1C4295;
  padding: 330px 0 215px 0;
  z-index: 1;
  @media screen and (max-width: 768px) {
    padding: 160px 0 150px 0;
  }

  .container {
    position: relative;
  }

  &-heading {
    position: absolute;
    top: -220px;
    left: 0;
    @include font-Montserrat;
    font-size: 120px;
    color: #fff;
    line-height: 1.15;
    z-index: 1;
    pointer-events: none;
    @media screen and (max-width: 980px) {
      left: 35px;
    }
    @media screen and (max-width: 768px) {
      font-size: 40px;
      top: -70px;
      left: 15px;
    }
  }

  &-link {
    position: relative;
    max-width: 980px;
    width: 100%;
    color: #fff;
    margin-left: auto;
    text-align: right;
    @media screen and (max-width: 980px) {
      max-width: 780px;
    }
    @media screen and (max-width: 768px) {
      padding: 0 0 0 20px;
    }

    &:hover {
      svg circle {
        fill: rgb(230, 0, 18);
        stroke: rgb(230, 0, 18);
      }
    }

    &-play {
      position: absolute;
      width: 144px;
      height: 144px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      @media screen and (max-width: 980px) {
        width: 115px;
        height: 115px;
      }
      @media screen and (max-width: 768px) {
        width: 70px;
        height: 70px;
      }

      svg circle {
        stroke: #fff;
        fill: rgba(255, 255, 255, 0);
        transition: fill 250ms, stroke 250ms;
      }
    }

    small {
      font-size: 16px;
    }
  }

  &-bg-top {
    position: absolute;
    top: -110px;
    right: 120px;
    max-width: 400px;
    width: 100%;
    z-index: 1;
    @media screen and (max-width: 980px) {
      right: 40px;
    }
    @media screen and (max-width: 768px) {
      max-width: 170px;
      right: 10px;
      top: -30px;
    }

    span {
      position: absolute;
      top: 53px;
      right: 2px;
      font-size: 20px;
      transform: rotate(-15deg);
      white-space: nowrap;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        top: -10px;
        right: -10px;
      }
    }

    &.is-anim-over {
      img {
        animation: shakeAnim 1.25s ease-in-out 3;
      }
    }
  }

  &-bg-bottom {
    position: absolute;
    bottom: -160px;
    left: 85px;
    max-width: 285px;
    width: 100%;
    z-index: 1;
    @media screen and (max-width: 980px) {
      left: 50px;
    }
    @media screen and (max-width: 768px) {
      max-width: 130px;
      left: 7px;
      bottom: -40px;
    }

    span {
      position: absolute;
      bottom: 90px;
      left: 40px;
      font-size: 20px;
      transform: rotate(-15deg);
      white-space: nowrap;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        color: #fff;
        bottom: 115px;
        left: 105px;
      }
    }

    &.is-anim-over {
      img {
        animation: shakeAnim 1.25s ease-in-out 3;
      }
    }
  }
}

/*========================
stats
=========================*/

.stats {
  padding: 180px 0 30px 0;
  @media screen and (max-width: 768px) {
    padding: 80px 0 30px 0;
  }

  .container {
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &.is-anim-over {
      .stats-heading strong {
        &::after {
          transform: scaleX(1);
        }
      }

      .stats-imagebox {
        opacity: 1;
      }
    }
  }

  &-textbox {
    max-width: 712px;
    width: 100%;
    @media screen and (max-width: 980px) {
      max-width: 475px;
    }
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  &-lead {
    font-size: 18px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      text-align: center;
    }
  }

  &-heading {
    font-size: 28px;
    font-weight: 300;
    margin: 25px 0 35px 0;
    @media screen and (max-width: 768px) {
      font-size: 20px;
      margin: 10px 0 25px 0;
      text-align: center;
    }

    span {
      display: block;
      line-height: 1.8;
    }

    strong {
      position: relative;
      display: inline-block;
      font-size: 90px;
      line-height: 1;
      margin: 25px 0 0 0;
      @media screen and (max-width: 980px) {
        font-size: 60px;
      }
      @media screen and (max-width: 768px) {
        font-size: 40px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        height: 6px;
        background-color: #1C4295;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 750ms ease-out;
      }
    }
  }

  &-note {
    font-size: 18px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      text-align: center;
    }
  }

  &-text {
    font-size: 16px;
    margin: 40px 0 0 0;
    line-height: 1.8;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin: 30px 0 0 0;
    }
  }

  &-imagebox {
    width: calc(100% - 712px);
    text-align: center;
    opacity: 0;
    transition: opacity 750ms 500ms;
    @media screen and (max-width: 980px) {
      width: calc(100% - 475px);
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 40px 0 0 0;
    }
  }
}

/*========================
mydo-type
=========================*/

.mydo-type {
  padding: 130px 0 0 0;
  @media screen and (max-width: 980px) {
    padding: 100px 0 0 0;
  }
  @media screen and (max-width: 768px) {
    padding: 60px 0 0 0;
  }

  &-head {
    position: relative;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 0 130px 0;
    @media screen and (max-width: 768px) {
      margin: 0 0 90px 0;
    }

    .container {
      position: relative;
      padding-bottom: 37.5%;
      @media screen and (max-width: 768px) {
        padding-bottom: 80%;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -60px;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: #fff;
        z-index: -1;
      }
    }

    &.-sports {
      background-image: url('../img/mydo/type_sports_kv.jpg');
      @media screen and (max-width: 768px) {
        background-image: url('../img/mydo/type_sports_kv_sp.jpg');
      }
    }

      &.-regular {
        background-image: url('../img/mydo/type_regular_kv.jpg');

        @media screen and (max-width: 768px) {
          background-image: url('../img/mydo/type_regular_kv_sp.jpg');
        }
      }

      &.-junior {
        background-image: url('../img/mydo/type_junior_kv.jpg');

        @media screen and (max-width: 768px) {
          background-image: url('../img/mydo/type_junior_kv_sp.jpg');
        }
      }
  }

  &-heading {
    @include font-Montserrat;
    font-size: 120px;
    position: absolute;
    bottom: -100px;
    left: 20px;
    color: #fff;
    line-height: 1;
    text-transform: uppercase;
    mix-blend-mode: difference;
    @media screen and (max-width: 768px) {
      font-size: 60px;
      bottom: -65px;
    }
  }

  &-body {
    padding: 50px 0;
    @media screen and (max-width: 768px) {
      padding: 0 0 80px 0;
    }
  }

  &-block {

    + .mydo-type-block {
      margin-top: 100px;
    }
  }

  &-wrap {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &-info {
    width: 48%;
    padding-right: 15px;
    @media screen and (max-width: 768px) {
      width: 100%;
      padding-right: 0;
    }
  }

  &-products {
    width: 52%;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 40px 0 0 0;
    }

    .c-product-wrap {
      display: flex;
      @media screen and (max-width: 980px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .c-product {
      position: relative;
      max-width: 320px;
      width: 100%;
      padding-bottom: 0;
      border: 1px solid #BAD3D6;
      @media screen and (max-width: 768px) {
        padding-bottom: 70px;
      }

      &:nth-child(2) {
        @media screen and (max-width: 980px) {
          margin: 20px 0 0 0 !important;
        }
      }

      .c-productLink {
        border: none;
      }
      .c-productMain__try .c-text::before {
        @include pc {
          width: 22px;
          height: 22px;
        }
      }

      .c-productMain__try .c-text::after {
        @media screen and (max-width: 980px) {
          top: 5px;
        }
        @media screen and (max-width: 768px) {
          left: 3.5px;
        }
      }

      .mydo-product-btn {
        display: none;
        position: absolute;
        bottom: 20px;
        left: 50%;
        max-width: 295px;
        width: 100%;
        height: 44px;
        padding: 0 20px;
        margin: 20px 0 0 0;
        transform: translateX(-50%);
        @media screen and (max-width: 768px) {
          display: block;
        }

        a {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          background-color: #E50213;
          font-size: 13px;
          border-radius: 44px;
          text-decoration: none;
        }
      }
    }

    .c-product.-new {
      position: relative;
      overflow: hidden
    }

    .c-product.-new::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 80px;
      height: 80px;
      padding: 5px;
      font-family: Montserrat, "Noto Sans Japanese Regular", sans-serif;
      font-size: 10px;
      font-weight: 500;
      color: #fff;
      content: "NEW!";
      background-color: #c60218;
      transform: translate(-50%, -50%) rotate(-45deg)
    }

    .c-product p.caption {
      height: auto;
      margin-bottom: 10px;
    }

    @media screen and (min-width: 580px) {
      .c-product.-new::before {
        width: 95px;
        height: 95px;
        font-size: 12px
      }
    }
  }

  &-specs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 20px 0;
  }

  &-new {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 28px;
    font-size: 16px;
    margin: 0 14px 0 0;
    border-radius: 14px;
    color: #fff;
    background-color: #E50213;
  }

  &-model {
    font-size: 18px;
    font-weight: 700;
  }

  &-color {
    display: flex;
    align-items: center;
    font-size: 14px;
    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin: 10px 0 0 0;
    }

    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #000;
      padding: 0 10px;
      margin: 0 10px 0 20px;
      height: 28px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 5px;
      @media screen and (max-width: 768px) {
        margin: 0 10px 8px 0;
      }
    }
  }

  &-text {
    font-size: 20px;
    line-height: 1.8;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  &-image {
    margin: 15px 0 0 0;
    text-align: center;
  }
}

/*========================
voice
=========================*/

.voice {
  padding: 220px 0 60px 0;
  @media screen and (max-width: 980px) {
    padding: 160px 0 20px 0;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0 15px 0;
  }

  &-heading {
    position: relative;
    text-align: center;
    font-size: 36px;
    font-weight: 350;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }

    span {
      position: relative;
      display: inline-block;
      padding: 0 40px;
      background-color: #fff;
      z-index: 1;
      @media screen and (max-width: 768px) {
        padding: 0 15px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #333333;
      z-index: -1;
    }
  }

  &-list {
    margin: 60px 0 0 0;
    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    margin: 60px 0 0 0;
    @media screen and (max-width: 768px) {
      margin: 50px 0 0 0;
      flex-direction: column;
    }

    &.-reverse {
      flex-direction: row-reverse;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .voice-textbox {
        padding: 0 60px 0 0;
        @media screen and (max-width: 768px) {
          padding: 20px 0 0 0;
        }
      }
    }
  }

  &-image {
    width: 50%;
    text-align: center;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &-textbox {
    max-width: 540px;
    width: 100%;
    padding: 0 0 0 60px;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      padding: 20px 0 0 0;
    }
  }

  &-title {
    font-size: 32px;
    font-weight: 350;
    line-height: 1.45;
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  &-text {
    font-size: 16px;
    margin: 20px 0 0 0;
    line-height: 1.8;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin: 15px 0 0 0;
    }
  }
}

@keyframes shakeAnim {
  0% {transform: translate(0px, 0px) rotateZ(0deg)}
  2% {transform: translate(2px, 2px) rotateZ(1deg)}
  4% {transform: translate(0px, 2px) rotateZ(0deg)}
  6% {transform: translate(2px, 0px) rotateZ(-1deg)}
  8% {transform: translate(0px, 0px) rotateZ(0deg)}
  10% {transform: translate(0px, 0px) rotateZ(0deg)}
  12% {transform: translate(2px, 2px) rotateZ(1deg)}
  14% {transform: translate(0px, 2px) rotateZ(0deg)}
  16% {transform: translate(2px, 0px) rotateZ(-1deg)}
  18% {transform: translate(0px, 0px) rotateZ(0deg)}
  20% {transform: translate(0px, 0px) rotateZ(0deg)}
  22% {transform: translate(2px, 2px) rotateZ(1deg)}
  24% {transform: translate(0px, 2px) rotateZ(0deg)}
  26% {transform: translate(2px, 0px) rotateZ(-1deg)}
  28% {transform: translate(0px, 0px) rotateZ(0deg)}
  30% {transform: translate(0px, 0px) rotateZ(0deg)}
  32% {transform: translate(2px, 2px) rotateZ(1deg)}
  34% {transform: translate(0px, 2px) rotateZ(0deg)}
  36% {transform: translate(2px, 0px) rotateZ(-1deg)}
  40% {transform: translate(0px, 0px) rotateZ(0deg)}
  100% {transform: translate(0px, 0px) rotateZ(0deg)}
}

}

.p-brandMovie,
.p-mydoMovie {
  &__modal {
    position:absolute;
    width:100%;
    padding-top:56.25%;
    top: 50%;
    transform: translateY(calc(-50% - 70px));
    @include tb{
      position:relative;
      top: 0;
      transform: translateY(0);
    }
    iframe {
      position:absolute;
      top:0;
      right:0;
      width:100%;
      height:100%;
    }
  }
}
