@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-input {
  width: 100%;
  input[type="text"],
  input[type="tel"],
  input[type="number"],
  input[type="password"],
  input[type="email"] {
    width: 100%;
    min-height: 48px;
    padding: 6px 15px;
    background-color: #fff;
    border-radius: 0;
    letter-spacing: .16em;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    @include fz(13);
    @include font-noto_sans_regular;
    @include pc {
      min-height: 50px;
      padding: 8px 25px 4px;
    }
    @media all and (-ms-high-contrast: none) {
      min-height: 50px;
      padding: 11px 25px 4px;
    }
  }
  input:-internal-autofill-selected {
    background-color: #fff;
  }
  input::placeholder {
    font-size: 13px;
    color: #87909c;
    letter-spacing: 0.16em;
  }
  .l-menu & {
    input[type="text"] {
      padding: 10px 20px;
      @include IE{
        padding: 12px 20px 8px;
      }
    }
  }
}
// 低いタイプ
.p-form__dl.-low {
  .c-input {
    width: 100%;
    input[type="text"],
    input[type="tel"],
    input[type="number"],
    input[type="password"],
    input[type="email"] {
      @include pc {
        min-height: 35px;
        padding: 4px 25px 4px;
      }
    }
  }
}