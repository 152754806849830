@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.c-radio {
  input[type="radio"] {
    margin-right: 8px;
  }
  label {
    display: inline-block;
    padding: 9px 0;
    margin-right: 14px;
    line-height: 20px;
    vertical-align: middle;
    &:hover {
      cursor: pointer;
    }
  }
}
.c-radio.p-default.p-round.-inline {
  input[type="radio"] {
    display: none;
  }
  input[type="radio"]:checked + label::after {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 3;
    display: block !important;
    width: 10px;
    height: 10px;
    content: "";
    background: #e60012;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  label {
    padding-left: 25px;
    position:relative;
    margin-right: 10px;
    @include IE {
      padding-top: 15px;
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 10px;
      z-index: 2;
      display: block;
      width: 20px;
      height: 20px;
      content: "";
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
// @include sp {
//   .c-radio.p-default.p-round.-inline > label {
//     > input[type="radio"] {
//       display: inline-block !important;
//       display: none;
//       width: 1px;
//       height: 10px;
//       margin-right: 19px;
//       &:checked::before {
//         position: absolute;
//         top: 50%;
//         left: 10px;
//         z-index: 3;
//         display: block !important;
//         width: 10px;
//         height: 10px;
//         content: "";
//         background: #e60012;
//         border-radius: 50%;
//         -webkit-transform: translate(-50%, -50%);
//         transform: translate(-50%, -50%);
//       }
//     }
//     position: relative;
//     padding-left: 5px;
//     &::before {
//       position: absolute;
//       top: 50%;
//       left: 10px;
//       z-index: 2;
//       display: block;
//       width: 20px;
//       height: 20px;
//       content: "";
//       background-color: #fff;
//       border: 1px solid #ddd;
//       border-radius: 50%;
//       -webkit-transform: translate(-50%, -50%);
//       transform: translate(-50%, -50%);
//     }
//   }
// }

// radioカスタマイズ Windowsで表示できない
//.c-radio {
//  input[type=radio] {
//    margin-left: -46px;
//    position: absolute;
//    height: 20px;
//  }
//  label {
//    padding-left: 28px;
//    margin-right: 14px;
//    line-height: 20px;
//    position: relative;
//    overflow: hidden;
//    display: inline-block;
//    vertical-align: middle;
//    min-height: 20px;
//    padding: 9px 0;
//    &:hover {
//      cursor: pointer;
//    }
//  }
//  input[type=radio] {
//    &::before {
//      content: "";
//      display: block;
//      position: absolute;
//      top: 50%;
//      left: 28px;
//      width: 20px;
//      height: 20px;
//      border: 1px solid #dddddd;
//      border-radius: 50%;
//      background: #fff;
//      transform: translate(-50%, -50%);
//    }
//  }
//  input[type=radio]:checked {
//    &::after {
//      content: "";
//      display: block;
//      position: absolute;
//      top: 50%;
//      left: 28px;
//      width: 10px;
//      height: 10px;
//      background: #e60012;
//      border-radius: 50%;
//      transform: translate(-50%, -50%);
//    }
//  }
//}
