@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.l-aboutMaestro {
  padding: 36px 20px 33px;
  background: $c-base;
  margin-top: 45px;
  @include pc {
    padding: 80px 60px;
    margin-top: 120px;
  }
  &__body {
    max-width: 600px;
    margin: 0 auto;
    @include pc {
      position: relative;
      max-width: 1320px;
      padding-bottom: 14px;
    }
    &::before,
    &::after {
      display: none;
      @include pc {
        position: absolute;
        display: block;
        width: 70px;
        height: 70px;
        content: "";
      }
    }
    &::before {
      @include pc {
        top: 0;
        left: 0;
        background: url("/img/common/bg_ deco_about_left.svg") no-repeat 50%;
        background-size: contain;
      }
    }
    &::after {
      @include pc {
        right: 0;
        bottom: 0;
        background: url("/img/common/bg_ deco_about_right.svg") no-repeat 50%;
        background-size: contain;
      }
    }
  }
  &__text {
    position: relative;
    padding-top: 47px;
    line-height: (38 / 26);
    text-align: center;
    @include lhCrop(38 / 26);
    @include fz(13);
    @include pc {
      @include lhCrop(30 / 18);
      padding-top: 80px;
      line-height: (30 / 18);
      @include vw(18);
    }
    &::before {
      position: absolute;
      top: 0;
      left: 50%;
      width: 33px;
      height: 31px;
      content: "";
      background: url(/img/common/icon_maestro_black.svg) no-repeat;
      background-size: contain;
      transform: translateX(-50%);
      @include pc {
        top: 26px;
        width: 34px;
        height: 32px;
      }
    }
  }
  &__foot {
    width: 78%;
    max-width: 536px;
    margin: 15px auto 0;
    @include pc {
      width: 330px;
      margin-top: 25px;
    }
  }
  &__btn {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 45px;
      font-size: 13px;
      color: #000;
      text-decoration: none;
      letter-spacing: 0.16em;
      cursor: pointer;
      background: #fff;
      border: 1px solid $c-border1;
      border-radius: 35px;
      transition: all 0.2s;
      @include font-noto_sans;
      @include pc {
        height: 46px;
        margin-top: 8px;
      }
      &:hover {
        @include pc {
          color: #fff;
          background: $c-border1;
        }
      }
    }
  }
}
