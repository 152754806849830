@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-visionpc {
  $visionpc-blue: #26c1ed;
  @include font-noto_sans;

  &-contents {
    &.l-contents:not(.-top):not(.p-cardUrl) {
      padding-top: 0;
      @include pc {
        padding-top: 125px;
      }
    }
  }

  &-inner {
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }


/*-----------
.hero
-------------*/
.hero {
  &-tagline {
    display: flex;
    align-items: center;
    height: 70px;
    @include pc {
      height: 60px;
    }

    h1 {
      margin: 0;
      @include font-Montserrat_medium;
      font-size: 13px;
      font-weight: 300;
      @include pc {
        font-size: 14px;
      }
    }

    .p-visionpc-inner {
      display: flex;
      @include pc {
        justify-content: flex-end;
      }
    }

    span {
      display: block;
      font-weight: 500;
      color: $visionpc-blue;
      @include pc {
        display: inline;
      }
    }

    small {
      font-size: inherit;
      @include font-noto_sans;
    }
  }

  &-img {
    &-pc {
      display: none;
      @include pc {
        display: block;
      }
    }

    &-sp {
      display: block;
      @include pc {
        display: none;
      }
    }

    img {
      width: 100%;
    }
  }
}

/*-----------
.banner
-------------*/
.banner {
  background-color: $visionpc-blue;
  color: #fff;
  padding: 35px 0 40px 0;

  &-content {
    text-align: center;
  }

  &-title {
    font-size: 28px;
    @include font-Montserrat_regular;
    margin: 0;
    color: #fff;
    @include pc {
      font-size: 32px;
    }

    small {
      display: block;
      font-size: 13px;
      @include pc {
        display: inline;
        font-size: 18px;
      }

      &.-smaller {
        font-size: 12px;
      }
    }
  }

  &-subtitle {
    font-size: 21px;
    font-weight: 300;
    margin-bottom: 15px;
    @include pc {
      font-size: 32px;
    }

    .sp-hide {
      display: none;
      @include pc {
        display: inline;
      }
    }

    .price {
      @include font-Montserrat_medium;
    }

    .tax {
      font-size: 12px;
      vertical-align: middle;
    }

    .name {
      @include font-Montserrat_medium;
      font-size: 14px;
      @include pc {
        font-size: 32px;
      }
    }
  }

  &-text {
    font-size: 15px;
    margin: 15px 0 0 0;
    text-align: left;
    @include pc {
      font-size: 16px;
      text-align: center;
    }
  }

  &-highlight {
    width: 100%;
    display: inline-block;
    color: $visionpc-blue;
    font-size: 15px;
    background-color: #fff;
    padding: 10px 20px;
    margin: 15px 0 0 0;
    border-radius: 30px;
    @include pc {
      width: auto;
      font-size: 18px;
      padding: 10px 30px;
    }

    span {
      font-weight: 500;
    }

    small {
      font-size: 12px;
      @include pc {
        font-size: 13px;
      }
    }
  }

  &-footnote {
    font-size: 13px;
    margin: 15px 0 0 0;
    text-align: left;
    @include pc {
      font-size: 14px;
      text-align: center;
    }
  }

  &-btnwrap {
    margin: 15px 0 0 0;
  }

  &-btn {
    a {
      border: 1px solid #fff;

      &:hover {
        background-color: #333;
        border: 1px solid #333;
      }
    }
  }
}

/*-----------
.tabs
-------------*/
.tabs {
  padding: 30px 0;
  background-color: #EFF3F6;

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include pc {
      margin: 0 -15px;
    }
  }

  &-item {
    text-align: center;
    margin: 10px 15px;
    @include pc {
      text-align: left;
    }

    .c-btn {
      width: 420px;

      a {
        height: 48px !important;
        font-size: 13px;
      }

      small {
        font-size: 13px;
        @include pc {
          font-size: 12px;
        }
      }
    }

    .tabs-btn {
      a {
        padding: 3px 40px 3px 20px;
        flex-direction: column;
      }
    }
  }
}

/*-----------
.infoblock
-------------*/

.infoblock {
  display: flex;
  flex-direction: column;
  @include pc {
    flex-direction: row;
    min-height: 580px;
  }

  &.-reverse {
    @include pc {
      flex-direction: row-reverse;
    }
  }

  &-imagebox {
    position: relative;
    width: 100%;
    height: 230px;
    @include pc {
      width: 50%;
      height: auto;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top 20% center;
    @include pc {
      background-position: center;
    }
  }

  &-textbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    @include pc {
      width: 50%;
    }

    &.-lightblue {
      background-color: #e9f9fd;
    }

    &.-blue {
      background-color: #e5f2fc;
    }

    &.-orange {
      background-color: #faf5f1;
    }
  }

  &-textcontent {
    max-width: 480px;
    width: 100%;
  }

  &-title {
    font-size: 18px;
    font-weight: 200;
    text-align: left;
    margin: 0 0 10px 0;
    line-height: 1.25;
    @include pc {
      font-size: 32px;
    }

    strong {
      display: block;
      font-size: 32px;
      font-weight: 200;
      @include pc {
        font-size: 42px;
      }
    }

    &.-lightblue {
      color: #26c1ed;
    }

    &.-blue {
      color: #0484dd;
    }

    &.-orange {
      color: #d78e40;
    }
  }

  &-text {
    font-weight: 300;
  }

  &-circlewrap {
    display: flex;
    justify-content: center;
    margin: 30px -5px 0 -5px;
    @include pc {
      margin: 30px 0 0 0;
    }
  }

  &-circle {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 165px;
    margin: 0 5px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    line-height: 1;
    @include pc {
      width: 215px;
      height: 215px;
      margin: 0 10px;
    }

    &-top {
      font-size: 14px;
      @include pc {
        font-size: 18px;
      }
    }

    &-mid,
    &-bottom {
      @include font-Montserrat_regular;
    }

    &-mid {
      display: block;
      margin: 5px 0;
      font-size: 38px;
      @include pc {
        font-size: 52px;
      }

      small {
        font-size: 16px;
        @include pc {
          font-size: 22px;
        }
      }
    }

    &-bottom {
      font-size: 24px;
      @include pc {
        font-size: 32px;
      }
    }

    &-note {
      position: absolute;
      left: 50%;
      bottom: 15px;
      font-size: 9px;
      font-weight: 300;
      color: #000;
      transform: translateX(-50%);
      @include pc {
        font-size: 12px;
        bottom: 20px;
      }
    }

    &.-lightblue {
      color: $visionpc-blue;
      border: 1px solid $visionpc-blue;
    }

    &.-blue {
      color: #0484dd;
      border: 1px solid #0484dd;
    }

    &.-orange {
      color: #d78e40;
      border: 1px solid #d78e40;
    }

    &.-purple {
      color: #8541a1;
      border: 1px solid #8541a1;
    }
  }
}

/*-----------
.p-visionpc-products
-------------*/

.p-visionpc-products {
  padding: 40px 0 0 0;
  @include pc {
    padding: 80px 0;
  }

  .c-product {
    .c-productLink {
      &:hover {
        .c-productMain__foot::after {
          transform: translateX(5px);
        }
      }
    }

    .c-productMain {
      &__thumb {
        min-height: 0;
      }

      &__foot {
        &::after {
          content: "";
          position: absolute;
          bottom: 15px;
          right: 10px;
          width: 9px;
          height: 15px;
          background-image: url(/img/common/icon_arrow.svg);
          background-repeat: no-repeat;
          background-position: center;
          transition: transform 200ms;
          @include pc {
            bottom: 20px;
            right: 20px;
          }
        }
      }
    }
  }

  .c-btnWrap {
    margin: 10px auto 50px auto;
  }
}

/*-----------
.p-visionpc-btn
-------------*/

.p-visionpc-btn {
  width: 420px;
  height: 55px;

  a {
    height: 55px;
    text-align: center;
    @include pc {
      font-size: 14px;
    }
  }

  &.-lightblue {
    a {
      color: #fff;
      background-color: #26c1ed;
      border: 1px solid #26c1ed;

      &::after {
        background: url(/img/common/icon_blank_white.svg) no-repeat;
        background-size: 100% auto;
      }

      &:hover {
        color: #26c1ed;
        background-color: #fff;

        &::after {
          background: url(/img/common/icon_blank_lightblue.svg) no-repeat;
        }
      }
    }
  }

  &.-blue {
    a {
      color: #fff;
      background-color: #0484dd;
      border: 1px solid #0484dd;

      &::after {
        background: url(/img/common/icon_blank_white.svg) no-repeat;
        background-size: 100% auto;
      }

      &:hover {
        color: #0484dd;
        background-color: #fff;

        &::after {
          background: url(/img/common/icon_blank_blue.svg) no-repeat;
        }
      }
    }
  }

  &.-orange {
    a {
      color: #fff;
      background-color: #d78e40;
      border: 1px solid #d78e40;

      &::after {
        background: url(/img/common/icon_blank_white.svg) no-repeat;
        background-size: 100% auto;
      }

      &:hover {
        color: #d78e40;
        background-color: #fff;

        &::after {
          background: url(/img/common/icon_blank_orange.svg) no-repeat;
        }
      }
    }
  }
}

/*-----------
.p-visionpc-footnote
-------------*/

.p-visionpc-footnote {
  $this: &;
  font-size: 11px;
  margin: 20px 0 0 0;
  @include pc {
    font-size: 12px;
    margin: 30px 0 0 0;
  }

  &-item {
    + #{$this}-item {
      margin: 20px 0 0 0;
    }

    &.-centered {
      text-align: left;
      @include pc {
        text-align: center;
      }
    }
  }
}

/*-----------
.feature
-------------*/

.feature {
  padding: 40px 0;
  background-color: #eef3f6;
  @include pc {
    padding: 80px 0;
  }

  &-heading {
    text-align: left;
    font-size: 21px;
    font-weight: 200;
    line-height: 1.3;
    @include pc {
      font-size: 32px;
      text-align: center;
    }

    span {
      font-size: 18px;
      @include pc {
        font-size: 32px;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    @include pc {
      margin: 15px -15px 0 -15px;
      flex-wrap: nowrap;
    }
  }

  &-item {
    width: 100%;
    padding: 17px;
    margin: 20px 0 0 0;
    background-color: #fff;
    border: 1px solid #bfbfbf;
    @include pc {
      width: calc(33.333% - 30px);
      padding: 35px;
      margin: 0 15px;
    }

    &:first-child {
      margin: 0;
      @include pc {
        margin: 0 15px;
      }
    }
  }

  &-textbox {
    margin: 25px 0 0 0;
    font-size: 14px;
    font-weight: 300;
  }
}

/*-----------
.custom
-------------*/

.custom {
  padding: 40px 0;
  background-color: #eef3f6;
  @include pc {
    padding: 80px 0;
  }

  &-heading {
    font-size: 21px;
    font-weight: 200;
    text-align: left;
    margin: 0 0 15px 0;
    color: $visionpc-blue;
    @include pc {
      font-size: 32px;
      text-align: center;
    }

    span {
      font-size: 18px;
      @include pc {
        font-size: 32px;
      }
    }
  }

  &-lead {
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    margin: 15px 0 0 0;
    @include pc {
      margin: 20px 0 0 0;
      text-align: center;
    }
  }

  &-subheading {
    margin: 35px 0 17px 0;
    font-size: 18px;
    color: $visionpc-blue;
    text-align: left;
    font-weight: 500;
    @include pc {
      font-size: 22px;
      text-align: center;
      margin: 50px 0 25px 0;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px 13px;
    @include pc {
      display: flex;
      gap: 30px;
    }

    .card-item {
      display: flex;
      flex-direction: column;
      padding: 0 10px 15px 10px;
      background-color: #fff;
      border: 1px solid #cdcdcd;
      @include pc {
        width: 100%;
        padding: 0 20px 20px 20px;
      }

      .title {
        color: $c-primary;
        font-size: 15px;
        text-align: left;
        margin: 30px 0 0 0;
        @include pc {
          font-size: 16px;
        }
      }

      .image {
        margin: 0 -10px;
        border-bottom: 1px solid #cdcdcd;
        @include pc {
          margin: 0 -20px;
        }

        img {
          width: 100%;
        }
      }

      .lead {
        font-size: 13px;
        margin-top: 10px;
      }

      .caption {
        font-size: 13px;
        margin: 10px 0;
        @include font-noto_sans_light;
      }

      .price {
        background: #eef3f6;
        border-radius: 20px;
        font-size: 13px;
        margin-top: auto;
        text-align: center;
        padding: 4px 0;
        @include font-noto_sans_light;
      }
    }
  }

  &-other {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &-item {
      max-width: 415px;
      width: 100%;
      margin: 15px 0 0 0;
      border: 1px solid #cdcdcd;
      @include pc {
        margin: 0 15px;
      }

      a {
        text-decoration: none;

        &:hover {
          .custom-other-link {
            text-decoration: none;
          }
        }
      }
    }

    &-image {
      img {
        width: 100%;
      }
    }

    &-textbox {
      padding: 20px;
      background-color: #fff;
      @include pc {
        padding: 20px 30px 30px 30px;
      }
    }

    &-title {
      color: $c-primary;
      font-size: 15px;
      font-weight: 500;
      @include pc {
        font-size: 18px;
      }
    }

    &-text {
      font-size: 13px;
      font-weight: 300;
      @include pc {
        font-size: 14px;
      }
    }

    &-link {
      margin: 10px 0 0 0;
      text-align: right;
      text-decoration: underline;
    }
  }
}

/*-----------
.bluelight
-------------*/
.bluelight {
  padding: 0 0 50px 0;

  &-head {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
    margin: 0 0 50px 0;
    padding: 0 20px;
    text-align: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include pc {
      height: 300px;
      text-align: center;
    }
  }

  &-heading {
    color: #fff;
    font-size: 28px;
    font-weight: 200;
    margin: 0;
    text-shadow: 0px 0px 15px rgba(39,152,183,0.5), 0px 0px 10px rgba(39,152,183,0.5), 0px 0px 7px rgba(39,152,183,0.75), 0px 0px 5px rgba(39,152,183,1);
    @include pc {
      font-size: 42px;
    }
  }

  &-body {
    max-width: 875px;
    width: 100%;
    margin: 0 auto;

    .p-static {
      &__title {
        &.-main {
          font-size: 18px;
          font-weight: 500;
          padding-top: 30px;
          margin-top: 40px;
          margin-bottom: 10px;
          border-top: none;
          @include pc {
            padding-top: 40px;
          }

          &::before {
            position: absolute;
            top: 3px;
            left: 0;
            width: 195px;
            height: 2px;
            background-color: #bfbfbf;
          }

          &::after {
            width: 100px;
          }
        }
      }
    }
  }

  &-footnote {
    padding: 20px 0 0 0;
    margin: 40px 0 0 0;
    border-top: 2px solid #bfbfbf;
    @include pc {
      padding: 50px 0 0 0;
      margin: 50px 0 0 0;
    }
  }
}

/*-----------
.c-staticReserveWrap
-------------*/

.c-staticReserveWrap {
  padding: 40px 20px 80px 20px;
  @include pc {
    padding: 55px 0 120px 0;
  }

  &-last {
    padding: 40px 20px 0 20px;
    @include pc {
      padding: 55px 0 0 0;
    }
  }
}

}
