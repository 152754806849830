@charset "utf-8";

/*
@include sp{}
@include tb{}
@include pc{}
@include wide{}
@include spOnly{}
@include tbOnly{}
@include pcOnly{}
*/

.p-howtoReserve {
  padding: 40px 20px;
  max-width: 1440px;
  margin: auto;
  @include pc {
    padding: 50px 40px;
  }
  .image {
    text-align: center;
    img {
      display: block;
      margin: auto;
    }
  } // .image
  .text {
    font-size: 16px;
    text-align: center;
    @include pc {
      font-size: 20px;
    }
  }
  .step__list {
    border: 1px solid #e94609;
    display: block;
    font-size: 14px;
    padding: 20px;
    position: relative;
    margin: 60px auto;
    @include pc {
      font-size: 16px;
      margin: 90px auto;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      border-top: 30px solid #e94609;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent;
      height: 30px;
      width: 30px;
      @include pc {
        border-top: 40px solid #e94609;
        border-right: 40px solid transparent;
        border-left: 40px solid transparent;
        height: 40px;
        width: 40px;
      }
    }
    &::before {
      top: -40px;
      @include pc {
        top: -60px;
      }
    }
    &::after {
      bottom: -40px;
      @include pc {
        bottom: -60px;
      }
    }
    @include pc {
      padding: 40px;
      width: 50%;
      max-width: 700px;
    }
  }
  .c-btn {
    width: 100%;
    margin: 40px auto;
    @include pc {
      width: calc(50% - 3.29%);
    }
  }
} // .p-howtoReserve
